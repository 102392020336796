import React from "react";
import Banner from "../../../components/Banner";
import RightImageSection from "../../../components/RightImageSection";
import Ecommerce from "../Retail/Ecommerce";
import PSASoftware from "../ProfessionalServices/PSASoftware";
import CrmSoftware from "../RealEstate/CrmSoftware";
import WhyChooseWhiteBackground from "../../../components/WhyChooseWhiteBackground";
import FooterBanner from "../../../components/Footer/FooterBanner";
const shippingData = [
  {
    id: 1,
    title: "Multi-channel orders shipping",
  },
  {
    id: 2,
    title: "Shipping rate comparison",
  },
  {
    id: 3,
    title: "Smart rate comparison",
  },
  {
    id: 4,
    title: "Smart shipping bulk-actions",
  },
  {
    id: 5,
    title: "Shipping workflow automation",
  },
  {
    id: 6,
    title: "Customs shipping collaterals",
  },
  {
    id: 7,
    title: "Transparent shipment tracking",
  },
];
const PSAData = [
  {
    id: 1,
    title: "Centralized order management",
  },
  {
    id: 2,
    title: "Multiple payment gateways supported and fraud check",
  },
  {
    id: 3,
    title: "Inventory management",
  },
  {
    id: 4,
    title: "Fulfillment points integration",
  },
  {
    id: 5,
    title: "Shipping services integration",
  },
  {
    id: 6,
    title: "Customer management",
  },
  {
    id: 7,
    title: "Shopping platform integration",
  },
];
const Why = [
  {
    id: "1",
    title: "Timely project delivery",
    desc: "We guarantee that our processes are on point and your project will be delivered on time.",
  },
  {
    id: "2",
    title: "Flexible cooperation",
    desc: "Choose a time or a dedicated team model of your choice. We are ready to work with you.",
  },
  {
    id: "3",
    title: "Dedicated team",
    desc: "If you are looking for a dedicated development team, our model is perfect for you. Hire a team from us to take full control of your resources",
  },
  {
    id: "4",
    title: "Experienced team & qualified developers",
    desc: "Our developers pass a series of interviews both internally and externally and technical leads before employment. Each of them has a professional development plan.",
  },
  {
    id: "5",
    title: "Transparent pricing",
    desc: "Approximate or total cost of the project is clear. All prices depend on the number of functionalities to be implemented and business analysis conducted",
  },
  {
    id: "6",
    title: "Quality first",
    desc: "We onboard the highest code quality and functionality of the product keeping in mind of all quality assurance standards throughout the software development life cycle (SDLC).",
  },
];
export default function Transportation() {
  return (
    <>
      <Banner
        img="/images/industries/transportation/transportation-and-logistics-banner.jpeg"
        title="Transportation & Logistics"
        desc="Leverage intelligent applications powered by advanced technology to move product and services with efficiency and at a lower cost to stay ahead of your competitors in this highly competitive transportation and distribution service industry."
      />
      <RightImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FTransportation%2FtransportationOverview.jpg?alt=media&token=8a662d3f-db33-477f-8f2d-0197f3f3decc"
        title="Transportation and logistics applications development"
        desc="A hyper-connected ecosystem has resulted from the introduction of new-age technology and cloud-based solutions. Meanwhile, new digital entrants and shifting customer expectations are putting incumbent firms and old management systems under jeopardy. Organizations are embracing IT solutions for transportation and logistics management, digitizing the entire value chain, to withstand the rising demand and to maximize return on investment (RoI). This entails new ways of thinking about how to add value and a complete rethink of the digital services offering. At Innorik, our solutions powered by innovation are designed to handle business problems in real time."
      />
      <h2 className="lg:text-[32px] lg:w-3/5 mx-auto md:text-[28px] text-[20px] text-center py-[25px] md:py-[40px] font-medium lg:py-[50px]">
        Our custom transportation and logistics applications{" "}
      </h2>
      <Ecommerce
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2Fwarehouse%201.png?alt=media&token=e160d6ad-dfdb-49e4-b615-e62cd3db0689"
        title="Warehousing and distribution management system"
        desc="Our warehouse management software is tailored to suit every company needs, simplifying its inventory flow independent of the product and market, gain insight on warehousing activities, customer demand, route planning, schedule and handle stock processing & duties delegated to the employees, and smooth inventory management processes."
      />
      <PSASoftware
        head="Order management software (OMS)"
        desc="Our OMS allows a couple of essential features which is the core of every transportation industry. They are:"
        data={PSAData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FTransportation%2ForderManagement.jpg?alt=media&token=42d082d7-34a0-4d3d-bd43-c3ff87b69366"
      />
      <CrmSoftware
        head="Shipping Software"
        desc="Our shipping software integrates the following functionalities:"
        data={shippingData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2Fshipping%20software.png?alt=media&token=1fa5690c-83be-4063-b768-f96b216635ec"
      />
      <WhyChooseWhiteBackground data={Why} />
      <FooterBanner title="Send us your requirements" link="/contact-us" />
    </>
  );
}
