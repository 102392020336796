import React from "react";

export default function PartnerCompanies(props) {
  const Data = props.data;
  const dataList = Data.map((list, index) => {
    return (
      <div
        key={index}
        className="max-w-[1400px] px-[28px] md:px-[40px] lg:px-[85px] mx-auto  conatiner flex flex-col justify-evenly w-full md:flex-row"
      >
        <div className="m-auto md:ml-0 lg:ml-0 w-full md:w-1/2 lg:w-1/3">
          <div className="m-auto md:ml-0 lg:ml-0 w-9/12 sm:w-7/12 md:w-9/12 lg:w-11/12">
            <img
              className="m-auto md:ml-0 lg:ml-0 mt-10 mb-5 w-2/5 md:mt-0 md:mb-0  md:w-1/2 lg:w-5/12"
              src={list.img}
              alt="parthership with microsoft"
            />
          </div>
        </div>
        <div className="m-auto w-full md:w-2/3 lg:w-2/3">
          <p className="text-justify text-lg m-auto py-5 w-11/12 sm:py-10 sm:text-left">
            {list.desc}
          </p>
        </div>
      </div>
    );
  });

  return (
    <>
      {dataList}
      {/* <div className='invisible lg:visible h-0 lg:h-[340px] text-left grid lg:grid-cols-2 content-center justify-center  pr-[85px]'>
        <div className=' flex content-center justify-center lg:h-[42px] lg:w-[196px] pl-[85px]'>
            <img className='' src={props.img}></img>
        </div>
        <p className='text-center pt-[16px] pl-[29px] pr-[29px] text-[14px] text-[#222222]'>{props.desc}</p>
    </div>
      <div className='visible lg:invisible  pt-[50px] pb-[50px]'>
        <div className='flex content-center justify-center '>
            <img src={props.img}></img>
        </div>
        <p className='text-center pt-[16px] pl-[29px] pr-[29px] text-[14px] text-[#222222]'>{props.desc}</p>
    </div> */}
    </>
  );
}
