import React from "react";
import { Link } from "react-router-dom";
import BorderButton from "../../components/Buttons/BorderButton";

export default function Empowering() {
  return (
    <div className="">
      <div className="">
        <div className="bg-[#3066EA] ">
          <div
            className="elementor-shape elementor-shape-top fill-white"
            data-negative="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 50"
              preserveAspectRatio="none"
            >
              <path
                className="elementor-shape-fill"
                d="M500,24.9L0,0.1V0h1000v0.1L500,24.9z"
              ></path>
            </svg>
          </div>
          <div className="grid lg:grid-cols-12 py-[50px] max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px] ">
            <div className="col-span-6">
              <h1 className="text-[36px] text-white">
                Developing talents for our digital transformation
              </h1>
              <p className="text-white text-[18px] pt-[15px]">
                The structure of organizations and jobs are changing as new
                technologies are evolving like artificial intelligence, data
                analytics, deep learning, quantum computing, machine learning
                and other digital capabilities………
              </p>
              {/* <p className="text-white w-4/5 text-[18px] pt-[15px]">
                These are overwhelming the workplace, therefore, it is
                imperative that we constantly train our associates to develop
                their capabilities. Also, it is Innorik’s mission to empower new
                talents to take up new tech positions. This is why we are
                excited to invest in new talents like you to be fully equipped
                for the jobs of the future.
              </p> */}
              <Link to="/talent-world-wide">
                <BorderButton button="Explore" />
              </Link>

              {/* <p className="w-3/5 text-white pt-[40px]">View More </p> */}
            </div>
            <div className="col-span-6 md:w-4/5 mx-auto">
              {/* <video
                src="/videos/empower.webm"
                loop
                muted
                autoPlay
                controls=""
              ></video> */}

              {/* <img
                src="/images/empowerTransparent.gif"
                alt="butterfly transformation"
              /> */}
              <img
                src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fhome%2Fbutterfly_life_cycle.svg?alt=media&token=3c072745-bd2e-4c92-a403-b466dfce69fb"
                alt="butterfly transformation"
              />
            </div>
          </div>
        </div>
        <div
          className="elementor-shape elementor-shape-top fill-[#3066EA]"
          data-negative="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 50"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M500,24.9L0,0.1V0h1000v0.1L500,24.9z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
}
