import React from "react";
import Banner from "../../../components/Banner";
import FooterBanner from "../../../components/Footer/FooterBanner";
// import RightImageSection from "../../../components/RightImageSection";
// import WhyChoose from "../../../components/WhyChoose";
import WhyChooseNumber from "../../../components/WhyChooseNumber";
import Overview from "../../Technologies/LatestThinking/ArtificialIntellegence/Overview";
import AssesmentSoftware from "../Education/AssesmentSoftware";
import Lms from "../Education/Lms";
import CrmSoftware from "./CrmSoftware";
import TrackingSoftware from "./TrackingSoftware";
const ProjectData = [
  {
    id: 1,
    title: "Intutive dashboard",
  },
  {
    id: 2,
    title: "Mobile app",
  },
  {
    id: 3,
    title: "Automated approval Process",
  },
  {
    id: 4,
    title: "Digital receipt capture",
  },
  {
    id: 5,
    title: "Mileage tracker",
  },
  {
    id: 5,
    title: "Real-time reporting and analytics",
  },
];
const Data2 = [
  {
    id: 1,
    title: "User-friendly format",
  },
  {
    id: 2,
    title: "Neatly formatted property details",
  },
  {
    id: 3,
    title: "Full Control Over Property Listing",
  },
  {
    id: 4,
    title: "Google Maps Search",
  },
  {
    id: 5,
    title: "Unlimited Listings & Pricing",
  },
  {
    id: 6,
    title: "3D view of Properties",
  },
  {
    id: 7,
    title: "Multi – Agent Module",
  },
];
const CrmData = [
  {
    id: 1,
    title: "Account management",
  },
  {
    id: 2,
    title: "Sales forecasting",
  },
  {
    id: 3,
    title: "Calendars and task management",
  },
  {
    id: 4,
    title: "Workflow automation",
  },
  {
    id: 5,
    title: "Collaboration tools",
  },
  {
    id: 6,
    title: "Document management",
  },
  {
    id: 7,
    title: "Social media integration",
  },
  {
    id: 7,
    title: "Reporting and analytics",
  },
];
const Why = [
  {
    id: "1",
    title: "",
    desc: "We consider your needs and requirements for dynamic applications to be very important and dear to us; thus, our advanced technologies assist you in developing powerful and agile mobile applications for Android, iOS, and cross-platform. They play a critical role in connecting your business with clients while also empowering your agents.",
  },
  {
    id: "2",
    title: "",
    desc: "We use the latest and sophisticated technology to ensure that you get the optimum benefits out of your investment. Aside from building a strong foundation for robust solutions, we also invest tremendously in future technologies to ensure future-ready developments.",
  },
  {
    id: "3",
    title: "",
    desc: "Our real estate solutions are built to offer the most dynamic solutions and with that in mind we ensure that users get the best intuitive interface that can be used instantly with no or minimum supervision.",
  },
  {
    id: "4",
    title: "",
    desc: "We understand that the need for real estate software is very demanding in this era. To this effect, our technology solutions are built to be most cost effective and delivers extreme value for your money!",
  },
  {
    id: "5",
    title: "",
    desc: "Our products go through several stages of both intensive and extensive testing, quality assurance and practical usability to ensure the best end results. Great commitment and responsibility from our team is a key factor to achieving this.",
  },
  {
    id: "6",
    title: "",
    desc: "Our Dedicated team comprises of professional realtors and technical professionals. They all bring onboard their combined qualifications, experience, and expertise to develop the application.",
  },
];
export default function RealEstate() {
  return (
    <>
      <Banner
        img="/images/industries/realestate/real-estates-banner.jpeg"
        title="Real Estate"
        desc="Providing innovative PropTech and robust online software solutions to realtors in order to streamline and simplify real estate management."
      />
      <Overview
        textColor="bg-[#f9f9f9]"
        img="/images/industries/realestate/realestate-overview.jpeg"
        img2="/images/industries/realestate/realestate-overview-mobile.jpeg"
        title="Overview of real estate software development"
        desc="A variety of sectors have adopted advanced technology to enhance commercial benefits in recent years. One of them is real estate and property development. With easy tools and superior customer service, real estate’s industries heavily rely on technologies like databases, data analysis tools and communication apps to reach various clients. Moreover, mobile app development and real estate web development have helped this sector increase its potential clients and customers. Our real estate applications are built to enable your real estate organization to easily sail through the continuously changing technology and to increase ROI with the cheapest possible cost than our competitors."
      />
      <h2 className="lg:text-[32px] md:text-[28px] text-[20px] text-center py-[25px] md:py-[40px] font-medium lg:py-[50px]">
        Our real estate applications
      </h2>
      {/* <RightImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FRealEstate%2FrealestateOveriewNoLinear.jpg?alt=media&token=63097e13-9218-4187-8cdc-1a6c2fc7d81f"
        title="Overview"
        desc="A variety of sectors have adopted advanced technology to enhance commercial benefits in recent years. One of them is real estate and property development. With easy tools and superior customer service, real estate’s industries heavily rely on technologies like databases, data analysis tools and communication apps to reach various clients. More especially, mobile app development and real estate web development have helped this sector increase its potential clients and customers."
      /> */}

      {/* <WhyChoose
        textColor="text-white"
        head="Custom Real Estates Systems from Us"
        data={Data2}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FRealEstate%2FcustomRealEstate.jpg?alt=media&token=dcdec328-77be-4459-9520-cdae47c02b76"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      /> */}
      <AssesmentSoftware
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FRealEstate%2FrealestateSoftware.jpg?alt=media&token=40cbdc4c-0f82-406b-96d3-8ba6d569a061"
        title="Real estate marketing & design software"
        desc=" Our robust features include the ability to create, edit, and send electronic documentation from a desktop computer or mobile device. Realtors can send documents through online fax, SMS text, or email to clients to streamline the sales process easily. Real-time communication between realtors and clients is a unique feature of this software."
      />
      <Lms
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FtransactionManagement.png?alt=media&token=64d4ea93-92a2-4a62-bd0e-9628de8a08f4"
        title="Transaction management software"
        desc="Create powerful transaction checklists that will effortlessly help guide your real estate’s transaction from listing to close, set critical due dates automatically, generate custom transaction reports, provide simplicity for agents and admins, connect your accounts to other apps and separate offices or departments to ensure that each office will see only what they need, with our transaction management software."
      />
      <TrackingSoftware
        head="Expense tracking software"
        desc=" Our all-in-one expense tracking software incorporates the following features"
        data={ProjectData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FRealEstate%2FtrackingSoftware.jpg?alt=media&token=e8bbe4c7-e8cc-49c0-94ee-fb75efd34e41"
      />
      <CrmSoftware
        head="Real Estate CRM software "
        desc="Our system manages overall operational activities and processes, starting from the management of the property to the management of real estate agencies, clients and financial transactions. This software has the following modules"
        data={CrmData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FRealEstate%2FcrmSoftware.jpg?alt=media&token=a9a2aece-2110-4dd2-b655-30f0101526e5"
      />
      <WhyChooseNumber
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FProfessionalServices%2FwhyBackground.png?alt=media&token=03d970d1-9dd2-403e-8549-9c63d23568dd"
        data={Why}
      />
      <FooterBanner title="Send us your requirements" link="/contact-us" />
    </>
  );
}
