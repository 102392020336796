import React from "react";
import "./home.css";
import IndustriesButton from "./IndustriesButton";
export default function Industries() {
  return (
    <div className="pt-[50px]">
      {/* <img
          className="w-full "
          src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Find-start.png?alt=media&token=336b7f79-0ac4-407b-ac32-4c6da963e375"
        /> */}
      <div
        className="elementor-shape elementor-shape-top"
        data-negative="false"
      ></div>
      <div className="bg-gradient-to-r from-[#2BAEBC] to-[#332A78] py-[0px]">
        <div
          className="elementor-shape elementor-shape-top fill-white"
          data-negative="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 50"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M500,24.9L0,0.1V0h1000v0.1L500,24.9z"
            ></path>
          </svg>
        </div>
        <div className="grid lg:grid-rows-3 py-[50px] max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] mx-auto">
          <div className="grid lg:grid-cols-8">
            <div className="col-span-2 md:pb-[40px] lg:pb-0 self-center">
              <h1 className="text-[36px] text-white ">Our Industries</h1>
              <p className="text-[18px] text-white">
                Serving 9 Various Industries
              </p>
            </div>
            <img
              className="h-[160px] w-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fhome%2Fretail.jpeg?alt=media&token=cdab478e-6645-452c-acb6-3abe1811cb08"
            />
            <IndustriesButton
              title="Retail"
              color="bg-[#3AA5BD]"
              link="/industries/retail"
            />
            <img
              className="h-[160px] w-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Findustries%2Frealestate.jpeg?alt=media&token=9f1f1f73-7acf-415f-9d0b-fa9778160ab7"
            />
            <IndustriesButton
              title="Realestate"
              color="bg-[#4F6C82]"
              link="/industries/real-estate"
            />
            <IndustriesButton
              title="Education"
              color="bg-[#CD6868]"
              link="/industries/education"
            />
            <img
              className="h-[160px] w-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Findustries%2Feducation.jpeg?alt=media&token=8066401b-d73a-4a23-aaae-9a9fc7f32f42"
            />
          </div>
          <div className="grid lg:grid-cols-8">
            <div className="col-span-1"></div>
            <img
              className="h-[160px] w-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Findustries%2Ffintech.jpeg?alt=media&token=8c41f45e-daa1-428e-838b-7af20208e1ea"
            />
            <IndustriesButton
              title="Fintech"
              color="bg-[#364A8C]"
              link="/industries/fintech"
            />
            <img
              className="h-[160px] w-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Findustries%2Fhealthcare.jpeg?alt=media&token=f904a82e-f8fd-4e91-861c-5e14e00760ad"
            />
            <IndustriesButton
              title="Healthcare"
              color="bg-[#d28555]"
              link="/industries/healthcare"
            />
            <img
              className="h-[160px] w-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fhome%2Fprofessional-services.jpeg?alt=media&token=d95b2453-205f-4857-91a7-a6ebf4cdf5d5"
            />
            <IndustriesButton
              title="Professional "
              color="bg-[#cac531]"
              link="/industries/professional-services"
            />
            <div className="col-span-1"></div>
          </div>
          <div className="grid lg:grid-cols-8">
            <div className=" col-span-2"></div>
            {/* <div className=" "></div> */}
            <IndustriesButton
              title="Insurance"
              color="bg-[#31709C]"
              link="/industries/insurance"
            />
            <img
              className="h-[160px] w-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Findustries%2Finsurance.jpeg?alt=media&token=7a341b02-91d3-44ea-b72c-28bf8af95ae8"
            />
            <img
              className="h-[160px] w-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Findustries%2Fmanufacturing.jpeg?alt=media&token=335da7b5-6708-4de7-a519-adeaea11f2bd"
            />
            <IndustriesButton
              title="Manufacturing"
              color="bg-[#6EB1A5]"
              link="/industries/manufacturing"
            />
            <img
              className="h-[160px] w-full object-cover"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Findustries%2Ftransportation.jpeg?alt=media&token=3145ac93-1448-44fd-ae3e-5a3340e625a6"
            />
            <IndustriesButton
              title="Transportation"
              color="bg-[#4F6C82]"
              link="/industries/transportation"
            />
            <div className=" "></div>
          </div>
          {/* <div className="grid grid-cols-6">
              <div></div>
              <div></div>

              <div className="bg-[#3AA5BD] h-[160px] flex justify-center	items-center ">
                <p className="text-white text-[18px] font-semibold ">Retail</p>
              </div>
              <div className="bg-[#d28555] h-[160px] flex justify-center	items-center ">
                <p className="text-white text-[18px] font-semibold ">Retail</p>
              </div>
              <div className="bg-[#cac531] h-[160px] flex justify-center	items-center ">
                <p className="text-white text-[18px] font-semibold ">Retail</p>
              </div>
              <div className="bg-[#3AA5BD] h-[160px] flex justify-center	items-center ">
                <p className="text-white text-[18px] font-semibold ">Retail</p>
              </div>
            </div> */}
        </div>
        <div
          className="elementor-shape elementor-shape-top fill-white"
          data-negative="false"
        ></div>
      </div>
      <div
        className="elementor-shape elementor-shape-top fill-white"
        data-negative="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 50"
          preserveAspectRatio="none"
        >
          <defs>
            <linearGradient id="gEffect">
              <stop offset="0%" stopColor="#2BAEBC" />
              <stop offset="100%" stopColor="#332A78" />
            </linearGradient>
          </defs>
          <path
            className="elementor-shape-fill shape"
            d="M500,24.9L0,0.1V0h1000v0.1L500,24.9z"
          ></path>
        </svg>
      </div>
      {/* <div className="h-[500px] w-[500px] mx-auto">
        
        <svg viewBox="0 0 100 100">
          <g className="grid"></g>
          <path d="M20,40 H80 V70 H20 Z" fill="none" stroke="blue"></path>
          <path d="M10,50 C20,70 50,30 70,50" fill="none" stroke="blue"></path>
          <path
            d="M10,50 C20,80 40,80 50,50 S70,20 90,50"
            fill="none"
            stroke="blue"
          ></path>
          <path d="M20,50 Q55,25 70,60 T90,50" fill="none" stroke="blue"></path>
        </svg>
      </div> */}
    </div>
  );
}
