import React from "react";

export default function Services(props) {
  const Data = props.data;
  const listData = Data.map((list, index) => {
    return (
      <div>
        <img
          className="w-full h-[300px] object-cover pt-[25px]"
          src={list.img}
        />
        <h1 className="text-[18px] font-medium pt-[25px] lg:text-[24px]">
          {" "}
          {list.title}
        </h1>
        <p className="pt-[10px]">{list.desc}</p>
      </div>
    );
  });

  return (
    <>
      <div className="py-[40px] lg:py-[100px] mx-auto max-w-[1500px]  lg:pt-[50px] px-[28px] md:px-[40px] lg:px-[85px]">
        <h1 className="text-[24px] font-semibold md:text-[26px] lg:text-[36px] text-[#222222] text-center">
          {props.title}
        </h1>
        <div className=" grid grid-cols-1 lg:grid-cols-2 lg:gap-[60px] pt-[25px]">
          {listData}
        </div>
      </div>
    </>
  );
}
