import React from "react";
const offeringData = [
  {
    id: 1,
    img: "/images/icons/log.png",
    title: "ELog book",
    desc: "For most Power System Control Rooms, the capturing of logs is a big headache since information is mostly captured manually. To streamline the capturing of such logs and its associated security, the eLog Book empowers Operators and System Control personnel to capture and manage logs without the headache of keeping track of all the associated rules and regulations, as the system is designed from the grounds-up to ensure such rules are taken care of.",
  },
  {
    id: 2,
    img: "/images/icons/socket.png",
    title: "Outage management",
    desc: "The activities surrounding the request, approval and taking out of power system equipment for maintenance and others, usually involves profuse rules and regulations that must be adhered to. The handling of such laborious operations are best left to the Outage Management. It gives all stakeholders in the power generation, transmission, and distribution supply chain the opportunity to request, and be granted the permission to work on power system equipment operations at the click of a button. This module works best together with the Power System Asset Management (Generation, Transmission and Distribution) module.",
  },
  {
    id: 3,
    img: "/images/icons/telemetry.png",
    title: "Telemetry",
    desc: "Automatic Energy Meter reading is best left for the Telemetry module to handle and thus no need of personnel going around reading energy meter data. This system gives personnel in the power system value chain the flexibility, accuracy, and cost effectiveness required in the sector and ensure that the large volumes of data generated are accurately kept for posterity. This data can also be fed directly into the Power Generation, Transmission or Distribution Billing system just by clicking a button.",
  },
  {
    id: 4,
    img: "/images/icons/distribution.png",
    title: "Transmission/Distribution Billing System",
    desc: "This module ensures the seamless automatic generation of Power System, Transmission Services or Distribution bills once the telemetric data is either captured through the Telemetry module or uploaded manually. This relieves personnel of the tedious tasks of generating manual Bills for customers.",
  },
  {
    id: 5,
    img: "/images/icons/networking.png",
    title: "Connection Application Tracker",
    desc: "In the power System industry, one of the most frustrating instances for customers is the ability to seamlessly apply for power to be provided to their premises. In this case, the bulk power customer must sign Power Purchase agreements with the individual power consumer. This module comes as a great relieve for most customers as this streamlines the process of getting customer connected to power sources.",
  },
];
export default function Modules(props) {
  // const dataCount = data.filter((item) => item.id);
  // const dataNumber = dataCount.length;
  // console.log(dataNumber);

  // const [items, setItems] = useState([]);
  // const [showMoreItems, setShowMoreItems] = useState(4);
  // const [showButton, setShowButton] = useState(true);
  // console.log(showMoreItems + "before");

  // const showMoreDataHandler = () => {
  //   // setShowButton()
  //   setShowMoreItems((prevValue) => prevValue + 2);
  //   // console.log(showMoreItems + "inside");
  //   if (showMoreItems >= dataNumber) {
  //     // console.log(showMoreItems + "OnClick");
  //     setShowButton(false);
  //   }
  // };
  // useEffect(() => {
  //   setItems(data);
  // }, []);

  // const [items, setItems] = useState(4);

  const listData = offeringData.map((item, index) => {
    return (
      <div key={index}>
        <img className="h-[48px] mx-auto lg:ml-0" src={item.img} />
        <h2 className=" text-[18px] pt-[10px] font-medium lg:text-[20px]">
          {item.title}
        </h2>
        <p className="text-[#6D6D6D] ">{item.desc}</p>
      </div>
    );
  });

  return (
    <>
      <div className="bg-[#F1F4FF]">
        <div className="max-w-[1500px] mx-auto px-[28px] md:px-[40px] py-[25px]  lg:py-[50px] lg:px-[85px]">
          <div className="text-center  md:px-[50px] lg:px-[100px]"></div>
          <div className="grid grid-cols-1 md:grid-cols-12  lg:grid-cols-12 pt-[50px] mx-auto">
            <div className="md:col-span-5 pb-[20px]   md:w-full mx-auto sticky  md:top-28 lg:pr-[40px]">
              <h2 className="text-[24px]  text-[#3066EA]  lg:text-[36px]">
                {props.title}
              </h2>
              <p className="text-[16px] lg:text-[18px]    text-[#222222] pt-[36px]">
                {props.desc}
              </p>
              <img
                className="md:col-span-5 sticky top-40 pb-[20px]   md:w-full   "
                src={props.img}
                alt="power house"
              />
            </div>
            <div className="col-span-7 text-center mx-auto">
              <div className="grid lg:text-left md:px-[40px] md:grid-cols-1 gap-[77px] ">
                {listData}
              </div>
              {/* {showButton ? (
                <div>
                  {dataNumber > 4 ? (
                    <div className="pt-[50px] pl-[40px] ml-0 float-left">
                      <button
                        onClick={showMoreDataHandler}
                        className="text-center text-white pl-auto mx-center py-[8px] px-[20px] bg-[#428f66]"
                      >
                        Show More
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
