import React from "react";
import Banner from "../../../../components/Banner";
import Industries from "../../../../components/NewIndustries";
import { phpDevelopers } from "../../../../data/programming";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import OurDevelopers from "../../Platforms/Magento/OurDevelopers";
import Offerings from "../Php/Offerings";
// import Overview from "../Php/Overview";
import Services from "../Php/Services";
import WhyChooseUs from "../Php/WhyChooseUs";
import WhyPython from "../Python/WhyPython";
const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fcode.svg?alt=media&token=21f82e42-2f30-47ee-a7f5-b2eae3475f00",
    title: "Revel Go",
    desc: "The Revel Go framework is used to create GoLang-based software and apps. Revel Go is scalable and easy to migrate.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fdecision-making.svg?alt=media&token=3a8c6926-baa5-4064-8fa4-a88eeb75ab8c",
    title: "AWS Lambda",
    desc: "The Go programming language is supported by Amazon Web Service (AWS) Lambda. AWS uses Golang to deliver Amazon security, performance, and scalability. ",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fdatabase.svg?alt=media&token=8a28b929-46d5-4213-9528-18e313141931",
    title: "Go Bootstrap",
    desc: "Go bootstrap enables the usage of MySQL database integration and PostgreSQL database software for custom application modifications using Golang.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fspeedometer.svg?alt=media&token=c4c6182e-0f4a-48c3-8884-2c7004e1c0cc",
    title: "Gin Gonic",
    desc: "Gin gonic is written in the Go programming language. It's one of the fastest Go frameworks for building web apps. Gin gonic's software has been modified to increase performance.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fscalibility.svg?alt=media&token=8616da02-0be6-4ae9-b6d9-1330851d868c",
    title: "Go on Rails",
    desc: "Go on rails allows modification to existing rails apps for scalability. Go on rails enables usage of the rails tool on your Golang development.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fapi.svg?alt=media&token=ff5e59f8-b0f2-4402-b701-c170053ed953",
    title: "Go Echo",
    desc: "The echo Go web framework is a high-performance framework for creating middleware for Golang restful APIs.",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fdevelopment-tools.svg?alt=media&token=f3fbf241-9931-4031-8f66-a7ed58995706",
    title: "BeeGo",
    desc: "BeeGo is an open-source framework for customizing software for high-volume apps. Golang BeeGo comes with a built-in set of development tools.",
  },
  {
    id: 8,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fdocker.svg?alt=media&token=897291d7-90a6-4f9d-bb58-d35d81afea09",
    title: "Docker",
    desc: "Golang Docker integrates Go language programming and code into application contexts, allowing for faster development and cleaner code.",
  },
];
const OfferingsWehave = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fillustrations%2FGolang-AI.svg?alt=media&token=529b84f7-98a4-4027-ae7a-c28df6da67f4",
    title: "Golang AI Development",
    desc: "Using our Golang AI development services, staying on top of the latest trend is a breeze. Our Golang AI programmers can assist you in implementing customizable services that make error management and debugging a breeze.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fillustrations%2FGolang-streamlined.svg?alt=media&token=b66c312b-775c-41ac-81cf-c3666ea8d586",
    title: "Streamlined Apps Development",
    desc: "Multi-threading is quite simple with Golang goroutines and lightweight threads. Golang concurrency's streaming design facilitates the creation of concurrent applications in large multi-regional teams.",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fillustrations%2FGolang-Cloud.svg?alt=media&token=c3a46cad-c688-45d6-baa0-0e5a372e63e0",
    title: "Cloud and Network Services",
    desc: "The Go programming language provides a high level of cloud interoperability. With Goroutines and channels, our Golang programmers can assist you in developing complicated cloud solutions.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fillustrations%2Fgolang-mobiledevelopment.svg?alt=media&token=75a4126d-cb0b-439f-a5b4-8b9d01f7439c",
    title: "Golang for Mobile Development",
    desc: "Golang has extended cross-compilation capabilities to enable speedy and safe cross-platform apps development. Hire a Golang developer from us to develop cross-platform apps within the time and budget constraints.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fillustrations%2Fgolang-fullstack.svg?alt=media&token=c92eff23-51c4-48cf-bc3e-47e1ad72414b",
    title: "Full-Stack Development with Go",
    desc: " Our Golang programmers are experts at combining a wide range of frontend and backend technologies, including ReactJS, AngularJS, MongoDB, Strings, Docker, Vuejs, and others. ",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fillustrations%2Fgolang-webDevelopment.svg?alt=media&token=ed5a3839-725c-4f3d-97bb-fb7f4b8da558",
    title: "Golang web development",
    desc: "The Golang programming language brings a fresh perspective to coding paradigms. Golang has swiftly developed a reputation as an open-source, cross-platform programming language that is truly adaptable.",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fillustrations%2FGoLang-Support%26Maintainance.svg?alt=media&token=69ff6239-1d2e-45f9-b223-a1469289b87d",
    title: "Golang support and maintenance",
    desc: "Our competent support team will assist with any question or app maintenance to keep your application up to date and to meet changing requirements.",
  },
];
const Data2 = [
  {
    id: 1,
    title: "Highly skilled in-house team of C++ developers",
  },
  {
    id: 2,
    title: "Cost effective engagement model",
  },
  {
    id: 3,
    title: "Portal development",
  },
  {
    id: 4,
    title: "Comprehensive quality assurance ",
  },

  {
    id: 6,
    title: "Maximum Speed and flexibility",
  },
  {
    id: 8,
    title: "Agile software development",
  },
  {
    id: 9,
    title: "360-degree Requirement Analysis",
  },

  {
    id: 10,
    title: "Reduced cost to 50% of our competitors",
  },
];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];
const whylang = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fcode.svg?alt=media&token=21f82e42-2f30-47ee-a7f5-b2eae3475f00",
    desc: "Highly Scalable",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fwhygolang%2Fheavy-load.svg?alt=media&token=8e6dbe2e-42b8-4430-8054-81a74b883c10",
    desc: "Handles heavy load",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fwhygolang%2Flibrary.svg?alt=media&token=a39c712d-943e-4bde-a4e1-c3c962915e23",
    desc: "Rich Library",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fwhygolang%2Fcloud.svg?alt=media&token=39d3d30a-d3ea-4e81-a80c-e69e4e25643f",
    desc: "Explicit designing for the cloud",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fwhygolang%2Fcross-platform.svg?alt=media&token=79372c33-01a2-4434-b3e1-dcc8d7927d74",
    desc: "Cross-platform development",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fwhygolang%2Fstreamlining.svg?alt=media&token=5de3b2f8-51d4-4695-bbb1-80a570247d21",
    desc: "Streamlining software development",
  },
  // {
  //   id: 7,
  //   img: "",
  //   desc: "Concurrent app development",
  // },
  // {
  //   id: 8,
  //   img: "",
  //   desc: "Simple Code",
  // },
  // {
  //   id: 9,
  //   img: "",
  //   desc: "Object-oriented capabilities",
  // },
  // {
  //   id: 10,
  //   img: "",
  //   desc: "Easy maintenance",
  // },
];
export default function GoLang() {
  return (
    <>
      <Banner
        button="Talk to Experts"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fgo-lang.jpeg?alt=media&token=4019704e-fecd-4080-8c91-098db4a09231"
        title="Golang Development Services"
        desc="Bringing Google's next-generation programming language to market to create a more secure robust solutions and a seamless digital world "
      />
      <Overview
        textColor="text-white bg-[#222222]"
        title="Golang Overview"
        desc="Golang works well on a variety of operating system platforms, including Windows, Linux/Unix, Android, iOS, and macOS, and it improves corporate productivity by assisting with frontend, backend, UI/UX, automatic memory management, and structured programming. Our Golang engineers are experts in full-stack software development, with great skills in frontend and backend technologies. They create and manage a wide range of software, including databases, scalable systems, servers, command-line programs, APIs, and web apps."
        img="/images/technologies/programming/golang/golang-overview.jpeg"
        img2="/images/technologies/programming/golang/golang-overview-mobile.jpeg"
        alt="php"
      />

      {/* <Overview
        color="bg-black"
        textColor="text-white"
        title="Golang Overview"
        desc="Golang works well on a variety of operating platforms, including Windows, Linux/Unix, Android, iOS, and macOS, and improves corporate productivity by assisting with frontend, backend, UI/UX, automatic memory management, and structured programming. "
        desc2="Our Golang engineers are experts in full-stack software development, with great skills in front-end and back-end technologies. They create and manage a wide range of software, including databases, scalable systems, servers, command-line programs, APIs, and web apps."
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fgo-lang-no-linear.jpeg?alt=media&token=234b8e29-989a-4cd3-a559-21869ed4cd11"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fgolang-overview.jpeg?alt=media&token=7ba99904-e993-44d6-8f23-d9392eaccf21"
      /> */}
      <WhyPython title="Why choose Golang" data={whylang} />
      <Offerings
        title="Go Language Environments and Libraries Used"
        desc="Golang works well on a variety of operating system platforms, including Windows, Linux/Unix, Android, iOS, and macOS, and it improves corporate productivity by assisting with frontend, backend, UI/UX, automatic memory management, and structured programming. Our Golang engineers are experts in full-stack software development, with great skills in frontend and backend technologies. They create and manage a wide range of software, including databases, scalable systems, servers, command-line programs, APIs, and web apps."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2Fphp%2Fofferings.png?alt=media&token=d3fd7a68-2751-45e1-b71d-5e9fcce9184b"
        data={servicesData}
      />
      <Services
        title="Our custom GoLang Development Services"
        desc=""
        img=""
        data={OfferingsWehave}
      />
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our Golang expertise"
        desc="Innorik has a large team of Golang developers who have extensive experience with the language due to working with various project domains such as banking, telecommunication, healthcare, insurance systems and with different companies. Our developers have experience ranging from 1-20 years."
        button="Hire a GoLang Expert"
        data={phpDevelopers}
      />
      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fgolang-developer.jpeg?alt=media&token=9de565e6-902b-4560-8826-4c87ce30d498"
        title="Our Golang Experts"
        desc="Innorik has a large team of Golang developers who have very good experience with the language from working with various project domains such as banking, telecommunication, healthcare, insurance systems and with different companies. Our developers have experience ranging from 1year to 20 years."
        button="Hire GoLang Expert"
      /> */}
      <WhyChooseUs
        head="Why Integrate with cloud? "
        title="Why choose us?"
        desc=""
        data={Data2}
      />
      <Industries
        title="Industies Served"
        desc="We are international company and we deliver technology solutions to clients globally. "
        data={Indust}
      />
    </>
  );
}
