import React from "react";
// import { Link } from "react-router-dom";
import Banner from "../../../components/Banner";
// import BorderButton from "../../../components/Buttons/BorderButton";
// import FiveColCard from "../../../components/FiveColCard";
import FourColCard from "../../../components/FourColCard";
import LeftImageSection from "../../../components/LeftImageSection";
import Industries from "./Industries";
import DocumentManagementSection from "./DocumentManagementSection";

import WhyChoose from "../../../components/WhyChoose";
import Infographics from "../../Testing/Infographics";
import Overview from "../LatestThinking/ArtificialIntellegence/Overview";
import FooterBanner from "../../../components/Footer/FooterBanner";
import Services from "./Services";
import MobileTab from "./MobileTab";
import VerticalTabs from "./TextTab";
import Performance from "./Performance";
import Modules from "./Modules";
const Data = [
  {
    id: 1,
    img: "/images/technologies/erp/streamlined-business-process.jpg",
    alt: "streamline business process",
    title: "Streamlined Business Process ",
    desc: "",
  },
  {
    id: 8,
    img: "/images/technologies/erp/significant-time-savings.jpg",
    title: "Significant time savings",
    desc: "",
  },
  {
    id: 2,
    img: "/images/technologies/erp/high-data.jpeg",
    title: "High data visibility and availability",
    desc: "",
  },
  {
    id: 3,
    img: "/images/technologies/erp/improved-financial-consolidation.jpg",
    title: "Improved Financial Consolidation",
    desc: "",
  },

  {
    id: 4,
    img: "/images/technologies/erp/supply-chain-optimization.jpeg",
    title: "Supply Chain Optimization",
    desc: "",
  },

  {
    id: 5,
    img: "/images/technologies/erp/tracibility.jpeg",
    title: "Traceability",
    desc: "",
  },
  {
    id: 6,
    img: "/images/technologies/erp/improved-customer-satisfaction.jpg",
    title: "Improved Customer Satisfaction",
    desc: "",
  },
  {
    id: 7,
    img: "/images/technologies/erp/increased-competitiveness.jpg",
    title: "Increased Competitiveness",
    desc: "",
  },
];

const servicesData = [
  {
    id: 1,
    img: "/images/technologies/erp/illustration/performance-management.svg",
    title: "4. Task Management",
    desc: "InnoX task management module can be used by any team, project, or workflow to organize activities so that teams know what to do, who oversees specific tasks, what the deadline is, and how to track overall progress.",
  },
  {
    id: 2,
    img: "/images/technologies/erp/illustration/task-management.svg",
    title: "5. Budget Management",
    desc: "Companies can use the Budget Management module to manage their whole strategic, operational, and financial plans by generating a budget that suits their future vision. This module allows for the creation of an endless number of budget scenarios, as well as the versioning, selection, and easy modifications of the budgets generated.",
  },
  {
    id: 3,
    img: "/images/technologies/erp/illustration/budget-management.svg",
    title: "6. Asset Management",
    desc: "This module takes care of an organization’s entire asset portfolio, with each interface custom-made to suit the details of each item in the organization. The following assets can be managed; Transport, Real Estate, Information Technology (IT), Transmission, Distribution and Telecommunication assets.",
  },
  {
    id: 4,
    img: "/images/technologies/erp/illustration/asset-management.svg",
    title: "7. Project Management",
    desc: "This module provides the transparency and visibility every management and project management team need to manage the costs and resources required to complete each phase of a project.",
  },
  {
    id: 5,
    img: "/images/technologies/erp/illustration/project-management.svg",
    title: "8. Safety Management",
    desc: "The Safety Management module gives the Safety Managers an overview of all safety related activities and statuses for the organization. This module also handles activities including Accident Reporting, Safety Program Schedules, Safety Document Management, Central and Group PPE stock management among others.",
  },
  {
    id: 6,
    img: "/images/technologies/erp/illustration/safety-management.svg",
    title: "9. Software Development",
    desc: "This module captures a 360-degree activity tracking for the processes involved in either in-house software development projects or for the implementation of third party software for the organization. The provision is available to capture all aspects of the Software Development Life Cycle (SDLC), including the various stages of User Acceptance Test (UAT).",
  },
  {
    id: 7,
    img: "/images/technologies/erp/illustration/software-development.svg",
    title: "10. Business Intelligence",
    desc: "The Business Intelligence module of InnoX comprises of a number of visual tools that help operators or users design stunning reports by providing visual data pointers to the most essential metrics for their discipline. Graphs, Kanbans, Gantt charts, maps, and KPIs can all be used to create system dashboards. All of this functionality combines to provide a combination of useful data graphics for any job activity.",
  },
  // {
  //   id: 7,
  //   img: "/images/technologies/erp/illustration/human-resource-management.svg",
  //   title: "Project management",
  //   desc: "The project management module manages the following: management of projects and their budget, management of tasks and timesheets, integrated with sales and CRM module, ability to bill customers based on timesheets, can also be integrated with the website module to provide access for customers and monitor the progress of projects via charts.",
  // },
  // {
  //   id: 7,
  //   img: "/images/technologies/erp/illustration/document-management.svg",
  //   title: "Document Management",
  //   desc: "This module enables managers store different versions of the same document for reusability. Businesses can also go an extra mile by securing its documents from unauthorized access, allowing users to collaborate with the help of file sharing, and allowing users to access documents from anywhere. This module has the following features.",
  // },
  {
    id: 8,
    img: "/images/technologies/erp/illustration/business-intellegence.svg",
    title: "11. Customer Relationship Management",
    desc: "Maintaining contact with clients and prospective consumers, as well as supplying them with useful information, is a difficult effort. This module creates eye-catching campaigns for your target market by managing your contacts, tasks, activities, and appointments directly, all from a single point.",
  },
  {
    id: 9,
    img: "/images/technologies/erp/illustration/customer-relation-management.svg",
    title: "12. Purchasing",
    desc: "The Purchasing module makes it easier to purchase requisite materials needed for your company and automates the purchasing procedure. The processes of awarding purchase orders to suppliers and negotiating the price are also embedded into this module.",
  },
  {
    id: 10,
    img: "/images/erp/illustrations/financial-and-accounting.svg",
    title: "13.Financials and accounting",
    desc: "The accounting module would allow you to handle your finances more efficiently while adhering to local tax requirements, as well as give segmented analysis to help you rationalize your financial perspectives for each business unit. The financial controller's integrated capabilities would allow it to keep track of all transactions in real time and send out alerts to sales managers if any orders were produced, ensuring that cash flow was not jeopardized.    ",
  },
  {
    id: 11,
    img: "/images/erp/illustrations/marketing-and-sales.svg",
    title: "14.Marketing and Sales",
    desc: "The sales and marketing module is designed to help you gain a better understanding of your sales and marketing processes. It was created with the specific requirements of salesmen and marketers in mind. InnoX is specifically developed to assist you in efficiently managing revenue and profit generating projects, allowing you to retain existing clients while also acquiring new ones.",
  },
  // {
  //   id: 7,
  //   img: "/images/technologies/erp/illustration/purchasing.svg",
  //   title: "Purchasing",
  //   desc: "The Purchasing module makes it easier to get the raw materials you need for your company. Furthermore, it automates the purchasing procedure. Identifying possible suppliers and negotiating the price are examples of processes.",
  // },
  // {
  //   id: 7,
  //   img: "/images/technologies/erp/illustration/financial-and-accounting.svg",
  //   title: "Financials and accounting",
  //   desc: "The accounting module would allow you to handle your finances more efficiently while adhering to local tax requirements, as well as give segmented analysis to help you rationalize your financial perspectives for each business unit.",
  // },
  // {
  //   id: 7,
  //   img: "/images/technologies/erp/illustration/marketing-and-sales.svg",
  //   title: "Marketing and Sales",
  //   desc: "The sales and marketing module is designed to help you gain a better understanding of your sales and marketing processes. iGlimpse is specifically developed to assist you in efficiently managing revenue and profit generating projects, allowing you to retain existing clients while also acquiring new ones. It was created with the specific requirements of salesmen and marketers in mind.",
  // },
];
const mobileData = [
  {
    id: 1,
    title: "Decreased Operational Costs",
    desc: "He flexible nature of iGlimpse ERP makes it very easy to integrate with other common systems like Oracle E-Business Suite, Microsoft Dynamics ERP and Computron Financial System. These systems have been integrated with iGlimpse out of the box without the need to do any further customization to achieve integration requirements, except in very extreme cases",
  },
  {
    id: 2,
    title: "Performance Monitoring",
    desc: "It ensures that the system is able to capture 360 degrees view of all transactions on the system and report on them as such, giving management team members the ability to monitor each and every task and assignments just at the click of a button, as and when they happen.",
  },
  {
    id: 3,
    title: "Availability",
    desc: "This system has accurate and timely access to reliable information, has the ability to share information between all components of the organization and you can be able to login from anywhere and anytime.",
  },
  {
    id: 4,
    title: "Efficiency",
    desc: "Elimination of unnecessary operation and data (avoidance of duplication and redundancy), Optimization of business processes, Integrated information, Reporting and Customer service improvement",
  },
  {
    id: 5,
    title: "Real time information",
    desc: "Innoriks' one-of-a-kind custom ERP software allows businesses to generate real-time information reports. The ability to access real-time data reduces the risk of data duplication and improves the workflow of all departments.",
  },
  {
    id: 6,
    title: "integrity and security",
    desc: "A rise in unnecessary operating costs might be caused by misplaced, inaccurate, or out-of-date data. Through effective data movement and regular data duplication analysis, iGlimpse ERP increases data integrity.",
  },
];
const Data3 = [
  {
    id: 1,
    title: "Estimation Accuracy",
  },
  {
    id: 2,
    title: "Minimized Financial Risk",
  },
  {
    id: 3,
    title: "Fast development",
  },
  {
    id: 4,
    title: "Flexible contract management",
  },
  {
    id: 5,
    title: "Successful project delivery",
  },
  {
    id: 6,
    title: "Skilled team with expert developers",
  },
  {
    id: 7,
    title: "Cost effective engagement models",
  },
];

const DMS = [
  {
    id: 1,
    title: "Document capture",
  },
  {
    id: 2,
    title: "Document storage",
  },
  {
    id: 3,
    title: " Control access / permissions",
  },
  {
    id: 4,
    title: "Collaboration tools",
  },
  {
    id: 5,
    title: "Document archive",
  },
  {
    id: 6,
    title: "Logging and tracing for audit",
  },
  {
    id: 7,
    title: "E-Signature capability",
  },
];
const Indust = [
  {
    id: 1,
    nav: "#",
    title: "Performance Management",
    img: "/images/erp/performance-management.jpeg",
  },
  {
    id: 2,
    nav: "#",

    title: "PowerApps",
    img: "/images/erp/power-apps.jpeg",
  },
  {
    id: 3,
    title: "Legal Management",
    nav: "#",

    img: "/images/erp/legal-management.jpeg",
  },
  {
    id: 4,
    title: "Marketing and Sales",
    nav: "#",

    img: "/images/erp/marketing.jpeg",
  },
  {
    id: 5,
    title: "Task Management",
    nav: "#",

    img: "/images/erp/task-management.jpeg",
  },
  {
    id: 6,
    nav: "#",

    title: "Budget Management",

    img: "/images/erp/budget-management.jpeg",
  },
  {
    id: 7,
    nav: "#",

    title: "Asset Management",
    img: "/images/erp/asset-management.jpeg",
  },
  {
    id: 8,
    nav: "#",

    title: "Project Management",
    img: "/images/erp/project-management.jpeg",
  },
  {
    id: 9,
    nav: "#",

    title: "Safety Management",
    img: "/images/erp/safety-management.jpeg",
  },
  {
    id: 10,
    nav: "#",

    title: "Software Development",
    img: "/images/erp/software-management.jpeg",
  },
  {
    id: 11,
    nav: "#",

    title: "Human Resource Management ",
    img: "/images/erp/hr-management.jpeg",
  },
  {
    id: 12,
    nav: "#",

    title: "Document Management System",
    img: "/images/erp/document-management.jpeg",
  },
  {
    id: 13,
    nav: "#",

    title: "Business Intelligence",
    img: "/images/erp/business-intellegence.jpeg",
  },
  {
    id: 14,
    nav: "#",

    title: "Customer Relationship Management",
    img: "/images/erp/customer-relationship-manager.jpeg",
  },
  {
    id: 15,
    nav: "#",

    title: "Purchasing",
    img: "/images/erp/purchasing.jpeg",
  },
  {
    id: 16,
    nav: "#",

    title: "Financials and accounting",
    img: "/images/erp/finance-and-accounting.jpeg",
  },
];

export default function ERP() {
  return (
    <>
      <Banner
        button="Request for demo"
        img="/images/erp/erp-hero.jpeg"
        title="Enterprise Resource Planning (ERP)"
        desc="Simplify your workflow and improve productivity with Innorik’s powerful InnoX, An easy-to-implement ERP system for small and large businesses."
      />
      <Overview
        textColor="bg-[#f9f9f9] text-[#222222]"
        title="ERP – A powerful arsenal for modern businesses."
        desc="ERP (Enterprise Resource Planning) is an abbreviation used in the industry that refers to the various technologies and tools used for automating and integrating a company's fundamental operations. Organizations can increase the effectiveness of their day-to-day activities such as project management, financials, procurement, asset management, document management, and more by using custom ERP software. Most ERP software solutions developed presently have been shown to efficiently combine data and streamline corporate processes by utilizing advanced automations and integrations."
        img="/images/technologies/erp/enterprise-resource-planning-overview.jpg"
        img2="/images/technologies/erp/enterprise-resource-planning-overview-mobile.jpg"
        alt="erp"
      />
      {/* <Overview
        title="ERP – A powerful arsenal for modern businesses."
        desc="Enterprise Resource Planning (ERP) is an abbreviation used in the industry and it refers to the automation and integration of a company's fundamental operations. Organizations can increase the effectiveness of their day-to-day company activities such as project management, accounting, order fulfillment, and more by using custom ERP software. Most ERP software solutions developed presently have been shown to efficiently combine data and streamline corporate processes by utilizing advanced automations and integrations."
      /> */}
      <div className="">
        <FourColCard title="Advantages of ERPs" data={Data} />
      </div>
      <LeftImageSection
        img="/images/MeshApps.png"
        title="Our Custom ERP - InnoX"
        desc="Innoriks' custom ERP software solutions have been shown to efficiently combine data and streamline corporate processes by utilizing advanced automations and integrations. Its main purpose is to automate all of the manual way of working and to create a single platform where teams could effectively work together to reduce clutter and duplicate files as ERPs do."
      />
      <h2 className="text-center text-[36px] mx-auto lg:w-1/2 pb-2 md:pb-8 lg:pb-16">
        The functional modules of our innovative suite
      </h2>
      <Performance
        textColor="#f9f9f9"
        img1="/images/performance-management-1.jpeg"
        img2="/images/performance-management-2.jpeg"
        head="1. Performance Management"
        text="Employees, supervisors, and HR experts must all be connected for real-time cooperation and communication in order for the Performance Management process to be genuinely effective. Employee performance goals are aligned with organizational goals in every successful organization and this is the core functionality of InnoX. It monitors and captures data from all other modules and make sure the information is available for fast and responsive decision making by authorized personnel.
"
        text2=""
      />

      <Modules
        img="/images/power.png"
        title="2. PowerApps"
        desc="This module is purposely built for the Power sector, be it Transmission, Distribution and/or Power Generation."
      />
      <div className="bg-[#f9f9f9]">
        <div className="max-w-[1500px] mx-auto lg:px-[85px] md:px-[40px] px-[28px] lg:py-[50px] md:py-[25px] py-[20px]">
          <h3 className="text-[24px]  text-[#3066EA]  lg:text-[36px]">
            3. Legal Management
          </h3>
          <p className="text-[16px] lg:text-[18px]    text-[#222222] pt-[10px]">
            The legal Departments or Sections of most organisations do not have
            customized software products to handle and monitor their numerous
            cases. The Legal Management module on InnoX empowers legal
            administrators, counsellors, and other legal brains to manage cases,
            track timelines and capture progress activities. Automatic alert
            managements are built in the system to inform users of their
            progress and impending deadlines. Intuitive dashboards are readily
            available to users giving them top notch information just at a
            glance and immensely help speed up actions. The Document Management
            feature of this module ensures documents of any size and type are
            all handled without the need for another Document Management System.
            The Laws and Judgement Catalogues gives users the benefit of having
            access to a large pool of information from the various laws,
            articles, publications, constitutions, and other legal documents
            that helps speed up the work of legal personnel.
          </p>
        </div>
      </div>

      <Services title="" img="" data={servicesData} />
      <DocumentManagementSection
        textColor="text-[#222222] bg-[#]"
        desc="With our DMS module, businesses can go an extra mile by securing its documents from unauthorized access, allowing users to collaborate with the help of file sharing, and allowing users to access documents from anywhere. This module features;"
        head="15. Document Management System (DMS)"
        data={DMS}
        img="/images/document-management-1.jpeg"
        img2="/images/document-management-mobile.jpeg"
      />

      <div className="max-w-[1500px] mx-auto lg:px-[85px] md:px-[40px] px-[28px] lg:py-[50px] md:py-[25px] py-[20px]">
        <h3 className="text-[24px]  text-[#3066EA]  lg:text-[36px]">
          16. Human Resource Management (HRM)
        </h3>
        <p className="text-[16px] lg:text-[18px]    text-[#222222] pt-[10px]">
          The HRM module is a comprehensive solution that ensures HR personnel
          are agile and effective in carrying out HR related duties. It also
          manages both corporate and individual strategic tasks throughout the
          organisation. It provides a platform the HR personnel can use to
          integrate, manage, and automate many of the routine HR processes in an
          organization. The end-to-end modern interface makes it possible to
          handle all functions of the employee life cycle. The following are the
          various sub-modules on the HRM module:
        </p>
        <ul className="text-[16px] lg:text-[18px]    text-[#222222] pt-[10px] space-y-2">
          <li>
            <span className="font-semibold">Core HR operations</span> - handles
            the core operations of the module.
          </li>
          <li>
            <span className="font-semibold">Payroll</span> - ties into the Core
            HR together with further configurations to ensure employees payroll
            is run on time.
          </li>

          <li>
            <span className="font-semibold">Self Service</span> - the various
            employee self-services, ranging from Leave Management, Overtime
            Requests, Loans and Advances Management, Staff data, updating
            personal records, Claims Management, Vacancies, and recruitment
            Management, etc, are all available for users of the system to
            access. Extensive workflows are incorporated into the system that
            ensures that any level of logic can be configured on the system
            irrespective of the complexity involved.
          </li>
          <li>
            <span className="font-semibold">Talent Management</span> - provides
            a way for the HR personnel to manage talent, career opportunities,
            workforce performance and progression throughout the entire
            organisation. HR personnel can tell whether the organisation is
            improving on its performance over time or not.
          </li>
          <li>
            <span className="font-semibold">
              Compensation and Benefits Management
            </span>
          </li>
          <li>
            <span className="font-semibold">Recruitment</span>
          </li>
        </ul>
      </div>

      <div className="hidden lg:block  lg:px-[85px] max-w-[1500px] mx-auto py-[50px]">
        <div>
          <h1 className="text-[36px] pb-[20px]">
            What you get from our ERP system?
          </h1>
          {/* <p className="text-[18px] pb-[25px]">The Flexibility You Deserve!</p> */}
        </div>
        <VerticalTabs />
      </div>
      <div className="block lg:hidden ">
        <MobileTab
          title="What you get from our ERP system?"
          desc=""
          data={mobileData}
        />
      </div>
      <Infographics
        title="Our development process"
        img="/images/technologies/erp/Our-iGlimpse-ERP-Development-Process.svg"
        img2="/images/technologies/erp/illustration/mobile-illustration.svg"
      />
      {/* <div
        className="bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage: `url("/images/technologies/erp/start-solving-problems-with-iGlimpse.jpg")`,
        }}
      >
        <div className=" bg-black/50 ">
          <div className="max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px] py-[20px] md:py-[30px] lg:py-[70px] text-center text-white ">
            <h1 className="text-[24px] md:text-[28px] lg:text-[36px]">
              Ready to start solving problems with our custom ERP system?
            </h1>
            <div className="flex justify-center">
              <Link to="/contact-us">
                <BorderButton button="Request a demo" />
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      <WhyChoose
        textColor="text-[#222222] bg-[#]"
        desc=""
        head="Why choose us?"
        data={Data3}
        img="/images/technologies/erp/why-choose-us-erp.jpeg"
        img2="/images/technologies/erp/why-choose-us-erp-mobile.jpeg"
      />
      <Industries
        color="#00E6B3"
        title="The functional modules of our innovative suite"
        desc=""
        data={Indust}
      />
      <FooterBanner title="Request a demo" link="/contact-us" />
    </>
  );
}
