import React from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import "./component.css";

export default function Industries(props) {
  const settings = {
    infinite: true,
    autoplay: true,
    arrows: false,
    slidesToShow: 2,
    autoplaySpeed: 2000,
    speed: 500,
    dots: true,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
  };
  const data = props.data;
  const renderSlides = () =>
    data.map((num) => (
      <div>
        <div className="w-full cursor-pointer  md:w-auto  shrink-0 bg-white pt-5 pb-4 px-5 mr-8">
          <Link to={num.nav}>
            <img
              className="w-full aspect-[336/221]  object-cover object-center"
              src={num.img}
            />
            <p className="pt-5 text-[18px]  md:text-[20px] lg:text-[20px] ">
              {num.title}
            </p>
          </Link>
        </div>
      </div>
    ));
  const renderSlides2 = () =>
    data.map((num) => (
      <div>
        <div className="w-full  cursor-pointer  md:w-auto  shrink-0 bg-white pt-5 pb-4 px-5 mr-8">
          <Link to={num.nav}>
            <img
              className="w-full h-[180px]  object-cover object-center"
              src={num.img}
            />
            <p className="pt-5 text-[18px]  md:text-[20px] lg:text-[20px] ">
              {num.title}
            </p>
          </Link>
        </div>
      </div>
    ));

  return (
    <div className="bg-blue-100 py-10">
      <div className=" max-w-[1500px] lg:px-[85px]  md:px-[40px] px-[28px] mx-auto grid grid-cols-1 md:grid-row lg:grid-cols-4 lg:w-full">
        <div className="md:col-span-1 my-auto pb-5  md:pb-0">
          <p className="leading-normal	 m-auto text-xl sm:text-[26px] md:text-[36px] xl-[36px] 2xl:text-3xl font-[500] text-center md:text-center md:pb-[20px] lg:text-left">
            {props.title}
          </p>
          <p className="leading-normal pr-4 font-regular text-[#222222]	 m-auto text-xl sm:text-[16px] md:text-[18px]  lg:[18px] xl:text-[18px] 2xl:text-[18px]  text-center md:text-left ">
            {props.desc}
          </p>
        </div>
        <div className="invisible md:visible lg:visible h-0 md:h-auto lg:h-auto  md:col-span-3 pl-[20px]">
          <Slider {...settings}>{renderSlides()}</Slider>
        </div>
        <div className="visible md:invisible lg:invisible h-auto md:h-0 lg:h-0  md:col-span-3 pl-[20px]">
          <Slider
            infinite={true}
            autoplay={true}
            arrows={false}
            slidesToShow={1}
            autoplaySpeed={2000}
            speed={500}
            dots={true}
          >
            {renderSlides2()}
          </Slider>
        </div>
      </div>
    </div>
  );
}

// import React from "react";
// import "./component.css";
// export default function Industries(props) {
//   const data = props.data;
//   const dataList = data.map((data, index) => {
//     return (
//       <>
//         <div className="w-full  md:w-auto sm:w-3/5  shrink-0 bg-white pt-5 pb-4 px-5 mr-8">
//           <img
//             className="w-full aspect-[336/221] md:w-[336px] md:h-[221px] object-cover object-center"
//             src={data.img}
//           />
//           <p className="pt-5 text-[18px]  md:text-[24px] lg:text-[26px] xl:text-[26px] font-">
//             {data.title}
//           </p>
//         </div>
//       </>
//     );
//   });

//   return (
//     <div className="bg-blue-100 py-10">
//       <div className=" max-w-[1500px] lg:px-[85px] md:px-[40px] px-[28px] mx-auto grid grid-cols-1 md:grid-cols-4 w-10/12 sm:w-11/12 lg:w-11/12 2xl:w-11/12">
//         <div className="md:col-span-1 my-auto pb-5 md:pb-0">
//           <p className="leading-normal	 m-auto text-xl sm:text-[26px] md:text-[36px] xl-[36px] 2xl:text-3xl font-[500] text-center md:text-left ">
//             {props.title}{" "}
//           </p>
//           <p className="leading-normal pr-4 font-regular text-[#222222]	 m-auto text-xl sm:text-[16px] md:text-[18px]  lg:[18px] xl:text-[18px] 2xl:text-[18px]  text-center md:text-left ">
//             {props.desc}{" "}
//           </p>
//         </div>
//         <div className="flex w-full md:col-span-3 overflow-x-scroll hide-scroll-bar">
//           {dataList}
//         </div>
//       </div>
//     </div>
//   );
// }
