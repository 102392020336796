import React from "react";
import Banner from "../../../../components/Banner";
import FooterBanner from "../../../../components/Footer/FooterBanner";
import Industries from "../../../../components/NewIndustries";
// import WhyChoose from "../../../../components/WhyChoose";
import WhyChooseText from "../../../../components/WhyChooseText";
import Overview from "../ArtificialIntellegence/Overview";
// import Overview from "../BigData/Overview";

// const Data3 = [
//   {
//     id: 1,
//     title: "20+ years in IT and 10+ years in IoT.",
//   },
//   {
//     id: 2,
//     title: "Ensuring asset authenticity",
//   },
//   {
//     id: 3,
//     title: "Post-delivery services",
//   },
//   {
//     id: 4,
//     title: "Client-Centric Approach",
//   },
//   {
//     id: 5,
//     title: "Transparent workflow",
//   },
//   {
//     id: 6,
//     title: "Professional & Result Oriented team",
//   },
//   {
//     id: 7,
//     title: "Client-Centric Approach",
//   },
// ];
const analysisData = [
  {
    id: 1,
    img: "/images/technologies/latest-thinking/computer-vision/icons/facial-recognition.svg",
    alt: "Facial recognition",
    title: "Facial recognition",
    desc: "A solution that Identifies and authenticates a specific person by their facial features from an image or video. ",
  },
  {
    id: 2,
    img: "/images/technologies/latest-thinking/computer-vision/icons/emotion.svg",
    alt: "smiling emoji",
    title: "Emotion & pose recognition",
    desc: "A solution to track and analyze human movement and activity and give user feedback. This can be resourceful in assessing the level of a customer’s satisfaction to solve unique business challenge.",
  },
  // {
  //   id: 3,
  //   img: "/images/technologies/latest-thinking/computer-vision/icons/computer-aided.svg",
  //   alt: "",
  //   title: "Computer-aided diagnosis",
  //   desc: " a solution that can Read X-ray images, CT, PET and MRI scans, ultrasound scans (including 3D and 4D), isotope scans, etc. Enhancing clinical images, measuring organ dimensions and blood flow, detecting pathological signs and suggesting a diagnosis.",
  // },
  {
    id: 4,
    img: "/images/technologies/latest-thinking/computer-vision/icons/object.svg",
    alt: "Object Detedtion",
    title: "Object Detection, Tracking and Labelling",
    desc: "A solution that can extract insights from visual data for various needs such as medical image processing, virtual try-ons, visual search, etc.  ",
  },
  {
    id: 5,
    img: "/images/technologies/latest-thinking/computer-vision/icons/data.svg",
    alt: "Data",
    title: "OCR & Data Capture",
    desc: "A custom automated document processing solution that can capture data and extract solutions. ",
  },
];

const Why = [
  {
    id: "1",
    title: "Customizable solutions",
    desc: "We don’t settle for average. The solutions that we deliver are tailored to fit our customer’s needs and the nature of their data. ",
  },
  {
    id: "2",
    title: "Highly experienced team",
    desc: "We pursue greatness in every development we undertake to provide our customers with best-in-class solutions in computer vision applications development.",
  },
  {
    id: "3",
    title: "Cloud infrastructure ",
    desc: "Our leading ML engineers, consultants, and infrastructure architects work together to achieve the best performance for our customers. ",
  },
  {
    id: "4",
    title: "Quality at scale",
    desc: "Our tried-and-true processes offer correct data in a secure and timely manner, and they're built to scale and change with your needs.",
  },
  // {
  //   id: "5",
  //   title: "Client engagement and leveragability",
  //   desc: "We take a long-term approach to involvement with our clients, both during and after their transformation journeys. If our clients require it, we can use all or part of our enterprise level digital transformation platform (SmartOps) to ensure that an end-to-end digital transformation is possible.",
  // },
  // {
  //   id: "6",
  //   title: "Professional team",
  //   desc: "large team of highly qualified, certified, and experienced Artificial Intelligence professionals capable of providing best-in-class artificial intelligence services.",
  // },
];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];

export default function ComputerVision() {
  const listAnalysis = analysisData.map((item, index) => {
    return (
      <div className="flex">
        <img className="h-[64px]" src={item.img} />
        <div className="pl-[40px] ">
          <h1 className="text-[20px] md:text-[24px] font-medium">
            {item.title}
          </h1>
          <p className="pt-[10px]">{item.desc}</p>
        </div>
      </div>
    );
  });
  return (
    <>
      <Banner
        button="Talk To Experts"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fcomputer-vision%2Fcomputer-vision.jpeg?alt=media&token=4fa338b9-4749-41b7-b222-e4d8839ff431"
        title="Computer vision solution"
        desc="Leveraging next generation computer vision tech solutions to grow and improve your business"
      />
      <Overview
        textColor="text-white"
        title="Custom computer vision & machine learning solutions."
        desc="Computer vision (CV) is an artificial intelligence-based technology that helps global customers bring innovations to their organizations by analyzing visual data, digitization of paper-based processes, automating video surveillance etc. With this technology you can add value to your business with ML algorithms for robust computer vision solutions with image analysis, object detection and video analysis. Our software development team at Innorik, provides Computer Vision solutions that can improve your business performance, and drive your revenue growth.  Some of the industries we help fuel growth with CV are Healthcare, Manufacturing, Fitness and Sports, E-commerce, Marketing, Logistics, and others.  "
        img="/images/technologies/latest-thinking/computer-vision/computer-vision-solution-overview.jpeg"
        img2="/images/technologies/latest-thinking/computer-vision/computer-vision-solution-overview-mobile.jpeg"
        alt="servers"
      />
      <div className="">
        <div
          className="bg-no-repeat bg-cover bg-center"
          style={{
            backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fcomputer-vision%2Fcomputer-vision-services.jpeg?alt=media&token=71899799-5e74-418a-a9bb-1a6f9919289e")`,
          }}
        >
          <div className="max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] py-[20px] md:py-[30px] lg:py-[70px] text-center text-white mx-auto">
            <h1 className="text-[24px] font-medium md:text-[28px] lg:text-[34px]">
              Our Computer Vision Services
            </h1>
            {/* <p className="text-[16px] lg:text-[18px] pt-[20px] lg:pt-[35px]">
              Our computer vision software development team builds custom
              solutions that perform these and many more:
            </p> */}
          </div>
        </div>
      </div>
      <div className="max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] mx-auto py-[50px]">
        <div className="text-left  text-[#222222]">
          <h1 className="text-[24px] font-medium lg:text-[28px]">
            1. Image Analysis Solutions
          </h1>
          <p className="lg:pt-[25px] pt-[20px]">
            Our computer vision software development team builds custom
            solutions that perform these and many more:{" "}
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[50px] lg:pt-[50px] pt-[40px]">
          {listAnalysis}
        </div>
        <div className="text-left pt-[40px] lg:pt-[50px]  text-[#222222]">
          <h1 className="text-[24px] font-medium lg:text-[28px]">
            2. Computer Vision products integration{" "}
          </h1>
          <p className="lg:pt-[25px] pt-[20px]">
            We provide a range of computer vision software solutions that are
            backed by our continuous technology research and a track record of
            successful implementations.
          </p>
        </div>
        <div className="text-left pt-[25px] md:pt-[40px]  text-[#222222]">
          <h1 className="text-[24px] font-medium lg:text-[28px]">
            3. CV app development
          </h1>
          <p className="lg:pt-[25px] pt-[20px]">
            We develop slid computer vision-powered applications aligned to your
            business specifics and needs.
          </p>
        </div>
        <div className="text-left pt-[25px] md:pt-[40px]  text-[#222222]">
          <h1 className="text-[24px] font-medium lg:text-[28px]">
            4. Design and optimization
          </h1>
          <p className="lg:pt-[25px] pt-[20px]">
            Our in-house development team can design and optimize the solution
            for the specific platforms and devices per client’s requirement.{" "}
          </p>
        </div>
      </div>
      {/* <WhyChoose
        textColor="text-white"
        desc="Extensive experience with the IoT technology to develop comprehensive IoT solutions. "
        head="Why choose us for your Computer Vision Solutions?"
        data={Data3}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fcomputer-vision%2Fwhy-choose-computer-vision-linear.jpeg?alt=media&token=a77df477-b894-4809-82e1-c74c8ddc5e78"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fcomputer-vision%2Fmobile%2Fcomputer-vision-image.jpeg?alt=media&token=650ce9d0-3fc4-4f4f-a5e3-c63340f46c0f"
      /> */}
      <Industries
        title="Industies Served"
        desc="We are international company and we deliver technology solutions to clients globally."
        data={Indust}
      />
      <WhyChooseText
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FProfessionalServices%2FwhyBackground.png?alt=media&token=03d970d1-9dd2-403e-8549-9c63d23568dd"
        data={Why}
        title="Why partner with us?"
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
