import React from "react";

export default function WhyPython(props) {
  const data = props.data;
  const listData = data.map((list, index) => {
    return (
      <div>
        <img className="h-[50px] mx-auto" src={list.img} />
        <p className="text-center pt-[20px] lg:pt-[25px] text-[16px] lg:text-[18px]">
          {list.desc}
        </p>
      </div>
    );
  });
  return (
    <>
      <div className="max-w-[1500px] mx-auto px-[28px] pt-[10px] md:pt-[50px] md:px-[40px] lg:px-[85px]">
        <div>
          <h1 className="text-center text-[24px] md:text-[28px] lg:text-[36px]">
            {props.title}
          </h1>
        </div>
        <div className="grid  grid-cols-2 md:grid-cols-6 lg:grid-cols-6 gap-2 gap-y-8  py-[25px] lg:py-[50px]">
          {listData}
        </div>
      </div>
    </>
  );
}
