import Banner from "../../../../components/Banner";
import WhyChoose from "../../../../components/WhyChoose";
import OurDevelopers from "../Magento/OurDevelopers";
// import Overview from "../Magento/Overview";
import WhatWeOffer from "../Magento/WhatWeOffer";
// import Overview from "../../../CloudAppliationIntegration/Overview";

import ConsultationIcon from "../../../../Assets/Images/technologies/platforms/salesforce/icons/consultation.svg";
import ImplementIcon from "../../../../Assets/Images/technologies/platforms/salesforce/icons/implement.svg";
import ManagementIcon from "../../../../Assets/Images/technologies/platforms/salesforce/icons/management.svg";
import SupportIcon from "../../../../Assets/Images/technologies/platforms/salesforce/icons/support.svg";
import BannerImage from "../../../../Assets/Images/technologies/platforms/salesforce/salesforce-banner.jpg";
import OfferingImage from "../../../../Assets/Images/technologies/platforms/salesforce/Salesforce-What-we-offering.jpg";
import WhyImageMobile from "../../../../Assets/Images/technologies/platforms/salesforce/why-choose-salesforce-mobile.jpg";
import WhyImage from "../../../../Assets/Images/technologies/platforms/salesforce/why-choose-salesforce.jpg";
import FooterBanner from "../../../../components/Footer/FooterBanner";
import { phpDevelopers } from "../../../../data/programming";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import Infographics from "../MicrosoftDynamic365/Infographics";

const weOffer = [
  {
    id: 1,
    img: ConsultationIcon,
    title: "Salesforce Consulting",
    desc: "We give the best Salesforce consulting services based on the clients' specific requirements. Our consultants will help you unlock the true potential of this platform, which can help you speed up your business processes.     ",
  },
  {
    id: 2,
    img: ImplementIcon,
    title: "Salesforce CRM integration",
    desc: "We deliver real-time Salesforce CRM integration that is seamless, easy to comprehend, and provides the best marketing automation systems, ERP systems, banking systems, and financial systems for the firm.",
  },
];
const weOffer2 = [
  {
    id: 1,
    img: ManagementIcon,
    title: "Salesforce data migration services ",
    desc: "Data transfer from websites and apps to the Salesforce platform is something we specialize in. Our migration solutions, which we devised and implemented, assist our clients in achieving the highest levels of data security and integrity.",
  },
  {
    id: 2,
    img: SupportIcon,
    title: "Salesforce maintenance & support ",
    desc: "With data management, user and employee administration, and security management, the CMS website completely supports and maintains its salesforce services. We also incorporate new developing technology to keep you ahead of the curve. ",
  },
];
const Data3 = [
  {
    id: 1,
    title: "360-degree Salesforce services",
  },
  {
    id: 2,
    title: "In-depth industry knowledge",
  },
  {
    id: 3,
    title: "24 × 7 availability",
  },
  {
    id: 4,
    title: "A pool of smart and experienced developers ",
  },
  {
    id: 5,
    title: "Reduced cost to 50% of our competitors ",
  },
];
const weOffer3 = [
  {
    id: 1,
    img: "/images/technologies/platforms/salesforce/repair.svg",
    title: "Salesforce development",
    desc: "Our Salesforce developers have extensive development experience and are up to date on the latest technology. Our development team provides adaptable solutions that are flexible, secure, resilient, scalable, and user-friendly. ",
  },
  {
    id: 2,
    img: "/images/technologies/platforms/salesforce/process.svg",
    title: "Salesforce configuration",
    desc: "Our Salesforce developers are highly qualified and experienced in configuring, optimizing, and developing new Salesforce apps that are tailored to your business needs and allow you to make informed decisions.",
  },
];

export default function Salesforce() {
  return (
    <>
      <Banner
        img={BannerImage}
        title="SALESFORCE"
        desc="Transforming customer experience with Salesforce!"
      />
      <Overview
        textColor="text-[#222222] bg-[#f9f9f9]"
        title="The best CRM platform"
        desc="Salesforce, when used strategically, boosts productivity and improves the customer experience. Salesforce implementation services from Innorik address potential complications and hazards, allowing businesses to run smoothly. Our Salesforce installation services provide flawless deployment of various cloud CRM platforms, from accurate risk assessment to strategic approach and optimum technique. We provide a variety of Salesforce implementation services to clients across sectors by streamlining business operations while maintaining data integrity, security, and business continuity. We can deliver well-planned CRM deployment instances since we have Salesforce experts who are very experienced in working with Salesforce for many years. "
        img="/images/technologies/platforms/salesforce/salesforce-overview.jpg"
        img2="/images/technologies/platforms/salesforce/Salesforce-overview-mobile.jpg"
        alt="Microsoft Dy"
      />
      {/* <Overview
        color=""
        textColor="text-[#222222]"
        title="Overview"
        desc="Salesforce suite of products offers revenue generation and account management solutions focused on marketing, sales, commerce, service and IT teams work as one from anywhere for businesses - so you can keep your customers happy everywhere. The cloud based salesforce platform has changed the way businesses used and consumed CRM & marketing automation solutions. Now, salesforce has blurred the lines between marketing and sales because it unites your marketing, sales, commerce, service, and IT teams from anywhere with our Customer care solutions. With the introduction of this type of solutions you can now focus on your employees interest, stabilise your business and get back to delivering exceptional customer experiences."
        img={OverviewImage}
        img2={OverviewImageMobile}

      /> */}
      <WhatWeOffer
        img={OfferingImage}
        show="true"
        title="Our Salesforce Offerings"
        title2=""
        data={weOffer}
        data2={weOffer2}
        data3={weOffer3}
      />
      <Infographics />
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our Expertise"
        desc="We have certified Salesforce Professionals that can assist our clients in understanding the need of utilizing unified enterprise solutions like Salesforce and also help you customize and implement such, based on your organizational needs. "
        button="Hire Salesforce Professional"
        data={phpDevelopers}
      />
      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2Fphp%2FphpHero.jpg?alt=media&token=67832652-836b-4594-ace0-22fe0e18cc6a"
        title="Our Salesforce Experts"
        desc2="Our Team consist of highly motivated and committed engineers with over 20+ years’ in the IT business and over 10+ years in the CRM development. These professionals have both practical and professional experience in the development and consultation in IoT, data science, collaboration solutions, and other complex projects that go beyond Salesforce."
        desc="We have Certified Salesforce engineers and admins that can assist our clients in understanding the need of utilising unified enterprise solutions like Salesforce and also help you customise and implement such, based on your organisational needs."
        button="Hire Salesforce Expert"
      /> */}
      <WhyChoose
        textColor="text-white"
        desc="Extensive experience with the IoT technology to develop comprehensive IoT solutions. "
        head="Why choose Innorik for your Salesforce solutions?"
        data={Data3}
        img={WhyImage}
        img2={WhyImageMobile}
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
