import React, { useEffect } from "react";

export default function Offerings(props) {
  const data = props.data;

  // const dataCount = data.filter((item) => item.id);
  // const dataNumber = dataCount.length;
  // console.log(dataNumber);

  // const [items, setItems] = useState([]);
  // const [showMoreItems, setShowMoreItems] = useState(4);
  // const [showButton, setShowButton] = useState(true);
  // console.log(showMoreItems + "before");

  // const showMoreDataHandler = () => {
  //   // setShowButton()
  //   setShowMoreItems((prevValue) => prevValue + 2);
  //   // console.log(showMoreItems + "inside");
  //   if (showMoreItems >= dataNumber) {
  //     // console.log(showMoreItems + "OnClick");
  //     setShowButton(false);
  //   }
  // };

  // wangchu
  // wenice

  // const [items, setItems] = useState(4);

  const listData = data.map((item, index) => {
    return (
      <div key={index}>
        <img className="h-[48px] mx-auto lg:ml-0" src={item.img} alt="" />
        <h2 className=" text-[18px] pt-[10px] font-medium lg:text-[20px]">
          {item.title}
        </h2>
        <p className="text-[#6D6D6D] ">{item.desc}</p>
      </div>
    );
  });

  return (
    <>
      <div className="bg-[#F1F4FF]">
        <div className="max-w-[1500px] mx-auto px-[28px] md:px-[40px] py-[25px]  lg:py-[50px] lg:px-[85px]">
          <div className="text-center  md:px-[50px] lg:px-[100px]">
            <h1 className="text-[24px]  text-[#3066EA]  lg:text-[36px]">
              {props.title}
            </h1>
            {/* <p className="text-[16px] lg:text-[18px] text-[#222222] pt-[36px]">
              {props.desc}
            </p> */}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12  lg:grid-cols-12 pt-[50px] mx-auto">
            <img
              className="md:col-span-5 pb-[20px]  w-3/5 md:w-full mx-auto md:sticky  md:top-28 lg:pr-[40px]"
              src={props.img}
              alt=""
            />
            <div className="col-span-7 text-center mx-auto">
              <div className="grid lg:text-left md:px-[40px] md:grid-cols-1 lg:grid-cols-2 gap-[77px] ">
                {listData}
              </div>
              {/* {showButton ? (
                <div>
                  {dataNumber > 4 ? (
                    <div className="pt-[50px] pl-[40px] ml-0 float-left">
                      <button
                        onClick={showMoreDataHandler}
                        className="text-center text-white pl-auto mx-center py-[8px] px-[20px] bg-[#428f66]"
                      >
                        Show More
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
