import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="shadow-[10px_10px_30px_0px_rgba(42,67,113,0.15)]">
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          boxShadow: "10px",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          TabIndicatorProps={{
            style: {
              background: "#FF0000",
              height: "40px",
            },
          }}
          sx={{
            borderRight: 1,
            backgroundColor: "#00092A",
            borderColor: "divider",
            color: "#ffffff",
            paddingTop: "40px",
            paddingBottom: "40px",

            paddingLeft: "40px",
            width: "370px",
            //   paddingRight: "60px",
          }}
        >
          <Tab
            sx={{
              alignItems: "start",
              color: "#ffffff",
              textTransform: "capitalize",
              fontSize: "16px",
              paddingRight: "60px",
            }}
            label="Decreased operational costs"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              alignItems: "start",
              color: "#ffffff",
              textTransform: "capitalize",
              fontSize: "16px",
              paddingRight: "60px",
            }}
            label="Performance monitoring"
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              alignItems: "start",
              color: "#ffffff",
              textTransform: "capitalize",
              fontSize: "16px",
              paddingRight: "60px",
            }}
            label="Availability"
            {...a11yProps(2)}
          />
          <Tab
            sx={{
              alignItems: "start",
              color: "#ffffff",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
            label="Efficiency"
            {...a11yProps(3)}
          />

          <Tab
            sx={{
              alignItems: "start",
              color: "#ffffff",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
            label="Improved data integrity and security"
            {...a11yProps(4)}
          />
          <Tab
            sx={{
              alignItems: "start",
              color: "#ffffff",
              textTransform: "capitalize",
              fontSize: "16px",
            }}
            label="Access to real-time information"
            {...a11yProps(5)}
          />
        </Tabs>
        <TabPanel style={{ width: "70%" }} value={value} index={0}>
          <p className="text-[16px] text-[#222222] pt-[20px]">
            The flexible nature of our ERP system makes it very easy to
            integrate with other common systems like Oracle E-business suite,
            Microsoft Dynamics ERP and Computron financial system. These systems
            have been integrated with our ERP out of the box without the need to
            do any further customization to achieve integration requirements.
          </p>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={1}>
          <p className="text-[16px] text-[#222222] pt-[20px]">
            It ensures that the system is able to capture all transactions on
            the system, giving management the ability to monitor and track
            individual performance in the organization.
          </p>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={2}>
          <p className="text-[16px] text-[#222222] pt-[20px]">
            This system has accurate and timely access to reliable information,
            the ability to share information between all components of the
            organization and feasible login access from anywhere and at anytime.
          </p>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={3}>
          <div className="pl-[20px]">
            <h1 className="text-[24px] text-[#222222] ">Efficiency </h1>
            <ul className="pt-[20px] list-disc 	">
              <li className="pt-[10px]">
                Elimination of unnecessary operation and data
              </li>
              <li className="pt-[10px]">
                Optimization of business processes - The system eliminates
                repetitive processes and greatly reduces the need to manually
                enter information. The system will also streamline business
                processes and makes it easier and more efficient to collect
                data.
              </li>
              <li className="pt-[10px]">
                Integrated information - Instead of having data distributed
                throughout a number of separate databases, all information is
                now located in a single location. Data is also kept consistent
                and up-to-date
              </li>
              <li className="pt-[10px]">
                Reporting - The system helps make reporting easier and more
                customizable. With improved reporting capabilities, your company
                can respond to complex data requests conveniently. Users can
                also run their own reports without relying on help from IT.
              </li>
              <li className="pt-[10px]">
                Customer service improvement - It’s easier to provide
                high-quality customer service using the system as data is
                readily available to serve users.
              </li>
            </ul>
          </div>
        </TabPanel>

        <TabPanel style={{ width: "70%" }} value={value} index={4}>
          <p className="text-[16px] text-[#222222] pt-[20px]">
            Improved data integrity and security - A rise in unnecessary
            operating costs might be caused by misplaced, inaccurate, or
            out-of-date data. Through effective data movement and regular data
            duplication analysis, our ERP increases data integrity.
          </p>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={5}>
          <p className="text-[16px] text-[#222222] pt-[20px]">
            Access to real-time information – Our ERP system allows businesses
            to generate real-time information reports. The ability to access
            real-time data reduces the risk of data duplication and improves the
            workflow of all departments.
          </p>
        </TabPanel>
      </Box>
    </div>
  );
}
