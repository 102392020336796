const mobileData = [
  {
    tab: "Web Technologies",
    details: [
      {
        id: 1,
        img: "/images/company/about/icons/backend/dot-net.svg",
        desc: ".Net",
        alt: "microsoft .Net",
      },
      {
        id: 1,
        img: "/images/company/about/icons/backend/Golang.svg",
        desc: "Go Lang",
        alt: "Go Lang",
      },
      {
        id: 1,
        img: "/images/company/about/icons/backend/JAVA.svg",
        desc: "Java",
        alt: "java",
      },
      {
        id: 1,
        img: "/images/company/about/icons/backend/nodejs.svg",
        desc: "Node Js",
        alt: "node js",
      },
      {
        id: 1,
        img: "/images/company/about/icons/frontend/CSS3.svg",
        desc: "CSS",
        alt: "css",
      },
      {
        id: 1,
        img: "/images/company/about/icons/frontend/Html5.svg",
        desc: "HTML",
        alt: "html",
      },
      {
        id: 1,
        img: "/images/company/about/icons/frontend/JavaScript.svg",
        desc: "Javascript",
        alt: "javascript",
      },
      {
        id: 1,
        img: "/images/company/about/icons/backend/php.svg",
        desc: "Php",
        alt: "php",
      },
      {
        id: 1,
        img: "/images/company/about/icons/backend/Python.svg",
        desc: "Python",
        alt: "python",
      },
      {
        id: 1,
        img: "/images/company/about/icons/framework/AngularJS.svg",
        desc: "Angular Js",
        alt: "angular",
      },
      {
        id: 1,
        img: "/images/company/about/icons/framework/Ember.svg",
        desc: "Ember Js",
        alt: "UWP Apps",
      },
      {
        id: 1,
        img: "/images/company/about/icons/framework/meteor.svg",
        desc: "Meteor Js",
        alt: "meteor",
      },
      {
        id: 1,
        img: "/images/company/about/icons/framework/next.svg",
        desc: "Next Js",
        alt: "next js",
      },
      {
        id: 1,
        img: "/images/company/about/icons/framework/react.svg",
        desc: "React Js",
        alt: "react js",
      },
      {
        id: 1,
        img: "/images/company/about/icons/framework/vue.svg",
        desc: "Vue Js",
        alt: "vue js",
      },
    ],
  },
  {
    tab: "Devops",
    details: [
      {
        id: 1,
        img: "/images/company/about/icons/devops/Automation/Ansible.svg",
        desc: "Ansible",
        alt: "ansible",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/Automation/Chef.svg",
        desc: "Chef",
        alt: "chef",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/Automation/Packer.svg",
        desc: "Packer",
        alt: "packer",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/Automation/Puppet.svg",
        desc: "Puppet",
        alt: "puppet",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/Automation/Saltstack.svg",
        desc: "Saltstack",
        alt: "saltstack",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/Automation/Terraform.svg",
        desc: "Terraform",
        alt: "terraform",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/CICD Tools/Amazon-Web-Services.svg",
        desc: "AWS",
        alt: "aws",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/CICD Tools/Azure-DevOps.svg",
        desc: "Azure",
        alt: "azure",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/CICD Tools/Google-Developer-Tools.svg",
        desc: "Dev Tools",
        alt: "dev tools",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/CICD Tools/Jenkins.svg",
        desc: "Jenkins",
        alt: "jenkins",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/Containerization/Docker.svg",
        desc: "Docker",
        alt: "docker",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/Containerization/Kubernetes.svg",
        desc: "Kubernetes",
        alt: "kubernetes",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/Containerization/Mesos.svg",
        desc: "Mesos",
        alt: "mesos",
      },
      {
        id: 1,
        img: "/images/company/about/icons/devops/Containerization/Openshift.svg",
        desc: "Openshift",
        alt: "openshift",
      },
      {
        id: 1,
        img: "/images/company/about/icons/data-storage/NOSQL/Nifi.svg",
        desc: "Nifi",
        alt: "Nifi",
      },
    ],
  },

  {
    tab: "Mobile and database",
    details: [
      {
        id: 1,
        img: "/images/company/about/icons/mobile/Flutter.svg",
        desc: "Flutter",
        alt: "flutter",
      },
      {
        id: 1,
        img: "/images/company/about/icons/mobile/Cordova.svg",
        desc: "Cordova",
        alt: "Cordova",
      },
      {
        id: 1,
        img: "/images/company/about/icons/mobile/Ionic.svg",
        desc: "Ionic",
        alt: "ionic",
      },
      {
        id: 1,
        img: "/images/company/about/icons/mobile/pwa.svg",
        desc: "PWA",
        alt: "pwa",
      },
      {
        id: 1,
        img: "/images/company/about/icons/mobile/React-Native.svg",
        desc: "Native",
        alt: "react native",
      },
      {
        id: 1,
        img: "/images/company/about/icons/mobile/Xamarin.svg",
        desc: "Xamarin",
        alt: "Xamarin",
      },
      {
        id: 1,
        img: "/images/company/about/icons/data-storage/SQL/mysql.svg",
        desc: "My Sql",
        alt: "",
      },

      {
        id: 1,
        img: "/images/company/about/icons/data-storage/SQL/oracle.svg",
        desc: "Oracle",
        alt: "oracle",
      },
      {
        id: 1,
        img: "/images/company/about/icons/data-storage/SQL/PostgreSQL.svg",
        desc: "Postgre SQL",
        alt: "Postgre SQL",
      },
      {
        id: 1,
        img: "/images/company/about/icons/data-storage/SQL/SQL.svg",
        desc: "SQL",
        alt: "sql",
      },

      {
        id: 1,
        img: "/images/company/about/icons/data-storage/SQL/mySql2.svg",
        desc: "My SQL",
        alt: "my sql",
      },
      {
        id: 1,
        img: "/images/company/about/icons/data-storage/NOSQL/apache.svg",
        desc: "Apache",
        alt: "",
      },

      {
        id: 1,
        img: "/images/company/about/icons/data-storage/NOSQL/Cassandra.svg",
        desc: "Cassandra",
        alt: "Cassandra",
      },
      {
        id: 1,
        img: "/images/company/about/icons/data-storage/NOSQL/Hive.svg",
        desc: "Hive",
        alt: "Hive",
      },
      {
        id: 1,
        img: "/images/company/about/icons/data-storage/NOSQL/MongoDB.svg",
        desc: "MongoDB",
        alt: "MongoDB",
      },
    ],
  },

  {
    tab: "Big Data",
    details: [
      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/AWS/AWS.svg",
        desc: "AWS",
        alt: "aws",
      },

      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/AWS/documentDB.svg",
        desc: "DocumentDB",
        alt: "document db",
      },
      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/AWS/elasticSearch.svg",
        desc: "Elastic Search",
        alt: "elastic search",
      },
      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/AWS/RDS.svg",
        desc: "RDS",
        alt: "RDS",
      },

      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/AWS/redshift.svg",
        desc: "Redshift",
        alt: "redshift",
      },

      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/AWS/S3.svg",
        desc: "S3",
        alt: "s3",
      },

      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/Azure/Azure-Cosmos-DB.svg",
        desc: "Cosmos DB",
        alt: "cosmos db",
      },

      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/Azure/azure-sql.svg",
        desc: "Azure sql",
        alt: "azure sql",
      },

      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/Azure/Azure-Data-Lake.svg",
        desc: "Data Lake",
        alt: "data lake",
      },

      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/Azure/Azure-Synapse-Analytics.svg",
        desc: "Synapse",
        alt: "Synapse",
      },

      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/Azure/AzureBlobStorage.svg",
        desc: "Blob Storage",
        alt: "blob storage",
      },

      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/google-cloud/Google-Cloud-Datastore.svg",
        desc: " Cloud",
        alt: "google cloud",
      },
      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/google-cloud/google-cloud-sql.svg",
        desc: "Google SQL",
        alt: "google cloud sql",
      },
      {
        id: 1,
        img: "/images/company/about/icons/cloud-databases/google-cloud/google-cloud.svg",
        desc: "G Cloud",
        alt: "google cloud",
      },
    ],
  },
];
export { mobileData };
