import React from "react";

export default function SectionWithImage(props) {
  return (
    <div className=" bg-black">
      <div className="conatiner lg:px-[85px] px-[28px] md:px-[40px] flex lg:flex-row  flex-col-reverse max-w-[1400px] mx-auto  text-white w-full py-[40px]">
        <div className="m-auto mx-0 w-full ">
          <p className="text-justify md:text-center lg:pr-4 lg:text-left text-[16px] lg:text-[18px] m-auto  sm:py-10 sm:text-left ">
            The structure of organizations and jobs are changing as new
            technologies are evolving like artificial intelligence, data
            analytics, deep learning, quantum computing, machine learning and
            other digital capabilities. These are overwhelming the workplace,
            therefore, it is imperative that we constantly train our associates
            to develop their capabilities. Also, it is Innorik’s mission to
            empower new talents to take up new tech positions. This is why we
            are excited to invest in new talents like you to be fully equipped
            for the jobs of the future.
          </p>
        </div>
        <div className="m-auto w-full lg:w-2/5  pb-[10px]">
          <div className="m-auto lg:mr-0 md:w-full g:w-full">
            <img
              className="m-auto h-[120px] md:h-[165px] md:mt-0 lg:mr-0 md:mb-0 "
              src={props.img}
            />
          </div>
        </div>
      </div>
      {/* <div className="invisible lg:visible h-0 lg:h-[500px] bg-black grid lg:grid-cols-2 lg:gap-4 items-center lg:last:space-x-40">
        <p className="w-[90%] text-white pl-32 text-left">
          The structure of organizations and jobs are changing as new
          technologies are evolving like artificial intelligence, data
          analytics, deep learning, quantum computing,machine learning and other
          digital capabilities and these are overwhelming the workplace, it’s
          therefore imperative that we constantly train our associates to
          develop their capabilities. It’s also Innorik’s mission to empower new
          talents to take up new tech positions that is why we are excited to
          invest in new talents like you to be fully equipped for the jobs of
          the future.
        </p>
        <div className="">
          <img
            className="h-[18rem]"
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/mind.jpg?alt=media&token=c22f5eb1-559f-49fe-990b-9361c9da4e00"
          ></img>
        </div>
      </div>
      <div className=" visible lg:invisible lg:h-0 md:h-[486px] bg-black grid lg:grid-rows-2 lg:gap-  items-center ">
        <div className="flex content-center justify-center pt-[55px] md:pt-[60px]">
          <img
            className="h-[120px] md:h-[165px]  content-center	"
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/mind.jpg?alt=media&token=c22f5eb1-559f-49fe-990b-9361c9da4e00"
          ></img>
        </div>
        <p className=" md:text-[18px] pb-[53px] text-[14px] pt-[8px] text-white pl-[27px] md:pl-[40px] md:pr-[40px] pr-[27px] text-center">
          The structure of organizations and jobs are changing as new
          technologies are evolving like artificial intelligence, data
          analytics, deep learning, quantum computing,machine learning and other
          digital capabilities and these are overwhelming the workplace, it’s
          therefore imperative that we constantly train our associates to
          develop their capabilities. It’s also Innorik’s mission to empower new
          talents to take up new tech positions that is why we are excited to
          invest in new talents like you to be fully equipped for the jobs of
          the future.
        </p>
      </div> */}
    </div>
  );
}
