import React, { useState } from "react";
import { TestingData } from "./Data";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { FiPlus, FiMinus } from "react-icons/fi";

const AccordionSection = styled.div`
  flex-direction: column;
  align-items: start;

  justify-content: center;
  position: relative;
`;

const Container = styled.div`
  left: 4%;
  top: 10%;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #e7e7e7;

  h1 {
    padding: 1rem;
  }
  span {
    margin-right: 1.5rem;
  }
`;

const Dropdown = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export default function Accordian() {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <IconContext.Provider value={{ color: "#222222", size: "25px" }}>
      <AccordionSection className="md:px-[4% ]">
        <Container className=" ">
          {TestingData.map((item, index) => {
            return (
              <>
                <div>
                  <Wrap onClick={() => toggle(index)} key={index}>
                    <h1 className="text-[18px] lg:text-[18px] text-left">
                      {item.question}
                    </h1>
                    <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                  </Wrap>
                  {clicked === index ? (
                    <Dropdown className="text-left pl-4">
                      {/* <p className="text-[16px]">{item.answer}</p> */}
                      <ul className="text-[#686868]">
                        <li> {item.li1}</li>
                        <li> {item.li2}</li>
                        <li> {item.li3}</li>
                        <li> {item.li4}</li>
                        <li> {item.li5}</li>
                        <li> {item.li6}</li>
                        <li> {item.li7}</li>
                        <li> {item.li8}</li>
                      </ul>
                    </Dropdown>
                  ) : null}
                </div>
              </>
            );
          })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
  );
}
