import React from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import "./home.css";
// const Indust = [
//   {
//     id: 1,
//     nav: "/industries/healthcare",
//     title: "Healthcare",
//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
//   },
//   {
//     id: 2,
//     nav: "/industries/manufacturing",

//     title: "Manufacturing",
//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
//   },
//   {
//     id: 3,
//     title: "Insurance",
//     nav: "/industries/insurance",

//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
//   },
//   {
//     id: 4,
//     title: "Fintech",
//     nav: "/industries/fintech",

//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
//   },
//   {
//     id: 5,
//     title: "Retail",
//     nav: "/industries/retail",

//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
//   },
//   {
//     id: 6,
//     nav: "/industries/transportation",

//     title: "Transportation & Logistics",

//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
//   },
//   {
//     id: 7,
//     nav: "/industries/professional-services",

//     title: "Professional services",
//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
//   },
// ];
const PreviousBtn = (props) => {
  // console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <AiOutlineArrowLeft
        className="rounded-[100%] shadow-[0_5px_60px_5px_rgba(3,2,10,0.5)] bg-white"
        style={{
          color: "#222222",
          fontSize: "40px",
          marginLeft: "-30px",
          padding: "6px",
          // border: "2px solid",
        }}
      />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <AiOutlineArrowRight
        className="rounded-[100%] shadow-[0_5px_60px_5px_rgba(3,2,10,0.5)] bg-white"
        style={{
          color: "#222222",
          fontSize: "40px",
          marginLeft: "10px",
          padding: "6px",
          // border: "2px solid",
        }}
      />
    </div>
  );
};

export default function Spotlight(props) {
  const settings = {
    infinite: true,
    autoplay: false,
    slidesToShow: 3,
    autoplaySpeed: 2000,
    speed: 500,
    // dots: true,
    pauseOnHover: true,

    centerMode: true,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    // appendDots: (dots) => (
    //   <div>
    //     <ul> {dots} </ul>
    //   </div>
    // ),
  };
  const data = props.data;
  const renderSlides = () =>
    data.map((num, index) => (
      <div key={index}>
        <div className="w-full  md:w-auto  shrink-0 pt-5 pb-4 px- mx-4 relative h-[480px] lg:h-[480px] 2xl:h-[520px]">
          <Link to={num.nav}>
            <img
              className="w-full aspect-[336/221]  object-cover object-center"
              src={num.img}
              alt={num.alt}
            />
            <p className="pt-5 text-[18px]  md:text-[20px] lg:text-[20px] ">
              {num.title}
            </p>

            <p className="text-[14px] text-[#767676] pt-[12px] ">
              {`${num.desc.split(" ").splice(0, 28).join(" ")}...`}
            </p>
            <p className="text-[#3066EA] pt-[16px]  cursor-pointer  bottom-0 ">
              Learn More
            </p>
          </Link>
        </div>
      </div>
    ));
  const renderSlides2 = () =>
    data.map((num, index) => (
      <div key={index}>
        <div className="w-full  md:w-auto  shrink-0 pt-5 pb-4 ">
          <Link to={num.nav}>
            <img
              className="w-full aspect-[336/221]  object-cover object-center"
              src={num.img}
              alt={num.alt}
            />
            <p className="pt-5 text-[18px]  md:text-[20px] lg:text-[20px] ">
              {num.title}
            </p>

            <p className="text-[14px] text-[#767676] pt-[12px]">
              {/* {`${num.desc.substring(0, 170)}... `} */}
              {`${num.desc.split(" ").splice(0, 25).join(" ")}...`}
            </p>
            <p className="text-[#3066EA] pt-[24px]  cursor-pointer  bottom-0">
              Learn More
            </p>
          </Link>
        </div>
      </div>
    ));

  return (
    <div className=" py-[20px]">
      <div className="  md:px-[40px] px-[28\px] mx-ato lg:w-full">
        <div className="invisible md:px-[85px] md:visible lg:visible h-0 w-0 md:w-auto md:h-auto lg:h-auto  md:col-span-3 ">
          <Slider {...settings}>{renderSlides()}</Slider>
        </div>
        <div className="visible md:invisible lg:invisible h-auto md:h-0 lg:h-0  md:col-span-3 ">
          <Slider
            infinite={true}
            autoplay={true}
            slidesToShow={1}
            autoplaySpeed={2000}
            speed={500}
            dots={false}
            pauseOnHover={true}
            arrows={false}
            // centerMode={true}
            // prevArrow={<PreviousBtn />}
            // nextArrow={<NextBtn />}
            // focusOnSelect={true}
            // appendDots= {(dots) => (
            //   <div>
            //     <ul> {dots} </ul>
            //   </div>
            // ),
          >
            {renderSlides2()}
          </Slider>
        </div>
      </div>
    </div>
  );
}
