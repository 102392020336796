import React from "react";
// import List from "../../../components/Cards/List";

export default function AssesmentSoftware(props) {
  return (
    <>
      {/* change image for this section for mobile screen because it's hard to read text on image */}
      <div className="">
        {" "}
        <div className="max-w-[1500px] mx-auto lg:px-[85px] md:px-[40px] px:[28px]  invisible h-0 md:h-auto lg:h-auto sm:h-0 lg:visible md:visible">
          <div className="grid py-[48px]  grid-cols-12 justify-between  m-auto  text-center lg:text-left bg-cover bg-right ">
            <div className="  col-span-7 text-[#222222]  self-center	 lg:flex-row  lg:py-[30px] md:py-[40px] py-[56px] ">
              <div className="md:m-auto w-full justify-start">
                <h2 className=" px-[%] text-semibold text-[#5B8BFF] w-full text-[18px] lg:text-[26px] sm:mt-0 sm:mb-0 text-left lg:mr-0">
                  {props.title}
                </h2>
                <p className=" md:text-justify md:pr-[40px] py-[1%]  text-semibold  w-full text-[16px] lg:text-[18px] sm:mt-0 sm:mb-0 text-left lg:mr-0">
                  {props.desc}
                </p>
                {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
              </div>
            </div>
            <img
              className="ml-0  my-auto col-span-5 mx-auto  "
              src={props.img}
            ></img>
          </div>
        </div>
      </div>

      <div className=" bg-[#f9f9f9] pt-[20px] visible md:invisible lg:invisible xl:invisible md:h-0 lg:h-0 grid m-auto gap-0 w-full">
        <img
          className=" sm:h-full w-4/5 mx-auto object-cover object-top"
          src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FFintech%2FBankingSoftware1.png?alt=media&token=2bcbbe11-c042-469b-916f-4151e89e3d8f"
        />
        <div className=" text-[#222222] pt-8 ">
          <div className="md:m-auto w-full justify-start px-[5%]">
            <h2 className="  text-semibold  text-[24px] text-left ">
              {props.title}
            </h2>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
        </div>
        <div className=" text-[#222222] py-4 md:pr-4 mt-0">
          <p className=" text-justify  py-[1%] px-[5%] text-semibold  lg:w-full sm:w-full md:w-full text-[16px] lg:text-[18px] sm:mt-0 sm:mb-0  lg:mr-0">
            {props.desc}
          </p>
          {/* <ul className=" text-left list-outside text-[16px] lg:text-[18px] m-auto  w-11/12 py-4 sm:py-2 leading-loose">
           
            {dataList}
          </ul> */}
        </div>
      </div>

      {/*       
      <div
        className="m-auto py-[15%] sm:py-[5%] md:py-[5%] lg:py-[5%] text-center lg:text-left bg-cover bg-right h-full sm:h-[] md:h-[] lg:h-full xl:h-[]"
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <h1 className="px-[20px]  lg:w-1/2  text-[24px] lg:text-[36px] sm:text-[40px] md:text-[40px]  text-[#222222] ">
          Your cloud transformation journey is unique, let’s give you a helping
          hand
        </h1>
        
      </div> */}
    </>
  );
}
