import React from "react";
import List from "../../../../components/Cards/List";

export default function WhyChooseUs(props) {
  const newData = props.data;
  const dataList = newData.map((data, index) => {
    return (
      <div className="pt-[10px]">
        <List key={index} title={data.title} />
      </div>
    );
  });
  return (
    <>
      <div className="bg-[#252525]">
        <div className="py-[50px] grid text-white lg:grid-cols-12 md:grid-cols-2 grid-cols-1 mx-auto max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px]">
          <div className="my-auto md:ml-0 mx-auto lg:ml-0 ml-0 lg:text-left text-left col-span-5 w-4/5">
            <h1 className="text-[26px]">{props.title}</h1>
            <p className="pt-[16px] text-[16px] lg:text-[18px]">{props.desc}</p>
          </div>
          <div className="grid col-span-7 grid-cols-1 md:grid-cols-2 md:pt-[40px] pt-[20px] lg:pt-0">
            {dataList}
          </div>
        </div>
      </div>
    </>
  );
}
