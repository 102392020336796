import React from "react";
import AccordianColumn from "../../components/AccordianColumn";
// import List from "../../components/Cards/List";

export default function OurQA(props) {
  return (
    // oh man this is nice
    <div className="bg-[#F9F9F9] ">
      <h1 className=" lg:pt-[50px] pt-[25px]  px-[28px] md:px-[40px] lg:px-[85px] text-center text-[22px] md:text-[28px] lg:text-[36px]">
        {props.title}
      </h1>
      <div className="conatiner  flex lg:flex-row max-w-[1500px] mx-auto  lg:px-[85px] flex-col-reverse md:flex-col-reverse w-full    py-[5%]">
        <div className="conatiner px-6 md:px-8 lg:w-2/5 xl:1/2 text-black flex flex-col   ">
          <AccordianColumn />
        </div>
        <div className="ml-auto pl-[5%]  pb-[10px]">
          <div className="m-auto ">
            <img
              className="m-auto  md:h-[300px] lg:h-[500px] sm:h-[300px]  bg-fixed bg-no-repeat sm:w-[100vw] md:w-[100vw] lg:w-[580px] sm:object-cover md:object-cover md:center object-top	 lg:ml-auto right-0 pt-2"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2Ftesting%2FOur%20QA%20and%20Software%20Testing%20Services.jpg?alt=media&token=fbfb4173-9d96-474a-bf92-0f894dbbd687"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
