import React from "react";
import List from "../../components/Cards/List";

export default function DevopsApproach(props) {
  const newData = props.data;
  const dataList = newData.map((data, index) => {
    return <List key={index} title={data.title} />;
  });
  return (
    <div className="px-[28px] max-w-[1500px] mx-auto md:px-[40px] lg:px-[85px] pt-6 lg:pt-8 pb-8 ">
      <div className="conatiner bg-white shadow-xl flex lg:flex-row  flex-col md:flex-row w-full lg:pl-[60px] lg:pr-[85px] py-[40px]">
        <div className="m-auto w-full md:w-1/2 lg:w-1/2 pb-[10px]">
          <div className="m-auto w-full">
            <img
              className="m-auto w-full sm:w-1/2 md:w-full lg:w-full pt-2"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FDevops%2Fwhy-devops.svg?alt=media&token=3124d316-e983-463f-a133-a195e221be3b"
            />
          </div>
        </div>
        <div className="conatiner text-black flex flex-col  w-full ">
          <div className="md:m-auto  w-full justify-center pl-[5%] lg:pl-0 ">
            <h2 className="m-auto lg:pl-[40%]  text-semibold  text-[24px] lg:text-[36px] text-left sm:mt-0 sm:mb-0 lg:text-left lg:mr-0">
              {props.head}
            </h2>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
          <div className="m-auto lg:pl-[40%] md:pl-[0%]  w-full pt-2 ">
            <ul className=" list-outside grid grid-cols-1 md:w-[100%]  md:grid-cols-2 lg:grid-cols-1  text-[16px] lg:text-[18px] m-auto  w-11/12 sm:py-2 leading-loose">
              {dataList}
            </ul>
            {/* <p className="text-justify md:text-left  text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-center">
              Cloud technologies are the key enabler to achieve digital
              transformation. Innorik’s infrastructure management solution help
              businesses deliver high-quality, cost-effective, and secure
              application services on-demand. At Innorik, our digital
              transformation goal is to help your business achieve your desired
              cloud computing state across private, public and hybrid cloud
              environments. Our comprehensive cloud digitalization plan will
              enable your business to expand your application delivery chain
              including your mission-critical legacy systems into the cloud.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
