import React from "react";
import Banner from "../../../../components/Banner";
import FooterBanner from "../../../../components/Footer/FooterBanner";
import WhyChoose from "../../../../components/WhyChoose";
import Overview from "../ArtificialIntellegence/Overview";
// import Overview from "./Overview";
import Services from "./Services";

const servicesData = [
  {
    id: 1,
    img: "/images/technologies/latest-thinking/big-data/it-engineer-work.jpeg",
    alt: "Data ingestion ",
    title: "Data ingestion ",
    desc: "Our skills assist in discovering viable data sources and developing a reliable data ingestion method for collecting, ingesting, and managing diverse data sources.",
  },
  {
    id: 2,
    img: "/images/technologies/latest-thinking/big-data/data-storage.jpeg",
    alt: "Data storage",
    title: "Data storage",
    desc: "Our knowledge of databases, numerous tech stacks, and database models such as graph, search, columnar, and time series ensures that we select the best possible solution for your use case in order to make data available quickly and in the manner you require. ",
  },

  {
    id: 3,
    img: "/images/technologies/latest-thinking/big-data/data.jpeg",
    alt: "Data pipeline ",
    title: "Data pipeline ",
    desc: "Our skilled data engineering teams provide data pipelines with integrated quality checks to handle stream (real-time) or batch (historical) data to manage amount, diversity, and velocity of data.",
  },
  {
    id: 4,
    img: "/images/technologies/latest-thinking/big-data/analytics.jpeg",
    alt: "BI & analytics",
    title: "BI & analytics",
    desc: "Businesses also use our BI and Analytics capabilities to create interactive and self-service dashboards that allow them to consume and display their data to draw insights for better decision making. We use standard BI platforms or create unique ones to meet the demands of your company.",
  },
  {
    id: 4,
    img: "/images/technologies/latest-thinking/big-data/aritificial-intellegence.jpeg",
    alt: "Artificial intelligence",
    title: "Artificial intelligence",
    desc: "Our artificial intelligence practice​ allows the business to build custom models to automate decision making.",
  },
  {
    id: 4,
    img: "/images/technologies/latest-thinking/big-data/consulting.jpeg",
    alt: "Big Data consulting",
    title: "Big Data consulting",
    desc: "With our Big Data analytic solutions, we give suggestions on information quality administration customized to clients’ present innovation landscape, preferences, and objectives in defining their big data strategy and selecting the appropriate technology, tools, and processes to achieve such strategic goals. ",
  },
];
const Data3 = [
  {
    id: 1,
    title: "Faster and better decision making",
  },
  {
    id: 2,
    title: "Improved customer service",
  },
  {
    id: 3,
    title: "Experienced with Apache Spark and Hadoop",
  },
  {
    id: 4,
    title: "Ensuring asset authenticity",
  },
  {
    id: 5,
    title:
      "Skilled in working with AWS analytical tools, like EMR and Kinesis.",
  },
  {
    id: 6,
    title: "Post-delivery services",
  },

  {
    id: 7,
    title: "Competitive advantage ",
  },
];
export default function BigData() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fbig-data%2Fbig-data.jpeg?alt=media&token=a05084a0-981c-475b-b91d-b277ed3d2ca4"
        title="Big Data Services"
        desc="Unearthing useful insights to enable our big data consulting services assist businesses in making data-driven decisions."
      />
      <Overview
        textColor="text-white"
        title="Why modern enterprise data strategy? "
        desc="In this undeniably digitized world, how much information produced is developing at an exponential rate. All businesses have difficulties overseeing and figuring out this huge data and seek best practices to handle data meaningfully. Innorik has a proven competency at the front of big data transformation. We assist many enterprises to make massive progress with their big data management and analysis. Our team of hardworking developers has expertise in big data analytic solutions with technology platforms including Cloudera and Microsoft HDInsight and can utilise around the-minute technology and analytical capabilities to produce good results."
        img="/images/technologies/latest-thinking/big-data/big-data-services-overview.jpeg"
        img2="/images/technologies/latest-thinking/big-data/big-data-services-overview-mobile.jpeg"
        alt="artificial intellegence"
      />
      {/* <Overview
        title="Why Modern Enterprise Data Strategy?"
        desc="In this undeniably digitised world, how much information produced is developing at an exponential rate. All businesses have confronting difficulties in overseeing and figuring out this huge data and are seeking best practices to handle data meaningfully. Inability to accurately address these big data challenges can bring about heightening expenses, as well as reduced productivity and competitiveness. In general, an organisation is probably going to profit from big data advances if it is leveraged and well utilised. Some of the Industries where big data can be integrated are Manufacturing, Healthcare, Financial services, Retail and e-commerce, Telecommunications etc."
        desc2="Innorik has been at the front of the Big Data transformation for the beyond couple of years. We have assisted many enterprises to make massive progress with their Big Data management and analysis. Our team has expertise in Big Data Analytics solutions with technology platforms including Cloudera and Microsoft HDInsight and can utilise around the-minute technology and analytical capabilities to produce good results."
      /> */}
      <Services title="Our Big Data Offerings" data={servicesData} />
      <WhyChoose
        head="Why choose Innorik for your Big Data solutions?"
        textColor="text-white"
        data={Data3}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fbig-data%2Fwhy-choose-big-data-linear.jpeg?alt=media&token=8c0619a0-d4d2-4b7e-8115-c217c0bf4995"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fbig-data%2Fbig-data-why-choose-us.jpeg?alt=media&token=29ecd806-9b75-4cff-b496-bb64d1edea0f"
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
