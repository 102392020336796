import React from "react";
// import DefaultCard from "./Cards/DefaultCard";

// import HoverCard from "../../components/Cards/HoverCard";
// import NoHoverCard from "../../components/Cards/NoHoverCard";
// import HoverCard from "./Cards/HoverCard";
import NoHoverCard from "./Cards/NoHoverCard";

export default function FourIcons(props) {
  const listData = props.data;
  //   console.log(listData)

  const newData = listData.map((data, index) => {
    return (
      <NoHoverCard
        key={index}
        image={data.img}
        title={data.title}
        desc={data.desc}
      />
    );
  });
  return (
    <>
      <div className=" text-center max-w-[1500px] mx-auto  lg:pb-[40px] self-center	 leading-snug ">
        <h1 className="text-[#222222] pt-8 px-2 lg:pt-8 mt-0  text-[26px] font-[600] text-xl text-center mb-6 ms:text-2xl ms:mb-6 md:text-3xl md:mb-9 lg:text-[36px] lg:mb-12">
          {props.title}
        </h1>
        <div className="grid grid-cols-1 lg:pt-[40px] sm:grid-cols-2 lg:grid-cols-4 mx-auto w-8/12 sm:w-10/12 lg:w-11/12 2xl:w-11/12 gap-6 sm:gap-8 md:gap-14 lg:gap-5 xl:gap-10 2xl:gap-14">
          {newData}
        </div>
      </div>
    </>
  );
}
