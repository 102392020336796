import React from "react";
import Banner from "../../components/Banner";
import FooterBanner from "../../components/Footer/FooterBanner";
import RightImageSection from "../../components/RightImageSection";
import WhyChoose from "../../components/WhyChoose";
import WhatCard from "../WebDevelopment/WhatCards";
import ResponsiveSolutions from "./ResponsiveSolutions";
const Data = [
  {
    id: 1,
    title: "Maintain a consistent user experience which increases retention",
  },
  {
    id: 2,
    title: "Consolidate analytics, tracking, and reporting",
  },
  {
    id: 3,
    title: "Decrease time and cost on site content management",
  },
  {
    id: 4,
    title: "Compete in your industry with other brands.",
  },
  {
    id: 5,
    title: "Mobile usage is on the rise",
  },
  {
    id: 6,
    title: "Increase reach to consumers on all devices",
  },
];
const Data2 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Ficons%2Faffordable.svg?alt=media&token=7af26268-2760-4772-b833-d061bb0644c8",
    title: "",
    desc: "Reduced cost to 50% of our competitors ",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Ficons%2Fsatisfaction.svg?alt=media&token=7428d8a9-7070-4a42-a445-5970d2115694",
    title: "",
    desc: "Client satisfaction is our goal ",
  },

  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Ficons%2FStand-out.svg?alt=media&token=f41e8308-58f4-44fc-8406-4c07f4e2f0c6",
    title: "",
    desc: "Large pool of experienced tech talents",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Ficons%2FResponsive%20design.svg?alt=media&token=428fdb48-2260-42b1-a774-a20f3601c1ac",
    title: "",
    desc: "We have a smart solution for every IT challenge ",
  },

  {
    id: 1,
    img: "/images/services/responsive-design/icons/project-management.svg",
    title: "",
    desc: "We use latest technologies and best development practices  ",
  },
  {
    id: 2,
    img: "/images/services/responsive-design/icons/customer-service.svg",
    title: "",
    desc: "We personalize and deliver extraordinary customer service",
  },

  {
    id: 3,
    img: "/images/services/responsive-design/icons/knowledge.svg",
    title: "",
    desc: "We provide knowledge transfer and easy exit process for our customers ",
  },
  {
    id: 4,
    img: "/images/services/responsive-design/icons/process.svg",
    title: "",
    desc: "We increase customers engagement in the project development process ",
  },
];

export default function ResponsiveDesign() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Fresponsive-web-design-banner.jpg?alt=media&token=155602eb-65dc-40be-adde-c6bd380ae3da"
        title="Responsive Web Design"
        desc="Your website needs to look great and work well on a desktop, a tablet and a smartphones browser"
      />
      <RightImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Fresponsive-web-design-overview.svg?alt=media&token=77a1b650-bca1-4829-92b9-c33215122a32"
        title="Responsive web designs you love"
        desc="Responsive web designs ensure a web solution’s adaptive behavior to different screen sizes of a variety of devices. Following the mobile-first strategy, our designers and developers create layouts that can respond to the device being used to view the content. Innorik translates the functionality of your web solution into intuitive, smoothly functioning, and highly converting digital experience"
      />
      <WhyChoose
        textColor="text-white"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Fwhy-invest-in-web-development.jpg?alt=media&token=882c4b3b-1f75-424a-8d18-6f155c1d97b5"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/tempImages%2FwhyresponsiveDesign.png?alt=media&token=18453df4-9480-4ae7-97cd-192bd2d9899e"
        head="Why Invest in responsive web designs?"
        data={Data}
      />
      <ResponsiveSolutions />
      <WhatCard
        title="Why choose Innorik for your responsive web designs?"
        data={Data2}
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
