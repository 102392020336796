import React from "react";

export default function Infographics() {
  return (
    <>
      <div className="max-w-[1500px] mx-auto pb-[50px] px-[28px] md:px-[40px] lg:px-[85px]">
        {/* <h1 className="text-center text-[24px] md:text-[28px] lg:text-[36px]">
          Implementaion Process
        </h1> */}
        <img
          className="invisible md:visible h-0  md:h-auto w-0 md:w-full mx-auto pt-[40px]"
          src="/images/company/engagement-module/Software-Development-Process-infographics.svg"
        />
        <img
          className="visible md:invisible md:h-0   md:w-0 mx-auto pt-[10px]"
          src="/images/company/engagement-module/Software-Development-Process-infographics.svg"
        />
      </div>
    </>
  );
}
