import React from "react";
import { Link } from "react-router-dom";

export default function IndustriesButton(props) {
  return (
    <>
      <Link to={props.link}>
        <div
          className={`${props.color} h-[160px]  text-center font-medium text-[#f9f9f9] text-[18px] flex justify-center  cursor-pointer	items-center hover:scale-105 transition ease-in-out duration-200 hover:text-white `}
        >
          {props.title}
        </div>
      </Link>
    </>
  );
}
