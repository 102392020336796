// import React, { useState } from "react";
// import { FiAlignLeft } from "react-icons/fi";

// export default function Nav() {
//   const [isOpen, setIsOpen] = useState(false);

//   const handleNavbar = () => {};
//   return (
//     <>
//       <div className="flex absolute justify-between w-full px-[28px] z-20  items-center py-[8px] bg-black/50 backdrop-blur	">
//         <img
//           className="h-[48px] "
//           src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Ficons%2Flogo.png?alt=media&token=fbbe4400-c1b5-465a-b6f7-a69e18277264"
//           alt="logo"
//         />
//         <button onClick={() => setIsOpen(!isOpen)}>
//           <FiAlignLeft className="text-[32px] text-white" />
//         </button>
//       </div>
//       {isOpen ? <div className="z-90  "> hello world</div> : null}
//     </>
//   );
// }
import { useState } from "react";
import Logo from "../../Assets/Images/logo.svg";
import Accordian from "./Accordian";
import { newMobileNavData } from "../../data/home";
// import { Link } from "react-router-dom";
export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="flex px-[28px] bg-black items-center justify-between  py-4">
      <a href="/">
        <img className="h-[48px]" src={Logo} alt="logo" />
      </a>
      <nav>
        <section className="MOBILE-MENU flex lg:hidden">
          <div
            className="HAMBURGER-ICON space-y-2"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-0.5 w-8 animate-pulse bg-white/80"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-white/80"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-white/80"></span>
          </div>

          <div
            className={
              isNavOpen
                ? "showMenuNav backdrop-blur bg-black/50 pb-[40px]"
                : "hideMenuNav"
            }
          >
            <div className="flex justify-between w-full items-center py-4 px-[28px]">
              <div className="top-0">
                <img className="h-[48px]" src={Logo} alt="logo" />
              </div>
              <div className="  " onClick={() => setIsNavOpen(false)}>
                <svg
                  className="h-8 w-8 text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  // strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </div>
            </div>

            <Accordian data={newMobileNavData} />
            <a className="mx-auto w-full px-[28px]" href="/contact-us">
              <button className="w-full">
                <div className="py-[8px] mx-auto  px-[18px] mt-[30px] bg-[#3066ea] text-white">
                  Hire Developers
                </div>
              </button>
            </a>
          </div>
        </section>
      </nav>
      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
      }
    `}</style>
    </div>
  );
}
