import React from "react";
import Banner from "../../components/Banner";
import FooterBanner from "../../components/Footer/FooterBanner";
import RightImageSection from "../../components/RightImageSection";
import ThreeColCards from "../../components/ThreeColCards";
import WhyChoose from "../../components/WhyChoose";
// import TextCols from "./TextCols";
import WhyApplication from "./WhyApplication";
import WhyApplicationMigration from "./WhyApplicationMigration";
// const Data = [
//   {
//     id: 1,
//     title: "1. Increasing Agility",
//     desc: "With modernized applications, you can tune your environment to react faster to seasonal fluctuations, leverage data across the organization, integrate systems to optimize processes, adapt to business conditions or quickly jump on the next innovation opportunity to beat your competitors in the marketplace.",
//   },
//   {
//     id: 2,
//     title: "4. Integration",
//     desc: "Modern technologies are integration-ready by default as modernized applications. Modern software platforms often rely on third-party APIs for some functionalities such as geolocation, user authentication, and data sharing. Legacy systems are obsolete, and they lack such compatibility.",
//   },
//   {
//     id: 3,
//     title: "2. Reducing Cost",
//     desc: "Decommissioning monolithic apps, data center space and physical servers reduces software, hardware, and licensing costs.",
//   },
//   {
//     id: 4,
//     title: "5. Operational efficiency",
//     desc: "Modernized applications deliver better performance, faster time to market and better experiences for customers and stakeholders.",
//   },
//   {
//     id: 4,
//     title: "3. Integration",
//     desc: "Modern technologies are integration-ready by default as modernized applications. Modern software platforms often rely on third-party APIs for some functionalities such as geolocation, user authentication, and data sharing. Legacy systems are obsolete, and they lack such compatibility. ",
//   },
// ];
const Data2 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FapplicationMigration%2FCloud%20Migration.jpg?alt=media&token=f178b26a-8719-436a-81a7-bde330e61e20",
    title: "Cloud Migration",
    desc: "",
    alt: "cloud migration",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FapplicationMigration%2FOnsite%20data%20center.jpg?alt=media&token=2d83b240-c57d-4de4-9c00-10f835ea308d",
    title: "Onsite data center migration to cloud",
    desc: "",
    alt: "onsite data center migration to cloud",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FapplicationMigration%2FReplacing%20legacy%20system.jpg?alt=media&token=05ecefae-dfee-41d5-985a-20e48c40248d",
    title: "Replacing legacy system with a completely new system",
    desc: "",
    alt: "replacing legacy system",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FapplicationMigration%2FRebuild%20and%20Restructing.jpg?alt=media&token=bdc58e96-1352-47fc-b4d7-332ec5e02d3b",
    title: "Rebuilding and restructuring the legacy system",
    desc: " ",
    alt: "rebuld and refacturing",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FapplicationMigration%2FCloud%20to%20cloud%20migration.jpg?alt=media&token=ad50767b-aafd-49a0-bbf9-6241a118afe1",
    title: "Cloud to cloud migration",
    alt: "cloud to cloud migration",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FapplicationMigration%2FRefactoring%20and%20rearchitecting.jpg?alt=media&token=aaac1553-1bc2-4731-b69f-975c2cda5945",
    title: "Refactoring and rearchitecting of legacy system    ",
    desc: "",
    alt: "refacturing and rearchitecting",
  },
];
const Data3 = [
  {
    id: 1,
    title: "Reduced cost to 50% of our competitors",
  },
  {
    id: 2,
    title: "We have all the skills and expertise to get your job done",
  },
  {
    id: 3,
    title: "We use latest technologies and best migration practices",
  },
  {
    id: 4,
    title: "We have smart solutions for every migration problem",
  },
  {
    id: 5,
    title: "Large pool of experienced tech talents",
  },
  {
    id: 6,
    title: "We personalize and deliver extraordinary customer service",
  },
  {
    id: 7,
    title: "We increase customers engagement in the project migration process",
  },
  {
    id: 8,
    title:
      "We provide knowledge transfer and easy exit process for our customers ",
  },
];
const Roadmap = [
  {
    id: 1,
    number: "01",
    title: "Cloud Migration",
    desc: "Moving some or all of a company’s onsite data center capabilities into the cloud to run on the cloud infrastructure. Cloud to Cloud Migration – Migrating between different cloud providers",
  },
  {
    id: 2,
    number: "02",
    title: "Replace",
    desc: "Completely decommission and replace an existing legacy system with a new system",
  },
  {
    id: 3,
    number: "03",
    title: "Rebuild",
    desc: "Restructure and optimize legacy systems into a cloud native environment. A full rebuild, and rearchitects of the legacy system into a fully modernized, cloud native environment.",
  },
  {
    id: 4,
    number: "04",
    title: "Refactor & Rearchitect ",
    desc: "This involves implementing new technologies in tiers of the application to take advantage of cloud-based or other systems with some code changes in selective tiers.",
  },
];

export default function ApplicationMigration() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FapplicationMigration%2FHero%20banner%20.jpg?alt=media&token=a73405ca-fbb1-4589-9779-1a030dcdcfe7"
        title="Application Migration"
        desc="Enable digital-first user experience through modernization"
        alt="application migration"
      />
      <RightImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FapplicationMigration%2Fapplication-migration-overview.svg?alt=media&token=583dbb51-1412-4808-a4d6-2fdb3dd58d99"
        title="Speed up modernization with software and platform-driven solutions"
        alt="platform driven solutions"
        desc="Digital transformation has become the new normal and modernizing softwares and applications are a critical part of any successful digital transformation. As the world continues to digitize, most enterprise applications need to be transformed to fuel innovations and to keep up with user expectations which will lead to long-term growth of business."
        desc2="At Innorik, our application modernization services helps your business achieve a like-to-like system parity with operational enhancements by integrating several accelerators, platforms, and strategic planning to technologize your core business systems, softwares, and processes. Our goal is to help your business achieve significant leaps in performance, agility, and innovation"
      />
      {/* <TextCols data={Data} /> */}
      <WhyApplication />
      <WhyApplicationMigration data={Roadmap} />
      <ThreeColCards title="Our Modernization Services" data={Data2} />
      <WhyChoose
        textColor="text-white"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FapplicationMigration%2FWhy%20choose%20application%20migration%20with%20strip.jpg?alt=media&token=9351fbd3-0df6-4ec2-a4ea-628967b28fb0"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FapplicationMigration%2FWhy-choose-application-migration.jpeg?alt=media&token=c44aeb2b-1768-4dbd-989e-2ad066616510"
        head="Why choose Innorik for your Application Modernization needs?"
        data={Data3}
      />
      <FooterBanner
        title="Discover the right solution for your business"
        link="/contact-us"
      />

      {/* <ColumnsCard title="Our Cloud Services" data={Data2} /> */}
    </>
  );
}
