import React from "react";
// import List from "./Cards/List";

export default function Overview(props) {
  //   const newData = props.data;
  //   const dataList = newData.map((data, index) => {
  //     return <List key={index} title={data.title} />;
  //   });
  return (
    <>
      {/* change image for this section for mobile screen because it's hard to read text on image */}
      <div
        className={`hidden ${props.textColor}   lg:block m-auto  text-center lg:text-left bg-cover bg-right `}
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <div className=" max-w-[1500px] px-[85px] mx-auto grid-cols-2   lg:flex-row  lg:py-[30px] md:py-[40px] py-[56px] ">
          <div className="md:m-auto w-full justify-start pl-[%]">
            <div className="ml-auto lg:w-1/2 sm:w-2/5 md:w-1/2">
              <h2 className="  text-semibold  text-[24px] lg:text-[36px] sm:mt-0 sm:mb-0 text-left lg:mr-0">
                {props.title}
              </h2>
              <p className="text-[16px] lg:text-[18px] pt-[10px]">
                {props.desc}
              </p>
            </div>

            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
        </div>
      </div>
      <div
        className={`bg-black ${props.textColor}  text-center block lg:hidden lg:h-0  m-auto gap-0 w-full`}
      >
        <img
          className="w-full sm:h-[400px] object-cover object-center"
          src={props.img2}
          alt={props.alt}
        />
        <div className="ml-auto lg:w-1/2 px-[28px]  py-[25px] ">
          <h2 className="  text-semibold   text-[24px] lg:text-[36px] sm:mt-0 sm:mb-0 text- lg:mr-0">
            {props.title}
          </h2>
          <p className="text-[16px]  lg:text-[18px] pt-[10px]">{props.desc}</p>
        </div>
      </div>

      {/*       
      <div
        className="m-auto py-[15%] sm:py-[5%] md:py-[5%] lg:py-[5%] text-center lg:text-left bg-cover bg-right h-full sm:h-[] md:h-[] lg:h-full xl:h-[]"
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <h1 className="px-[20px]  lg:w-1/2  text-[24px] lg:text-[36px] sm:text-[40px] md:text-[40px]  text-white ">
          Your cloud transformation journey is unique, let’s give you a helping
          hand
        </h1>
        
      </div> */}
    </>
  );
}
