import React from "react";

export default function Overview(props) {
  return (
    <>
      <div className="invisible  lg:visible lg:h-auto h-0 px-[20px] md:px-[40px] lg:py-[40px] lg:px-[85px] max-w-[1500px] mx-auto">
        <div className={`${props.color}`}>
          <div className="grid grid-cols-12 py-[28px] w-full">
            <div className=" col-span-5">
              <img
                className=" md:pl-20px w-[100%] h-full object-cover"
                src={props.img}
              />
            </div>
            <div className=" col-span-7 my-auto py-[20px] px-[40px]">
              <h1
                className={`text-[20px] text-[#3066ea] md:text-[24px] ${props.titleColor}`}
              >
                {props.title}{" "}
              </h1>
              <p className="pt-[20px] text-[#222222]">{props.desc}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="visible  md:visible lg:invisible mx-auto  lg:h-0">
        <div className={`${(props.color, props.textColor)}`}>
          <div className="py-[20px] w-full">
            <div className="py-[20px] ">
              <img
                className=" mx-auto px-[20px] md:h-[300px] "
                src={props.img}
              />
            </div>
            <div className="  my-auto px-[40px]">
              <h1 className="text-[20px] text-center md:text-[24px]">
                {props.title}{" "}
              </h1>
              <p className="pt-[20px]  text-center">{props.desc}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
