import React from "react";

export default function Infographics() {
  return (
    <>
      <div className="max-w-[1500px] mx-auto py-[50px] px-[28px] md:px-[40px] lg:px-[85px]">
        <h1 className="text-center text-[24px] md:text-[28px] lg:text-[36px]">
          Implementaion Process
        </h1>
        <img
          className="hidden md:block   md:h-auto w-0 md:w-full lg:w-4/5 mx-auto pt-[40px]"
          src="/images/technologies/platforms/infographics.svg"
        />
        <img
          className="block md:hidden    md:w-0 mx-auto pt-[10px]"
          src="/images/technologies/platforms/Our-Implementation-Process-mobile.svg"
        />
      </div>
    </>
  );
}
