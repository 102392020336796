import React, { useState } from "react";
import styled from "styled-components";
import { HiPlus } from "react-icons/hi";
// import './Collapsible.css'
import { IoIosArrowForward } from "react-icons/io";
import { FiXCircle, FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";

export const ButtonWrapper = styled.div`
  //transform: rotateX(180deg);

  &:hover {
    // font-size: 30px;
  }
`;
export default function WhoWeAre() {
  const [isOpen, setIsOpen] = useState(false);
  const [string, setString] = useState(null);

  // const rotate = () => {
  //   setIsOpen(true);
  // };

  const handelMouseClick = (e, str) => {
    setIsOpen(true);
    setString(str);
    // console.log("fuck the fucker");
  };
  const handleClose = (e) => {
    setIsOpen(false);
  };
  return (
    <>
      <div className="pt-[40px] md:py-[50px] pb-[20px]">
        <h2 className="text-[24px] md:text-[36px] text-[#222222] text-center ">
          Who are we and what we serve
        </h2>

        <div className="bg-[#B0C4FD] pb-[20px]">
          <div
            className="elementor-shape elementor-shape-top fill-white"
            data-negative="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 50"
              preserveAspectRatio="none"
            >
              <path
                className="elementor-shape-fill"
                d="M500,24.9L0,0.1V0h1000v0.1L500,24.9z"
              ></path>
            </svg>
          </div>
          <div className="max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4  md:gap-[130px] mx-auto">
            <div className="mx-auto pt-[25px] sm:pt-0">
              <img
                className="rounded-full h-[100px] w-[100px]  md:h-[200px] md:w-[200px]"
                src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fwhoarewe%2Fabout-us.jpeg?alt=media&token=65322134-44af-431f-ab22-3d718648d7ea"
                alt="about us"
              />
              <div className="flex pt-[20px] lg:pt-[50px] mx-auto justify-center items-center">
                <div className="invisible h-0 w-0 lg:h-auto lg:w-auto lg:visible">
                  <button
                    className="flex items-center"
                    onClick={(e) => {
                      handelMouseClick(e, "about");
                    }}
                    // onMouseLeave={handelMouseLeaveOnCompany}
                  >
                    <p className="text-center text-[18px] ">About us</p>
                    <div className="collapsible">
                      <ButtonWrapper className="toggle">
                        <HiPlus
                          className="hover:rotate-180	text-[20px] ml-[8px]"
                          onClick={() => setIsOpen(!isOpen)}
                          style={{
                            cursor: "pointer",
                            transition: "all 0.1s linear",
                          }}
                        />
                      </ButtonWrapper>
                    </div>
                  </button>
                </div>
                <div className="visible lg:h-0 lg:w-0 lg:invisible">
                  <button
                    className="flex items-center"

                    // onMouseLeave={handelMouseLeaveOnCompany}
                  >
                    <Link to="/about">
                      <p className="text-center text-[18px] ">About us</p>
                    </Link>

                    <div className="collapsible">
                      <ButtonWrapper className="toggle">
                        <FiChevronRight className="	text-[20px] ml-[8px]" />
                      </ButtonWrapper>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="mx-auto  pt-[25px] md:pt-0 lg:pt-[50px]">
              <img
                className="rounded-full w-[100px]  md:h-[200px] md:w-[200px]"
                src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fwhoarewe%2Ftechnology.jpeg?alt=media&token=4a68f58e-e8a0-4750-9b16-7e0af3d6395f"
                alt="technology"
              />
              <div className="flex  pt-[20px]  lg:pt-[50px] mx-auto justify-center items-center">
                <div className="invisible h-0 w-0 lg:h-auto lg:w-auto lg:visible">
                  <button
                    className="flex items-center"
                    onClick={(e) => {
                      handelMouseClick(e, "tech");
                    }}
                    // onMouseLeave={handelMouseLeaveOnCompany}
                  >
                    <p className="text-center text-[18px] ">Technology</p>
                    <div className="collapsible">
                      <ButtonWrapper className="toggle">
                        <HiPlus
                          className="hover:rotate-180	text-[20px] ml-[8px]"
                          onClick={() => setIsOpen(!isOpen)}
                          style={{
                            cursor: "pointer",
                            transition: "all 0.1s linear",
                          }}
                        />
                      </ButtonWrapper>
                    </div>
                  </button>
                </div>
                <div className="visible lg:h-0 lg:w-0 lg:invisible">
                  <button
                    className="flex items-center"

                    // onMouseLeave={handelMouseLeaveOnCompany}
                  >
                    <Link to="/latest-thinking/virtual-reality">
                      <p className="text-center text-[18px] ">Technology</p>
                    </Link>

                    <div className="collapsible">
                      <ButtonWrapper className="toggle">
                        <FiChevronRight className="	text-[20px] ml-[8px]" />
                      </ButtonWrapper>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="mx-auto lg:pt-[50px]">
              <img
                className="rounded-full w-[100px]  md:h-[200px] md:w-[200px]"
                src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fwhoarewe%2Fservices.jpeg?alt=media&token=773f5b59-bec5-416e-a15c-cbcc88fab764"
                alt=""
              />
              <div className="flex pt-[20px] lg:pt-[50px] mx-auto justify-center items-center">
                <div className="invisible h-0 w-0 lg:h-auto lg:w-auto lg:visible">
                  <button
                    className="flex items-center"
                    onClick={(e) => {
                      handelMouseClick(e, "services");
                    }}
                    // onMouseLeave={handelMouseLeaveOnCompany}
                  >
                    <p className="text-center text-[18px] ">Services</p>
                    <div className="collapsible">
                      <ButtonWrapper className="toggle">
                        <HiPlus
                          className="hover:rotate-180	text-[20px] ml-[8px]"
                          onClick={() => setIsOpen(!isOpen)}
                          style={{
                            cursor: "pointer",
                            transition: "all 0.2s linear",
                          }}
                        />
                      </ButtonWrapper>
                    </div>
                  </button>
                </div>
                <div className="visible lg:h-0 lg:w-0 lg:invisible">
                  <button
                    className="flex items-center"

                    // onMouseLeave={handelMouseLeaveOnCompany}
                  >
                    <Link to="/web-development">
                      <p className="text-center text-[18px] ">Services</p>
                    </Link>

                    <div className="collapsible">
                      <ButtonWrapper className="toggle">
                        <FiChevronRight className="	text-[20px] ml-[8px]" />
                      </ButtonWrapper>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <div className="mx-auto  lg:pt-0">
              <img
                className="rounded-full w-[100px]  md:h-[200px] md:w-[200px]"
                src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fwhoarewe%2Foperations.jpeg?alt=media&token=e3d114f7-4809-4cdf-945b-9b54f07db36b"
                alt="engagement module"
              />
              <div className="flex  pt-[20px] lg:pt-[50px] mx-auto justify-center items-center">
                <div className="invisible h-0 w-0 lg:h-auto lg:w-auto lg:visible">
                  <button
                    className="flex items-center"
                    onClick={(e) => {
                      handelMouseClick(e, "engagement");
                    }}
                    // onMouseLeave={handelMouseLeaveOnCompany}
                  >
                    <p className="text-center text-[18px] ">Engagement</p>
                    <div className="collapsible">
                      <ButtonWrapper className="toggle">
                        <HiPlus
                          className="hover:rotate-180	text-[20px] ml-[8px]"
                          onClick={() => setIsOpen(!isOpen)}
                          style={{
                            cursor: "pointer",
                            transition: "all 0.2s linear",
                          }}
                        />
                      </ButtonWrapper>
                    </div>
                  </button>
                </div>
                <div className="visible lg:h-0 lg:w-0 lg:invisible">
                  <button
                    className="flex items-center"

                    // onMouseLeave={handelMouseLeaveOnCompany}
                  >
                    <Link to="/engagement-module">
                      <p className="text-center text-[18px] ">Engagement</p>
                    </Link>

                    <div className="collapsible">
                      <ButtonWrapper className="toggle">
                        <FiChevronRight className="	text-[20px] ml-[8px]" />
                      </ButtonWrapper>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-shape elementor-shape-top fill-[#B0C4FD]"
          data-negative="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 50"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M500,24.9L0,0.1V0h1000v0.1L500,24.9z"
            ></path>
          </svg>
        </div>
        {string === "about" ? (
          <div className="pb-[50px] mx-auto max-w-[1500px] px-[85px] transition-transform ease-in-out delay-150 duration-300">
            <div
              style={{
                backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fwhoarewe%2Fabout-us-team.jpeg?alt=media&token=9c781902-65f9-4e09-84a1-bf34fd042824")`,
              }}
              className=" bg-no-repeat bg-cover w-full"
            >
              <div className="bg-black/70 gap-[80px] grid grid-cols-12 py-[50px] px-[28px] md:px-[40px] lg:px-[50px] text-white">
                <div className="col-span-8 ">
                  <h3 className="text-[24px] font-semibold">
                    Together, we move your business forward in this digital
                    transformation era
                  </h3>
                  <p className="pt-[25px] text-[18px]">
                    Our vision is to help both local and international
                    businesses overcome the ever-trending technological
                    challenges of digital transformation by delivering world
                    class and enterprise software products and services, thereby
                    enabling them to leverage technology for business growth and
                    sustainability.
                  </p>
                </div>
                <div className="col-span-3">
                  <ul className="space-y-4">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/culture-and-values">Culture and Values</Link>
                    </li>
                    <li>
                      <Link to="/partnership">Partnership</Link>
                    </li>
                    <li>
                      <Link to="/talent-world-wide">Talent Worldwide</Link>
                    </li>
                    {/* <li>
                      <Link to="/business-process-outsourcing">
                        Outsourcing Services
                      </Link>
                    </li> */}
                  </ul>
                  <div className=" pt-[15px] col-span-1">
                    <button className="text-[16px] lg:text-[18px] py-[px] px-[12px] lg:py-[6px] md:py-[10px] lg:px-[24px] md:px-[15px] flex text-white border border-white">
                      <Link to="/about">Explore</Link>
                      <div className="lg:pl-[6px] md:pl-[10px] pl-[5px] m-auto">
                        <IoIosArrowForward />
                      </div>
                    </button>
                  </div>
                </div>
                <button
                  className="self-start place-items-end col-span-1 hover:scale-110 transition ease-in-out duration-200"
                  onClick={(e) => {
                    setString();
                  }}
                >
                  <FiXCircle className="text-[30px]" />
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {string === "services" ? (
          <div className="pb-[50px] mx-auto max-w-[1500px] px-[85px] transition-transform ease-in-out delay-150 duration-300">
            <div
              style={{
                backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fwhoarewe%2Fservice-background.jpeg?alt=media&token=64dbbe10-136d-47eb-ada9-05f849f3a496")`,
              }}
              className=" bg-no-repeat bg-cover w-full"
            >
              <div className="bg-black/70 gap-[80px] grid grid-cols-12 py-[50px] px-[28px] md:px-[40px] lg:px-[50px] text-white">
                <div className="col-span-8 ">
                  <h3 className="text-[24px] font-semibold">
                    Be ahead and stay connected to the world of innovation
                  </h3>
                  <p className="pt-[25px] text-[18px]">
                    Digital transformation has become the new normal and
                    modernizing softwares and applications are a critical part
                    of any successful digital transformation. As the world
                    continues to digitize, most enterprise applications need to
                    be transformed to fuel innovations and to keep up with user
                    expectations which will lead to long-term growth of
                    business. We deliver impactful and engaging top-notch
                    applications for leading companies across many industries
                    globally.
                  </p>
                </div>
                <div className="col-span-3">
                  <ul className="space-y-4">
                    <li>
                      <Link to="/web-development">Web development</Link>
                    </li>
                    <li>
                      <Link to="/mobile-development">
                        Mobile App Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/application-migration">
                        Application Migration
                      </Link>
                    </li>

                    <li>
                      <Link to="/testing">Software Quality Engneering</Link>
                    </li>

                    <li>
                      <Link to="/devops">Devops</Link>
                    </li>
                  </ul>
                  <div className=" pt-[15px] col-span-1">
                    <button className="text-[16px] lg:text-[18px] py-[px] px-[12px] lg:py-[6px] md:py-[10px] lg:px-[24px] md:px-[15px] flex text-white border border-white">
                      <Link to="/cloud-infrastructure">Explore</Link>
                      <div className="lg:pl-[6px] md:pl-[10px] pl-[5px] m-auto">
                        <IoIosArrowForward />
                      </div>
                    </button>
                  </div>
                </div>
                <button
                  className="self-start place-items-end col-span-1 hover:scale-110 transition ease-in-out duration-200"
                  onClick={(e) => {
                    setString();
                  }}
                >
                  <FiXCircle className="text-[30px]" />
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {string === "tech" ? (
          <div className="pb-[50px] mx-auto max-w-[1500px] px-[85px] transition-transform ease-in-out delay-150 duration-300">
            <div
              style={{
                backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fwhoarewe%2Ftechnology-background.jpeg?alt=media&token=017b6742-7031-4ea6-9f06-ba7e6d98131c")`,
              }}
              className=" bg-no-repeat bg-cover w-full"
            >
              <div className="bg-black/70 gap-[80px] grid grid-cols-12 py-[50px] px-[28px] md:px-[40px] lg:px-[50px] text-white">
                <div className="col-span-8 ">
                  <h3 className="text-[24px] font-semibold">
                    Transitioning from manual workflow to smarter automated
                    solutions for better business proficiency
                  </h3>
                  <p className="pt-[25px] text-[18px]">
                    AI has become the most revolutionary and much-needed
                    technology for global companies due to its potential to
                    entirely automate important jobs and give efficiency to
                    business operations. Whether it's improving the customer
                    experience or automating the most boring and tiresome jobs,
                    AI development has been revolutionizing the world in the
                    most effective way possible.
                  </p>
                </div>
                <div className="col-span-3">
                  <ul className="space-y-4">
                    <Link to=""></Link>
                    <li>
                      <Link to="/latest-thinking/virtual-reality">
                        Virtual Reality
                      </Link>
                    </li>
                    <li>
                      <Link to="/latest-thinking/artificial-intellegence">
                        Artificial Intellegence
                      </Link>
                    </li>

                    <li>
                      <Link to="/latest-thinking/blockchain">Blockchain</Link>
                    </li>

                    <li>
                      <Link to="/latest-thinking/iot">Internt Of Thinking</Link>
                    </li>
                    <li>
                      <Link to="/latest-thinking/big-data">Big Data</Link>
                    </li>
                  </ul>
                  <div className=" pt-[15px] col-span-1">
                    <button className="text-[16px] lg:text-[18px] py-[px] px-[12px] lg:py-[6px] md:py-[10px] lg:px-[24px] md:px-[15px] flex text-white border border-white">
                      <Link to="/platforms/power-bi">Explore</Link>
                      <div className="lg:pl-[6px] md:pl-[10px] pl-[5px] m-auto">
                        <IoIosArrowForward />
                      </div>
                    </button>
                  </div>
                </div>
                <button
                  className="self-start place-items-end col-span-1 hover:scale-110 transition ease-in-out duration-200"
                  onClick={(e) => {
                    setString();
                  }}
                >
                  <FiXCircle className="text-[30px]" />
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {string === "engagement" ? (
          <div className="pb-[50px] mx-auto max-w-[1500px] px-[85px] transition-transform ease-in-out delay-150 duration-300">
            <div
              style={{
                backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fwhoarewe%2Fengagement-background.jpeg?alt=media&token=15183b79-5477-4a63-a69b-d7153cb0105a")`,
              }}
              className=" bg-no-repeat bg-cover w-full"
            >
              <div className="bg-black/70 gap-[80px] grid grid-cols-12 py-[50px] px-[28px] md:px-[40px] lg:px-[50px] text-white">
                <div className="col-span-8 ">
                  <h3 className="text-[24px] font-semibold">
                    Successful projects are well-managed
                  </h3>
                  <p className="pt-[25px] text-[18px]">
                    At Innorik, our mission is to deliver high-quality software
                    to our customers, so we tailor the software development
                    process to your specific business needs to deliver the
                    product on time and within budget.
                  </p>
                </div>
                <div className="col-span-3">
                  <ul className="space-y-4">
                    <li>
                      <Link to="/engagement-module">Development Process</Link>
                    </li>
                  </ul>
                  <div className=" pt-[15px] col-span-1">
                    <button className="text-[16px] lg:text-[18px] py-[px] px-[12px] lg:py-[6px] md:py-[10px] lg:px-[24px] md:px-[15px] flex text-white border border-white">
                      <Link to="/engagement-module">Explore</Link>
                      <div className="lg:pl-[6px] md:pl-[10px] pl-[5px] m-auto">
                        <IoIosArrowForward />
                      </div>
                    </button>
                  </div>
                </div>
                <button
                  className="self-start place-items-end col-span-1 hover:scale-110 transition ease-in-out duration-200"
                  onClick={(e) => {
                    setString();
                  }}
                >
                  <FiXCircle className="text-[30px]" />
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
