import React from "react";
import ImageCardHeight from "./Cards/ImageCardHeight";

export default function FiveColCard(props) {
  const newData = props.data;
  const dataList = newData.map((data, index) => {
    return (
      <ImageCardHeight
        key={index}
        title={data.title}
        img={data.img}
        desc={data.desc}
      />
    );
  });
  return (
    <>
      <div className="max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] mx-auto">
        <h1 className=" pt-10 font-semibold  text-xl text-center pb-6 ms:text-2xl ms:pb-6 md:text-3xl md:pb-9 lg:text-4xl lg:pb-12">
          {props.title}
        </h1>
        <div className="bg-white">
          <div className="grid  grid-cols-1 mx-auto sm:grid-cols-2 lg:grid-cols-5  lg:w-full  gap-6 sm:gap-8 md:gap-14 lg:gap-8 xl:gap-10 2xl:gap-14 pb-10">
            {dataList}
          </div>
        </div>
      </div>
    </>
  );
}
