import React from "react";
import Banner from "../../../../components/Banner";
import Industries from "../../../../components/NewIndustries";
import Offerings from "../Php/Offerings";
// import Overview from "../Php/Overview";
import Services from "../Php/Services";
import WhyChooseUs from "../Php/WhyChooseUs";
// import Advantages from "../Java/Advantages";
import OurDevelopers from "../../Platforms/Magento/OurDevelopers";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import { phpDevelopers } from "../../../../data/programming";

// const advantageData = [
//   {
//     id: 1,
//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Fidea.png?alt=media&token=172c733f-269c-4584-b8ca-6fd7e228b6e9",
//     title: "Client-side Execution",
//     desc: "Javascript codes needs not to be submitted to server for processing and this reduces load time significantly",
//   },
//   {
//     id: 2,
//     img: "",
//     title: "Interface Interactivity",
//     desc: "JavaScript's speed is a significant advantage. The user interface interaction of JavaScript is fully utilized by our professional JavaScript developers",
//   },
//   {
//     id: 3,
//     img: "",
//     title: "Responsive Design",
//     desc: " Provision of the greatest experience across all platforms by unifying HTML, CSS, and JavaScript in a single code base.",
//   },
//   {
//     id: 4,
//     img: "",
//     title: "Platform Independent",
//     desc: "JavaScript frameworks are simple to install and maintain, regardless of platform.",
//   },
//   {
//     id: 4,
//     img: "",
//     title: "Versatile and Updated",
//     desc: "JavaScript apps work in a variety of browsers and operating systems.",
//   },
//   {
//     id: 4,
//     img: "",
//     title: "Offline Support",
//     desc: "You can enjoy responsive and usable app features even if you don't have an active internet connection.",
//   },
// ];
const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Fillustrations%2Fconsulting.svg?alt=media&token=b4d26300-aa53-4253-84d9-d5cf158f1030",
    title: "Node.JS Consulting Services",
    desc: "Professional consulting with Node JS development services and on-demand developers for development, customization, and maintenance of Node.JS apps.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Fillustrations%2Fweb-app.svg?alt=media&token=84d68ce1-74db-49d9-b18d-859f3726fe6a",
    title: "Node.JS WebApp Development",
    desc: "For conceptualizing and constructing sturdy and performant apps, the best-in-class Node JS web development firm is available.",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Fillustrations%2Fsupport.svg?alt=media&token=75f5c351-8e91-4b00-a11f-6aa0a2862d27",
    title: "Node.JS Maintenance and Support ",
    desc: "",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Fillustrations%2Fmobile.svg?alt=media&token=38a91fa8-3888-47d4-8aeb-39bf754af3df",
    title: "Node.JS Mobile Development",
    desc: "Developing lightweight, flexible, and high-performance apps under our Node.JS Android development services.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Fillustrations%2Fdevelopment.svg?alt=media&token=9e297970-cd99-4a10-8eb7-c213839b6865",
    title: "Node.JS API Development ",
    desc: " In a shorter amount of time, we build a safe and ready-to-use REST API from scratch using Node.JS.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Fillustrations%2Fmigration.svg?alt=media&token=1690694b-04ec-4f93-9c3f-c104ea6b1d72",
    title: "Node.JS Migration",
    desc: "Migrating complex applications built on different platforms to Node.JS and automated DB migration in MongoDB using Node.JS.",
  },
];
const Data2 = [
  {
    id: 1,
    title: "Unmatched performance and speed ",
  },
  {
    id: 2,
    title: "Maximum security and scalability ",
  },
  {
    id: 3,
    title: "Agile software development  ",
  },
  {
    id: 4,
    title: "Mobile friendly UI ",
  },
  {
    id: 5,
    title: "360-degree Requirement Analysis ",
  },
  {
    id: 6,
    title: "State of the art designs ",
  },
  {
    id: 8,
    title: "Reduced cost to 50% of our competitors ",
  },
  {
    id: 9,
    title: "A pool of smart and experienced developers ",
  },
];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];

const offeringData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Ficons%2Fsmartphone.svg?alt=media&token=8dcaf5e7-1667-49bd-afd4-f0e3edcd40d2",
    title: "App Development ",
    desc: "Node.js can be used to create a variety of web applications.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Ficons%2Fnodejs.svg?alt=media&token=d071ebeb-3fd5-42a3-adb9-1e4c8217a488",
    title: "Used as a Single Programming Language",
    desc: "NodeJS is used to develop both frontend and backend web applications, it is most powerful competitor of PHP.",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Ficons%2Fcache.svg?alt=media&token=d82c64b1-6cd4-4508-b197-7613410007fb",
    title: "Caching",
    desc: "Node.js also provides the facility of caching single modules, which makes it fast and smooth.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Ficons%2Fnode.svg?alt=media&token=cd935f08-b859-44de-8686-9b0ee19ec027",
    title: "Easy Flexibility",
    desc: "During vertical scaling of a web application, Node.js also allows you to add extra resources to single nodes. As a result, it is very scalable and provides a better alternative to existing JavaScript servers.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Ficons%2Feasy-use.svg?alt=media&token=82fb719f-183b-4ad0-bd86-6a8636df7c84",
    title: "Easy to Learn",
    desc: "Node.js is easy to learn because it’s a JavaScript framework. Most software developers today have very good experience in JavaScript which makes it easy to learn and grasp Node.js.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Ficons%2Fclean-code.svg?alt=media&token=939ddf8a-ca95-4b8e-916c-3ae93c39228d",
    title: "Clean Code and Highly Customizable ",
    desc: "NodeJS is high customized Javascript framework, it can be customized to any level of updates.",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Ficons%2Flibrary.svg?alt=media&token=a9524940-3290-469d-9f22-bc6f7f4d4f1e",
    title: "Built-in Libraries",
    desc: "NodeJS works well on all systems, including Macintosh, Windows, and UNIX. It comes with a number of built-in functions and libraries that aid in rapid development.",
  },
  {
    id: 8,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Ficons%2Ffast-time.svg?alt=media&token=a323c8da-4873-4fbc-894e-af235aa51039",
    title: "Light Weight",
    desc: "It is light-weight and fast, which help web applications to load fast",
  },
  {
    id: 9,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Ficons%2Fperformance.svg?alt=media&token=63e5aaf1-38aa-458f-bf53-f84ced4f1c35",
    title: "High-performing, quick, and safe",
    desc: "NodeJS based build web applications are fast, secure, reliable and high performing.",
  },
  {
    id: 10,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Ficons%2Fseo.svg?alt=media&token=74136148-91c6-495c-acd7-a6a40da01d64",
    title: "SEO friendly URLs",
    desc: "The URLs created by NodeJS are search engine friendly. NodeJS is fully optimized for search engines, allowing you to rank on Google quickly.",
  },
];
export default function NodeJs() {
  return (
    <>
      <Banner
        button="Talk to Experts"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Fnodejs.jpeg?alt=media&token=2b6d4b87-9184-4f19-90b8-ee437bc56bd6"
        title="Node JS Development Services"
        desc="Building high-performance, scalable, robust, and feature-rich Node.js applications to provide an excellent experience for your users with our unmatched expertise."
      />
      <Overview
        textColor="bg-white text-[#0F203C]"
        title="Finest Javascript Framework for Creating Custom Web Apps"
        desc=" Node.js is an open-source, cross-platform JavaScript runtime environment for executing JavaScript code on the server-side rather than in a browser. It's primarily used to create lightweight applications. Our development team specializes in creating client-specific, scalable network applications for a wide range of industries. Hire our Node.js developers to create a load-bearing infrastructure that includes Node.js as a primary technology. We have extensive knowledge of JavaScript and JavaScript libraries, which may be used to create a variety of apps utilizing different software engineering methodologies."
        img2="/images/technologies/programming/nodejs/nodejs-overview-mobile-white.jpeg"
        img="/images/technologies/programming/nodejs/nodejs-overview-white.jpeg"
        alt="node js"
      />
      {/* <Overview
        color="bg-black"
        textColor="text-white"
        title="Finest Javascript Framework for Creating Custom Web Apps"
        desc="Because Node.js runs on a single thread and uses non-blocking I/O calls, it can handle thousands of concurrent requests in the event loop. The node package manager (NPM) comes pre-installed with Node.js. Every installation includes NPM by default. NPM modules are reusable components with version and dependency management that are publicly available. However, heavy computations might clog a single thread, causing problems for other users by blocking incoming requests until all calculations are finished and thus using Node.js here in this scenario is not advised."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Fnode-js-coding.jpeg?alt=media&token=44b754bb-b76c-4a24-80d3-d299e2934111"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Fnode-js-coding.jpeg?alt=media&token=44b754bb-b76c-4a24-80d3-d299e2934111"
      /> */}
      {/* <Advantages
        total="6"
        head="Advantages of using Javascript"
        data={advantageData}
      /> */}
      <Offerings
        title="Advantages of using Node.js"
        desc=""
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2Fphp%2Fofferings.png?alt=media&token=d3fd7a68-2751-45e1-b71d-5e9fcce9184b"
        data={offeringData}
      />

      <Services
        title=" Our Node.js Development offerings"
        img=""
        data={servicesData}
      />
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our expertise"
        desc="Innorik has a large team of Node JS developers who have extensive experience with the language due to working with various project domains such as banking, telecommunication, healthcare, insurance systems, and with different companies. Our developers have experience ranging from 1-20 years. "
        button="Hire NodeJs Expert"
        data={phpDevelopers}
      />

      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FNodeJs%2Fnodejs-developers.jpeg?alt=media&token=8410c345-ebfa-4ce2-b615-9e201864d954"
        title="Node JS developers"
        desc="Innorik has a large team of node js developers who have very good experience with the language from working with various project domains such as banking, telecommunication, healthcare, insurance systems and with different companies. Our developers have experience ranging from 1year to 20 years."
        button="Hire NodeJs Expert"
      /> */}

      <WhyChooseUs
        head="Why choose us? "
        title="Why choose us?"
        desc=""
        data={Data2}
      />
      <Industries
        title="Industies Served"
        desc="We are international company and we deliver technology solutions to clients globally."
        data={Indust}
      />
    </>
  );
}
