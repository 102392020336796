import React from "react";
import List from "./Cards/List";

export default function WhyChoose(props) {
  const newData = props.data;
  const dataList = newData.map((data, index) => {
    return <List key={index} title={data.title} />;
  });
  return (
    <>
      <div
        style={{
          backgroundImage: `url("${props.img}")`,
        }}
        className=" text-white bg-cover   bg-top"
      >
        <div className="bg-black/40 w-full">
          <div className="conatiner max-w-[1500px] mx-auto bg-cover shadow-xl flex lg:flex-row  flex-col md:flex-row w-full lg:pl-[60px] lg:pr-[85px] py-[40px]">
            <div className="m-auto ml-0 w-full md:w-1/2 lg:w-2/3  pb-[10px]">
              <div className="m-auto ml-0 w-full">
                <h2 className="m-auto px-[5%] lg:pl-0 md:w-4/5 text-semibold  text-[24px] lg:text-[36px] text-left sm:mt-0 sm:mb-0 lg:text-left lg:mr-0">
                  {props.heading}
                </h2>
              </div>
            </div>
            <div className="conatiner text-white flex flex-col  w-full ">
              <div className="md:m-auto  w-full justify-center pl-[5%] ">
                <h2 className="m-auto  lg:pl-[30%] text-semibold  text-[24px] lg:text-[36px] text-left sm:mt-0 sm:mb-0 lg:text-left lg:mr-0">
                  {props.head}
                </h2>
                {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
              </div>
              <div className="m-auto lg:pl-[30%] md:pl-[0%]  w-full pt-2 ">
                <ul className=" list-outside grid grid-cols-1 md:w-[100%]  md:grid-cols-2 lg:grid-cols-1  text-[16px] lg:text-[18px] m-auto  w-11/12 sm:py-2 leading-loose">
                  {dataList}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
