import React from "react";
import Banner from "../../components/Banner";
import FooterBanner from "../../components/Footer/FooterBanner";
import Industries from "../../components/Industries";
// import TitleOverview from "../../components/TitleOverview";
import WhyChoose from "../../components/WhyChoose";
import Overview from "../CloudInfrastructure/Overview";
import ItNeeds from "./ItNeeds";
const Data = [
  {
    id: 1,
    title: "Unimaginable low costs",
  },
  {
    id: 2,
    title: "Process efficiency",
  },
  {
    id: 3,
    title: "Concentrate on key business functions",
  },
  {
    id: 4,
    title: "Flexibility in scaling",
  },

  {
    id: 4,
    title: "Staff motivation and retention",
  },
  {
    id: 4,
    title: "Reliability and quality of service",
  },
];

const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];

const Data2 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FbusinessProcessoutsourcing%2FApplication%20services%20outsourcing-1.jpg?alt=media&token=976dce8e-f17a-4ed2-a7fc-4df42e632fc7",
    title: "Cloud Migration",
    desc: "Onsite data center to cloud, Cloud to cloud migration",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FbusinessProcessoutsourcing%2FInfrastructure%20services%20outsourcing-1.jpg?alt=media&token=73608e70-2db5-4790-bfd9-5ae54b1635d3",
    title: "Cloud App Development",
    desc: "Involved in building, and managing cloud apps on any cloud service provider",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FbusinessProcessoutsourcing%2FQuality%20testing%20services%20outsourcing-1.jpg?alt=media&token=99002e80-5499-4c60-960a-e5d74dd713d1",
    title: "Cloud Security",
    desc: "We are security conscious, and we develop best strategies with technologies, polices, and services to protect cloud data, applications, and infrastructure from threats",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCSCloudIntegration.jpg?alt=media&token=aef0a0af-43f5-4d67-8345-e0072ef84d56",
    title: "Cloud Integration & Consolidation ",
    desc: "Innovative strategies and architectures to help connect your heterogeneous systems",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCSCloudConsult.jpg?alt=media&token=e76c31de-bd5e-48dc-9e46-d8ebf5bf6713",
    title: "Cloud Consult",
    desc: "	With our proven cloud-first strategy and our expertise in cloud transformation, Innorik enables companies to accelerate their growth in digital transformation.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCSCloudStrategy.jpg?alt=media&token=ae7fdd07-9005-4fa4-8c8d-e201aaab6794",
    title: "Cloud Strategy",
    desc: "Innorik’s strategic cloud migration roadmap has enabled companies to successfully migrate to the cloud.",
  },
];
export default function BusinessOutsourcing() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FbusinessProcessoutsourcing%2Fbusiness%20Process%20Outsourcing%20banner.jpg?alt=media&token=3cac3cfb-b7fa-4e92-8ea1-482c60cb84cf"
        title="Business Process Outsourcing "
        desc="Keeping the pendulum of application delivery chain swinging with Business Process Outsourcing."
      />
      <Overview
        title="Get ahead and stay connected to the world of innovation with Business Process Outsourcing"
        desc="In today’s world of digital transformation, a successful business is often an efficient one and the difference can come down to smart, innovative business processes. Modern business process outsourcing techniques can skyrocket your business growth. Business process outsourcing makes perfect sense because it enables you to concentrate on your core business activities. At Innorik, we will enable you to identify your core strength and take care of the rest for you. We will be your IT department you’ve been dreaming of. We will handle your technology so you can handle your business. What makes us great is our talented dedicated people worldwide. We have both onsite teams and offshore teams located in London, Canada, India, and Ghana."
      />{" "}
      <ItNeeds title="" data={Data2} />
      <Industries title="Our Industries" data={Indust} />
      <WhyChoose
        textColor="text-white"
        head="Why choose Innorik for your business process outsourcing? "
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FbusinessProcessoutsourcing%2Fbusiness-process-outsourcing-mobile-background.jpeg?alt=media&token=850a8e54-97a6-45bc-8313-1daa716eb4da"
        data={Data}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FbusinessProcessoutsourcing%2FWhy%20choose%20business%20process%20outsourcing.jpg?alt=media&token=549dd9d7-4bb2-4d72-8191-c6e40ee44761"
      />
      <FooterBanner title="Start a dialog" link="/contact-us" />
    </>
  );
}
