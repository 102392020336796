import React from "react";

export default function WhyChooseUs(props) {
  return (
    <>
      {/* change image for this section for mobile screen because it's hard to read text on image */}

      <div
        className="hidden lg:block m-auto py-4 text-center lg:text-left bg-cover bg-right "
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <div className=" text-white max-w-[1500px] mx-auto lg:flex-row  lg:py-[30px] md:py-[40px] py-[56px] ">
          <div className="md:m-auto w-full justify-start ">
            <h2 className="text-white px-[5%] text-semibold ml-auto  lg:w-2/2 sm:w-2/5 md:w-1/2 text-[24px] lg:text-[36px] sm:mt-0 sm:mb-0 text-left lg:mr-0">
              {props.head}
            </h2>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
          <div className="pt-4">
            <p className=" px-[5%] text-medium ml-auto  lg:w-2/2 sm:w-2/5 md:w-1/2 text-[18px] lg:text-[18px] sm:mt-0 sm:mb-0 text-left lg:mr-0">
              {props.data}
            </p>
            <p className=" px-[5%] text-medium ml-auto  lg:w-2/2 sm:w-2/5 md:w-1/2 text-[18px] lg:text-[18px] sm:mt-0 sm:mb-0 text-left lg:mr-0">
              {props.data2}
            </p>
            {/* <p className="text-justify md:text-left  text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-center">
              Cloud technologies are the key enabler to achieve digital
              transformation. Innorik’s infrastructure management solution help
              businesses deliver high-quality, cost-effective, and secure
              application services on-demand. At Innorik, our digital
              transformation goal is to help your business achieve your desired
              cloud computing state across private, public and hybrid cloud
              environments. Our comprehensive cloud digitalization plan will
              enable your business to expand your application delivery chain
              including your mission-critical legacy systems into the cloud.
            </p> */}
          </div>
        </div>
      </div>
      <div className="bg-black block lg:hidden  m-auto gap-0 w-full">
        <img
          className="w-full sm:h-[400px] object-cover object-center"
          src={props.img2}
        />
        <div className=" text-white pt-8 ">
          <div className="md:m-auto w-full justify-start px-[5%]">
            <h2 className="  text-semibold  text-[24px] text-left ">
              {props.head}
            </h2>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
        </div>
        <div className="bg-black text-white py-4 mt-0">
          <p className=" px-[5%] text-medium m-auto   text-[16px]  sm:mt-0 sm:mb-0 text-left lg:mr-0">
            {props.data}
            <br /> {props.data2}
          </p>
        </div>
      </div>

      {/*       
      <div
        className="m-auto py-[15%] sm:py-[5%] md:py-[5%] lg:py-[5%] text-center lg:text-left bg-cover bg-right h-full sm:h-[] md:h-[] lg:h-full xl:h-[]"
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <h1 className="px-[20px]  lg:w-1/2  text-[24px] lg:text-[36px] sm:text-[40px] md:text-[40px]  text-white ">
          Your cloud transformation journey is unique, let’s give you a helping
          hand
        </h1>
        
      </div> */}
    </>
  );
}
