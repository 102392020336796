import React from "react";
import Banner from "../../../components/Banner";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

import { deopsResponsibilities, deopsRequirement } from "../../../data/career";
export default function DevopsJob() {
  const listdeopsRequirement = deopsRequirement.map((item, index) => {
    return <li>{item.list}</li>;
  });
  const listdeopsResponsibilities = deopsResponsibilities.map((item, index) => {
    return <li>{item.list}</li>;
  });
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2Fgo-lang.jpeg?alt=media&token=4019704e-fecd-4080-8c91-098db4a09231"
        title="DevOps Engineer"
      />
      <div className="max-w-[1500px]  py-[50px] px-[28px]  md:px-[80px] lg:px-[85px] mx-auto text-[#222222]">
        <div className="lg:flex justify-between">
          <div>
            <h1 className="md:text-[28px] text-[24px] lg:text-[28px] font-semibold">
              Job Description :
            </h1>
          </div>
          <div className="md:w-9/12 text-[18px] text-[#222222]">
            <div>
              <h1 className="text-[20px] pt-[20px] md:text-[24px] font-medium">
                Responsibilities
              </h1>
              <ul className="space-y-2  text-[16px] pt-[20px] list-disc	list-outside	">
                {listdeopsRequirement}
              </ul>

              <h1 className="text-[20px] font-medium pt-[20px] lg:pt-[50px] md:text-[24px]">
                Requirement
              </h1>
              <ul className="space-y-2  text-[16px] pt-[20px] list-disc	">
                {listdeopsResponsibilities}
              </ul>
              <h1 className="text-[20px] lg:text-[24px] pt-[40px]">
                About Innorik
              </h1>
              <p className="text-[16px] mt-[10px]">
                Innorik is a North American based Information Technology company
                that specializes in the provision of IT consulting and software
                development services. We are not just another IT company, we are
                simply a think-tank that thinks outside the box and put the wow
                in technology. We are powered by innovation, and it is the core
                of everything we do. Our relentless software engineering never
                stop thinking team of experts is pushing limits. At Innorik, our
                goal is to deliver with unwavering integrity, “Best in Class” IT
                solutions, IT consultancy services and systems that help our
                customers stay one step ahead and out-compete their competition.
              </p>
              <div className=" pt-[40px] ">
                <Link to="/career/jobs/apply">
                  <button className="text-[16px] lg:text-[18px] py-[6px] px-[20px] lg:py-[10px] md:py-[10px] lg:px-[30px] md:px-[15px] flex text-[#5F8DFF] border border-[#5F8DFF]">
                    Apply
                    <div className="lg:pl-[20px] md:pl-[10px] pl-[5px] m-auto">
                      <IoIosArrowForward />
                    </div>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" bg- bg-no-repeat top-0 relative flex flex-col  bg-cover bg-center h-[350px] md:h-[300px]"
        style={{
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Career%2Fcareer-engineers-team.jpeg?alt=media&token=2c4e04b5-5de3-4224-b3f2-d5bb0a4f0c69")`,
        }}
      >
        <div className="bg-black/20 h-full">
          <div className=" max-w-[1000px] h-full px-[28px] md:px-[40px] lg:px-[85px] py-[20px] md:py-[30px] lg:py-[70px] text-center mx-auto">
            {/* <h1 className="text-[24px] md:text-[28px] lg:text-[36px]">
              {props.title}
            </h1> */}
            <div className="  md:py-4 lg:h-full m-auto">
              <div className="bg-white p-[20px]">
                <p className=" text-[16px] md:text-[18px]  text-[#222222]/80 lg:text-[18px]  lg:pt-[25px]">
                  Our people’s ideology aims at building a strong development
                  team of committed and motivated software professionals. Do you
                  have the skills to be part of the Innorik family? Do you have
                  the desire to build top-notch applications? Then apply to join
                  the family.
                </p>
                <p className="text-[16px] md:text-[18px]  text-[#5F8DFF]/90 lg:text-[18px] p-[0px] ">
                  career@innorik.com
                </p>
              </div>
            </div>

            <div className="flex justify-center">
              {/* <BorderButton button={props.button} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
