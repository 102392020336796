import React from "react";
import { Link } from "react-router-dom";
const whyData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fworkingatinnorik%2Fendless-oportunities.jpeg?alt=media&token=61ef725c-3169-47f6-9a41-26a664a78dd8",
    title: "Endless Opportunities",
    desc: "We aim to build a strong development team of committed and motivated professionals worldwide",
    click: "Connect with Us",
    link: "/career/jobs/apply",
    alt: "endless opportunities",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fworkingatinnorik%2Fgrowth-centric.jpeg?alt=media&token=8cfa7866-3a2c-4287-80de-c554439541ec",
    title: "Growth Centric",
    desc: "The structure of organizations and jobs are changing and it's imperative for us to keep training and to empower our talents for the jobs of the future",
    click: "Check our trainings",
    link: "/talent-world-wide",
    alt: "growth centric",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fworkingatinnorik%2Fpeople-oriented.jpeg?alt=media&token=c9e47730-2f33-47e4-aceb-5166c8743826",
    title: "People Oriented",
    desc: "Our values form the building blocks of our culture and defines how we work together to serve our customers and to change the world.",
    click: "Our core values",
    link: "/culture-and-values",
    alt: "Our core values",
  },
];
export default function WorkingAtInnorik() {
  const listData = whyData.map((list, index) => {
    return (
      <div key={index}>
        <div className="bg-[#222222] justify-between h-[320px] md:h-[300px] px-[40px] py-[30px]  text-white relative">
          <h1 className="text-[20px] md:text-[24px]">{list.title}</h1>
          <p className="py-[25px] text-[16px] md:text-[18px]">{list.desc}</p>
          {/* <p className="pt-[16px] pb-[10px] text-[18px]">
          Want to make a difference?
        </p> */}
          <Link to={list.link}>
            <p className="text-[18px] pt-[16px] absolute bottom-[20px] text-[#6590ff] lg:text-white lg:hover:text-[#6590ff] cursor-pointer">
              {list.click}
            </p>
          </Link>
        </div>
        <img
          className="h-[230px] w-full object-cover"
          src={list.img}
          alt={list.alt}
        />
      </div>
    );
  });
  return (
    <>
      <h1 className="text-[36px] text-[#222222] text-center pt-[50px]">
        Working at Innorik
      </h1>
      <div>
        <div className="] grid md:grid-cols-1 lg:grid-cols-3 gap-[20px] max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] pt-[50px] mx-auto">
          {listData}
        </div>
      </div>
    </>
  );
}
