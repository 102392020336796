import React from "react";
import Banner from "../../components/Banner";
import FooterBanner from "../../components/Footer/FooterBanner";
import FourColCard from "../../components/FourColCard";
import RightImageSection from "../../components/RightImageSection";
// import TechnologiesScroll from "../../components/TechnologiesScroll";
import OurItConsulting from "./ourItConsulting";
const Data = [
  {
    id: 1,
    title: "Digital transformation consulting",
  },
  {
    id: 2,
    title: "IT strategy consulting",
  },
  {
    id: 3,
    title: "IT solutions consulting",
  },
  {
    id: 4,
    title: "Technology consulting",
  },
  {
    id: 5,
    title: "Cybersecurity consulting",
  },
  {
    id: 6,
    title: "Cloud and data migration consulting",
  },
  {
    id: 7,
    title: "IT infrastructure management",
  },
  {
    id: 8,
    title: "Remote work consulting",
  },
  {
    id: 9,
    title: "Risk assessment analysis",
  },
  {
    id: 10,
    title: "Disaster recovery planning",
  },
];
const Data2 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FitConsulting%2FAnalysis-1.jpg?alt=media&token=fbdcaa1e-c0f5-4cb8-8bfe-000b02ab6974",
    title: "Analysis",
    desc: "",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FitConsulting%2FStrategy-1.jpg?alt=media&token=431638d9-bcd0-45ef-8b1c-c3e63500180d",
    title: "Strategy",
    desc: "",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FitConsulting%2FPerformance-1.jpg?alt=media&token=397a8d47-8f7b-4d89-9ac1-b8949d4031e3",
    title: "Performance",
    desc: "",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FitConsulting%2FImprovements-1.jpg?alt=media&token=5c410eb3-ac06-4f10-9b8b-d6ee55db716e",
    title: "Improvements",
    desc: "",
  },
];
export default function ItConsulting() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FitConsulting%2FITConsulting%20Banner.jpg?alt=media&token=ae8adac7-03d7-443c-b24e-b481af07b539"
        title="IT Consulting"
        desc="Bringing innovations closer to your business for a successful digital transformation"
      />
      <RightImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FitConsulting%2Fit-consulting-overview.svg?alt=media&token=4296c5d3-83ce-4c71-bbf8-67472875ca28"
        title="Reimagine your business with our world-class IT consultancy services"
        desc="Technology is the central driving force of progress in every industry that leads to achieving organizational goals, but it may be challenging to build a successful digital transformation strategy. At Innorik, we take a high-level view of an organization’s problem and use our expertise, deep tech, and IT consultancy domain experience to create a comprehensive IT strategy for a digital and technological transformation of your business in line with your organizational objectives. Our strategic IT consultancy services aims at putting the future of technology in your hands by automating and digitizing your business operations and implementing the latest technologies to drive competitive advantage and power business growth."
      />
      <OurItConsulting
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FitConsulting%2FOur%20cloud%20consulting%20offerings.jpg?alt=media&token=57d7f6a7-5f50-4f0f-8e61-00d70b4f61d8"
        head="Our IT Consulting Services"
        data={Data}
      />
      <FourColCard title="Our strategic consultancy process" data={Data2} />
      {/* <div>
        <h1 className="text-center pt-[50px] text-[24px] md:text-[26px] lg:text-[36px]">
          Our Technologies
        </h1>
        <TechnologiesScroll />
      </div> */}
      <FooterBanner title="Let's get started" link="/contact-us" />
    </>
  );
}
