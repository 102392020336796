import React from "react";

export default function FlipImageCard(props) {
  return (
    <>
      <div className="h-[400px] text-center px-[2px]">
        <div
          style={{
            backgroundImage: `url("${props.img}")`,
          }}
          className=" h-full bg-center	bg-cover  "
        >
          <div className="hover:bg-black/70 bg-black/30 h-full duration-300 overflow-hidden">
            <div className=" h-full pt-[280px] hover:-translate-y-[240px] duration-500">
              <div className="py-[45px] h-full w-full text-white px-[15px]">
                {/* <img className="h-[48px]" src={props.flag} /> */}
                <h1 className="pt-[20px] text-[24px] ">{props.title}</h1>
                <p className="pt-[40px]  ">{props.desc}</p>
                {/* <p className="pt-[20px]">{list.phone}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
