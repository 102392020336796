import React from "react";
import List from "../../../components/Cards/List";

export default function WhyChoose(props) {
  const newData = props.data;

  return (
    <>
      {/* change image for this section for mobile screen because it's hard to read text on image */}
      <div
        className="hidden lg:block h-0 md:h-auto lg:h-auto sm:h-0 lg:visible  m-auto py-4 text-center lg:text-left bg-cover bg-right "
        style={{
          backgroundImage: `url(${props.img1})`,
        }}
      >
        <div className=" max-w-[1500px] px-[85px] mx-auto   lg:flex-row  lg:py-[30px] md:py-[40px] py-[56px] ">
          <div
            className={`${props.textColor} md:m-auto w-full justify-start pl-[%]`}
          >
            <h2 className=" px-[%] text-semibold text-[#3066EA] lg:w-1/2 sm:w-2/5 md:w-1/2 text-[24px] lg:text-[36px] sm:mt-0 sm:mb-0 text-left lg:mr-0">
              {props.head}
            </h2>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
            <div className="pt-4 lg:w-1/2">
              {props.text ? (
                <p className=" text-[18px] pt-[25px] text-[#222222] ">
                  {props.text}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${props.textColor} bg-[#f9f9f9]  block lg:hidden grid m-auto gap-0 w-full`}
      >
        <img
          className="w-full sm:h-[400px] object-cover object-center"
          src={props.img2}
        />
        <div className="  pt-8 ">
          <div className="md:m-auto w-full justify-start px-[5%]">
            <h2 className="text-[#3066EA]  text-semibold  text-[24px] text-left ">
              {props.head}
            </h2>
          </div>
        </div>
        <div className="pt-4 px-[28px]">
          {props.text ? (
            <p className=" pb-[25px] text-[18px] pt-[25px] text-[#222222]">
              {props.text}
            </p>
          ) : null}
          {/* <ul className=" text-left list-outside text-[16px] lg:text-[18px] m-auto  w-11/12 sm:py-2 leading-loose">
            {dataList}
          </ul> */}
        </div>
      </div>

      {/*       
      <div
        className="m-auto py-[15%] sm:py-[5%] md:py-[5%] lg:py-[5%] text-center lg:text-left bg-cover bg-right h-full sm:h-[] md:h-[] lg:h-full xl:h-[]"
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <h1 className="px-[20px]  lg:w-1/2  text-[24px] lg:text-[36px] sm:text-[40px] md:text-[40px]  text-white ">
          Your cloud transformation journey is unique, let’s give you a helping
          hand
        </h1>
        
      </div> */}
    </>
  );
}
