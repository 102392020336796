import React from "react";
import Banner from "../../components/Banner";
// import OurSolutions from "./OurSolutions";
import Overview from "./Overview";
import ServicesCard from "./ServicesCard";
import WhyChooseUs from "./WhyChooseUs";
// import TabPanel from "./Tabs";
import Industries from "../../components/Industries";
// import WeBeleive from "./WeBeleive";
// import VerticalTabs from "./Tabs";
import TechTab from "./Tabs";
import NextGen from "./NextGen";
// import BannerImage from "../../Assets/Images/company/about/powerd-by-innovation.jpg";
import Services from "../../Assets/Images/company/about/services.jpg";
import Empower from "../../Assets/Images/company/about/Our-people.jpg";
import EmpowerMobile from "../../Assets/Images/company/about/Our-people-mobile.jpg";
import MobileTechTab from "../Technologies/Programming/DotNet/MobileTechTabs";
import { mobileData } from "../../data/about";
// const Data = [
//   {
//     id: 1,
//     title: "User Friendly",
//   },
//   {
//     id: 2,
//     title: "Responsive Design",
//   },
//   {
//     id: 3,
//     title: "Optimized for speed",
//   },
//   {
//     id: 4,
//     title: "Open source",
//   },
// ];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];
export default function About() {
  return (
    <>
      <Banner
        img="/images/company/about/about-banner.jpeg"
        title="Powered by Innovation"
        desc="We innovate to find a better way to leverage tech to drive a better IT experience for the clients who depend on us, the customers who rely on them and the communities who count on us all."
      />
      <div
        className="bg-fixed bg-no-repeat bg-cover bg-center"
        style={{
          backgroundImage: `url("${Services}")`,
        }}
      >
        <div className="max-w-[1500px] mx-auto  p-4 md:p-8 lg:p-20">
          <Overview />
        </div>
        <div className="max-w-[1500px] mx-auto">
          <ServicesCard />
        </div>
      </div>
      {/* <OurSolutions /> */}
      <div></div>
      <NextGen />

      <WhyChooseUs
        head="Our People"
        data="Our associates are our most valuable asset, they are skilled problem solvers with innovative minds. What is now proved was once only imagined! Our talented people imbibe these values and are inspired to solve any technology problem which leads to new discoveries.
        We have over 500 exceptional IT professionals globally who are well-versed in the following technology domains:"
        // data2="We have over 500 exceptional IT professionals globally who are well-versed in the following technology domains:"
        img={Empower}
        img2={EmpowerMobile}
      />
      <div className="hidden md:block lg:px-[85px] max-w-[1500px] mx-auto py-[50px]">
        <div>
          <h1 className="text-[36px] pb-[20px]">Our .Net Technologies Stack</h1>
        </div>
        <TechTab />
      </div>
      <div className="block md:hidden lg:px-[85px] max-w-[1500px] mx-auto py-[50px]">
        <div>
          <h1 className="text-[26px] text-center pb-[20px]">
            Our Technologies Stack
          </h1>
        </div>
        <MobileTechTab title="" desc="" data={mobileData} />
      </div>

      {/* <WeBeleive /> */}
      {/* <WhyChooseUs
        head="Why choose us "
        data="At Innorik we believe in the power of choice, choosing us is a smart investment for a caring eye for all your IT needs at a very unimaginable low cost."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FOurPeople.jpg?alt=media&token=f25dc7f0-a9c6-4a94-8695-994d6ae1c2d9"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FourPeopleNormal.jpg?alt=media&token=791941f5-1cb8-44d4-a0b5-fc603c65e5f2"
      /> */}
      <Industries
        title="Our Customers"
        desc="We are an international company, and we deliver technology solutions, and services to clients globally.  We have acquired expertise and a deep understanding of business models and processes across different industries including: "
        data={Indust}
      />
      {/* <WeBeleive/> */}
      {/* <TabPanel /> */}
    </>
  );
}
