import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import "./techtab.css";
import TabBox from "../Technologies/Programming/DotNet/TabBox";
// import { tabsData } from "./data";
// import TabPannelBox from './TabPannelBox'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function TechTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="border-solid border-2 border-[#CFCFCF]">
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "#F3F3F3",
          display: "flex",
          boxShadow: "10px",
        }}
      >
        <div className="shadow-[2px_0px_8px_0px_rgba(42,67,113,0.15)]">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            Tab={{
              style: {
                background: "#ffffff",
              },
            }}
            TabIndicatorProps={{
              style: {
                background: "#796EFF",
                //   height: "40px",
              },
            }}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              color: "#222222",
              bgcolor: "#ffffff",

              paddingLeft: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              width: "370px",
              //   paddingRight: "60px",
            }}
          >
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
                paddingRight: "60px",
              }}
              label="Backend "
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
                paddingRight: "60px",
              }}
              label="Frontend"
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
                paddingRight: "60px",
              }}
              label="Mobile "
              {...a11yProps(2)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Database"
              {...a11yProps(3)}
            />

            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Cloud Database"
              {...a11yProps(4)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Big data"
              {...a11yProps(5)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Containerization"
              {...a11yProps(6)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Automation"
              {...a11yProps(7)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="CI/CD Tools"
              {...a11yProps(8)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Monitoring"
              {...a11yProps(9)}
            />
            {/* <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Project Management Tools"
              {...a11yProps(10)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="User Interface"
              {...a11yProps(11)}
            /> */}
          </Tabs>
        </div>

        <TabPanel style={{ width: "70%" }} value={value} index={0}>
          <h1 className="text-[32px] text-[#3066EA]">Backend</h1>
          <div className="grid grid-cols-6 md:grid-cols-3 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fframeworks%2Fdot-net.svg?alt=media&token=85c04eb4-c13e-431b-8daf-1568235e4d54"
              desc=".NET"
              alt="microsoft dot net"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBackend%2FJAVA.svg?alt=media&token=606a4342-f294-46a5-89ba-9993eac957db"
              desc="Java"
              alt="java Programming Languages"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBackend%2FPython.svg?alt=media&token=6ed01f47-a80f-4e16-b005-c4d59f05a29c"
              desc="Python"
              alt="Python Programming language"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBackend%2FNode.js%20Logo.svg?alt=media&token=10a1b27c-ae3d-4c2a-8a75-0dafdec63795"
              alt="Node Js Programming language"
              desc="NodeJs"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBackend%2FPHP%20Logo.svg?alt=media&token=3ee9cea4-5e8b-44f6-8667-7a1f83260ec1"
              alt="Php Programming Language"
              desc="PHP"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBackend%2FGolang.svg?alt=media&token=4cd14935-ed8a-42cd-9c12-e3be3f6357df"
              alt="Go lang Programming language"
              desc="GoLang"
            />

            {/* <div className=" w-[106px] h-[106px]">
              <div className="bg-white border-solid border-2 border-[#CFCFCF] p-[24px] ">
                <img className="w-[58px] h-[58px] m-auto" />
              </div>
            </div> */}
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={1}>
          <h1 className="text-[32px] text-[#3066EA]">Frontend</h1>
          <div className="grid grid-cols-6 md:grid-cols-3 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FFrontend%2FHtml%205.svg?alt=media&token=a0d56e2f-3937-41cb-a4c7-671bb84241e0"
              alt="HTML 5"
              desc="HTML5"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FFrontend%2FCSS3.svg?alt=media&token=3a5aec6c-c70a-4734-89f7-779c358a1e34"
              alt="CSS 3"
              desc="CSS3"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FFrontend%2FJavaScript.svg?alt=media&token=a0e07756-7b6e-482f-98f8-6b183923c796"
              alt="Javascript"
              desc="Javascript"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FFrontend%2FAngularJS.svg?alt=media&token=5f5bf273-66ff-4486-8b80-2205762e09ff"
              alt="Angular js"
              desc="Angular JS"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FFrontend%2FReact%20Logo.svg?alt=media&token=f53cbf37-f300-49c8-a5db-fdadca03aba7"
              alt="React JS"
              desc="React JS"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FFrontend%2Fnext.svg?alt=media&token=4369e643-4c9a-4f82-933f-2f15caf31a86"
              alt="Next JS"
              desc="Next JS"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FFrontend%2Fmeteor.svg?alt=media&token=d98821b7-8e7a-4ec4-9ab1-10510225aefb"
              alt="Meteor"
              desc="Meteor"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FFrontend%2FVue.js%20Logo.svg?alt=media&token=1f726e75-c310-4b67-82ed-51bb1573e7d4"
              alt="View JS"
              desc="View JS"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FFrontend%2FEmber.svg?alt=media&token=74f679c6-4579-4365-ad3d-3ad99526abc3"
              alt="Ember JS"
              desc="Ember JS"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={2}>
          <h1 className="text-[32px] text-[#3066EA]">Mobile</h1>
          <div className="grid grid-cols-6 md:grid-cols-3 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FMobile%2FiOS%20Logo.svg?alt=media&token=1b12e2e7-e6d0-4394-b03f-8ec74d6315a9"
              desc="IOS"
              alt="Apple IOS"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FMobile%2FAndroid%20OS.svg?alt=media&token=c6837c7c-b166-453a-b7fd-7d653e4a26fd"
              desc="Android"
              alt="Android "
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FMobile%2FXamarin.svg?alt=media&token=c6e8813a-4119-41e3-aaf3-916b07077e31"
              desc="Xamarin"
              alt="Xamarin logo"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FMobile%2FCordova.svg?alt=media&token=709c5763-ef61-4786-983c-9e9990d6e8e9"
              desc="Cordova"
              alt="Cordova"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FMobile%2Fpwa.svg?alt=media&token=97f4c56d-dff0-4867-9d6e-5c98ffcfd932"
              alt="Progressive Web Apps"
              desc="PWA"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FMobile%2FReact%20Native.svg?alt=media&token=57471cad-96e6-423c-af9c-459689f73411"
              alt="React Native"
              desc="React Native"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FMobile%2FFlutter.svg?alt=media&token=04021b03-56b7-4370-a254-d88588af25e2"
              alt="Flutter"
              desc="Flutter"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FMobile%2FIonic.svg?alt=media&token=79aee34a-419e-4180-a6e5-3e60e52f9be5"
              alt="Ionic"
              desc="Ionic"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={3}>
          <h1 className="text-[32px] text-[#3066EA]">Database</h1>
          <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDatabase%2FSQL.svg?alt=media&token=7c87ec1c-ddfa-46cf-a82b-466b124020c0"
              desc="SQL"
              alt="SQL"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDatabase%2Fmysql.svg?alt=media&token=860e8072-0591-4ef0-95a8-4491d67e5b48"
              desc="MySQL"
              alt="MySQL"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDatabase%2FOracle%20Logo.svg?alt=media&token=e6b645ae-c0af-4799-a890-3be4448a4aef"
              desc="Oracle"
              alt="Oracle Database"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDatabase%2FPostgreSQL.svg?alt=media&token=dd4ecd0c-bca2-4b45-af76-db3fcfb677db"
              desc="Bootstrap"
              alt="BootStrap"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2Fjquery.svg?alt=media&token=e60467a6-297a-40ff-8ae5-bce319ea6f91"
              alt="PostgreSQL"
              desc="PostgreSQL"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDatabase%2FCassandra.svg?alt=media&token=31aecbe7-bd58-42e3-bb18-89d02309ad34"
              alt="Cassandra"
              desc="Cassandra"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDatabase%2FHive.svg?alt=media&token=48ba6c91-1318-4c30-b99a-5f61bf1d2a42"
              alt="Hive"
              desc="Hive"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDatabase%2FApache%20HBase.svg?alt=media&token=abcaed80-5a8a-44a5-acfe-f262bd341f59"
              alt="Apache HBase"
              desc="Apache HBase"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDatabase%2FNifi.svg?alt=media&token=ab35c338-8e3d-4b79-bdfc-b82125791ea8"
              alt="Nifi"
              desc="Nifi"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDatabase%2FMongoDB.svg?alt=media&token=c8fb1e85-8cde-494d-b0df-a2a55706162c"
              alt="MongoDB"
              desc="MongoDB"
            />
          </div>
        </TabPanel>

        <TabPanel style={{ width: "70%" }} value={value} index={4}>
          {/* <h1 className="text-[32px] text-[#3066EA]">Cloud Database</h1> */}
          <div className="grid md:grid-cols-3 grid-cols-6 gap-[48px] py-[16px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAWS%2FAmazon%20Web%20Services.svg?alt=media&token=d4136603-2ff2-452e-9516-43c6bdd101c5"
              desc="AWS"
              alt="Amazon web service"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAWS%2FAmazon%20S3.svg?alt=media&token=6caed6bc-acec-4ad6-8d41-7f7cee71f7b0"
              desc="Amazon S3"
              alt="Amazon S3"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAWS%2FAmazon%20RDS.svg?alt=media&token=aea52175-517c-4bb5-bfdb-e2333e73a278"
              desc="Amazon RDS"
              alt="Amazon RDS"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAWS%2FAmazon%20DynamoDB.svg?alt=media&token=bdb17a0d-3cff-41a3-93b0-3e82db4dd54d"
              desc="DynamoDB."
              alt="DynamoDB."
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAWS%2FAmazon%20DocumentDB.svg?alt=media&token=a95425ac-bdd2-4985-a372-deb94a6e5cac"
              alt="DocumentDB"
              desc="DocumentDB"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAWS%2FAWS%20Elasticache.svg?alt=media&token=d5c2e32c-ef3b-4fc2-a08b-462c12763de5"
              alt="Elasticache"
              desc="Elasticache"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdeployment%2Fazure.svg?alt=media&token=be774a0f-6735-4adc-827f-55e3cae93910"
              alt="Microsoft Azure"
              desc="Azure"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAzure%2FAzure%20Blob%20Storage.svg?alt=media&token=94100b02-603e-4e9c-b796-6d9c22e78228"
              alt="Blob Storage"
              desc="Blob Storage"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAzure%2FAzure%20Cosmos%20DB.svg?alt=media&token=150b638c-d9bb-4da2-a817-88248468f7bf"
              alt="Azure Cosmos DB"
              desc="Cosmos DB"
            />{" "}
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAzure%2FAzure%20Data%20Lake.svg?alt=media&token=b227f723-5e5f-4290-ac26-884f6182eda8"
              alt="Data Lake"
              desc="Data Lake"
            />{" "}
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAzure%2Fazure-sql.svg?alt=media&token=538d253a-1301-4b55-9724-273708b986cc"
              alt="Azure SQL"
              desc="Azure SQL"
            />{" "}
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FAzure%2FAzure%20Synapse%20Analytics.svg?alt=media&token=4b18eb0b-b4e4-4d14-be49-4b4d221b9909"
              alt="Azure Synapsee"
              desc="Azure Synapsee"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FGoogle%2FGoogle%20Cloud%20SQL.svg?alt=media&token=8c5b4ace-d5ce-410d-9b16-185f14a0cc46"
              alt="Google Cloud"
              desc="Google Cloud"
            />{" "}
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FGoogle%2Fgoogle-cloud-sql.svg?alt=media&token=3e5be3f9-0438-4938-be90-6d92880a7a4d"
              alt="Google SQL"
              desc="Google SQL"
            />{" "}
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FCloud%2FGoogle%2FGoogle%20Cloud%20Datastore.svg?alt=media&token=e86c2001-c7b4-417c-9768-7e9f10c513b7"
              alt="Datastore"
              desc="Datastore"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={5}>
          <h1 className="text-[32px] text-[#3066EA]">Big Data</h1>
          <div className="grid grid-cols-6 md:grid-cols-3 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FHadoop%20Distributed%20File%20System.svg?alt=media&token=7f82cf4f-240b-456d-951c-d61c7ac496f4"
              desc="Hadoop"
              alt="Hadoop"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FSpark.svg?alt=media&token=05436d8c-28b3-4385-8cda-530fb1caec85"
              desc="Spark"
              alt="Spark"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FCassandra.svg?alt=media&token=713d84d0-23c4-4c6c-addf-0f0e81955b3d"
              desc="Cassandra"
              alt="Cassandra"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FKafka.svg?alt=media&token=a235de03-8713-4cff-ae5d-65f23e7bfc01"
              desc="Kafka"
              alt="Kafka"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FHive.svg?alt=media&token=f867f65b-3d36-4cb0-aa15-3e991ecc494f"
              desc="Hive"
              alt="Hive"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FZooKeeper.svg?alt=media&token=64d0efcb-d420-41ba-9f41-40cc4af2ae7f"
              desc="ZooKeeper"
              alt="ZooKeeper"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FApache%20HBase.svg?alt=media&token=efda6e36-23da-426a-a8cc-9386a2f4a8d9"
              desc="HBase"
              alt="HBase"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2Fazure%20cosmos.svg?alt=media&token=c4084d61-3a30-4ae2-a18d-56308ce772e5"
              desc="Cosmos DB"
              alt="Cosmos DB"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FAmazon%20Redshift.svg?alt=media&token=cfdbe22e-11e9-49fc-b30b-0b2ac788a1ba"
              desc="Redshift"
              alt="Redshift"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FAmazon%20DynamoDB.svg?alt=media&token=db47ed82-14f9-4125-b737-16690dcd6204"
              desc="DynamoDB"
              alt="DynamoDB"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FMongoDB.svg?alt=media&token=d57e458f-5247-4303-a6c4-647b4c19d586"
              desc="MongoDB"
              alt="MongoDB"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FBigData%2FGoogle%20Cloud%20Datastore.svg?alt=media&token=cd56fdba-6eed-435a-90b4-7e6e8cf307de"
              desc="Datastore"
              alt="Datastore"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={6}>
          <h1 className="text-[32px] text-[#3066EA]">Containerization</h1>
          <div className="grid grid-cols-6 md:grid-cols-3 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FConterization%2FDocker.svg?alt=media&token=f7bbd3b6-956a-43bc-a959-caccf6829714"
              desc="Docker"
              alt="Docker"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FConterization%2FKubernetes.svg?alt=media&token=cd5db56e-774d-4757-b132-bb1a2140b2f8"
              desc="Kubernetes"
              alt="Kubernetes"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FConterization%2FMesos.svg?alt=media&token=226b1f3b-9733-4b3d-a8ab-a03bae200bf2"
              desc="Mesos"
              alt="Mesos"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FConterization%2FOpenshift.svg?alt=media&token=022a5e25-47aa-4cc9-8ad9-767c3d60ed9e"
              desc="Openshift"
              alt="Openshift"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={7}>
          <h1 className="text-[32px] text-[#3066EA]">Automation</h1>
          <div className="grid grid-cols-6 md:grid-cols-3  gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FAutomation%2FAnsible.svg?alt=media&token=a942207a-4b70-4fc4-ae11-c227219ae40f"
              desc="Ansible"
              alt="Ansible"
            />{" "}
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FAutomation%2FChef.svg?alt=media&token=b807484d-bed3-48da-8190-38d3a24f246d"
              desc="Chef"
              alt="Chef"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FAutomation%2FPacker.svg?alt=media&token=1be29ed3-d17b-4880-ab3a-056573982607"
              desc="Packer"
              alt="Packer"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FAutomation%2FPuppet.svg?alt=media&token=e15fbea9-cb21-4efc-bc77-71e6d7f658ea"
              desc="Puppet"
              alt="Puppet"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FAutomation%2FSaltstack.svg?alt=media&token=97808544-d9a4-4584-8295-015cd24e3581"
              desc="Saltstack"
              alt="SaltStack"
            />{" "}
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FAutomation%2FTerraform.svg?alt=media&token=0975f48e-5550-47e4-b684-dabda4074d13"
              desc="Terraform"
              alt="Terraform"
            />
          </div>
        </TabPanel>

        <TabPanel style={{ width: "70%" }} value={value} index={8}>
          <h1 className="text-[32px] text-[#3066EA]">CI/CD Tools</h1>
          <div className="grid grid-cols-6 md:grid-cols-3 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FCiCdTools%2FJenkins.svg?alt=media&token=64cea7f2-71ec-4c28-876a-4e42b34435f8"
              desc="Jenkins"
              alt="Jenkins"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FCiCdTools%2FAmazon%20Web%20Services.svg?alt=media&token=2d076da5-989c-4258-badd-c638e50624a7"
              desc="AWS"
              alt="AWS"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FCiCdTools%2FAzure%20DevOps.svg?alt=media&token=a052d794-9eb6-4a05-a439-2e5e763b9670"
              desc="Azure Devops"
              alt="Azure Devops"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FCiCdTools%2FGoogle%20Developer%20Tools.svg?alt=media&token=9de17182-2405-42b9-941c-016bba970ff9"
              desc="Google Developer Tools"
              alt="Google Developer Tools"
            />
            {/* <TabBox img="" desc="" alt="" />
            <TabBox img="" desc="" alt="" /> */}
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={9}>
          <h1 className="text-[32px] text-[#3066EA]">Monitoring</h1>
          <div className="grid grid-cols-6 md:grid-cols-3 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FMonitoring%2FDatadog.svg?alt=media&token=a28635b0-fbcc-455f-80c6-7a74220fb1f2"
              desc="Datadog"
              alt="Datadog"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FMonitoring%2FElasticsearch.svg?alt=media&token=ed699656-9d2b-4208-b1fc-e41f77668b73"
              desc="ElasticSearch"
              alt="Elastic Search"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FMonitoring%2FGrafana.svg?alt=media&token=62c32096-b6f1-4cf7-aebe-62fc2f06e6d4"
              desc="Grafana"
              alt="Grafana"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FMonitoring%2FNagios.svg?alt=media&token=e484dc24-71f3-49a6-aadc-c9b13e94e9ab"
              desc="Nagios"
              alt="Nagios"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FMonitoring%2FPrometheus.svg?alt=media&token=afe09d51-513a-4a53-8b3b-46ee50e8b2fb"
              desc="Prometheus"
              alt="Prometheus"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/About%2FIcons%2Ftabs%2FDevops%2FMonitoring%2FZabbix.svg?alt=media&token=d05d3d64-a0ce-4ea8-abd3-7244a9ae346e"
              desc="Zabbix"
              alt="Zabbix"
            />
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
