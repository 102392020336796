import React, { useState } from "react";
// import { Data } from "./Data";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";

import styled from "styled-components";
import { IconContext } from "react-icons";
// import { FiPlus, FiMinus } from "react-icons/fi";
// import { list } from "postcss";
// import { newMobileNavData } from "../../data/home";
const AccordionSection = styled.div`
  flex-direction: column;
  align-items: start;

  justify-content: center;
  position: relative;
`;

const Container = styled.div`
  left: 4%;
  top: 10%;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;

  span {
    margin-right: 1.5rem;
  }
`;

const Dropdown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export default function Accordian({ data }) {
  const [clicked, setClicked] = useState(false);
  // const [subClicked, setSubClickec] = useState(false)

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };
  // const listData = Data.map()
  // console.log(newMobileNavData.details);

  const listingNewData = data.map((item, index) => (
    <div key={index} className="text-white pt-[20px] pb-[10px]">
      <Wrap onClick={() => toggle(index)} key={index}>
        <h1 className="text-[18px] font-medium lg:text-[22px] text-left">
          {item.tab}
        </h1>
        <span>
          {clicked === index ? (
            <FiChevronDown color="white" />
          ) : (
            <FiChevronRight color="white" />
          )}
        </span>
      </Wrap>
      {clicked === index ? (
        <Dropdown className="text-left pl-2 pt-[20px]">
          {item.details.map((list, index) => (
            <>
              <p className="text-white/90 text-[18px] font-medium underline">
                {list.title}{" "}
              </p>
              <ul className=" space-y-[10px] text-[16px] py-[10px]">
                {list.data.map((data, index) => (
                  <li className="flex ">
                    <FiChevronRight color="white" className="text-[14px] " />
                    <a href={data.link}>{data.title} </a>
                  </li>
                ))}
              </ul>
            </>
          ))}
        </Dropdown>
      ) : null}
    </div>
  ));

  return (
    <IconContext.Provider value={{ color: "#222222", size: "25px" }}>
      <AccordionSection className="px-[28px]">
        <Container className="">{listingNewData}</Container>
      </AccordionSection>
    </IconContext.Provider>
  );
}
