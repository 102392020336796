import React from "react";
import Banner from "../../components/Banner";
import FooterBanner from "../../components/Footer/FooterBanner";
import TechnologiesScroll from "../../components/TechnologiesScroll";
import WhyChoose from "../../components/WhyChoose";
import Overview from "../Technologies/Programming/Php/Overview";
// import Overview from "../Devops/Overview";
import AccordianSection from "./AccordianSection";
import WhyCloudConsulting from "./WhyCloudConsulting";
const Data = [
  {
    id: 1,
    title: "No matter your budget, we are ready to work with you",
  },
  {
    id: 2,
    title: "Strategic partnership",
  },
  {
    id: 3,
    title: "Client-first approach",
  },
  {
    id: 4,
    title:
      "Large pool of security conscious and highly experienced tech talents",
  },
  {
    id: 4,
    title: "Client collaboration with enhanced communication",
  },
  {
    id: 4,
    title:
      "We have all the skills and the domain expertise to get your job done",
  },
];

const Offering = [
  {
    id: 1,
    title: "Reduced cost to 50%",
    desc: "sdfsdfjsd sdfjslkdjf sdklfj sdfjlks dfewiuoifshdf soifhdds ohif",
  },
  {
    id: 1,
    title: "Reduced cost to 50%",
    desc: "sdfsdfjsd sdfjslkdjf sdklfj sdfjlks dfewiuoifshdf soifhdds ohif",
  },
];

export default function CloudConsulting() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudConsulting%2FcloudConsulting%20banner.jpg?alt=media&token=dc0386b0-b67e-4780-83e6-ad18288c585b"
        title="Your pathway to cloud possibilities"
        desc=""
      />
      <Overview
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudConsulting%2FCloud%20Consulting.jpg?alt=media&token=a7cb59af-be40-45bb-aa9c-6e2a61a466e6"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudConsulting%2FCloud%20Consulting.jpg?alt=media&token=a7cb59af-be40-45bb-aa9c-6e2a61a466e6"
        title="Enjoy a breath of fresh air with our cloud technologies"
        desc="Cloud technology has the potential to revolutionize business, offering agility, scale, and favorable economics but without guidance it is as if you are flying a plane without a compass. At Innorik we engineer cloud transformation at every step and our cloud consulting services can help you determine the best strategy to adopt, platform selections, best cost modeling to use and to get you started with your cloud journey. We will help you accelerate your business transformation by applying industry and domain level expertise with cloud and AI technologies. "
      />
      <AccordianSection title="Our cloud consulting offerings" />
      <WhyCloudConsulting />
      <WhyChoose
        textColor="text-white"
        head="Why choose Innorik for your cloud consultancy services?"
        data={Data}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudConsulting%2FWhy%20choose%20cloud%20consultancy.jpg?alt=media&token=89e31e01-bf8e-4e5d-a39a-3c6e4b45e8d3"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudConsulting%2Fcloud-consulting-background.jpeg?alt=media&token=aa3fec99-090d-44fe-86b1-1e4f104b42ed"
      />
      <div>
        <h1 className="text-center pt-[50px] text-[24px] md:text-[26px] lg:text-[36px]">
          Our Technologies
        </h1>
        <TechnologiesScroll />
      </div>
      <FooterBanner title="Let's get started" link="/contact-us" />
    </>
  );
}
