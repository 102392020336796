import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
// import { data } from "./data";
// import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
// import { render } from "@testing-library/react";
const data = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Contact%2Fnew-york-statue-of-liberty.jpeg?alt=media&token=6e9443a0-be0f-44da-a0ea-50a1aa42c328",
    flag: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Ficons%2Funited-states-of-america.svg?alt=media&token=8e636739-9a1d-4b92-8e00-a989e8de434b",
    country: "USA",
    text: "80 Broad Street 5th Floor, New York City, New York 10004, United States of America",
    phone: "+1 855-221-9979",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Contact%2Flotus-temple-india-delhi.jpeg?alt=media&token=359adaab-859e-4772-b413-6cc45547a87c",
    flag: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Contact%2Fflags%2Findia1.svg?alt=media&token=9f1ac936-696c-44c8-b523-7c97e48ec7c7",
    country: "India",
    text: "Prestige Central, 36, Infantry Road, Bengaluru, Karnataka 560001, India",
    phone: "+91 86268 08149",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Contact%2Fkwame-nkrumah-memorial-park-accra-ghana.jpeg?alt=media&token=428e0310-e6c8-4732-a90d-6261fe9229d6",
    flag: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Contact%2Fflags%2Fghana.svg?alt=media&token=80cb8c1b-bd16-40dc-bdbe-5a218816c008",
    country: "Ghana",
    text: "Presidential Floor Mövenpick Ambassador Hotel Accra Independence Avenue, Ridge Accra",
    phone: "+233 30 701 1887",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Contact%2Fbig-ben-clock-tower-london.jpeg?alt=media&token=fe84dd98-88de-4056-a520-e30e56efdb97",
    flag: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Contact%2Fflags%2Funited-kingdom.svg?alt=media&token=3889a0ba-d9de-43fb-b55e-a587b73c464d",
    country: "UK",
    text: "128 City Rd, London EC1V 2NJ, United Kingdom",
    phone: "+44 20 3014 8930",
  },
  {
    id: 5,
    img: "/images/company/home/canada-office.jpeg",
    flag: "/images/company/home/icons/canada.svg",
    country: "Canada",
    text: "2 St. Clair Avenue West, Toronto, Ontario M4V 1L5 Canada",
    phone: "+1 855-221-9979",
  },
  {
    id: 6,
    img: "/images/shimla.jpeg",
    flag: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Contact%2Fflags%2Findia1.svg?alt=media&token=9f1ac936-696c-44c8-b523-7c97e48ec7c7",
    country: "India",
    text: "Krishna Cottage, Bhattakufer, Shimla, 171006",
    phone: "+91 8626808149",
  },
];
const PreviousBtn = (props) => {
  // console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FiChevronLeft
        className="stroke-[1.2px]	"
        style={{ color: "white", fontSize: "60px" }}
      />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FiChevronRight
        className="stroke-[1.2px]	"
        style={{ color: "white", fontSize: "60px" }}
      />
    </div>
  );
};

const Carousel = () => {
  var settings = {
    slidesToShow: 4,
    autoplay: false,
    autoplaySpeed: 2000,
    // dots
    // initialSlide: 4,

    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="carousel max-w-[1500px]  px-[28px] md:px-[40px] lg:px-[85px] mx-auto pb-[40px] lg:pt-[50px]">
      <h1 className="text-center pb-[50px] text-[28px] lg:text-[36px]">
        Our offices
      </h1>
      <Slider {...settings}>
        {data.map((list, index) => {
          return (
            <div key={index} className="h-[400px] px-[2px]">
              <div
                style={{
                  backgroundImage: `url("${list.img}")`,
                }}
                className=" h-full bg-center	bg-cover  "
              >
                <div className="hover:bg-[#6894ff]/70 text-center bg-black/30 h-full duration-300 overflow-hidden">
                  <div className=" h-full pt-[290px] hover:-translate-y-[200px] duration-500">
                    <div className="py-[45px] h-full text-white px-[15px]">
                      <div className="flex justify-center items-center mx-auto">
                        <img className=" h-[48px]" src={list.flag} />
                        <h1 className="pt-[0px] pl-[20px] text-[24px] ">
                          {list.country}
                        </h1>
                      </div>

                      <p className="pt-[20px]   ">{list.text}</p>
                      <p className="pt-[20px]">{list.phone}</p>
                      <p className="pt-[10px]">info@innorik.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* ))} */}
      </Slider>
    </div>
  );
};

export default Carousel;
