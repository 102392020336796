import React from "react";
// const data = [
//   {
//     img: "/images/company/home/icons/light/Client-always-first-light.svg",
//     title: "Talent Transformation",
//     desc: "Transforming talents through our internal training programs and Innorik clubs in colleges worldwide",
//   },
//   {
//     img: "/images/company/about/icons/devops/CICD Tools/Jenkins.svg",
//     title: "High capacity series",
//     desc: "We address customers pain points and leverage tech to drive a better IT experience",
//   },
// ];
// const data2 = [
//   {
//     img: "/images/company/about/icons/devops/CICD Tools/Jenkins.svg",
//     title: "Experts in the field",
//     desc: "Our passion is innovation, our expertise is unmatched, we get you more.",
//   },
//   {
//     img: "/images/company/about/icons/devops/CICD Tools/Jenkins.svg",
//     title: "Precision of work",
//     desc: "We personalize and deliver extraordinary customer service. We are innovating to build a better tomorrow.",
//   },
// ];
export default function WhyWork() {
  // const listData = data.map((list, index) => {
  //   return (
  //     <div className="lg:px-[20px] lg:pb-[20px]  ">
  //       <img className="h-[52px] " src={list.img} />
  //       <h1 className=" text-[20px] md:text-[24px] pt-2">{list.title}</h1>
  //       <p className="text-[16px] md:text-[18px] pt-2 text-white/90">
  //         {list.desc}
  //       </p>
  //     </div>
  //   );
  // });
  return (
    <>
      <h1 className="text-[32px] lg:text-[36px] py-[40px] lg:pt-[100px] text-center text-[#222222]">
        Why work with us?
      </h1>

      <div className="bg-[#3066EA] pb-[80px]">
        <div
          className="elementor-shape elementor-shape-top fill-white"
          data-negative="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 50"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M500,24.9L0,0.1V0h1000v0.1L500,24.9z"
            ></path>
          </svg>{" "}
        </div>

        <div className="text-[#FFFFFF] mx-auto  grid  md:grid-cols-3 max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] divide-dashed md:divide-x">
          {/* 2 rows for col 1 */}
          <div className="grid grid-rows-2 divide-dashed divide-y ">
            <div className="lg:pr-[40px] lg:pb-[20px]  py-[20px]">
              <img
                className="h-[84px]"
                src="/images/company/home/icons/light/Reduced-cost-light.svg"
                alt=""
              />

              <h1 className=" text-[20px] md:text-[24px] pt-4">Reduced Cost</h1>
              <p className="text-[16px] md:text-[18px] pt-2 text-white/90">
                We are on a mission to make every business enjoy technology at
                an unimaginable low cost
              </p>
            </div>
            <div className="lg:pr-[40px] lg:pb-[20px]  py-[40px]">
              <img
                className="h-[84px]"
                src="/images/company/home/icons/light/Project-Timelines-light.svg"
                alt=""
              />

              <h1 className=" text-[20px] md:text-[24px] pt-4">
                Talent Transformation
              </h1>
              <p className="text-[16px] md:text-[18px] pt-2 text-white/90">
                Transforming talents through our internal training programs and
                Innorik clubs in colleges worldwide
              </p>
            </div>
          </div>
          {/* 2 rows for row 2 */}
          <div className="grid grid-rows-2 divide-dashed divide-y ">
            <div className="lg:px-[40px] lg:pb-[20px]  py-[20px]">
              <img
                className="h-[84px]"
                src="/images/company/home/icons/light/High-capacity-series-light.svg"
                alt=""
              />

              <h1 className=" text-[20px] md:text-[24px] pt-4">
                High capacity series
              </h1>
              <p className="text-[16px] md:text-[18px] pt-2 text-white/90">
                We address customers pain points and leverage tech to drive a
                better IT experience
              </p>
            </div>
            <div className="lg:px-[40px] lg:pb-[20px]  py-[40px]">
              <img
                className="h-[84px]"
                src="/images/company/home/icons/light/Client-always-first-light.svg"
                alt=""
              />

              <h1 className=" text-[20px] md:text-[24px] pt-4">
                Client always first
              </h1>
              <p className="text-[16px] md:text-[18px] pt-2 text-white/90">
                We prioritize our customers need until they are done and
                satisfied.
              </p>
            </div>
          </div>

          {/* 2rows for 3rd coloum */}
          <div className="grid grid-rows-2 divide-dashed divide-y ">
            <div className="lg:pl-[40px] lg:pb-[20px]  py-[20px]">
              <img
                className="h-[84px]"
                src="/images/company/home/icons/light/Experts-in-the-field-light.svg"
                alt=""
              />

              <h1 className=" text-[20px] md:text-[24px] pt-4">
                Experts in the field
              </h1>
              <p className="text-[16px] md:text-[18px] pt-2 text-white/90">
                Our passion is innovation, our expertise is unmatched, we get
                you more.
              </p>
            </div>
            <div className="lg:pl-[40px] lg:pb-[20px]  py-[40px]">
              <img
                className="h-[84px]"
                src="/images/company/home/icons/light/Precision-for-work-light.svg"
                alt=""
              />

              <h1 className=" text-[20px] md:text-[24px] pt-4">
                Precision of work
              </h1>
              <p className="text-[16px] md:text-[18px] pt-2 text-white/90">
                We personalize and deliver extraordinary customer service. We
                are innovating to build a better tomorrow.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-shape elementor-shape-top fill-[#3066EA]"
        data-negative="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 50"
          preserveAspectRatio="none"
        >
          <path
            className="elementor-shape-fill"
            d="M500,24.9L0,0.1V0h1000v0.1L500,24.9z"
          ></path>
        </svg>{" "}
      </div>
    </>
  );
}
