import React from "react";

export default function Overview(props) {
  return (
    <>
      <div className="bg-gradient-to-b from-[#02327A] to-[#001245]">
        <div className="conatiner max-w-[1500px] mx-auto px-[28px] md:px[40px] lg:px-[85px] text-white flex flex-col lg:flex-row  justify-evenly w-full lg:py-[30px] md:py-[40px] py-[56px] ">
          <div className="m-auto w-4/5 ">
            <h2 className="m-auto lg:pr-[40px] text-semibold text-[24px] lg:text-[36px] text-center sm:mt-0 sm:mb-0  lg:text-left lg:mr-0">
              {props.title}
            </h2>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
          <div className="m-auto w-full ">
            <p className="text-justify md:text-left  text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px]  sm:py-10 sm:text-center">
              {props.desc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
