import React from "react";

// import HoverCard from "../../components/Cards/HoverCard";
import NoHoverCard from "../../components/Cards/NoHoverCard";

export default function WhatCard(props) {
  const listData = props.data;
  //   console.log(listData)

  const newData = listData.map((data, index) => {
    return (
      <NoHoverCard
        key={index}
        image={data.img}
        title={data.title}
        desc={data.desc}
      />
    );
  });
  return (
    <>
      <div className=" text-center max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px] self-center	 leading-snug lg:pt-[50px]">
        <h1 className="pt-8 text-[#222222] px-2 lg:pt-8 mt-0 font-medium text-xl text-center mb-2 ms:text-[28px] ms:mb-6 md:text-[26px] md:mb-2 lg:text-[36px] lg:mb-2">
          {props.title}
        </h1>
        <p className="text-center py-[20px] xl:text-lg 2xl:text-xl w-4/5 lg:w-3/5 mx-auto text-[#222222]">
          {" "}
          {props.desc}
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mx-auto w-full gap-6 sm:gap-8 md:gap-14 lg:gap-5 xl:gap-10 lg:py-[40px] 2xl:gap-14">
          {newData}
        </div>
      </div>
    </>
  );
}
