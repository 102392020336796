import React from "react";

export default function WhyCloudConsulting() {
  return (
    <>
      <div className="max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px]">
        <h1 className="mt-10 font-semibold text-xl text-center mb-8 ms:text-2xl ms:mb-6 md:text-3xl md:mb-9 lg:text-4xl lg:mb-12">
          Why you need cloud consulting?
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mx-auto pb-10 w-full gap-6 sm:gap-8 md:gap-8 lg:gap-5 ">
          <div className="flex group h-[400px] sm:h-[360px] lg:h-[380px] bg-slate-900/[.04]">
            <div className="w-[8px] shrink-0 group-hover:bg-[#6793ff]"></div>
            <div className="flex flex-col">
              <div className="font-semibold pl-4 pr-10 pt-6 pb-5 text-lg xl:text-xl 2xl:text-2xl">
                Knowldge
              </div>
              <div className="px-5 2xl:text-lg">
                You get the advisory needed to confidently start your cloud
                adoption journey
              </div>
            </div>
          </div>
          <div className="flex group h-[420px] sm:h-[360px] lg:h-[380px] bg-slate-900/[.04]">
            <div className="w-[8px] shrink-0 group-hover:bg-[#6793ff]"></div>
            <div className="flex flex-col">
              <div className="font-semibold pl-4 pr-10 pt-6 pb-5 text-lg xl:text-xl 2xl:text-2xl">
                Efficiency
              </div>
              <div className="px-5 2xl:text-lg">
                Handling some of the tasks needed to run your cloud environment
                to a consulting firm will enable you to focus on other aspects
                of your company leading to an increase in organizational
                efficiency to drive business outcomes.
              </div>
            </div>
          </div>
          <div className="flex group h-[400px] sm:h-[360px] lg:h-[380px] bg-slate-900/[.04]">
            <div className="w-[8px] shrink-0 group-hover:bg-[#6793ff]"></div>
            <div className="flex flex-col">
              <div className="font-semibold pl-4 pr-10 pt-6 pb-5 text-lg xl:text-xl 2xl:text-2xl">
                Reduced Costs
              </div>
              <div className="px-5 2xl:text-lg">
                Cloud consulting firms has cloud consultants and experts to set
                up your cloud infrastructure in the most efficient way possible
                to save your organization from unnecessary cloud spending.
              </div>
            </div>
          </div>
          <div className="flex group h-[400px] sm:h-[360px] lg:h-[380px] bg-slate-900/[.04]">
            <div className="w-[8px] shrink-0 group-hover:bg-[#6793ff]"></div>
            <div className="flex flex-col">
              <div className="font-semibold pl-4 pr-10 pt-6 pb-5 text-lg xl:text-xl 2xl:text-2xl">
                Enhanced Security
              </div>
              <div className="px-5 2xl:text-lg">
                Working with a cloud consulting firm allows your infrastructure
                to be fully managed and continuously check for security issues
                and compliance to ensure the safety of your data.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
