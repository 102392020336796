import React from "react";
import Banner from "../../../../components/Banner";
import Industries from "../../../../components/NewIndustries";
import Offerings from "../Php/Offerings";
// import Overview from "../Php/Overview";
import Services from "../Php/Services";
import WhyChooseUs from "../Php/WhyChooseUs";
import Advantages from "./Advantages";
import OurDevelopers from "../../Platforms/Magento/OurDevelopers";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import { phpDevelopers } from "../../../../data/programming";
const advantageData = [
  {
    id: 1,
    img: "/images/technologies/programming/angular/icons/code.svg",
    title: "Multiple platforms",
    desc: "A codebase created for constructing an AngularJS application can be used to create any application for any target platform. Web, mobile, native desktop, and native mobile applications all use the same code.",
  },
  {
    id: 2,
    img: "/images/technologies/programming/angular/icons/web.svg",
    title: "Progressive web apps ",
    desc: "AngularJS is used to create modern web apps that are dynamic, easy to install, offline, have zero loading time, and give excellent performance. It also improves the web platform's capabilities.",
  },
  {
    id: 3,
    img: "/images/technologies/programming/angular/icons/mobile-phone.svg",
    title: "Native mobile apps",
    desc: "The native mobile application is built using AngularJS in conjunction with Ionic framework, React native, or NativeScript. The programs have an excellent interactive interface and may be installed and used like native applications.",
  },
  {
    id: 4,
    img: "/images/technologies/programming/angular/icons/rocket.svg",
    title: "High performance and speed",
    desc: "On the web platform, AngularJS achieves the highest potential speed.",
  },
  {
    id: 4,
    img: "/images/technologies/programming/angular/icons/process.svg",
    title: "Code generation",
    desc: "AngularJS converts templates into highly optimized code, giving us the feel of handwritten code. This code acts as a framework for developing online and mobile applications. By using AngularJS to build data models on Immutable.js, RxJS, or any push-model, a large data requirement can be met.",
  },
  {
    id: 4,
    img: "/images/technologies/programming/angular/icons/file.svg",
    title: "Angular command line ",
    desc: "The use of command line tools speeds up the implementation process. It provides for quick project implementation and testing. The output may be simply captured using the test data. The project is deployed in a matter of seconds.",
  },
];
const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2Fillustrations%2FAngular-Consulting.svg?alt=media&token=007ae2de-24d2-4061-b80f-a6d06ffee2d9",
    title: "AngularJS consulting",
    desc: "We provide expedited AngularJS development services to help you rebuild the most active parts of your website.",
    alterText: "multiple platforms",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2Fillustrations%2FAngular-API-development.svg?alt=media&token=e77c1979-b2ac-4ab6-9a75-96659e76f0d0",
    title: "API development",
    desc: "Our premium, scalable, and highly functional enterprise-oriented Angular API development services may be customized to meet your specific needs. Define your needs, and we'll create a custom AngularJS enterprise solution for you.",
    alterText: "progressive web apps",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2Fillustrations%2FAngular-MigrationServicesApps.svg?alt=media&token=7afe7f2b-8390-4068-a3aa-3c6a673f9743",
    title: "Migration Services",
    desc: "Do you wish to effortlessly upgrade your present AngularJS application to a more powerful version? This is something that our skilled AngularJS developers can help you with. Let us know what you're looking for, and we'll do our best to accommodate you.",
    alterText: "native mobile apps",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2Fillustrations%2FAngular-Ecommerce.svg?alt=media&token=6bf238c2-1548-4ecc-8e49-e62007273f6a",
    title: "Ecommerce Application Development",
    desc: "We build a top-of-the-line, feature-rich, and superior AngularJS online shopping store with advanced capabilities tailored to your specific needs.",
    alterText: "desktop apps",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2Fillustrations%2FAngular-custom-AngularJS.svg?alt=media&token=61a520c4-1467-4b69-b8bf-fefe44d2c065",
    title: "Custom Angular JS Development ",
    desc: "Our AngularJS experts can create a feature-rich, resilient, and secure AngularJS web application to meet your needs.",
    alterText: "code generation",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2Fillustrations%2FAngular-Social-Interactive-apps.svg?alt=media&token=8ace5324-b8ad-4cf0-b6b0-1b784e33cf8b",
    title: "Social Interactive Apps",
    desc: "With us, you can develop high-quality, strong social interactive apps.",
    alterText: "angular command line",
  },
  // {
  //   id: 7,

  //   img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2Fillustrations%2FAngular-Content-Management.svg?alt=media&token=f0667b09-20fe-4f25-a0fa-78eabd749058",
  //   title: "Content Management Framework",
  //   desc: "If you require a content management framework for your website or application, we can build one for you using AngularJS. This manner, you can receive high-quality outcomes in a short amount of time.",
  //   alterText: "high performance and speed",
  // },
];
const Data2 = [
  {
    id: 1,
    title: "Highly Skilled In-House Team ",
  },
  {
    id: 2,
    title: "Cost effective engagement model",
  },
  {
    id: 3,
    title: "Portal development",
  },
  {
    id: 4,
    title: "Comprehensive quality assurance ",
  },
  {
    id: 5,
    title: "Mobile Friendly UI",
  },
  {
    id: 6,
    title: "Maximum Speed and flexibility",
  },
  {
    id: 8,
    title: "Agile software development",
  },
  {
    id: 9,
    title: "360-degree Requirement Analysis",
  },
  {
    id: 10,
    title: "Reduced cost to 50% of our competitors",
  },
];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];

const offeringData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2FIcons%2Fspeedometer.svg?alt=media&token=c2c8d343-57bd-4944-883c-8fe79dc8f848",
    title: "AngularUI BootStrap",
    desc: "AngularJS's robustness and speed, combined with Bootstrap's simplicity",
    alt: "angular bootstrap",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2FIcons%2Fmobile-development.svg?alt=media&token=d5c3a3c7-36b4-4e78-8bd5-d80105fc688a",
    title: "Mobile Angular UI",
    desc: "Extension of Bootstrap framework used majorly to build mobile apps",
    alt: "mobile angular ui",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2FIcons%2Fnetwork-connection.svg?alt=media&token=b8cfbc98-979b-4c28-90cc-343948a21961",
    title: "Angular Foundation",
    desc: "AngularJS dynamic routing and component directives by a foundation",
    alt: "angular foundation",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2FIcons%2Fionic-framework.svg?alt=media&token=1a02ae91-94fa-47cf-930a-6b91d169cccb",
    title: "Ionic Framework",
    desc: "Used for developing mobile applications quickly and easily",
    alt: "Ionik framework",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2FIcons%2Fdata-analysis.svg?alt=media&token=a66bfbc0-b87d-499d-ae64-75ccf08872a3",
    title: "UI Grid",
    desc: "Used in developing data analysis apps for enterprise",
    alt: "",
  },
];
export default function Angular() {
  return (
    <>
      <Banner
        button="Talk to Experts"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Test%2Fangular-js.jpeg?alt=media&token=c99a8b2d-f3a8-4b54-b176-aa18ff319a9a"
        title="AngularJS Development Services"
        desc="Developing rich and high-performance web applications using AngularJS "
      />
      <Overview
        textColor="text-[#222222] bg-[#f9f9f9]"
        title="AngularJS Overview"
        desc="When it comes to creating a dynamic single-page app, AngularJS is one of the most popular front-end frameworks for this job. Angular is a popular JavaScript framework that was created to simplify scripts, address single-page application (SPA) difficulties, and increase interactivity. Innorik’s AngularJS developers are equipped with the best tools and experience to assist you in everything you need, whether you're building a web app from the ground up or need post-development AngularJS services. Our AngularJS application development approach allows you to create dynamic web applications quickly."
        img="/images/technologies/programming/angular/angular-overview-white.jpeg"
        img2="/images/technologies/programming/angular/angular-overview-mobile-white.jpeg"
        alt="angular"
      />
      <Advantages head=" Advantages of AngularJS" data={advantageData} />

      {/* <Overview
        color="bg-black"
        textColor="text-white"
        title="AngularJS Overview"
        desc="When it comes to creating a dynamic single-page app, AngularJS is one of the most popular front-end frameworks. Angular is a popular JavaScript framework that was created with the goal of simplifying scripts, addressing single-page application (SPA) difficulties, and increasing interactivity. AngularJS is the greatest framework for creating interactive single-page applications, but it can be used to create any form of web application."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Test%2Fangular-linear.jpeg?alt=media&token=0b4d005b-a6e3-45e8-a6be-7464ceeac83a"
        alt="angular js overview"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Test%2Fangular.jpeg?alt=media&token=37255e97-6c28-4c7b-8390-72e7d96665f0"
      /> */}
      {/* <Advantages
        total="6"
        head="Advantages of using Javascript"
        data={advantageData}
      /> */}
      <Offerings
        title="Angular JS Frameworks"
        desc=""
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2Fillustrations%2Fangular-js-framework.svg?alt=media&token=a0995a2e-3636-4375-a365-644186de7b21"
        data={offeringData}
      />
      <Services
        title="Our Angular JS development offerings"
        // desc="AngularJS is a structural framework for dynamic web apps. It lets you use HTML as your template language and lets you extend HTML's syntax to express your application's components clearly and succinctly"
        data={servicesData}
      />
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our Angular Js expertise"
        desc="Innorik has a large team of Angular JS developers who have very good experience with the language from working with various project domains such as banking, telecommunication, healthcare, insurance systems, and with different companies. Our developers have experience ranging from 1-12 years."
        button="Hire Angular Js Expert"
        data={phpDevelopers}
      />
      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FAngular%2Fimages%2Four-angular-experts.jpeg?alt=media&token=7a4e7278-ea9f-4295-8cd9-0344d4e06fac"
        title="Our Angular Experts"
        desc="Innorik has a large team of Angular JS developers who have very good experience with the language from working with various project domains such as banking, telecommunication, healthcare, insurance systems and with different companies. Our developers have experience ranging from 1year to 20 years."
        button="Hire Angular Expert"
      /> */}
      <WhyChooseUs title="Why choose us?" desc="" data={Data2} />
      <Industries
        title="Industies Served"
        desc="We are international company and we deliver technology solutions to clients globally."
        data={Indust}
      />
    </>
  );
}
