import React, { useState } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { FiPlus, FiMinus } from "react-icons/fi";
export const Data = [
  {
    question: "1. Business analysis",
    answer:
      "Our business analysts are responsible for bridging the gap between IT and the business by using data analytics to assess processes, determine requirements and deliver data-driven recommendations and reports to executives and stakeholders. They engage our customers to understand how data-driven changes to processes, products, services, software and improve efficiencies and add value. They also elicit your business needs, and turn them into prioritized software requirements and help elaborate on the concept of your software.",
  },

  {
    question: "2. Software development process",
    answer:
      "We take care of the overall project management starting from initiation, planning, monitoring, and controlling the activities required to fullfill the project commitments and regularly report the status of the development progress to the project stakeholders. A sound development process needs to follow software engineering fundamentals and we take into consideration requirements analysis, functional and technical specifications, data and object orientation models, documentation standards, software testing, software maintenance, software quality assurance and configuration management.  ",
  },
  {
    question: "3. QA & testing",
    answer:
      "Our goal is to produce high quality software free of bugs. To achieve this quality, we do continuous testing of the software, starting from manual testing, unit testing, and code reviews by our senior developers to functionality, performance, usability, integration, ad hoc testing, compatibility, and security testing by our quality assurance (QA) team. Test automations are done to help speed the software delivery and minimize the number of missed bugs. Automation test is used to perform white box testing, load tests and other performance testing.",
  },
  {
    question: "4. Risk management",
    answer:
      "Software development projects may be laid low with outsized sort of risk. To get the team ready to consistently establish the necessary risks which could influence a project, we factor risks into completely different categories such as, project, technical and business risks. Our project managers then examine the risks from every category, relevant to the project. Then, we prepare a mitigation plan for each risk, monitor risks and report to responsible project stakeholders to keep all parties informed about existing risks and their states and ensure the consistency of risk management actions.",
  },
  {
    question: "5. Change management",
    answer:
      "We conduct a full change management including manage change, report on change, secure access, trace changes, see past “Checked In” and control change. We then review the required changes to artifacts such as code and requirements with all parties concerned, analyzing how the changes will impact software business logic and adapting it accordingly before any change implementation.",
  },
  {
    question: "6. Project delivery",
    answer: "The delivery of our high-quality software comes with: ",
    answer2: "• Documentation (technical requirements and design)",
    answer3: "• User manual",
    answer4: "• Setup program",
    answer5: "• Installation and maintenance instructions",
    answer6: "• Source code",
    answer7: "• Warranty",
    answer8: "• Maintenance and support services are provided",
  },
];
const AccordionSection = styled.div`
  flex-direction: column;
  align-items: start;

  justify-content: center;
  position: relative;
`;

const Container = styled.div`
  left: 4%;
  top: 10%;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #e7e7e7;

  h1 {
    padding: 1rem;
  }
  span {
    margin-right: 1.5rem;
  }
`;

const Dropdown = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export default function Accordian() {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <IconContext.Provider value={{ color: "#222222", size: "25px" }}>
      <AccordionSection className="md:px-[4% ]">
        <Container className="">
          {Data.map((item, index) => {
            return (
              <>
                <Wrap onClick={() => toggle(index)} key={index}>
                  <h1 className="text-[18px] lg:text-[22px] text-left">
                    {item.question}
                  </h1>
                  <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                </Wrap>
                {clicked === index ? (
                  <Dropdown className="text-left pl-4">
                    {/* <p className="text-[16px]">{item.answer}</p> */}
                    <ul className="text-[#686868]">
                      <li>{item.answer}</li> <br />
                      <li>{item.answer2}</li>
                      <li>{item.answer3}</li>
                      <li>{item.answer4}</li>
                      <li>{item.answer5}</li>
                      <li>{item.answer6}</li>
                      <li>{item.answer7}</li>
                      <li>{item.answer8}</li>
                    </ul>
                  </Dropdown>
                ) : null}
              </>
            );
          })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
  );
}
