import React from "react";

export default function List(props) {
  return (
    <>
      <li className="mt-[8px] items-center flex text-[#444444]">
        <span className="pr-[20px]">
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 11L6.5 6L1.5 1"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        {props.title}{" "}
      </li>
    </>
  );
}
