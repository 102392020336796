import React from "react";

export default function WhyApplication() {
  return (
    <>
      <div className="bg-[#042129] ">
        <div className=" max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] gap-[40px] mx-auto  text-white pt-8 lg:py-16 md:p-10">
          <h1 className="  pb-6 text-[24px] md:text-[28px] lg:text-[36px]">
            Why application modernization is important?
          </h1>
          <div className="grid grid-cols-1  md:grid-cols-12 gap-x-14 lg:grid-cols-12">
            <div className="col-span-6">
              <div>
                <h2 className="py-4 text-[22px]  lg:text-[26px] ">
                  1. Increasing Agility
                </h2>
                <p className="text-[16px]">
                  With modernized applications, you can tune your environment to
                  react faster to seasonal fluctuations, leverage data across
                  the organization, integrate systems to optimize processes,
                  adapt to business conditions or quickly jump on the next
                  innovation opportunity to beat your competitors in the
                  marketplace.{" "}
                </p>
              </div>
              <div>
                <h2 className="py-4 text-[22px] md:pt-[30px] lg:pt-[60px] lg:text-[26px] ">
                  2. Integration{" "}
                </h2>
                <p className="text-[16px]">
                  Modern technologies are integration-ready by default as
                  modernized applications. Modern software platforms often rely
                  on third-party APIs for some functionalities such as
                  geolocation, user authentication, and data sharing. Legacy
                  systems are obsolete, and they lack such compatibility.
                </p>
              </div>
            </div>
            <div className="col-span-6 lg:pl-[50px]">
              <div>
                <ul className="py-4 list-disc	space-y-4">
                  <h2 className="text-[22px] lg:text-[26px] space-y-2">
                    3. Operational efficiency
                  </h2>
                  <li>
                    Modernized applications deliver better performance, faster
                    time to market and better experiences for customers and
                    stakeholders.
                  </li>
                </ul>
              </div>

              <div>
                <ul className="py-4 list-disc	space-y-2">
                  <h2 className="text-[22px] lg:text-[26px] ">
                    4. Reducing Cost
                  </h2>
                  <li>
                    Decommissioning monolithic apps, data center space and
                    physical servers reduces software, hardware, and licensing
                    costs.{" "}
                  </li>
                </ul>
              </div>

              <div>
                <ul className="py-4 list-disc	space-y-2">
                  <h2 className="text-[22px] lg:text-[26px] ">
                    5. Minimizing Obsolescence
                  </h2>
                  <li>Losing control</li>
                  <li>No support </li>
                  {/* <li>The missing component </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
