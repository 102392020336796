import * as React from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
// import './tab.css'
const blue = {
  50: "#f9f9f9",
  100: "#f9f9f9",
  200: "#f9f9f9",
  300: "#f9f9f9",
  400: "#f9f9f9",
  500: "#f9f9f9",
  600: "#f9f9f9",
  700: "#f9f9f9",
  800: "#f9f9f9",
  900: "#f9f9f9",
};

const Tab = styled(TabUnstyled)`
  align-self: end;
  color: #909090;
  cursor: pointer;
  font-size: 18px;
  font-weight: regular;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 0px 0px 0px;
  border: none;
  //   border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    // background-color: white;
    color: #3066ea;
    // border-radius: 3px;
    // outline: 2px solid blue;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    border-bottom: 2px solid #3066ea;
    // background-color:black
    // background-color: white;

    font-weight: 100;

    font-size: 18px;
    color: #3066ea;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: ${blue[500]};
  //   margin-bottom: 16px;
  border-bottom: 2px solid #ebebeb;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export default function ImageTabs(props) {
  return (
    <>
      <div className="invisible lg:visible h-0  lg:h-auto max-w-[1500px] lg:pb-[100px] mx-auto lg:px-[85px]">
        <TabsUnstyled className=" shadow-xl" defaultValue={0}>
          <TabsList className="px-[4%] pt-2 lg:flex">
            <Tab className="">
              <div className="hover_effect lg:flex w-full justify-center py-5 bg-white">
                <img
                  className="h-[86px] p-4"
                  src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2F72-720954_microsoft-azure-cloud-logo%201.png?alt=media&token=f92a2cc5-a08b-43ca-9caa-4c84ca126abe"
                ></img>
              </div>
            </Tab>
            <Tab className="">
              <div className="hover_effect lg:flex w-full justify-center py-5 bg-white">
                <img
                  className="h-[86px] p-4"
                  src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Faws.svg.png?alt=media&token=84faaa8d-4bde-453b-8502-aa393b196872"
                ></img>
              </div>
            </Tab>
            {/* <Tab className="">
              <div className="hover_effect lg:flex w-full justify-center py-5 bg-white">
                <img
                  className="h-[86px] p-4"
                  src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Foracle.png?alt=media&token=0cb4eed6-fe30-450e-a340-e2bf359fd22d"
                ></img>
              </div>
            </Tab> */}
            <Tab className="">
              <div className="hover_effect lg:flex w-full justify-center py-5 bg-white">
                <img
                  className="h-[86px] p-4"
                  src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Fgoogle-cloud-logo-1%201.png?alt=media&token=b15a5776-f798-47f0-bd75-59d8587cf597"
                ></img>
              </div>
            </Tab>
            <Tab className="">
              <div className="hover_effect lg:flex w-full justify-center py-5 bg-white">
                <img
                  className="h-[86px] p-4"
                  src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2FIBM-Cloud%201.png?alt=media&token=2720c473-d8ff-42eb-9ab0-c8f5ff35874d"
                ></img>
              </div>
            </Tab>
            <Tab className="">
              <div className="hover_effect lg:flex w-full justify-center py-5 bg-white">
                <img
                  className="h-[86px] p-4"
                  src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Foracle.png?alt=media&token=0cb4eed6-fe30-450e-a340-e2bf359fd22d"
                ></img>
              </div>
            </Tab>
          </TabsList>
          <TabPanel className="px-[4%] bg-[#f9f9f9]" value={0}>
            <div className="conatiner text-[#222222] lg:flex-row bg-[#F9F9F9] justify-evenly w-full lg:py-[2%] md:py-[40px] py-[56px] ">
              <div className="p-6  flex lg:flex-row shadow-lg bg-white rounded">
                <div className="m-auto w-2/5 ">
                  <img
                    className="h-[56px] pl-16 "
                    src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2F72-720954_microsoft-azure-cloud-logo%201.png?alt=media&token=f92a2cc5-a08b-43ca-9caa-4c84ca126abe"
                  ></img>
                  {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
                </div>
                <div className="m- w-4/5 pr-28">
                  <p className="text-justify md:text-center lg:text-center text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-ceenter">
                    Innovative strategies and architectures to help you move
                    your applications to azure
                  </p>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="px-[4%] bg-[#f9f9f9]" value={1}>
            <div className="conatiner text-[#222222] lg:flex-row bg-[#F9F9F9] justify-evenly w-full lg:py-[2%] md:py-[40px] py-[56px] ">
              <div className="p-6  flex lg:flex-row shadow-lg bg-white rounded">
                <div className="m-auto w-2/5 ">
                  <img
                    className="h-[56px] pl-16 "
                    src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Faws.svg.png?alt=media&token=84faaa8d-4bde-453b-8502-aa393b196872"
                  ></img>
                  {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
                </div>
                <div className="m- w-4/5 pr-28">
                  <p className="text-justify md:text-center lg:text-center text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-ceenter">
                    Revolutionize your business by migrating your applications
                    to AWS with our AWS certified cloud practitioners
                  </p>
                </div>
              </div>
            </div>
          </TabPanel>
          {/* <TabPanel className="px-[4%] bg-[#f9f9f9]" value={2}>
            <div className="conatiner text-[#222222] lg:flex-row bg-[#F9F9F9] justify-evenly w-full lg:py-[2%] md:py-[40px] py-[56px] ">
              <div className="p-6  flex lg:flex-row shadow-lg bg-white rounded">
                <div className="m-auto w-2/5 ">
                  <img
                    className="h-[56px] pl-16 "
                    src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Foracle.png?alt=media&token=0cb4eed6-fe30-450e-a340-e2bf359fd22d"
                  ></img>
                
                </div>
                <div className="m- w-4/5 pr-28">
                  <p className="text-justify md:text-center lg:text-center text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-ceenter">
                    With our robust cloud strategy and plan, we will help your
                    business successfully migrate or integrate with oracle cloud
                  </p>
                </div>
              </div>
            </div>
          </TabPanel> */}
          <TabPanel className="px-[4%] bg-[#f9f9f9]" value={2}>
            <div className="conatiner text-[#222222] lg:flex-row bg-[#F9F9F9] justify-evenly w-full lg:py-[2%] md:py-[40px] py-[56px] ">
              <div className="p-6  flex lg:flex-row shadow-lg bg-white rounded">
                <div className="m-auto w-2/5 ">
                  <img
                    className="h-[86px] pl-16 "
                    src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Fgoogle-cloud-logo-1%201.png?alt=media&token=b15a5776-f798-47f0-bd75-59d8587cf597"
                  ></img>
                  {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
                </div>
                <div className="m- w-4/5 pr-28">
                  <p className="text-justify md:text-center lg:text-center text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-ceenter">
                    Let’s give you a helping hand in your google cloud journey
                    with our experienced cloud architects and developers
                  </p>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="px-[4%] bg-[#f9f9f9]" value={3}>
            <div className="conatiner text-[#222222] lg:flex-row bg-[#F9F9F9] justify-evenly w-full lg:py-[2%] md:py-[40px] py-[56px] ">
              <div className="p-6  flex lg:flex-row shadow-lg bg-white rounded">
                <div className="m-auto w-2/5 ">
                  <img
                    className="h-[86px] pl-16 "
                    src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2FIBM-Cloud%201.png?alt=media&token=2720c473-d8ff-42eb-9ab0-c8f5ff35874d"
                  ></img>
                  {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
                </div>
                <div className="m- w-4/5 pr-28">
                  <p className="text-justify md:text-center lg:text-center text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-ceenter">
                    Partnering with you in your digital transformation with IBM
                    cloud services
                  </p>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel className="px-[4%] bg-[#f9f9f9]" value={4}>
            <div className="conatiner text-[#222222] lg:flex-row bg-[#F9F9F9] justify-evenly w-full lg:py-[2%] md:py-[40px] py-[56px] ">
              <div className="p-6  flex lg:flex-row shadow-lg bg-white rounded">
                <div className="m-auto w-2/5 ">
                  <img
                    className="h-[86px] pl-16 "
                    src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Foracle.png?alt=media&token=0cb4eed6-fe30-450e-a340-e2bf359fd22d"
                  ></img>
                  {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
                </div>
                <div className="m- w-4/5 pr-28">
                  <p className="text-justify md:text-center lg:text-center text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-ceenter">
                    With our robust cloud strategy and plan, we will help your
                    business successfully migrate or integrate with oracle cloud
                  </p>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabsUnstyled>
      </div>
      <div className="visible px-[28px] text-[#222222 md:px-[40px] lg:invisible lg:h-0 grid md:grid-cols-3 md:gap-8 grid-cols-1">
        <div className="text-center py-[10px] ]">
          <img
            className="h-[56px] mx-auto "
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2F72-720954_microsoft-azure-cloud-logo%201.png?alt=media&token=f92a2cc5-a08b-43ca-9caa-4c84ca126abe"
          ></img>
          <p className="py-[10px]">
            Innovative strategies and architectures to help you move your
            applications to azure
          </p>
        </div>
        <div className="text-center py-[10px] ]">
          <img
            className="h-[56px] mx-auto "
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Faws.svg.png?alt=media&token=84faaa8d-4bde-453b-8502-aa393b196872"
          ></img>
          <p className="py-[10px]">
            Revolutionized your business by migrating your applications to AWS
            with our AWS certified cloud practitioners
          </p>
        </div>
        <div className="text-center py-[10px] ]">
          <img
            className="h-[56px] mx-auto "
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Foracle.png?alt=media&token=0cb4eed6-fe30-450e-a340-e2bf359fd22d"
          ></img>
          <p className="py-[10px]">
            With our robust cloud strategy and plan, we will help your business
            successfully migrate or integrate with oracle cloud
          </p>
        </div>
        <div className="text-center py-[10px] ]">
          <img
            className="h-[56px] mx-auto "
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Fgoogle-cloud-logo-1%201.png?alt=media&token=b15a5776-f798-47f0-bd75-59d8587cf597"
          ></img>
          <p className="py-[10px]">
            Let’s give you a helping hand in your google cloud journey with our
            experienced cloud architects and developers
          </p>
        </div>
        <div className="text-center py-[10px] ]">
          <img
            className="h-[56px] mx-auto "
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2FIBM-Cloud%201.png?alt=media&token=2720c473-d8ff-42eb-9ab0-c8f5ff35874d"
          ></img>
          <p className="py-[10px]">
            Partnering with you in your digital transformation with IBM cloud
            services
          </p>
        </div>
      </div>
    </>
  );
}
