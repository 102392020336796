import React from "react";
import Banner from "../../components/Banner";
import FiveColCard from "../../components/FiveColCard";
import FooterBanner from "../../components/Footer/FooterBanner";
import WhyChoose from "../../components/WhyChoose";
import ImageTabs from "./ImageTabs";
import CoreValues from "../CultureAndValues/CoreValues";
import Overview from "../Technologies/LatestThinking/ArtificialIntellegence/Overview";
// import Overview from "./Overview";
// import Overview from "./Overview";
const Data = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudApplication%2Frequirement-assessment.jpg?alt=media&token=b1da65e0-b024-4244-b2a2-78c295e7539c",
    title: "Requirement assessment",
    alt: "requirement assesment",
    desc: "",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudApplication%2Fplanning.jpg?alt=media&token=f6c06b87-173a-4eeb-86e4-5eb20bf104e9",
    title: "Planning",
    desc: "",
    alt: "planning",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudApplication%2Fdesign.jpg?alt=media&token=a011bfb9-7a78-4dc1-988d-f98c85ab103a",
    title: "Design",
    desc: "",
    alt: "designing",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudApplication%2Fimplementation.jpg?alt=media&token=b2efdc56-e460-45fd-988a-41b67c2e24c3",
    title: "Implementation",
    desc: "",
    alt: "implementation",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudApplication%2Fmaintenance-and-support.jpg?alt=media&token=1c0b6408-d03a-43eb-b04b-7019f9f86e3e",
    title: "Maintenance and Support",
    desc: "",
    alt: "maintainance and support",
  },
];
const Data2 = [
  {
    id: 1,
    title: "Reduced cost to 50% of our competitors ",
  },
  {
    id: 2,
    title:
      "Large pool of highly skilled, experienced, and certified cloud practitioners ",
  },
  {
    id: 3,
    title: "Clients’ happiness and satisfactions are our goals ",
  },
  {
    id: 4,
    title: "We use latest technologies and best development practices ",
  },
  {
    id: 5,
    title: "We have smart solutions for every integration challenge ",
  },
  {
    id: 6,
    title: "We personalize and deliver extraordinary customer service ",
  },
  {
    id: 7,
    title:
      "We provide knowledge transfer and easy exit process for our customers ",
  },
  {
    id: 8,
    title:
      "We increase customers engagement in the project development process ",
  },
];
const Data3 = [
  {
    id: 1,
    img: "/images/services/cloud-integration/icons/efficiency.svg",
    title: "",
    desc: "Improved operational efficiency",
  },
  {
    id: 1,
    img: "/images/services/cloud-integration/icons/faster.svg",
    title: "",
    desc: "Faster time-to-market",
  },
  {
    id: 1,
    img: "/images/services/cloud-integration/icons/flexible.svg",
    title: "",
    desc: "Increased flexibility and scalability",
  },
  {
    id: 2,
    img: "/images/services/cloud-integration/icons/competitive.svg",
    title: "",
    desc: "Increased competitive edge",
  },

  {
    id: 3,
    img: "/images/services/cloud-integration/icons/low-price.svg",
    title: "",
    desc: "Reduced operational costs and increased revenues",
  },
  {
    id: 3,
    img: "/images/services/cloud-integration/icons/cloud-computing.svg",
    title: "",
    desc: "The ability to combine all cloud applications and on-premises systems",
  },
];

export default function CloudApplicationIntegrationTest() {
  return (
    <>
      {/* <div className="">
        <Banner
          img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationHero.jpg?alt=media&token=66f696df-84c0-4dbd-af6b-421ea5ed4902"
          title="Getting and staying ahead
with cloud technologies"
          desc="Build a highly performant, secure, cloud complaint platforms for greater scalability, stability, reliability, and efficiency "
        />
      </div>
      <Overview
        color="bg-[#119EDC]"
        titleColor=" text-white"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationOverviewImage.jpg?alt=media&token=81ae935d-a76a-49f7-b830-a55520f86106"
        title="Cloud Application Integration "
        desc="The cloud is transforming the way businesses address data integration and helping them win the battle against data silos. Cloud application integration tools have open opportunities for organizations wanting to connect heterogeneous systems."
      />
      <div className="bg-[#181818] ">
        <div className="max-w-[1500px]  lg:px-[85px] mx-auto left-0 right-0">
          <div className="shadow-2xl	shadow-black	">
            <div className="bg-white"></div>
            <div className="bg-white">
              <FiveColCard
                title="We are empowered by these innovative technologies"
                data={Data}
              />
            </div>

            <div className="py-16 px-6 bg-white">
              <h1 className="text-center text-[26px] lg:text-[36px]">
                Cloud application services
              </h1>
              <p className="text-center text-[#222222] pt-6 text-[16px] lg:text-[18px] xl:text-[18px] lg:px-14">
                Our highly skilled cloud experts are ready to help your business
                use the latest cloud tools and technologies to connect your
                legacy applications, systems, repositories, and IT environments
                for the real-time exchange of data and process on all the major
                cloud service providers.
              </p>
            </div>
            <div className="bg-white">
              <ImageTabs />
            </div>
          </div>
        </div>
        <WhyChoose
          head="Why Integrate with cloud? "
          data={Data2}
          img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationWhyInnorik.jpg?alt=media&token=6fc3ecdd-478c-4578-8e95-139b86c8bb7f"
          img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
        />
        <div className="bg-[#181818]">
          <div className=" max-w-[1500px] lg:px-[85px] mx-auto">
            <div className="bg-white pb-[80px] ">
              <CoreValues title="Our Core Values" data={Data3} />
            </div>
          </div>
        </div>
      </div> */}
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudApplication%2Fcloud-application-integration-banner.jpg?alt=media&token=d4be7d9d-d3eb-465d-a37e-ef8c7a38e9f7"
        title="Cloud Application Services"
        desc="Build a high performance, secure, cloud complaint platforms for greater scalability, stability, reliability, and efficiency"
      />
      {/* <Overview
        color=" text-white"
        titleColor=" text-white"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudApplication%2Fcloud-application-integration-overview.jpg?alt=media&token=71c76969-cf9c-42f7-b641-7e33c24dfed6"
        title="Cloud Application Integration "
        desc="The cloud is transforming the way businesses address data integration and helping them win the battle against data silos. Cloud application integration tools have open opportunities for organizations wanting to connect heterogeneous systems."
      /> */}
      <Overview
        textColor="text-[#222222]  bg-[#f9f9f9]"
        title="Cloud Application Integration"
        desc="The cloud transforms the way businesses address data integration and helps them win the battle against data silos. Cloud application integration tools have open opportunities for organizations wanting to connect heterogeneous systems."
        img="/images/services/cloud-integration/cloud-application-integration-overview.jpeg"
        img2="/images/services/cloud-integration/cloud-application-integration-mobile.jpeg"
        alt="cloud application integration"
      />
      <FiveColCard title="Cloud Application Integration steps" data={Data} />
      <div className="py-16 max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px]">
        <h1 className="text-center text-[26px] lg:text-[36px]">
          Cloud application services
        </h1>
        <p className="text-center text-[#222222] pt-6 text-[16px] lg:text-[18px] xl:text-[18px] ">
          Our highly skilled cloud experts are ready to help your business use
          the latest cloud tools and technologies to connect your legacy
          applications, systems, repositories, and IT environments for the
          real-time exchange of data and processes on all the major cloud
          service providers.
        </p>
      </div>
      <ImageTabs />

      <WhyChoose
        textColor="text-white"
        head="Why choose us for your cloud needs? "
        data={Data2}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudApplication%2Fwhy-integrate-with-cloud.jpg?alt=media&token=4bc41a96-4602-4662-b4b5-3e9e46406504"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudApplication%2Fwhy-cloud-application.jpeg?alt=media&token=7695f958-e944-45c9-82a0-ada99af13312"
      />

      <CoreValues title="Why integrate with cloud?" data={Data3} />
      <FooterBanner title="Let's get started" link="/contact-us" />
    </>
  );
}
