import React from "react";
// import Mind from "../../Assets/images/mind.jpg";
export default function Requirements(props) {
  return (
    <div className="lg:my-[100px]">
      {/* <h1 className="font-semibold text-xl text-center mb-5 ms:text-2xl ms:mb-6 md:text-3xl md:mb-9 lg:text-4xl lg:mb-12" style="font-family: 'Poppins', sans-serif;">
    Requirements
  </h1>
  <div className="conatiner flex flex-col justify-evenly w-full sm:flex-row">
    <div className="m-auto w-10/12 sm:w-2/6">
      <img className="shadow-xl m-auto w-9/12 mb-10 sm:mb-0 sm:w-10/12" src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/TalentWorldwide%2FcomputerResources.jpg?alt=media&token=b0c414b6-c37a-4358-8482-46e3b97c1324"/>
    </div> 
    <div className="m-auto w-10/12 sm:w-2/6">
      <img className="shadow-xl m-auto w-9/12 mb-10 sm:mb-0 sm:w-10/12" src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/TalentWorldwide%2FstemEducation.jpg?alt=media&token=0da7c737-e760-4c96-a34c-a17c28dfad45"/>
    </div>
    <div className="m-auto w-10/12 sm:w-2/6">
      <img className="shadow-xl m-auto w-9/12 sm:w-10/12" src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/TalentWorldwide%2FinternetAccess.jpg?alt=media&token=d5dc48c2-79e8-4397-9483-1f4ebfdc43cc"/>
    </div>
  </div>   */}
      <h1 className="mt-10 mx-[] font-semibold text-xl text-center mb-8 ms:text-2xl ms:mb-6 md:text-3xl md:mb-9 lg:text-4xl lg:mb-12">
        Requirements
      </h1>
      <div className="lg:px-[50px] md:px-[20px] max-w-[1400px] mx- mx-auto conatiner flex flex-col justify-evenly w-full sm:flex-row">
        <div className="m-auto w-11/12 sm:w-2/6">
          <img
            className="shadow-xl m-auto w-10/12 sm:mb-0 sm:w-10/12"
            src={props.computer}
          />
          <p className="text-center pt-6 font-medium mb-8 sm:mb-0">
            Computer Resources
          </p>
        </div>
        <div className="m-auto w-11/12 sm:w-2/6">
          <img
            className="shadow-xl m-auto w-10/12  sm:mb-0 sm:w-10/12"
            src={props.stem}
          />
          <p className="text-center pt-6 font-medium mb-8 sm:mb-0">
            STEM Degree
          </p>
        </div>
        <div className="m-auto w-11/12 sm:w-2/6">
          <img
            className="shadow-xl m-auto w-10/12 sm:w-10/12"
            src={props.internet}
          />
          <p className="text-center pt-6 font-medium mb-8 sm:mb-0">
            Internet Access
          </p>
        </div>
      </div>
      {/* 
      <h1 className="text-center pt-24 text-4xl">Requirements</h1>
      <div className=" pl-20 pr-20 grid gap-[35px] grid-cols-3 pt-12">
        <div className="grid grid-rows-2 grid-flow-col gap-4">
          <img
            className="drop-shadow-xl h-72	"
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/TalentWorldwide%2FcomputerResources.jpg?alt=media&token=b0c414b6-c37a-4358-8482-46e3b97c1324"
          ></img>
          <h3 className="text-center pt-5 text-[18px] text-desc">
            Computer Resources
          </h3>
        </div>
        <div className="grid grid-rows-2 grid-flow-col gap-4">
          <img
            className="drop-shadow-xl h-72"
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/TalentWorldwide%2FstemEducation.jpg?alt=media&token=0da7c737-e760-4c96-a34c-a17c28dfad45"
          ></img>
          <h3 className="text-center pt-5 text-[18px] text-desc">
            STEM Education
          </h3>
        </div>
        <div className="grid grid-rows-2 grid-flow-col gap-4">
          <img
            className="drop-shadow-xl h-72"
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/TalentWorldwide%2FinternetAccess.jpg?alt=media&token=d5dc48c2-79e8-4397-9483-1f4ebfdc43cc"
          ></img>
          <h3 className="text-center pt-5 text-[18px] text-desc">
            Internet Access
          </h3>
        </div>
      </div> */}
    </div>
  );
}
