import React from "react";
import { Link } from "react-router-dom";

export default function ServicesCard() {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mx-auto py-10 w-10/12 sm:w-10/12 md:9/12 lg:w-11/12 2xl:w-11/12 gap-6 sm:gap-8 md:gap-14 lg:gap-4 xl:gap-10 2xl:gap-14 text-white">
        <div className="flex group h-[390px] sm:h-[360px] lg:h-[400px] bg-black">
          <div className="w-[10px] shrink-0 group-hover:bg-[#6793ff]"></div>
          <div className="flex flex-col justify-between">
            <div className="">
              <p className="font-semibold pl-4 pr-10 pt-6 pb-0 text-lg xl:text-xl 2xl:text-2xl">
                Application Services
              </p>
              {/* <p className="pl-4 font-semibold 2xl:text-xl pb-2">Development</p> */}
              <ul className="px-9 text-[14px] pt-[30px] list-outside list-disc space-y-2">
                <li>Web Development</li>
                <li>Mobile App Development</li>
                <li>Application Migration</li>
                <li>Software Quality Engineering</li>
                <li>Cloud Application Integration</li>
              </ul>
              {/* <p className="pl-4 font-semibold 2xl:text-xl py-2">Design</p>
              <ul className="px-9 text-sm list-outside list-disc">
                <li>UI/UX design</li>
                <li>Responsive web design</li>
                <li>Ecommerce website design</li>
                <li>Website redesign</li>
              </ul> */}
            </div>
            <div className="pl-5 pr-10 md:pr-5 pb-10 2xl:text-lg">
              <Link
                to="/testing"
                className="pb-2 text-white font-semibold border-b-[2px] border-white group-hover:text-[#6793ff] group-hover:border-[#6793ff]"
              >
                Learn more
                <i className="ml-3 fa fa-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex group h-[390px] sm:h-[360px] lg:h-[400px] bg-black">
          <div className="w-[10px] shrink-0 group-hover:bg-[#6793ff]"></div>
          <div className="flex flex-col justify-between">
            <div className="">
              <p className="font-semibold pl-4 pr-10 pt-6  text-lg xl:text-xl 2xl:text-2xl">
                IT Services
              </p>
              <ul className="px-9 text-sm list-outside pt-[30px] space-y-2 list-disc">
                <li>Cloud enablement</li>
                <li>Devops development</li>
                <li>Maintenance support</li>
              </ul>
            </div>
            <div className="pl-5 pr-10 md:pr-5 pb-10 2xl:text-lg">
              <Link
                to="/cloud-enablement"
                className="pb-2 text-white font-semibold border-b-[2px] border-white group-hover:text-[#6793ff] group-hover:border-[#6793ff]"
              >
                Learn more
                <i className="ml-3 fa fa-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex group h-[390px] sm:h-[360px] lg:h-[400px] bg-black">
          <div className="w-[10px] shrink-0 group-hover:bg-[#6793ff]"></div>
          <div className="flex flex-col justify-between">
            <div className="">
              <p className="font-semibold pl-4 pr-10 pt-6  text-lg xl:text-xl 2xl:text-2xl">
                Cloud Consulting
              </p>
              <ul className="px-5 text-sm pt-[30px] space-y-2 list-inside list-disc">
                <li>AWS Consulting</li>
                <li>Microsoft Azure</li>
                <li>Google Cloud</li>
                <li>IBM Cloud</li>
              </ul>
            </div>
            <div className="pl-5 pr-10 md:pr-5 pb-10 2xl:text-lg">
              <Link
                to="/cloud-consulting"
                className="pb-2 text-white font-semibold border-b-[2px] border-white group-hover:text-[#6793ff] group-hover:border-[#6793ff]"
              >
                Learn more
                <i className="ml-3 fa fa-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex group h-[390px] sm:h-[360px] lg:h-[400px] bg-black">
          <div className="w-[10px] shrink-0 group-hover:bg-[#6793ff]"></div>
          <div className="flex flex-col justify-between">
            <div className="">
              <p className="font-semibold pl-4 pr-10 pt-6  text-lg xl:text-xl 2xl:text-2xl">
                Custom Web Design
              </p>
              <ul className="px-5 text-sm list-inside space-y-2  pt-[30px] list-disc">
                <li>UI/UX Design</li>
                <li>Responsive Web Design</li>
                <li>Ecommerce Website Design</li>
                <li>Website Redesign</li>
              </ul>
            </div>
            <div className="pl-5 pr-10 md:pr-5 pb-10 2xl:text-lg">
              <Link
                to="/ui-ux-design"
                className="pb-2 text-white font-semibold border-b-[2px] border-white group-hover:text-[#6793ff] group-hover:border-[#6793ff]"
              >
                Learn more
                <i className="ml-3 fa fa-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex group h-[390px] sm:h-[360px] lg:h-[400px] bg-black">
          <div className="w-[10px] shrink-0 group-hover:bg-[#6793ff]"></div>
          <div className="flex flex-col justify-between">
            <div className="">
              <p className="font-semibold pl-4 pr-10 pt-6 text-lg xl:text-xl 2xl:text-2xl">
                Infrastructure Services
              </p>
              <ul className="px-9 text-sm space-y-2 list-disc pt-[30px] list-outside	">
                <li>Cloud Infrastructure</li>
                <li>Infrastructure Support Services</li>
                <li>Networking Management Services</li>
                <li>Infrastructure Management Services</li>
              </ul>
            </div>
            <div className="pl-5 pr-10 md:pr-5 pb-10 2xl:text-lg">
              <Link
                to="/cloud-infrastructure"
                className="pb-2 text-white font-semibold border-b-[2px] border-white group-hover:text-[#6793ff] group-hover:border-[#6793ff]"
              >
                Learn more
                <i className="ml-3 fa fa-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex group h-[390px] sm:h-[360px] lg:h-[400px] bg-black">
          <div className="w-[10px] shrink-0 group-hover:bg-[#6793ff]"></div>
          <div className="flex flex-col justify-between">
            <div className="">
              <p className="font-semibold pl-4 pr-10 pt-6 text-lg xl:text-xl 2xl:text-2xl">
                Custom Software Development
              </p>
              <ul className="px-9 text-sm  list-disc pt-[30px] space-y-2 list-outside	">
                <li>Microsoft .Net Solutions</li>
                <li>Enterprise Resource Planning System (ERPs)</li>
              </ul>
            </div>
            <div className="pl-5 pr-10 md:pr-5 pb-10 2xl:text-lg">
              <Link
                to="/custom-software"
                className="pb-2 text-white font-semibold border-b-[2px] border-white group-hover:text-[#6793ff] group-hover:border-[#6793ff]"
              >
                Learn more
                <i className="ml-3 fa fa-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex group h-[390px] sm:h-[360px] lg:h-[400px] bg-black">
          <div className="w-[10px] shrink-0 group-hover:bg-[#6793ff]"></div>
          <div className="flex flex-col justify-between">
            <div className="">
              <p className="font-semibold pl-4 pr-10 pt-6 text-lg xl:text-xl 2xl:text-2xl">
                Outsourcing Services
              </p>
              <ul className="px-9 text-sm  list-disc pt-[30px] space-y-2 list-outside	">
                <li>Business Process Outsourcing</li>
                <li>IT Consulting</li>{" "}
              </ul>
            </div>
            <div className="pl-5 pr-10 md:pr-5 pb-10 2xl:text-lg">
              <Link
                to="/business-process-outsourcing"
                className="pb-2 text-white font-semibold border-b-[2px] border-white group-hover:text-[#6793ff] group-hover:border-[#6793ff]"
              >
                Learn more
                <i className="ml-3 fa fa-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex group h-[390px] sm:h-[360px] lg:h-[400px] bg-black">
          <div className="w-[10px] shrink-0 group-hover:bg-[#6793ff]"></div>
          <div className="flex flex-col justify-between">
            <div className="">
              <p className="font-semibold pl-4 pr-10 pt-6 text-lg xl:text-xl 2xl:text-2xl">
                Cloud Migration
              </p>
              <ul className="px-9 text-sm  list-disc pt-[30px] space-y-2 list-outside	">
                <li>Cloud migration Guide</li>
                <li>Migration to Azure</li> <li>Migration to AWS</li>
                <li>Migration to Google Cloud</li>
                <li>Migration to IBM Cloud</li> <li>Cloud data warehouse</li>
              </ul>
            </div>
            <div className="pl-5 pr-10 md:pr-5 pb-10 2xl:text-lg">
              <Link
                to="/application-migration"
                className="pb-2 text-white font-semibold border-b-[2px] border-white group-hover:text-[#6793ff] group-hover:border-[#6793ff]"
              >
                Learn more
                <i className="ml-3 fa fa-arrow-right" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
