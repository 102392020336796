import React from "react";
import Banner from "../../../components/Banner";
import LeftImageSection from "../../../components/LeftImageSection";
// import RightImageSection from "../../../components/RightImageSection";
// import PaymentProcessing from "../Retail/PaymentProcessing";
import RetailManagement from "../Retail/RetailManagement";
import ProjectManagement from "../ProfessionalServices/ProjectManagement";
import InventoryManagement from "../Retail/InventoryManagement";
import WhyChooseWhiteBackground from "../../../components/WhyChooseWhiteBackground";
import FooterBanner from "../../../components/Footer/FooterBanner";

const inventoryData = [
  {
    id: 1,
    title:
      "Ordering and receiving modules - Create purchase orders and email directly to vendors, maintain a database of customers and suppliers, set low-level warnings so you know when to reorder, update item quantities when received and many others",
  },
  {
    id: 2,
    title:
      "Inventory reporting - Monitor and report stock levels, costs and averages, view inventory levels by location, category or overall product history to show when items were received or sold",
  },
  {
    id: 3,
    title:
      "Item control - Scan in barcodes to add new items, add notes, URLs and photos to item descriptions, group items into categories for organized stock control, set sales units for items sold etc.",
  },
  {
    id: 4,
    title: "Cloud access feature for remote users",
  },
];
const manufacturingData = [
  {
    id: 1,
    title: "Machine Kiosk i.e. production schedules, products etc",
  },
  {
    id: 2,
    title: "Work & Manufacturing Order",
  },
  {
    id: 3,
    title: "Bill of Material (BOM) Management",
  },
  {
    id: 4,
    title: "Schedule Management",
  },
  {
    id: 5,
    title: "Manufacturing Plan",
  },
  {
    id: 6,
    title: "Sales & CRM Management",
  },
  {
    id: 7,
    title: "Inventory & Warehouse",
  },
  {
    id: 8,
    title: "Procurement",
  },
  {
    id: 7,
    title: "Accounting Report ",
  },
  {
    id: 7,
    title: "Business Intelligence ",
  },
];
const costData = [
  {
    id: 1,
    title: "Bills of Material",
  },
  {
    id: 2,
    title: "Contact management",
  },
  {
    id: 3,
    title:
      "Cost Estimating i.e. Raw Material Pricing, Purchased Item Pricing, Outside Services Pricing etc.",
  },
  {
    id: 4,
    title: "Customizable Branding",
  },
  {
    id: 5,
    title: "Pricing Management",
  },
  {
    id: 6,
    title: "Product Configurator",
  },
  {
    id: 7,
    title: "Quotes/Estimates",
  },
];
const supplyData = [
  {
    id: 1,
    title:
      "Manage financial operations with general ledger, cost accounting and budgeting",
  },
  {
    id: 2,
    title: "Control procurement, cash flow and business purchases",
  },
  {
    id: 3,
    title:
      "View customers across sales, marketing, customer service and support",
  },
  {
    id: 4,
    title:
      "Project and implement manufacturing, wholesale distribution and service processes in your business",
  },
];
const InventoryData = [
  {
    id: 1,
    title: "Bills of material",
  },
  {
    id: 2,
    title: "Contact management",
  },
  {
    id: 3,
    title: "Cost estimating",
  },
  {
    id: 4,
    title: "Customizable branding",
  },
  {
    id: 5,
    title: "Pricing management",
  },
  {
    id: 6,
    title: "Product configurator",
  },
  {
    id: 7,
    title: "Quotes/estimates",
  },
  // {
  //   id: 8,
  //   title: "Run Custom Reports & Graphs ",
  // },
];
const Why = [
  {
    id: "1",
    title: "Technology",
    desc: "We use the latest and most sophisticated technology to ensure that you get the optimum benefits out of your investment. Aside from building a strong foundation for robust solutions, we also invest tremendously in future technologies to ensure future-ready developments.",
  },
  {
    id: "2",
    title: "Intuitive interfaces",
    desc: "Our Fintech software solutions are built to offer the most dynamic solutions and with that in mind we ensure that users get the best intuitive interface that can be used instantly with no or minimum supervision.",
  },
  {
    id: "3",
    title: "Cost-effective solutions",
    desc: "We take the pain to build cost effective yet innovative solutions which delivers extreme value for your money because we understand that the Fintech spectrum overwhelms a more noteworthy populace in this age.",
  },
  {
    id: "4",
    title: "Multiple testing",
    desc: "Our products go through several stages of both intensive and extensive testing, quality assurance and practical usability to ensure the best end results. Great commitment and responsibility from our team are key factors to achieving this.",
  },
  {
    id: "5",
    title: "Quality first",
    desc: "We onboard the highest code quality and appropriate functioning of the end product keeping in mind of all quality assurance standards throughout the Software Development Life Cycle (SDLC)",
  },
  {
    id: "6",
    title: "Dedicated team",
    desc: "If you are looking for a dedicated development team, our model is perfect for your needs. Hire a team from us to take full control of your resources.",
  },
];
export default function Manufacturing() {
  return (
    <>
      <Banner
        img="/images/industries/manufacturing/manufacturing-banner.jpeg"
        title="Manufacturing Software Solutions"
        desc="Reshaping the manufacturing industry with applications empowered by advanced technologies."
      />
      <LeftImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FManufacturing%2FmanufacturingOvreview.jpg?alt=media&token=4765067c-d13a-45fa-a2f9-8a04a1b83af9"
        title="The Manufacturing Industry"
        // desc2="At Innorik, we are here to help you match the reach by providing maximum strategies designed through continuous research and development. With our past experience with helping other small to large manufacturing companies in mastering their challenges through our software solutions we are confident we can provide the best for you per your unique needs for production, quality control, marketing, inventory & sales management etc."
        desc="The evolution in the manufacturing industries have become seamless in this era. Presently, the modern factory is more digital and one way or another mechanized in the manner they work. A segment of this key challenging business required for our manufacturing sectors ranges from material and creation planning to improving stock requirements and more noticeable deal efficiencies. Our solid team helps you match the reach by providing maximum strategies designed through continuous research and development."
        alt=""
      />
      <h2 className="lg:text-[32px] md:text-[28px] text-[20px] text-center py-[25px] md:py-[40px] font-medium lg:py-[50px]">
        Our custom manufacturing solutions{" "}
      </h2>
      <RetailManagement
        color="lightblue"
        head="Manufacturing software"
        desc="We blend both hardware and software to automate your manufacturing workflow standards set by your organization. This comes with a highly sophisticated analytical feature that help you analyse trends in your historical data and provide efficient ways of enhancing profitability and sales. The workflow involves:"
        data={manufacturingData}
        img="/images/industries/manufacturing/screens/Manufacturing software.png"
        alt="Manufacturing Software"
        // img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      />
      <ProjectManagement
        head="Inventory Management System"
        desc="We provide software solutions that can help you optimize your resource usage and plan your production in advance as per sales and inventory forecasts of your products. Our Package includes:"
        data={inventoryData}
        img="/images/industries/manufacturing/screens/inventory-management-software.png"
      />
      {/* <RetailManagement
        color="lightblue"
        head="Manufacturing cost estimation software"
        desc="Our Manufacturing Cost Estimating Software is database driven that enables you to store, access and retrieve all of your estimation and quotes. It also helps clients calculate accurate time and cost and find lowest cost options through a variety of cost estimating methods, cost models, and more. Some of the features are:"
        data={costData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FcostEstimation.png?alt=media&token=6031e1cb-6b02-4ed8-8e9c-d3d8982c4904"
        // img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      /> */}
      <InventoryManagement
        color="f9f9f9"
        head="Computerized maintenance management software (CMMS) for manufacturing"
        desc="Our manufacturing cost estimating software is database driven that enables you to store, access and retrieve all your estimations and quotes. It also helps clients calculate accurate time and cost and find the lowest cost options through a variety of cost estimating methods, cost models, and more. Some of the features are:"
        data={InventoryData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FManufacturing%2Fimage%20555.jpg?alt=media&token=4b252171-237f-4584-a85a-53e4f08ef099"
        // img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      />
      <RetailManagement
        head="Supply chain management system"
        desc="Our solution helps in avoiding supply chain disruptions by providing you with resilient real-time software to cater for your in-house warehouse management down to your dealership network and secondary sale logistics. This solution includes features that allow our clients to:"
        data={supplyData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FManufacturing%2Fsupplychain.jpg?alt=media&token=ed063a8f-6a74-4201-858a-7c16a6f25534"
        // img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      />

      <WhyChooseWhiteBackground data={Why} />
      <FooterBanner title="Let's get started" link="/contact-us" />
    </>
  );
}
