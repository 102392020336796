import React from "react";

export default function WhyChooseNumber(props) {
  const Data = props.data;
  const listData = Data.map((list, index) => {
    return (
      <div key={index} className="lg:py-[30px]">
        <h2 className="pt-[25px] p text-[18px] md:text-[20px] lg:text-[24px] text-white">
          {list.title}
        </h2>
        <div className="flex ">
          <p className="text-[36px] mt-0 text-[#d3d3d3]">0{list.id}</p>
          <p className="text-[16px] pl-4 pt-[10px] lg:text-[18px] text-white">
            {list.desc}
          </p>
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="bg-[#252525]">
        <div
          className="bg-opacity-50bg-no-repeat bg-cover bg-center	"
          style={{
            backgroundImage: `url("${props.img}")`,
          }}
        >
          <div className="">
            <div className="max-w-[1500px]  lg:px-[85px] md:px-[40px] px-[28px] mx-auto py-[20px] md:py-[30px] lg:py-[50px]">
              <h1 className="text-[36px] text-white"> Why choose us?</h1>
              <div className="grid md:grid-cols-2  lg:grid-cols-2 md:gap-x-[28px] lg:gap-x-[88px]">
                {listData}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
