import React from "react";
import Banner from "../../components/Banner";
// import TechnologiesScroll from "../../components/TechnologiesScroll";
import PartnerCompanies from "./PartnerCompanies";
import PartnershipScroll from "./PartnershipScroll";
import TextSection from "./TextSection";
import BannerImage from "../../Assets/Images/company/partnership/our-partners-banner.jpeg";
import FooterBanner from "../../components/Footer/FooterBanner";
const Data = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Faws.svg.png?alt=media&token=84faaa8d-4bde-453b-8502-aa393b196872",
    desc: "With our partnership with Amazon, we have helped businesses build and manage secure and resilient systems. Our AWS certified solution architects, cloud practitioners and SysOps administrators bring their expertise to diverse projects for the development of business applications, databases, and data warehouses, IoT infrastructures, big data processing and analytic solutions, application migration to AWS, managed services to keep AWS services and apps stable.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Fmicrosoft.png?alt=media&token=43a45c4b-18b4-4576-9fc0-3578096d6219",
    desc: "Through our partnership with Microsoft, we have helped businesses build and manage secure and resilient systems. Our Microsoft certified solution architects, developers, DevOps engineers, Data Analysts, Data engineers, Business Analysts and AI engineers bring their expertise to diverse projects for the development of business applications, databases, data warehouses, IoT infrastructures, big data processing and analytic solutions and application migration to Azure.",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2FSalesforce.svg.png?alt=media&token=7adca4b9-aa84-46cd-8802-384aaa278b20",
    desc: "As a Salesforce partner we are able to deliver a full-cycle Salesforce implementation services. Our team of Salesforce consultants, developers and administrators can implement and support a Salesforce solution tailored to enhance quality leads, increased sales, and Improved customer service for your business.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Foracle.png?alt=media&token=0cb4eed6-fe30-450e-a340-e2bf359fd22d",
    desc: "Our partnership with Oracle has enabled us to deliver the broad Oracle services and industry knowledge that any organization needs to drive change. We have been helping our customers achieve the best value for their money by creating robust data analytic solutions along with high-performing and secure database environments powered by Oracle expertise.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Fcisco-logo-transparent.png?alt=media&token=0bb22a73-65f5-46a6-b945-f370f2eb3a16",
    desc: "Our partnership with Cisco gives us access to robust and intelligent products to improve digital agility and resilience of our customers across various business operations. Reliance on Cisco technologies has enabled us to create scalable data centers and establish secure remote environments.",
  },
];
const partnersData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Faws.svg.png?alt=media&token=84faaa8d-4bde-453b-8502-aa393b196872",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Foracle.png?alt=media&token=0cb4eed6-fe30-450e-a340-e2bf359fd22d",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Fcisco-logo-transparent.png?alt=media&token=0bb22a73-65f5-46a6-b945-f370f2eb3a16",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Fmicrosoft.png?alt=media&token=43a45c4b-18b4-4576-9fc0-3578096d6219",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2FSalesforce.svg.png?alt=media&token=7adca4b9-aa84-46cd-8802-384aaa278b20",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Faws.svg.png?alt=media&token=84faaa8d-4bde-453b-8502-aa393b196872",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Foracle.png?alt=media&token=0cb4eed6-fe30-450e-a340-e2bf359fd22d",
  },
  {
    id: 8,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Fcisco-logo-transparent.png?alt=media&token=0bb22a73-65f5-46a6-b945-f370f2eb3a16",
  },
  {
    id: 9,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Fmicrosoft.png?alt=media&token=43a45c4b-18b4-4576-9fc0-3578096d6219",
  },
  {
    id: 10,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2FSalesforce.svg.png?alt=media&token=7adca4b9-aa84-46cd-8802-384aaa278b20",
  },
];

export default function Parther() {
  return (
    <>
      <Banner
        img={BannerImage}
        title="Partnership"
        desc="Put world-class thinking and top-tier technology at your service through a partnership"
      />
      <TextSection />
      {/* <TextSection img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Partner%2Fmicrosoftlogo.png?alt=media&token=895673b0-38e6-4f0c-ad85-17822d714360" desc=""/> */}

      <PartnerCompanies data={Data} />

      {/* <PartnershipScroll /> */}
      <PartnershipScroll data={partnersData} />
      <FooterBanner title="Become our partner" link="/contact-us" />
    </>
  );
}
