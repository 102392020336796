import React from "react";
import Banner from "../../../../components/Banner";
import Industries from "../../../../components/NewIndustries";
import { javaDevelopers } from "../../../../data/programming";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import OurDevelopers from "../../Platforms/Magento/OurDevelopers";
import Offerings from "../Php/Offerings";
// import Overview from "../Php/Overview";
import Services from "../Php/Services";
import WhyChooseUs from "../Php/WhyChooseUs";
import Advantages from "./Advantages";

const advantageData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Fadvantages%2Feasy.svg?alt=media&token=36a973d8-4572-4568-ba5e-49c52b8ccd73",
    title: "Simple",
    desc: "A rich set of API (application protocol interfaces) is available to develop any complex application.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Fadvantages%2Farchitecture.svg?alt=media&token=b407a9a3-dd0a-432b-aad4-7817eb5b9231",
    title: "Platform Independent and Portable",
    desc: "Java can be used on any operating system and on any processor, regardless of architecture or supplier.",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Fadvantages%2Fserver.svg?alt=media&token=9e877f0f-e084-45e5-8691-91e107b67cf4",
    title: "Distributed",
    desc: "Java runs on multiple servers and can be accessed by a number of clients across the globe using a technology called J2EE.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Fadvantages%2Fcompilation.svg?alt=media&token=66c82089-a5a8-4a9f-bb25-1cbd0691f30b",
    title: "Robust",
    desc: "A reliable language. Java puts a lot of emphasis on early checking for all possible errors and exceptions since it is both a compiler and interpreter language.",
  },
];
const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fillustrations%2Fjava-web-development.svg?alt=media&token=ffaa325b-8a9d-4cd9-b5af-6d9db6ce4ff6",
    title: "Java Web Development",
    desc: "Our developers create highly scalable solutions using the most up-to-date tools and technologies.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fillustrations%2Fweb-service-integration.svg?alt=media&token=11d2f302-8846-494f-a9e3-6481707aacb9",
    title: "API & Web Service Integration",
    desc: "Development of quality API and integration into different software applications",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fillustrations%2Fecommerce.svg?alt=media&token=8ca328f7-b054-42aa-aed8-a13e71d22c4e",
    title: "Java-Based eCommerce Development ",
    desc: "Our full-stack Java web developers are well-versed in the development of online portals. They are noted for their user-friendly interfaces and security features.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fillustrations%2Fmodule-development.svg?alt=media&token=93cf8513-0104-4406-9903-10d864ad0965",
    title: "Java Module Development",
    desc: "Get a user-friendly and feature-packed modules that build interactive products / applications quickly from us",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fillustrations%2Fcms-development.svg?alt=media&token=44f72158-8476-4f5c-a52e-ac9607f9fc31",
    title: "Java-Based CMS Development",
    desc: "Our expert Java programmers can quickly create Java-based content management systems that are well-known for their user-friendliness.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fillustrations%2Fproduct-development.svg?alt=media&token=6939afee-41ad-40d4-a66a-6c609b58066d",
    title: "Java-Based Product Development",
    desc: "We develop feature-rich and unique software products to meet your organization’s specific requirements.",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fillustrations%2Fdynamic-website.svg?alt=media&token=62380290-bfec-4722-a835-c6951246b8fd",
    title: "Dynamic Websites and Web Portals",
    desc: "We build advanced-level dynamic web apps and web portals with the latest design trends.",
  },
  {
    id: 8,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fillustrations%2Ftech-migraiton.svg?alt=media&token=5c5d5f12-8927-494e-8068-81c888bd6ace",
    title: "Tech Migration",
    desc: "Our Java web developers are ever ready to transit your web app from other technologies to Java. They will complete it with minimal disruption and at a reasonable rate.",
  },
  {
    id: 9,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fillustrations%2Fsupport.svg?alt=media&token=76fea821-f06a-48ff-82a5-4f386980b8f8",
    title: "Support and Maintenance Service",
    desc: "We offer a complete support and maintenance of your current website and keep it up to date",
  },
];
const Data2 = [
  {
    id: 1,
    title: "Competitive Pricing ",
  },
  {
    id: 2,
    title: "Leading-edge technology ",
  },
  {
    id: 3,
    title: "World-class development team ",
  },
  {
    id: 4,
    title: "Industry-spanning innovation ",
  },
  {
    id: 5,
    title: "Comprehensive quality assurance",
  },
  {
    id: 6,
    title: "Complete customer satisfaction ",
  },
];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];

const offeringData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Frealtime.svg?alt=media&token=853e8047-fa01-43a8-8b9f-06055bd2e2cf",
    title: "Spring Framework",
    desc: "A complete and powerful modular framework that can be used for all layer implementations of a real-time application.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Fproductivity.svg?alt=media&token=61be9911-a9d0-4382-9953-1d187270d1f8",
    title: "Grails",
    desc: " An object-oriented language for Java platform which intends to enhance developer productivity.",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Fsynchronize.svg?alt=media&token=125d4286-5fb2-48fd-9fdb-c2ca8b1d7cc6",
    title: "Blade",
    desc: "Blade is a lightweight MVC Framework with a restful routing interface that makes the web API cleaner and easier to grasp, as well as assisting with data synchronization with the website.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Frouting.svg?alt=media&token=35a0bc92-f8d0-48cd-9517-d63b98b257d6",
    title: "Play",
    desc: "A reactive web and mobile framework for highly scalable Java applications. Play makes it possible to develop lightweight and web-friendly Java and Scala applications for desktop and mobile interfaces.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Ffeatured.svg?alt=media&token=c1a07d74-c28a-4fbd-a8f7-908e6eac5f6c",
    title: "Struts",
    desc: "This full-featured Java web application framework allows developers to design a Java application that is simple to maintain.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Fcommunity.svg?alt=media&token=be4f4c37-2ede-4861-ab4d-bb8600e32aeb",
    title: "JavaServer Faces (JSF)",
    desc: "An official standard of the Java Community Process (JCP) initiative developed by Oracle for building user interfaces for Java-based web applications.",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Ficons%2Fopen-source.svg?alt=media&token=46999dd6-ce9c-4843-a244-790d1eb86277",
    title: "Google web toolkit (GWT)",
    desc: "An open-source framework that allows developers to create client-side Java code and convert it to JavaScript.",
  },
];
export default function Java() {
  return (
    <>
      <Banner
        button="Talk to Experts"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fjava.jpeg?alt=media&token=29079dcc-fa0f-4f99-b8ef-16901662e53e"
        title="Java Development Services"
        desc="Building rich web-based Java applications for both server and client-side projects and ensuring immense exposure to all breathtaking technologies."
      />
      <Overview
        textColor="text-[#222222] bg-white"
        title="Java Programming Language"
        desc="The most popular object-oriented and open-source programming language globally is Java. Developers can construct enterprise-level solutions in a short amount of time using some of java’s powerful features like 'write once, deploy anywhere'. It is easy to see why Java is one of the most popular programming languages, given its cross-platform capabilities, lightweight architecture, ease of use, and wide range of security features. Our developers have extensive expertise in building cutting-edge applications with Java's ever-evolving technologies and frameworks. With the purpose of fulfilling the market’s rising needs at the lowest feasible incremental cost, our chosen team of certified smart developers provides intuitive and highly scalable Java application development solutions."
        img="/images/technologies/programming/java/java-overview-white.jpeg"
        img2="/images/technologies/programming/java/java-overview-mobile-white.jpeg"
        alt="java"
      />
      {/* <Overview
        color=""
        textColor="text-[#222222]"
        title="Java Programming Language"
        desc="The most popular object-oriented and open-source programming language globally is Java. Developers can construct enterprise-level solutions in a short amount of time because to the 'write once, deploy anywhere' feature. It's easy to see why Java is one of the most popular programming languages, given its cross-platform capabilities, lightweight architecture, ease of use, and wide range of security features."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fjava-illustartion.jpeg?alt=media&token=ef9f7845-b04e-4b49-a155-25f5f4599dbe"
      /> */}
      <Advantages head="Advantages of using Java" data={advantageData} />
      <Offerings
        title="Java Frameworks Used"
        desc=""
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2Fphp%2Fofferings.png?alt=media&token=d3fd7a68-2751-45e1-b71d-5e9fcce9184b"
        data={offeringData}
      />
      <Services
        title="Our Java Development Services"
        desc=""
        img=""
        data={servicesData}
      />
      <OurDevelopers
        banner="true"
        devs="false"
        profile="true"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our Java expertise"
        desc="Innorik has a large team of Java developers who have extensive experience with the language due to working with various project domains such as banking, telecommunication, healthcare, insurance systems, and with different companies. Our developers have experience ranging from 1-20 years."
        button="Hire Java Developer"
        data={javaDevelopers}
      />
      {/* <OurDevelopers
        hide="true"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fjava-developer.jpeg?alt=media&token=f0b6d1d3-f046-4554-837e-e3899e3e5722"
        title="Our Java Experts"
        desc="Innorik has a large team of JavaScript developers who have very good experience with the language from working with various project domains such as banking, telecommunication, healthcare, insurance systems and with different companies. Our developers have experience ranging from 1year to 20 years."
        button="Hire Java Expert"
      /> */}
      <WhyChooseUs
        head="Why Integrate with cloud? "
        title="Why choose us?"
        desc=""
        data={Data2}
      />
      <Industries
        title="Industies Served"
        desc="We are international company and we deliver technology solutions to clients globally."
        data={Indust}
      />
    </>
  );
}
