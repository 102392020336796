import React, { useState } from "react";
import Banner from "../../components/Banner";
import "./home.css";
import Spotlight from "./Spotlight";
// import FooterBanner from "../../components/Footer/FooterBanner";
import WhoWeAre from "./WhoWeAre";
import Empowering from "./Empowering";
import Innovate from "./Innovate";
import Industries from "./Industries";
import WorkingAtInnorik from "./WorkingAtInnorik";
// import Infograhics from "./Infograhics";
// import Location from "./Location";
import InnovationBanner from "../../Assets/Images/home/innovation-banner.jpeg";
// import WomenBanner from "../../Assets/Images/home/women-banner.jpeg";
import IndustriesMobile from "./IndustriesMobile";
import Carousel from "./Carousel";
import WhyWork from "./WhyWork";

import { Link } from "react-scroll";

const Indust = [
  {
    id: 1,
    desc: "We deliver comprehensive web development services with clear and trustworthy results and a clear development process. Our web application solutions will enable your organization to easily sail through the continuously changing technology and business ramifications. ",
    nav: "/web-development",
    title: "Web Development",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fweb-development.jpeg?alt=media&token=da993e54-cf50-480d-9da4-c76e53068472",
    alt: "web development services",
  },
  {
    id: 2,
    desc: "Innorik is building a community of talented and diverse professionals who are passionate about technology and exceptional client service. We are searching for talented students and non-students to join our exciting, online training program with no out-of-pocket cost to you that will accelerate your career in a technology related field. ",
    nav: "/talent-world-wide",
    alt: "talent world wide ",
    title: "Talent Worldwide ",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Ftalent-world-wide.jpeg?alt=media&token=9c0ae34e-fc76-4f0c-9b03-be4753728dd6",
  },
  {
    id: 3,
    title: "Cloud Integration ",
    desc: "The cloud transforms the way businesses address data integration and helps them win the battle against data silos. Cloud application integration tools have open opportunities for organizations wanting to connect to heterogeneous systems. ",
    nav: "/cloud-application-integration",
    alt: "cloud Integration",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fcloud-computing.jpeg?alt=media&token=ca00f009-2848-4eda-8da1-48e023a62e93",
  },
  {
    id: 4,
    title: "Virtual Reality",
    alt: "virtual reality services",
    desc: "Our VR studio team is made up of both creative and technical professionals that can provide new VR solutions to tackle complicated business problems and also leveraging platforms including Unity, ARCore, ARKit, Vuforia etc. to design, develop and deploy these unique and engaging VR applications. ",
    nav: "/latest-thinking/virtual-reality",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fvirtual-reality.jpeg?alt=media&token=df76febf-bd6b-498a-a431-83aaacbf560d",
  },
  {
    id: 5,
    title: "Application Migration",
    desc: "At Innorik, our application modernization services helps your business achieve a like-to-like system parity with operational enhancements by integrating several accelerators, platforms, and strategic planning to technologize your core business systems, softwares, and processes. ",
    nav: "/application-migration",
    alt: "application migration services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fapplication-migration.jpeg?alt=media&token=e32bd28d-eaf2-4198-a472-2ec18ec70990",
  },
  {
    id: 6,
    desc: "Want to build your product with a team that establishes a clear design process, meets deadlines, and delivers that perfect product you’ve been dreaming of? Turn to Innorik UI and UX services. We have very creative designers who can help you build an engaging and stunning product easily and quickly. ",
    nav: "/ui-ux-design",

    title: "Designing",
    alt: "ui ux desinging",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fdesigning.jpeg?alt=media&token=aed3cd68-f673-4558-b59b-3626dce82c2b",
  },
  {
    id: 7,
    desc: "Responsive web designs ensure a web solution’s adaptive behavior to different screen sizes of a variety of devices. Following the mobile-first strategy, our designers and developers create layouts that can respond to the device being used to view the content. ",
    nav: "/responsive-design",
    alt: "responsive website design",
    title: "Responsive Web Design ",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fresponsive-web-design.jpeg?alt=media&token=fa1dd769-dbfe-4b03-a6c9-3f65d80bbf7b",
  },
  // {
  //   id: 7,
  //   img: "",
  //   title: "Software Quality Engineering     ",
  //   desc: "Reimagine your business with our top-quality assurance services. Our quality engineers include quality engineering methods and processes right from the system conception, planning, development, and implementation. They produce systems that exceeds our customers’ expectations. ",
  //   nav: "/",
  // },
  {
    id: 8,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fcloud-consulting.jpeg?alt=media&token=ee758d09-af57-4397-94cd-6281bb51e099",
    title: "Cloud Consulting ",
    desc: "Cloud technology has the potential to revolutionize business, offering agility, scale, and favorable economics but without guidance it is as if you are flying a plane without a compass. At Innorik we engineer cloud transformation at every step and our cloud consulting services can help you determine the best strategy to adopt, platform selections, best cost modeling to use and to get you started with your cloud journey. ",
    nav: "/cloud-consulting",
    alt: "cloud consulting services",
  },
  {
    id: 9,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fbusines-process-outsourcing.jpeg?alt=media&token=15c1615b-3fde-46ee-b1e5-6fcf1218d5ff",
    title: "Business Process Outsourcing ",
    desc: "In today’s world of digital transformation, a successful business is often an efficient one and the difference can come down to smart, innovative business processes. Modern business process outsourcing techniques can skyrocket your business growth. Business process outsourcing makes perfect sense because it enables you to concentrate on your core business activities. ",
    nav: "/business-process-outsourcing",
    alt: "business process outsourcing services",
  },
  {
    id: 10,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fartificial-intellegence.jpeg?alt=media&token=5734822c-8187-474d-bf01-1d5509673815",
    title: "Artificial intelligence services",
    desc: "AI has become the most revolutionary and much-needed technology for global companies due to its potential to entirely automate important jobs and give efficiency to business operations. Whether it's improving the customer experience or automating the most boring and tiresome jobs, AI development has been revolutionizing the world in the most effective way possible. ",
    nav: "/latest-thinking/artificial-intellegence",
    alt: "Artificial intellenbence services",
  },
  {
    id: 11,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fblockchain-web-3.jpeg?alt=media&token=6a9cd0c8-4789-44d3-b607-4cd1473a412d",
    title: "Blockchain Services ",
    desc: "Blockchain is changing the way the world lives and work, and it is gradually becoming the world’s most popular way to buy, sell, and trade crypto. In view of this, Innorik is ready to step in at any stage of your product development to assist you in designing, development, implementation, and deployment of your product successfully. ",
    nav: "/latest-thinking/blockchain",
    alt: "blockchain development services",
  },
  {
    id: 12,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fservice-now.jpeg?alt=media&token=059246d9-2e21-42fc-bc93-03cb5d494e51",
    title: "ServiceNow ",
    desc: "ServiceNow is a software-as-a-service platform for technological performance management and faultless process control systems (SaaS). With the help of this software, we can easily manage projects, staff, and customer experiences using a variety of apps and plugins. ",
    nav: "/platforms/services-now",
    alt: "service now services",
  },
  {
    id: 13,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fmicrosoft-power-bi.jpeg?alt=media&token=5e5afa91-b51b-4472-aaa1-56495aee2d62",
    title: "Microsoft power BI ",
    desc: "Microsoft Power BI is a collection of software services, apps, and connectors used to transform data into a form of immersive live dashboards and easy-to-digest reports and interactive insights. Power BI offers extensive modelling, real-time analytics, and custom visualizations and can help your organization find clarity when you need it most to empower team members to discover insights hidden in your data. ",
    nav: "/platforms/power-bi",
    alt: "microsoft power bi",
  },
  {
    id: 14,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Flife-at-innorik.jpeg?alt=media&token=0f04d5bf-5a46-4164-9788-dc297361693b",
    title: ".NET Development Services ",
    desc: "Most web development companies prefer Python because of its unique and practical characteristics. Python is used to create a wide range of software programs, from scientific computing to business-related systems. ",
    nav: "/technologies/programming/dot-net",
    alt: "dot net development",
  },
  {
    id: 15,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Fmicrosoft-power-bi.jpeg?alt=media&token=5e5afa91-b51b-4472-aaa1-56495aee2d62",
    title: "AngularJS Development Services ",
    desc: "Angular is a popular JavaScript framework that was created with the goal of simplifying scripts, addressing single-page application (SPA) difficulties, and increasing interactivity. AngularJS is the greatest framework for creating interactive single-page applications, but it can be used to create any form of web application. ",
    nav: "/technologies/Angular",
    alt: "angular programming language",
  },
  {
    id: 16,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Ftransportation.jpeg?alt=media&token=013e9af7-1fc4-4a18-b30c-1a7ef35fe615",
    title: "Transportation & Logistics ",
    desc: "Leverage intelligent applications powered by advanced technology to move products and services with efficiency and at lower cost to stay ahead of your competitors in this highly competitive transportation and distribution service industry. ",
    nav: "/industries/transportation",
    alt: "transportation and logistics industry",
  },
  {
    id: 17,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Freal-estate.jpeg?alt=media&token=d58687d4-9127-41ec-9fca-91af61a80d79",
    title: "Real Estates ",
    desc: "A variety of sectors have adopted advanced technology to enhance commercial benefits in recent years. One of them is real estate and property development. With easy tools and superior customer service, real estate’s industries heavily rely on technologies like databases, data analysis tools and communication apps to reach various clients. ",
    nav: "/industries/real-estate",
    alt: "realestate industry",
  },
  {
    id: 18,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Finsurance.jpeg?alt=media&token=9f854774-32a2-45cf-8c0e-0e9149e5a467",
    title: "Insurance",
    desc: "We help insurance companies align stakeholders on software development plans, to facilitate a change in broker and agent driven sales, education prospects, policyholder support, etc. to improve the customer experience and map out steady developments that results in positive momentum. ",
    nav: "/industries/insurance",
    alt: "insurance industry",
  },
  {
    id: 19,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Ffinancial-services.jpeg?alt=media&token=d1af57ac-5aa8-4bed-95c4-8275eda0a6dc",
    title: "Financial Services ",
    desc: "We help financial institutions leverage advanced technologies like Blockchain services, and analytic software solutions to enable them drive insightful financial forecasting and budgeting for faster and better decision making, more accurate data, and less repetitive tasks than ever. ",
    nav: "/industries/fintech",
    alt: "financial services fintech",
  },
  {
    id: 20,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2Fimages%2Fspotlight%2Flife-at-innorik.jpeg?alt=media&token=0f04d5bf-5a46-4164-9788-dc297361693b",
    title: "Life at Innorik ",
    desc: "At Innorik, we believe that continuous improvement is the catalyst for growth, creativity and innovation and we provide our associates at all levels the support, training, and the opportunities they need to be fully equipped for any future challenges and to take their lives and careers to new heights. ",
    nav: "/culture-and-values",
    alt: "culture and values working at innorik",
  },
];
export default function Home() {
  const [isActive, setIsActive] = useState(false);
  const [isBannerActive, setIsBannerActive] = useState(false);
  const handelSetActive = (to) => {
    // setIsActive(true);
    setIsActive(true);
    setIsBannerActive(false);
    // console.log(to + " hello");
  };
  const handelBannerSetActive = (to) => {
    // setIsActive(true);
    setIsActive(false);

    setIsBannerActive(true);
    // console.log(to + " hello");
  };

  return (
    <>
      <div className="relative">
        {/* <div className="pr-[20px] w-[280px] fixed my-auto top-[20%]  right-0 z-90  grid grid-row-7 self "> */}
        {/* <div className="pr-[20px] text-white active:text-black  text-right text-[14px] fixed my-auto top-[20%]  right-0 z-90  grid grid-row-8  self "> */}
        {/* <div className="justify-self-end w-[95px] ">
            <hr className="  py-[5px] " />
            <h1 className="    px-[10px]">
              <a
                href="/"
                onClick={(e) => {
                  let hero = document.getElementById("hero");
                  e.preventDefault();
                  hero &&
                    hero.scrollIntoView({ behavior: "smooth", block: "start" });
                  window.history.pushState("hero", "hero", "/hero");
                }}
              >
                Navigate your next
              </a>
            </h1>
          </div> */}
        <div className="z-[1000] hidden md:block  fixed  top-[20%]  right-0  pr-[20px]">
          <ul className={`float-right text-right justify-self-end `}>
            <li
              className={
                isBannerActive
                  ? `border-t-2  cursor-pointer group border-white ml-auto  w-[30px] hover:w-[90px] h-[60px] text-white`
                  : `border-t-2  cursor-pointer group border-black ml-auto w-[30px] hover:w-[90px] h-[60px] text-black`
              }
            >
              <Link
                to="hero"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                activeClass=""
                onSetActive={handelBannerSetActive}
              >
                <span className="hidden pt-[5px] text-[14px] bg-transparent group-hover:block ">
                  Explore your next
                </span>
              </Link>
            </li>
            <li
              className={
                isBannerActive
                  ? `border-t-2  cursor-pointer group border-white ml-auto  w-[30px] hover:w-[90px] h-[60px] text-white`
                  : `border-t-2  cursor-pointer group border-black ml-auto w-[30px] hover:w-[90px] h-[60px] text-black`
              }
            >
              <Link
                to="spotlight"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                activeClass="text-black"
                onSetActive={handelSetActive}
              >
                <span className="hidden pt-[5px] text-[14px] bg-transparent group-hover:block ">
                  Spotlight
                </span>
              </Link>
            </li>

            <li
              className={
                isBannerActive
                  ? `border-t-2  cursor-pointer group border-white ml-auto  w-[30px] hover:w-[90px] h-[60px] text-white`
                  : `border-t-2  cursor-pointer group border-black ml-auto w-[30px] hover:w-[90px] h-[60px] text-black`
              }
            >
              <Link
                to="whoweare"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                activeClass="text-black"
                onSetActive={handelSetActive}
              >
                <span className="hidden pt-[5px] text-[14px] bg-transparent group-hover:block ">
                  Who we are
                </span>
              </Link>
            </li>

            <li
              className={
                isBannerActive
                  ? `border-t-2  cursor-pointer group border-white ml-auto  w-[30px] hover:w-[90px] h-[60px] text-white`
                  : `border-t-2  cursor-pointer group border-black ml-auto w-[30px] hover:w-[90px] h-[60px] text-black`
              }
            >
              <Link
                to="empowering"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                activeClass="text-black"
                onSetActive={handelSetActive}
              >
                <span className="hidden pt-[5px] text-[14px] bg-transparent group-hover:block ">
                  Empower Talents
                </span>
              </Link>
            </li>

            <li
              className={
                isBannerActive
                  ? `border-t-2  cursor-pointer group border-white ml-auto  w-[30px] hover:w-[90px] h-[60px] text-white`
                  : `border-t-2  cursor-pointer group border-black ml-auto w-[30px] hover:w-[90px] h-[60px] text-black`
              }
            >
              <Link
                to="innovate"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                activeClass="text-black"
                onSetActive={handelSetActive}
              >
                <span className="hidden pt-[5px] text-[14px] bg-transparent group-hover:block ">
                  Latest Thinking
                </span>
              </Link>
            </li>
            <li
              className={
                isBannerActive
                  ? `border-t-2  cursor-pointer group border-white ml-auto  w-[30px] hover:w-[90px] h-[60px] text-white`
                  : `border-t-2  cursor-pointer group border-black ml-auto w-[30px] hover:w-[90px] h-[60px] text-black`
              }
            >
              <Link
                to="industries"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                activeClass="text-black"
                onSetActive={handelSetActive}
              >
                <span className="hidden pt-[5px] text-[14px] bg-transparent group-hover:block ">
                  Industries
                </span>
              </Link>
            </li>
            <li
              className={
                isBannerActive
                  ? `border-t-2  cursor-pointer group border-white ml-auto  w-[30px] hover:w-[90px] h-[60px] text-white`
                  : `border-t-2  cursor-pointer group border-black ml-auto w-[30px] hover:w-[90px] h-[60px] text-black`
              }
            >
              <Link
                to="workingatinnorik"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                activeClass="text-black"
                onSetActive={handelSetActive}
              >
                <span className="hidden pt-[5px] text-[14px] bg-transparent group-hover:block ">
                  Working @ Innorik
                </span>
              </Link>
            </li>
            <li
              className={
                isBannerActive
                  ? `border-t-2  cursor-pointer group border-white ml-auto  w-[30px] hover:w-[90px] h-[60px] text-white`
                  : `border-t-2  cursor-pointer group border-black ml-auto w-[30px] hover:w-[90px] h-[60px] text-black`
              }
            >
              <Link
                to="whywork"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                activeClass="text-black"
                onSetActive={handelSetActive}
              >
                <span className="hidden pt-[5px] text-[14px] bg-transparent group-hover:block ">
                  Why work with us?
                </span>
              </Link>
            </li>
            <li
              className={
                isBannerActive
                  ? `border-t-2  cursor-pointer group border-white ml-auto  w-[30px] hover:w-[90px] h-[60px] text-white`
                  : `border-t-2  cursor-pointer group border-black ml-auto w-[30px] hover:w-[90px] h-[60px] text-black`
              }
            >
              <Link
                to="location"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                activeClass="text-black"
                onSetActive={handelSetActive}
              >
                <span className="hidden pt-[5px] text-[14px] bg-transparent group-hover:block ">
                  Our offices
                </span>
              </Link>
            </li>
          </ul>
        </div>

        <section id="hero">
          <Banner
            img="/images/home/people-with-lightbulb-icon.jpg"
            title="Powered by Innovation"
            desc="Put world-class thinking and top-tier technology at your service through partnership. Together we move your business forward in this digital transformation era "
          />
        </section>
        <section id="spotlight">
          <Spotlight data={Indust} />
        </section>

        <section id="whoweare">
          <WhoWeAre />
        </section>

        <section id="empowering">
          <Empowering />
        </section>

        <section id="innovate">
          <Innovate />
        </section>
        <section id="industries">
          <div className="hidden md:block">
            <Industries />
          </div>
        </section>

        <div className="block md:hidden">
          <IndustriesMobile />
        </div>
        <section id="workingatinnorik">
          <WorkingAtInnorik />
        </section>

        {/* <Infograhics /> */}
        <section id="whywork">
          <WhyWork />
        </section>
        <section id="location">
          <Carousel />
        </section>

        {/* <Location /> */}
        {/* <FooterBanner title="Connect with us" link="/contact-us" /> */}
      </div>
    </>
  );
}
