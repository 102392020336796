import React from "react";
import Banner from "../../components/Banner";
import DevProcess from "../../components/DevProcess";
import FooterBanner from "../../components/Footer/FooterBanner";
import FourIcons from "../../components/FourIcons";
import WhyChoose from "../../components/WhyChoose";
import DevopsApproach from "./DevopsApproach";
import Overview from "./Overview";
const IconsData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FDevops%2Ficons%2FAnalyze.svg?alt=media&token=a08c7032-ec1b-47f7-a7ef-70dadd7bd063",
    title: "",
    desc: "Analyse",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FDevops%2Ficons%2FSolution.svg?alt=media&token=6d73c5e3-e3a1-4563-9453-6c34f959d560",
    title: "",
    desc: "Choose a solution",
  },

  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FDevops%2Ficons%2FExecute.svg?alt=media&token=9a98f425-c3ad-497b-a73f-486e44582600",
    title: "",
    desc: "Execute",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FDevops%2Ficons%2FSupport.svg?alt=media&token=a5b9b8ee-ab94-4ca5-9d3c-48d524fa055f",
    title: "",
    desc: "	Support",
  },
];
const Data2 = [
  {
    id: 1,
    title: "Speed",
  },
  {
    id: 2,
    title: "Rapid Delivery",
  },
  {
    id: 3,
    title: "Reliability",
  },
  {
    id: 4,
    title: "Scale",
  },
  {
    id: 5,
    title: "Improved Collaboration",
  },
  {
    id: 5,
    title: "Security",
  },
  ,
];
const Data = [
  {
    id: 1,
    title: "Continuous Integration",
  },
  {
    id: 2,
    title: "Continuous Delivery",
  },
  {
    id: 3,
    title: "Microservices",
  },
  {
    id: 4,
    title: "Infrastructure as Code",
  },
  {
    id: 5,
    title: "Monitoring and Logging",
  },
  {
    id: 6,
    title: "Communication and Collaboration",
  },
];
const Data3 = [
  {
    id: 1,
    title: "No matter your budget, we are ready to work with you  ",
  },
  {
    id: 2,
    title: "Strategic partnership ",
  },
  {
    id: 1,
    title: "Modernization",
  },
  {
    id: 2,
    title: "Client-first approach",
  },
  {
    id: 1,
    title: "Large pool of experienced tech talents",
  },
  {
    id: 2,
    title: "Client collaboration with enhanced communication",
  },
  {
    id: 2,
    title:
      "We have all the skills and the domain expertise to get your job done ",
  },
  {
    id: 2,
    title:
      "We increase customers engagement at all levels in the project life cycle ",
  },
];

export default function Devops() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FDevops%2FDEVOPS%20banner.jpg?alt=media&token=efa64094-3d3a-4b85-9cc6-5bbe0bcadd60"
        title="DevOps Development"
        desc=""
      />
      <Overview
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Devops%2FnewDevops.png?alt=media&token=e13006b6-03a1-40f3-8d79-85ab411de67c"
        title="Leaving behind Siloed Team"
        desc="Need a modern software development approach that establishes close collaboration between development, IT operations, quality engineering, and security to produce better and more reliable software at the speed of light and greater customer satisfaction? DEVOPS is your answer. DEVOPS uses tools to automate, optimize, and accelerate processes, which helps to increase reliability. Our expert DEVOPS engineers will help you adopt DEVOPS methodology to help your business innovate faster leaving your competitors behind."
      />
      <div className="pb-8  pt-4 lg:py-8">
        <FourIcons title="Implementation Process" data={IconsData} />
      </div>
      <DevProcess
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FDevops%2FDEVOPS%20Process%20we%20follow%20with%20stirp-1.jpg?alt=media&token=13cee6e7-620b-4d36-97c2-725bbf89365f"
        heading="DevOps process we follow"
        data={Data}
      />
      <DevopsApproach head="Why DevOps Approach" data={Data2} />

      <WhyChoose
        textColor="text-white"
        head="Why Innorik?"
        data={Data3}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FDevops%2FWhy%20choose%20Cloud%20solutions%20with%20strip.jpg?alt=media&token=8fb60b17-d62a-4906-b0af-f6320e88b5b7"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FDevops%2Fwhy-us-for-devops.jpeg?alt=media&token=31386339-7bf1-4448-906c-8894a7994f7b"
      />
      <FooterBanner title="Got a project? Let's talk" link="/contact-us" />
    </>
  );
}
