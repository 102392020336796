import React from "react";

export default function CenterScreen(props) {
  const color = props.color;
  return (
    <>
      <div
        className="bg-no-repeat bg-cover bg-center"
        style={{
          backgroundImage: `url("${props.img}")`,
        }}
      >
        <div className="mx-auto pt-[40px] md:pt-[50px] lg:pt-[50px] max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] w-full">
          <h1 className="text-center text-[#5B8BFF] text-[18px] md:text[24px] lg:text-[24px]">
            {props.title}
          </h1>
          <p
            className={
              color === "white"
                ? "text-center pt-[25px] text-[16px] md:pt-[25px] lg:pt-[25px] text-white lg:text-[18px]"
                : "text-center pt-[25px] text-[16px] md:pt-[25px] lg:pt-[25px] text-[#222222] lg:text-[18px]"
            }
          >
            {props.desc}
          </p>
          <p
            className={
              color === "white"
                ? "text-center pt-[25px] text-[16px] md:pt-[25px] lg:pt-[25px] text-white lg:text-[18px]"
                : "text-center pt-[25px] text-[16px] md:pt-[25px] lg:pt-[25px] text-[#222222] lg:text-[18px]"
            }
          >
            {props.desc2}
          </p>
          <img
            className="mx-auto pt-[20px] h-[180px] w-[400px] lg:h-[300px] md:h-[200px] object-cover object-top md:w-[500px] lg:w-[700px]"
            src={props.img2}
          />
        </div>
      </div>
    </>
  );
}
