// import React from "react";
// import "./responsive.css";
// export default function ResponsiveSolutions() {
//   return (
//     <>
//       <div className="">
//         <div className="grid grid-cols-1 mx-auto pt-8 md:pt-[8%] lg:pt-0 sm:grid-cols-2 lg:grid-cols-4 w-3/4  sm:w-10/12 lg:w-full 2xl:w-11/12 gap-6 sm:gap-8 md:gap-0 lg:gap-0 xl:gap-0 2xl:gap-14 pb-10">
//           <div className="img__wrap">
//             <div className="relative aspect-square ">
//               <img
//                 className="w-full h-full object-cover object-center img__img"
//                 src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/ResponsiveDesign%2FresponsiveEcom.jpg?alt=media&token=0e40c5fe-e8ea-4613-83e8-c759ebc468c5"
//               />
//               <div className="bg-white absolute h-full bottom-0  text-center leading-snug w-full bg-black/70 hover:bg-[#3066EA]">
//                 <div className="h-full grid justify-items-center px-5 sm:px-8 md:px-10 lg:px-8 xl:px-10 2xl:px-12">
//                   <p className="m-auto text-white mb-2 text-xl md:text-2xl 2xl:text-3xl font-regular-medium">
//                     Responsive ecommerce design
//                   </p>
//                   <button className="img__description h-10 bg-white text-[#3066EA] font-bold py- px-4 rounded-4 opacity-[90%]">
//                     Get a quote
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="relative aspect-square">
//             <img
//               className="w-full h-full object-cover object-center"
//               src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/ResponsiveDesign%2FresponsiveWebDesign.jpg?alt=media&token=f8fe1f73-42dc-4846-b1d7-6ac9c991f15f"
//             />
//             <div className="absolute h-full bottom-0 text-white text-center leading-snug w-full bg-black opacity-70">
//               <div className="h-full grid justify-items-center px-5 sm:px-8 md:px-10 lg:px-8 xl:px-10 2xl:px-12">
//                 <p className="m-auto mb-2 text-xl md:text-2xl 2xl:text-3xl font-regular-medium">
//                   Responsive Website design{" "}
//                 </p>
//                 <p className="m-auto mt-2 md:text-lg xl:text-xl 2xl:text-2xl"></p>
//               </div>
//             </div>
//           </div>

//           <div className="relative aspect-square">
//             <img
//               className="w-full h-full object-cover object-center"
//               src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/ResponsiveDesign%2FresponsiveRedesign.jpg?alt=media&token=3c6db861-3e4c-467a-9720-4c544fdccc02"
//             />
//             <div className="absolute h-full bottom-0 text-white text-center leading-snug w-full bg-black opacity-70">
//               <div className="h-full grid justify-items-center px-5 sm:px-8 md:px-10 lg:px-8 xl:px-10 2xl:px-12">
//                 <p className="m-auto mb-2 text-xl md:text-2xl 2xl:text-3xl font-regular-medium">
//                   Responsive Website redesign{" "}
//                 </p>
//                 <p className="m-auto mt-2 md:text-lg xl:text-xl 2xl:text-2xl">
//                   {/* We are security conscious, and we develop best strategies with
//                   technologies, polices, and services to protect cloud data,
//                   applications, and infrastructure from threats */}
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="relative aspect-square">
//             <img
//               className="w-full h-full object-cover object-center"
//               src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/ResponsiveDesign%2FresponsiveWebApp.jpg?alt=media&token=cb4e94da-ed16-48ac-9fe8-ae6559554aa0"
//             />
//             <div className="absolute h-full bottom-0 text-white text-center leading-snug w-full bg-black opacity-70">
//               <div className="h-full grid justify-items-center px-5 sm:px-8 md:px-10 lg:px-8 xl:px-10 2xl:px-12">
//                 <p className="m-auto mb-2 text-xl md:text-2xl 2xl:text-3xl font-regular-medium">
//                   Responsive Website App design{" "}
//                 </p>
//                 <p className="m-auto mt-2 md:text-lg xl:text-xl 2xl:text-2xl">
//                   {/* We are security conscious, and we develop best strategies with
//                   technologies, polices, and services to protect cloud data,
//                   applications, and infrastructure from threats */}
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
import React from "react";
import { Link } from "react-router-dom";
import "./responsive.css";
export default function ResponsiveSolutions() {
  return (
    <>
      <div className="max-w-[1500px]  px-[28px] md:px-[40px] lg:px-0 xl:px-0 2xl:px-[85px] mx-auto">
        <div className="grid grid-cols-1 mx-auto pt-8 md:pt-[8%] lg:pt-0 sm:grid-cols-2 lg:grid-cols-4 w-3/4  sm:w-10/12 lg:w-full  gap-6 sm:gap-8 md:gap-0 lg:gap-0  pb-10">
          <div className="img__wrap">
            <div className="relative aspect-square ">
              <img
                className="w-full h-full object-cover object-center img__img"
                src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Fresponsive-ecommerce-design.jpg?alt=media&token=6c3398f1-103a-4830-b9ad-f9da51bdeb88"
              />
              <div className="bg-white absolute h-full bottom-0  text-center leading-snug w-full bg-black/70 hover:bg-[#3066EA]/70">
                <div className="h-full grid justify-items-center px-5 sm:px-8 md:px-10 lg:px-8 xl:px-10 2xl:px-12">
                  <p className="m-auto text-white mb-2 text-xl md:text-2xl 2xl:text-3xl font-regular-medium">
                    Responsive ecommerce design
                  </p>
                  <Link to="/contact-us">
                    {" "}
                    <button className="img__description h-10 bg-white text-[#3066EA] font-regular-medium py- px-4 rounded-4 opacity-[100%]">
                      Get a quote
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="img__wrap">
            <div className="relative aspect-square ">
              <img
                className="w-full h-full object-cover object-center img__img"
                src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Fresponsive-website-designe.jpg?alt=media&token=f125b7c0-41e0-425a-b7cb-0e75d9280947"
              />
              <div className="bg-white absolute h-full bottom-0  text-center leading-snug w-full bg-black/70 hover:bg-[#3066EA]/70">
                <div className="h-full grid justify-items-center px-5 sm:px-8 md:px-10 lg:px-8 xl:px-10 2xl:px-12">
                  <p className="m-auto text-white mb-2 text-xl md:text-2xl 2xl:text-3xl font-regular-medium">
                    Responsive website design
                  </p>
                  <Link to="/contact-us">
                    {" "}
                    <button className="img__description h-10 bg-white text-[#3066EA] font-regular-medium py- px-4 rounded-4 opacity-[100%]">
                      Get a quote
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="img__wrap">
            <div className="relative aspect-square ">
              <img
                className="w-full h-full object-cover object-center img__img"
                src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Fresponsive-website-redesign.jpg?alt=media&token=0b64c8e6-4bca-4b25-8fea-62d19df7fdb2"
              />
              <div className="bg-white absolute h-full bottom-0  text-center leading-snug w-full bg-black/70 hover:bg-[#3066EA]/70">
                <div className="h-full grid justify-items-center px-5 sm:px-8 md:px-10 lg:px-8 xl:px-10 2xl:px-12">
                  <p className="m-auto text-white mb-2 text-xl md:text-2xl 2xl:text-3xl font-regular-medium">
                    Responsive website redesign
                  </p>
                  <Link to="/contact-us">
                    {" "}
                    <button className="img__description h-10 bg-white text-[#3066EA] font-regular-medium py- px-4 rounded-4 opacity-[100%]">
                      Get a quote
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="img__wrap">
            <div className="relative aspect-square ">
              <img
                className="w-full h-full object-cover object-center img__img"
                src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FresponsiveWebDesign%2Fresponsive-website-app-design.jpg?alt=media&token=e709ae1c-8845-41a2-964e-b2c7014e17ab"
              />
              <div className="bg-white absolute h-full bottom-0  text-center leading-snug w-full bg-black/70 hover:bg-[#3066EA]/70">
                <div className="h-full grid justify-items-center px-5 sm:px-8 md:px-10 lg:px-8 xl:px-10 2xl:px-12">
                  <p className="m-auto text-white mb-2 text-xl md:text-2xl 2xl:text-3xl font-regular-medium">
                    Responsive web App design
                  </p>
                  <Link to="/contact-us">
                    {" "}
                    <button className="img__description h-10 bg-white text-[#3066EA] font-regular-medium py- px-4 rounded-4 opacity-[100%]">
                      Get a quote
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
