import React from "react";
import Banner from "../../../../components/Banner";
// import WhyChoose from "../../../../components/WhyChoose";
// import ImplementationPorcess from "../Magento/ImplementationPorcess";
import OurDevelopers from "../Magento/OurDevelopers";
// import Overview from "../Magento/Overview";
import WhatWeOffer from "../Magento/WhatWeOffer";
import BannerImage from "../../../../Assets/Images/technologies/platforms/power-bi/power-bi-banner.jpg";
// import OverviewImage from "../../../../Assets/Images/technologies/platforms/power-bi/Powerbi-overview.jpg";
// import OverviewImageMobile from "../../../../Assets/Images/technologies/platforms/power-bi/Powerbi-overview-mobile.jpg";
import OfferingImage from "../../../../Assets/Images/technologies/platforms/power-bi/what-we-offering-for-Power-bi.jpg";
// import WhyChooseImage from "../../../../Assets/Images/technologies/platforms/power-bi/why-choose-power-bi.jpg";
// import WhyChooseMobile from "../../../../Assets/Images/technologies/platforms/power-bi/why-choose-service-now-mobile.jpg";
// import Overview from "../../../CloudAppliationIntegration/Overview";
import Infographics from "../MicrosoftDynamic365/Infographics";
import ConsultationIcon from "../../../../Assets/Images/technologies/platforms/power-bi/icons/consultation.svg";
import ImplementIcon from "../../../../Assets/Images/technologies/platforms/power-bi/icons/implement.svg";
import ManagementIcon from "../../../../Assets/Images/technologies/platforms/power-bi/icons/management.svg";
import SupportIcon from "../../../../Assets/Images/technologies/platforms/power-bi/icons/support.svg";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import { phpDevelopers } from "../../../../data/programming";
import FooterBanner from "../../../../components/Footer/FooterBanner";
import WhyChooseWhiteBackground from "../../../../components/WhyChooseWhiteBackground";

const weOffer = [
  {
    id: 1,
    img: ConsultationIcon,
    title: "Power BI consulting ",
    desc: "To provide unique and successful solutions, our Power BI professionals will assist you with business audits requirement, goals studies, Power BI implementation scope, planning, and strategy formulation. ",
  },
  {
    id: 2,
    img: ImplementIcon,
    title: "Power BI implementation",
    desc: "Our Power BI developers begin by analyzing and authenticating data before delivering a full-scale enterprise-class business intelligence solution. We quickly set up and deploy Power BI based on the results.",
  },
];
const weOffer2 = [
  {
    id: 1,
    img: ManagementIcon,
    title: "Custom app development",
    desc: "We assist you in integrating Power BI with your web and mobile app, which will improve its analytics, dashboards, reporting, and reporting capabilities while also allowing you to benefit from its exceptional business insights. ",
  },
  {
    id: 2,
    img: SupportIcon,
    title: "Power BI advanced analytics ",
    desc: "Professional Power BI users use machine learning capabilities with advanced analytics. With only dragging, dropping, and joining data modules. Our data science professionals can help you see machine learning algorithm results.",
  },
];
const Data3 = [
  {
    id: 1,
    title: "Highly Skilled In-House Team",
  },
  {
    id: 2,
    title: "Integrity and Transparency",
  },
  {
    id: 3,
    title: "Comprehensive quality assurance",
  },
  {
    id: 4,
    title: "360-degree Requirement Analysis",
  },
  {
    id: 5,
    title: "Cost effective engagement model",
  },
];
const weOffer3 = [
  {
    id: 1,
    img: "/images/services/power-bi/speedometer.svg",
    title: "Self-service dashboards ",
    desc: "Our Power BI solutions include intuitive and comprehensive self-service dashboards that deliver business information quickly while also allowing them to be dynamically modified.",
  },
  {
    id: 2,
    img: "/images/services/power-bi/chip.svg",
    title: "Power BI embedded",
    desc: "Get amazing graphics, dashboards, and reports across all your existing apps or websites with Power BI embedded services. Business people can make quick and informed judgments because of its easy data navigation.",
  },
];
const Why = [
  {
    id: "1",
    title: "Technical expertise ",
    desc: "Leverage deep Power BI capabilities in building and deploying highly innovative, scalable business solutions to derive insights.",
  },
  {
    id: "2",
    title: "Full-cycle services",
    desc: "Our Power BI professionals can help you with anything from consultation to design and deployment. ",
  },
  {
    id: "3",
    title: "Dedicated team",
    desc: "We give organizations complete control over deliverables and timeframes to unlock the potential of a specialized Power BI team.",
  },
  {
    id: "4",
    title: "Security & confidentiality",
    desc: "We sign a non-disclosure agreement with the customer since protecting enterprise identity and safeguarding confidentiality are top concerns.",
  },
  {
    id: "5",
    title: "Flexible time preference",
    desc: "We enable businesses to contact us conveniently according to their time zones, from managing project deadlines to analyzing work-in-progress.",
  },
  {
    id: "6",
    title: "Free consultation",
    desc: "Businesses can hire Power BI consultants to help them create results-oriented solutions that are tailored to the needs of their users.",
  },
];
export default function PowerBi() {
  return (
    <>
      <Banner
        button="Hire Power Bi Developer"
        img={BannerImage}
        title="Microsoft power BI"
        desc="Bringing your data to life through the adoption of enhanced data visualisation tools."
      />
      <Overview
        textColor="text-[#222222] bg-[#f9f9f9]"
        title="Power BI – Power business intelligence"
        desc="Microsoft Power BI is a collection of software services, apps, and connectors used to transform data into a form of immersive live dashboards and easy-to-digest reports, and interactive insights. Power BI offers extensive modeling, real-time analytics, and custom visualizations and can help your organization find clarity when you need it most to empower team members to discover insights hidden in your data. It can also help you create a data-driven culture with business intelligence for all, enabling everyone at every level of your organization to make confident decisions using up-to-the-minute analytics. Our team of dedicated experts can help you bridge the gap between your raw data and the transformation into better and improved visual reports even if your clients are non-technical business users."
        img="/images/technologies/platforms/powerbi/Powerbi-overview.jpg"
        img2="/images/technologies/platforms/powerbi/Powerbi-overview-mobile.jpg"
        alt="microsoft power bi"
      />
      {/* <Overview
        color="#222222"
        textColor="text-[#222222]"
        title="Overview"
        desc="Microsoft Power BI is a collection of software services, apps, and connectors used to transform data into a form of immersive live dashboards and easy-to-digest reports and interactive insights. Power BI offers extensive modelling, real-time analytics, and custom visualisations and can help your organisation find clarity when you need it most to Empower team members to discover insights hidden in your data. It can also help you create a data-driven culture with business intelligence for all enabling everyone at every level of your organisation make confident decisions using up-to-the-minute analytics."
        desc2="At innorik, we can help you bridge the gap between your raw data and the transformation of such into  better and improved visual reports even if our clients are non-technical business users."
        img={OverviewImage}
        img2={OverviewImageMobile}
      /> */}
      <WhatWeOffer
        show="true"
        img={OfferingImage}
        title="Our Power BI services"
        title2=""
        data={weOffer}
        data2={weOffer2}
        data3={weOffer3}
      />
      <Infographics />
      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2Fphp%2FphpHero.jpg?alt=media&token=67832652-836b-4594-ace0-22fe0e18cc6a"
        title="Our Power BI Experts"
        desc2="Our Team consist of highly motivated and committed engineers with over 20+ years’ in the IT business and over 10+ years practical and professional experience in the development of visualisation and reporting tools like Power BI"
        desc="We have Certified Power Bi engineers that can assist our clients in understanding the need of utilising unified enterprise solutions like Power BI and also help you customise and implement such, based on your organisational needs."
        button="Hire Power BI Expert"
      /> */}
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our Expertise"
        desc="We have certified Power Bi professionals that can assist our clients in understanding the need of utilizing unified enterprise solutions like Power BI and also help you customize and implement such, based on your organizational needs. "
        button="Hire PowerBI Expert"
        data={phpDevelopers}
      />
      {/* <WhyChoose
        textColor="text-white"
        desc="Extensive experience with the IoT technology to develop comprehensive IoT solutions. "
        head="Why choose Innorik for your PowerBI solutions?"
        data={Data3}
        img={WhyChooseImage}
        img2={WhyChooseMobile}
      /> */}
      <WhyChooseWhiteBackground data={Why} />

      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
