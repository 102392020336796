import React from "react";
import Banner from "../../../../components/Banner";
import Industries from "../../../../components/NewIndustries";
import { pythonDevelopers } from "../../../../data/programming";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import OurDevelopers from "../../Platforms/Magento/OurDevelopers";
import Offerings from "../Php/Offerings";
// import Overview from "../Php/Overview";
import WhyChooseUs from "../Php/WhyChooseUs";
// import Services from "./Services";
import WhyPython from "./WhyPython";
const Data2 = [
  {
    id: 1,
    title: "Highly skilled in-house development team ",
  },
  {
    id: 2,
    title: "Integrity and transparency ",
  },
  {
    id: 3,
    title: "Innovation is guaranteed ",
  },
  {
    id: 4,
    title: "Quality and security Adherence  ",
  },
  {
    id: 5,
    title: "Cost-effective engagement model ",
  },
  {
    id: 6,
    title: "Comprehensive quality assurance ",
  },
  {
    id: 8,
    title: "Maximum Speed and flexibility ",
  },
  {
    id: 9,
    title: "360-degree Requirement Analysis ",
  },
  {
    id: 10,
    title: "Reduced cost to 50% of our competitors ",
  },
  {
    id: 11,
    title: "A pool of smart and experienced developers ",
  },
];

const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Flive.svg?alt=media&token=00a3987c-181e-483a-807b-1b9fd7337cd2",
    title: "Python Streaming : ",
    desc: "We build custom Python programs for static, dynamic, and integrated online solutions, leveraging our core Python and open-source technical skills.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Fworld-wide-web.svg?alt=media&token=f2931bbc-7016-4d0f-ae99-b2231dbddd38",
    title: "Dynamic Website Development",
    desc: "We build and execute complex codes for optimized web applications using frameworks like Django and CherryPy",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Fgraphic-design.svg?alt=media&token=56765940-8a84-47d7-bf2b-35e673cfe30a",
    title: "Python GUI Programming",
    desc: "Our certified team of Python developers, expert designers, and testers are adept at Python GUI programming best practices. We use our design and development skills to create real-time, one-of-a-kind solutions.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Foffice-building.svg?alt=media&token=3064f91d-af34-49b2-9be1-2af28b6a64a4",
    title: "Enterprise Oriented Solutions",
    desc: "Get access to enterprise-oriented Python mobile and web app solutions that are highly scalable and perform based on the requirements and demands of your company.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Fmachine-learning.svg?alt=media&token=f8709bce-cb33-4b8d-93b1-8106c81c1cad",
    title: "Python Machine Learning",
    desc: "We implement machine-learning methods for predictive engines to enhance data analysis",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Fcustom-software.svg?alt=media&token=2cfedb27-1986-4496-ab8a-7b7cc1add52b",
    title: "Custom Python Development",
    desc: "Based on a set of shared needs or requirement, we create entirely customized Python applications which are secured, feature-packed and mobile-friendly",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Fcms.svg?alt=media&token=dfc00cd4-0591-4ca3-9627-dbfe18996632",
    title: "Python CMS Development",
    desc: "We employ highly-advanced technologies to produce a scalable content management system leveraging Django framework",
  },
  {
    id: 8,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Fhybrid.svg?alt=media&token=ac1ec59b-bbaa-453e-8204-072519bd87e4",
    title: "Python Hybrid Programming",
    desc: "We can add Java, C/C++, or C# modules to the Python landscape, and we can turn Python scripts into Java, C++/C, or C# code for unique customizations.",
  },
  {
    id: 9,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Fintegrated-system.svg?alt=media&token=1b22c642-f7ba-4240-832b-bbf458024a23",
    title: "Python Integration Services ",
    desc: "We are capable of providing Python-Perl, Python-ASP.NET, Python-PHP, Java-Python/Django, Python/Active Directory integrations as per the project requirement",
  },
  {
    id: 10,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Fcustomer-service.svg?alt=media&token=6dfb42f1-96bb-4acd-819a-4d03f0ae8c3d",
    title: "Python Support & Maintenance - ",
    desc: "With the help of our certified experienced developers, you can revamp your existing Python framework.",
  },
  {
    id: 11,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Ficons%2Fupgrade.svg?alt=media&token=68075bb6-9062-442b-9bca-77e165554afc",
    title: "Python Upgradation & Migration -",
    desc: "We have experts who can help you migrate from other platforms to Python or upgrade to the most recent version of Python.",
  },
];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];
const whylang = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fcode.svg?alt=media&token=21f82e42-2f30-47ee-a7f5-b2eae3475f00",
    desc: "Asynchronous coding",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fskill-development.svg?alt=media&token=65c22188-cdb8-4485-b3b7-f279a2fdb3cf",
    desc: "Less-limited programming approach",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fserver.svg?alt=media&token=a97abdac-09cf-478d-b55a-d7c821cef519",
    desc: "Server-side scripting",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fdecision-making.svg?alt=media&token=3a8c6926-baa5-4064-8fa4-a88eeb75ab8c",
    desc: "Enterprise application Integration",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Feasy-to-use.svg?alt=media&token=d321ede1-e5aa-44e0-94d6-320a5a709a89",
    desc: "Easy to use and read",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FGoLang%2FICONS%2Fopen-source.svg?alt=media&token=afba85c6-0845-413f-83a4-8d0d90194342",
    desc: "Open-Source community",
  },
];
export default function Python() {
  return (
    <>
      <Banner
        button="Talk to Experts"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Fpython-developer.jpeg?alt=media&token=82e604af-f489-4f17-9d93-f5a3e9a3d2a6"
        title="Python Development Services"
        desc="Reimagine your business with our best-in-class web and mobile solutions from our experienced developers delivering top-of-the-line python web development solutions."
      />
      <Overview
        textColor="bg-[#f9f9f9] text-[#222222]"
        title="Python development solutions"
        desc="Python is one of the most flexible and resilient of the many computer languages available. Python is popular among developers because it is open-source, platform-independent, object-oriented, and has a straightforward syntax. At Innorik, we build a highly safe and robust backend for real-time computation and scraping services for many web applications, enterprise portals, and e-commerce applications using Python's extensive libraries, superb features, and frameworks."
        img="/images/technologies/programming/python/python-overview-white.jpeg"
        img2="/images/technologies/programming/python/python-overview-mobile-white.jpeg"
        alt="python"
      />
      {/* <Overview
        color=""
        textColor="text-[#222222]"
        title="Python Programming Language"
        desc="When it comes to coding, Python is one of the most flexible and resilient of the many computer languages available. Python is popular among developers because it is open-source, platform-independent, object-oriented, and has a straightforward syntax. Python's syntax is easy to understand, so programmers just have to write a few lines of code and save a lot of time. Most web development companies prefer Python because of its unique and practical characteristic. Python is used to create a wide range of software programs, from scientific computing to business-related systems."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Fpython-overview.jpeg?alt=media&token=fec75416-3bc4-4d03-b264-c656816b9977"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPython%2Fpython-overview.jpeg?alt=media&token=fec75416-3bc4-4d03-b264-c656816b9977"
      /> */}
      <WhyPython title="Why we recommend python" data={whylang} />
      <Offerings
        title="Our Python Development Services"
        desc="For your Python development projects, we provide a comprehensive range of software development services, as well as technology resources and knowledgeable consultation."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FIllustrations%2FPHP-core-advantages-of-PHP.svg?alt=media&token=48db37fb-8f52-411e-aad5-6291faff0cf5"
        data={servicesData}
      />
      <OurDevelopers
        banner="true"
        devs="false"
        profile="true"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our Python Experts"
        desc="Innorik has a large team of python developers who have extensive experience with the language due to working with various project domains such as banking, telecommunication, healthcare, insurance systems, and with different companies. Our developers have experience ranging from 1-20 years."
        button="Hire a Python Expert"
        data={pythonDevelopers}
      />
      <WhyChooseUs
        head="Why Integrate with cloud? "
        title="Why choose us?"
        desc=""
        data={Data2}
      />
      <Industries
        title="Our Customers"
        desc="We are international company and we deliver technology solutions to clients globally. "
        data={Indust}
      />
    </>
  );
}
