import React from "react";
import Banner from "../../components/Banner";
import WhyChoose from "../../components/WhyChoose";
// import Overview from "./Overview";
import EagleView from "./EagleView";
import Infographics from "./Infographics";
import OurQA from "./OurQA";
// import BorderButton from "../../components/Buttons/BorderButton";
import Overview from "../Industries/Retail/Overview";
import FooterBanner from "../../components/Footer/FooterBanner";
const Data = [
  {
    id: 1,
    title: "No matter your budget, we are ready to work with you.",
  },
  {
    id: 2,
    title: "We use latest technologies and best tools for testing",
  },
  {
    id: 3,
    title: "Large pool of experienced tech talents",
  },
  {
    id: 4,
    title:
      "We have all the skills and the domain expertise to get your job done",
  },
  {
    id: 5,
    title: "We personalize and deliver extraordinary customer service",
  },
  {
    id: 6,
    title: "We increase customers engagement in the project migration process",
  },
];
export default function Testing() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2Ftesting%2Fit-consulting.jpeg?alt=media&token=d7e92765-2937-403a-ba9a-f4f08e84e68e"
        title="Software Quality Engineering "
        desc=""
      />
      <Overview
        color="bg-[#f9f9f9]"
        title="Overview"
        desc="Reimagine your business with our top-quality assurance services. Our quality engineers include quality engineering methods and processes right from the system conception, planning, development, and implementation. They produce systems that exceeds our customers’ expectations."
      />
      {/* <Overview
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Testing%2FTestingOverview.png?alt=media&token=8aa02f63-ea57-42c3-b396-ee6bf14234e2"
        title=""
        desc="Reimagine your business with our top-quality assurance services. Our quality engineers include quality engineering methods and processes right from the system conception, planning, development, and implementation and they produce systems that exceeds our customers’ expectations.
"
      /> */}
      <EagleView />
      <OurQA title="Our QA and Software Testing Services." />
      <Infographics
        title="Test Automation Life Circle"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2Ftesting%2FTest%20Automation%20Life%20Circle.svg?alt=media&token=75ce9cfa-4ed5-4f1c-886b-6c1e1a7a4150"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2Ftesting%2FTest%20Automation%20Life%20Circle%20mobile.svg?alt=media&token=55670376-d466-43d8-8f0b-08bc82220ba4"
      />

      <WhyChoose
        textColor="text-white"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2Ftesting%2Fwhy-choose-us-testing.jpg?alt=media&token=444e9b38-48e7-4a83-a580-ccc9394eb827"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2Ftesting%2FWhy%20Choose%20Us_%20testing%20mobile.jpg?alt=media&token=d4ce44fb-b91d-4e4f-96de-95d258167a65"
        head="Why choose us?"
        data={Data}
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
