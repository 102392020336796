import React from "react";

export default function ItNeeds() {
  return (
    <>
      <div className="max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px] ">
        <h1 className="mt-[4%] font-semibold text-xl text-center  ms:text-2xl ms:mb-6 md:text-3xl md:mb-9 lg:text-4xl ">
          A caring eye for all your IT needs
        </h1>

        <div className="pb-[4%] grid grid-cols-1 lg:grid-cols-3 mx-auto  gap-6 sm:gap-8 md:gap-14 lg:gap-5 xl:gap-10 2xl:gap-14 text-white">
          <div
            style={{
              backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FbusinessProcessoutsourcing%2FApplication%20services%20outsourcing-1.jpg?alt=media&token=976dce8e-f17a-4ed2-a7fc-4df42e632fc7")`,
            }}
            className="img1 mx-auto bg-center bg-no-repeat bg-cover h-[450px] w-full"
          >
            <div className="flex flex-col justify-start relative h-full bg-black/40">
              <div className="mx-5 my-auto sm:mx-auto md:m-auto lg:mt-[100px] lg:ml-[60px] lg:mr-4">
                <div className="font-medium text-[18px] md:text-[22px] lg:text-[24px] pb-4">
                  Application services outsourcing
                </div>
                <ul className="text-lg md:text-xl mx-5 lg:text-lg">
                  <li className="list-outside list-disc">
                    Software development
                  </li>
                  <li className="list-outside list-disc">UI/UX design</li>
                  <li className="list-outside list-disc">
                    Cloud application integration
                  </li>
                  <li className="list-outside list-disc">
                    Application modernization
                  </li>
                  <li className="list-outside list-disc">
                    Application migration
                  </li>
                  <li className="list-outside list-disc">Cloud migration</li>
                </ul>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FbusinessProcessoutsourcing%2FInfrastructure%20services%20outsourcing-1.jpg?alt=media&token=73608e70-2db5-4790-bfd9-5ae54b1635d3")`,
            }}
            className="img2 mx-auto bg-center bg-no-repeat bg-cover h-[450px] w-full"
          >
            <div className="flex flex-col justify-start relative h-full bg-black/40">
              <div className="mx-5 my-auto sm:mx-auto md:m-auto lg:mt-[100px] lg:ml-[60px] lg:mr-4">
                <div className="font-medium text-[18px] md:text-[22px] lg:text-[24px] pb-4">
                  Infrastructure services outsourcing
                </div>
                <ul className="text-lg mx-5 md:text-xl lg:text-lg">
                  <li className="list-outside list-disc">
                    IT services management
                  </li>
                  <li className="list-outside list-disc">
                    IT security management{" "}
                  </li>
                  <li className="list-outside list-disc">
                    Remote infrastructure management
                  </li>
                  <li className="list-outside list-disc">
                    Cloud infrastructure management
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FbusinessProcessoutsourcing%2FQuality%20testing%20services%20outsourcing-1.jpg?alt=media&token=99002e80-5499-4c60-960a-e5d74dd713d1")`,
            }}
            className="img3 mx-auto bg-center bg-no-repeat bg-cover h-[450px] w-full"
          >
            <div className="flex flex-col justify-start relative h-full bg-black/40">
              <div className="mx-5 my-auto sm:mx-auto md:m-auto lg:mt-[100px] lg:ml-[50px] lg:mr-4">
                <div className="font-medium text-[18px] md:text-[22px] lg:text-[24px] pb-4">
                  Quality testing services outsourcing
                </div>
                <ul className="mx-5 text-lg md:text-xl lg:text-lg">
                  <li className="list-outside list-disc">
                    Manual & Automation testing
                  </li>
                  <li className="list-outside list-disc">
                    Functional & Non-functional testing
                  </li>
                  <li className="list-outside list-disc">
                    Load & Stress testing
                  </li>
                  <li className="list-outside list-disc">Security Testing</li>
                  <li className="list-outside list-disc">API Testing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
