import React from "react";
import Banner from "../../components/Banner";
import Location from "../Home/Location";
// import ContactForm from "./ContactForm";
import Form from "./Form";

export default function ContactUs() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Contact%2Fcontact-us.jpeg?alt=media&token=7f0a06cd-6c3a-4695-9418-9097fdcb306f"
        title="Contact Us"
      />

      {/* <ContactForm /> */}
      <Form />
      <div className="pt-[25px] md:pt-[40px] lg:pt-[100px]">
        <Location />
      </div>
    </>
  );
}
