import React from "react";
import Banner from "../../../../components/Banner";
import WhyChoose from "../../../../components/WhyChoose";
// import Overview from "../../LatestThinking/BigData/Overview";
// import ImplementationPorcess from "../Magento/ImplementationPorcess";
import WhatWeOffer from "../Magento/WhatWeOffer";
import OurDevelopers from "../Magento/OurDevelopers";
// import BannerImage from "../../../../Assets/Images/technologies/platforms/services-now/service-now-banner.jpg";
import OfferingImage from "../../../../Assets/Images/technologies/platforms/services-now/Servicenow-What-we-offering.jpg";
import ConsultationIcon from "../../../../Assets/Images/technologies/platforms/services-now/icons/consultation.svg";
import ImplementIcon from "../../../../Assets/Images/technologies/platforms/services-now/icons/implement.svg";
import SupportIcon from "../../../../Assets/Images/technologies/platforms/services-now/icons/support.svg";
import TestingIcon from "../../../../Assets/Images/technologies/platforms/services-now/icons/testing.svg";
import Infographics from "../MicrosoftDynamic365/Infographics";
import WhyImage from "../../../../Assets/Images/technologies/platforms/services-now/why-choose-service-now-mobile.jpg";
import WhyImageMobile from "../../../../Assets/Images/technologies/platforms/services-now/why-choose-service-now.jpg";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import FooterBanner from "../../../../components/Footer/FooterBanner";
import { phpDevelopers } from "../../../../data/programming";

const weOffer = [
  {
    id: 1,
    img: ConsultationIcon,
    title: "ServiceNow consulting",
    desc: "We can aid your organization gain insights into opportunities by advising you on how best you can employ ServiceNow to increase your productivity and the best ways to tackle your challenges, be it ServiceNow functionality issues or enterprise scalability. ",
  },
  {
    id: 2,
    img: ImplementIcon,
    title: "ServiceNow implementation",
    desc: "Our team of experts provides ServiceNow implementation services that cover platform implementation from scratch, upgrading your existing platform functionality, and also integration & migration of your projects to ServiceNow. ",
  },
];
const weOffer2 = [
  {
    id: 1,
    img: TestingIcon,
    title: "ServiceNow Testing",
    desc: "We have a certified team of test engineers who make the QA process robust. They thoroughly check ServiceNow functionality, integrations, performance, security, usability, and data quality to help you make your solution fully comply with your requirements.",
  },
  {
    id: 2,
    img: SupportIcon,
    title: "Monitoring & support",
    desc: "Business reports, dashboards, and performance analytics are used to track performance with post-implementation assistance to address potential issues.",
  },
];
const Data3 = [
  {
    id: 1,
    title: "Strategic approach",
  },
  {
    id: 2,
    title: "Industry and domain expertise",
  },
  {
    id: 3,
    title: "Strong client relationships ",
  },
  // {
  //   id: 4,
  //   title: "360-degree turntable ",
  // },
  {
    id: 5,
    title: "Reduced cost to 50% of our competitors ",
  },
  {
    id: 6,
    title: "A pool of smart and experienced developers ",
  },
];
const weOffer3 = [
  {
    id: 1,
    img: "/images/services/service-now/icons/supplier.svg",
    title: "Industry solutions",
    desc: "With solutions and services for industries including telecommunications, financial services, and government, we help clients achieve their business needs.",
  },
  {
    id: 2,
    img: "/images/services/service-now/icons/applications.svg",
    title: "Platform application development ",
    desc: "Our team of professionals assist clients in maximizing the value of ServiceNow by creating custom enterprise processes and connectors to match their specific business requirements. ",
  },
];
export default function ServicesNow() {
  return (
    <>
      <Banner
        img="/images/technologies/platforms/servicenow/servicenow-banner.jpeg"
        title="SERVICES NOW"
        desc="Revolutionize workflows with ServiceNow!"
      />
      <Overview
        textColor="text-[#222222] bg-[#f9f9f9]"
        title="Transition to the ServiceNow platform "
        desc="ServiceNow is a software-as-a-service platform for technological performance management and faultless process control systems (SaaS). With the help of this software, we can easily manage projects, staff, and customer experiences using a variety of apps and plugins. Users can perform a variety of actions from within the application. The ServiceNow product line has a service model that focuses on assisting clients in identifying the root cause of their problems and resolving self-service issues. The service model evolves as functions, activities, and processes from ServiceNow devices, segregated by cloud computing."
        img="/images/technologies/platforms/servicenow/servicenow-overview.jpeg"
        img2="/images/technologies/platforms/servicenow/servicenow-overview-mobile.jpeg"
        alt="Microsoft Dy"
      />
      {/* <Overview
        title="Overview"
        desc2="With ServiceNow, greater height could be achieved because you can address your customers needs quickly, proactively, and consistently so to deliver great experiences for your them, you can give your employees more time to focus on the work they want to do by providing them unified experiences that drive engagement and productivity, with workflow automation, you can build apps, break down barriers, and create connected experiences, and also Strengthen and extend common services across your organisation with more efficiency and less or no risk."
        desc="ServiceNow is a robust cloud-based system for IT Service Management (ITSM) to give you the ability to request all your work related services yourself anytime any day and gives business owner the ability to fulfil your requests more efficiently. This means ServiceNow provides service-orientation for tasks, activities, and processes leveraging data and workflows to help businesses become faster and scalable. ServiceNow can be implemented by companies/organisations from almost all the fields that provide service and support like the automobile industry, IT industry, medication, insurance, media etc."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2Fplatforms%2Fmagento%2Foverview.jpg?alt=media&token=95f2b124-66a4-412a-ac6f-67141f2518fb"
      /> */}
      <WhatWeOffer
        show="true"
        img={OfferingImage}
        title="Our ServiceNow offerings "
        title2=""
        data={weOffer}
        data2={weOffer2}
        data3={weOffer3}
      />
      <Infographics />
      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2Fphp%2FphpHero.jpg?alt=media&token=67832652-836b-4594-ace0-22fe0e18cc6a"
        title="Our Services Now Experts"
        desc2="We are ServiceNow Partners and an official ServiceNow Reseller. Our Team also consist of highly motivated and committed engineers with over 20+ years’ in the IT business and over 10+ years practical and professional experience in ServiceNow and can offers services to cover all your platform-related needs."
        desc="We have Certified ServiceNow engineers that can assist our clients in understanding the need of utilising unified enterprise solutions like ServiceNow and also help you customise and implement such, based on your organisational needs."
        button="Hire Service Now Expert"
      /> */}
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our Expertise"
        desc="We have certified ServiceNow professionals that can assist our clients in understanding the need of utilizing unified enterprise solutions like ServiceNow and also help you customize and implement such, based on your organizational needs. "
        button="Hire ServiceNow Professional"
        data={phpDevelopers}
      />
      <WhyChoose
        textColor="text-white"
        desc=""
        head="Why choose Innorik for your ServiceNow solutions?"
        data={Data3}
        img={WhyImageMobile}
        img2={WhyImage}
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
