import React from "react";
import Banner from "../../components/Banner";
import ApplyForJobForm from "./apply";
// import ApplyForm from "./apply-form";

export default function ApplyForJob() {
  return (
    <>
      <Banner
        img="/images/company/career/apply.jpeg"
        title="Apply Today and join our Team "
      />
      <div className="pb-[100px]">
        <ApplyForJobForm />
        {/* <ApplyForm /> */}
      </div>
      <div
        className=" bg- bg-no-repeat top-0 relative flex flex-col  bg-cover bg-center h-[350px] md:h-[300px]"
        style={{
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Career%2Fcareer-engineers-team.jpeg?alt=media&token=2c4e04b5-5de3-4224-b3f2-d5bb0a4f0c69")`,
        }}
      >
        <div className="bg-black/20 h-full">
          <div className=" max-w-[1000px] h-full px-[28px] md:px-[40px] lg:px-[85px] py-[20px] md:py-[30px] lg:py-[70px] text-center mx-auto">
            {/* <h1 className="text-[24px] md:text-[28px] lg:text-[36px]">
              {props.title}
            </h1> */}
            <div className="  md:py-4 lg:h-full m-auto">
              <div className="bg-white p-[20px]">
                <p className=" text-[16px] md:text-[18px]  text-[#222222]/80 lg:text-[18px]  lg:pt-[25px]">
                  Our people’s ideology aims at building a strong development
                  team of committed and motivated software professionals. Do you
                  have the skills to be part of the Innorik family? Do you have
                  the desire to build top-notch applications? Then apply to join
                  the family.
                </p>
                <p className="text-[16px] md:text-[18px]  text-[#5F8DFF]/90 lg:text-[18px] p-[0px] ">
                  career@innorik.com
                </p>
              </div>
            </div>

            <div className="flex justify-center">
              {/* <BorderButton button={props.button} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
