import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

export default function PartnershpScroll(props) {
  const newData = props.data;

  const renderSlides = () =>
    newData.map((num) => (
      <div className="m-auto py-[28px] px-[14px] ">
        <img
          className="m-auto my-auto h-[70px] w-auto"
          src={num.img}
          alt="parthership with microsoft"
        />
      </div>
    ));
  const renderSlides2 = () =>
    newData.map((num) => (
      <div className="m-auto py-[28px] px-[24px] ">
        <img
          className="m-auto my-auto h-[50px] w-auto"
          src={num.img}
          alt="parthership with microsoft"
        />
      </div>
    ));

  return (
    <>
      <div className="invisible md:visible lg:visible h-0 md:h-auto lg:h-auto max-w-[1400px] px-[28px] md:px-[40px] lg:px-[35px] mx-auto lg:py-[40px]">
        <Slider
          infinite={true}
          autoplay={true}
          slidesToShow={6}
          autoplaySpeed={2000}
          speed={500}
          dots={false}
        >
          {renderSlides()}
        </Slider>
      </div>
      <div className="visible md:invisible lg:invisible md:h-0 lg:h-0 px-[28px] mx-auto">
        <Slider
          infinite={true}
          autoplay={true}
          slidesToShow={3}
          autoplaySpeed={2000}
          speed={500}
          dots={false}
        >
          {renderSlides2()}
        </Slider>
      </div>
    </>
  );
}
