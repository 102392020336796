import React from "react";
import Banner from "../../components/Banner";
import WhyChooseUs from "../About/WhyChooseUs";
import Agile from "./Agile";
import DevopsApproach from "./DevopsApproach";
import Overview from "./Overview";
import DevelopmentImage from "../../Assets/Images/company/engagement-module/cloud-native-development.jpg";
import DevelopmentImageMobile from "../../Assets/Images/company/engagement-module/cloud-native-development-mobile.jpg";
import BannerImage from "../../Assets/Images/company/engagement-module/engagement-model-banner.jpg";
import Devops from "../../Assets/Images/company/engagement-module/DevOps.png";
import Software from "../../Assets/Images/company/engagement-module/modular-software-design.png";
import FooterBanner from "../../components/Footer/FooterBanner";
import AccordianSection from "./AccordianSection";
import Infographics from "./Infographics";

export default function EngagementModule() {
  return (
    <>
      <Banner
        img={BannerImage}
        title="Development Process"
        desc="Be in control of your software development project"
      />
      <Overview
        title="Overview Of Our Software Development Process"
        desc="At Innorik, our mission is to deliver high-quality software to our customers, so we tailor the software development process to your specific business needs to deliver the product on time and within budget."
        color="#3066EA"
      />
      <div className="max-w-[1500px] mx-auto">
        <h2 className="text-center font-semibold text-[24px] lg:text-[36px] md:text-[26px] pt-[35px] lg:pt-[50px] md:pt-[50px] sm:pt-[35px]">
          Our Development Approach
        </h2>
        <Agile />
        <DevopsApproach
          title="DevOps"
          desc="DevOps has been defined as “the bridge between development and operations” and a properly implemented DevOps architecture comes with many benefits including reduced operational cost, increased productivity and release time, customers are served better with rich feedback and more. As a team we have a strong DevOps culture, and we streamline software delivery and improve its quality with the following best practices."
          img={Devops}
        />
        <div className="px-[28px] md:px-[40px] lg:px-[85px] max-w-[1500px] text-[16px] lg:text-[18px]">
          <p className="pb-[40px]">
            <span className="font-medium">
              {" "}
              Infrastructure as Code (IaC):{"  "}
            </span>
            Infrastructure as code evolved to solve the problem of environment
            drift in the release pipeline. We automate the creation of new
            infrastructures (networks, virtual machines, load balancers and
            connection topology) and keep them consistent with each other to
            ensure smooth software deployment.
          </p>
          <p className="pb-[40px]">
            <span className="font-medium">
              {" "}
              Continuous Integration/Continuous Deployment (CI/CD): {"  "}
            </span>
            We automate the integration and deployment of code changes.
          </p>
          <p className="pb-[40px]">
            <span className="font-medium">Automated testing:{"  "}</span>
            Automating regression, performance, and other types of testing that
            are continuously carried out within the CI/CD pipeline.{" "}
          </p>
          <p className="pb-[40px]">
            <span className="font-medium">
              Application performance monitoring:{"  "}
            </span>
            Configuring application performance monitoring tools to detect
            post-release bugs quickly.
          </p>
        </div>
      </div>
      <WhyChooseUs
        head="Cloud-native development"
        data="Cloud-native is a modern approach to building and running software applications that exploits the flexibility, scalability, and resilience of cloud computing. Cloud-native encompasses various tools and techniques used by software developers today to build applications for the public cloud, as opposed to traditional architectures suited to an on-premises data center. We take advantage of reduced software development and operation costs, easy scalability and access to advanced cloud services that enable the implementation of cutting-edge techs (AI, data science, deep computing and more)."
        img={DevelopmentImage}
        img2={DevelopmentImageMobile}
      />

      <div className="max-w-[1500px] mx-auto">
        <DevopsApproach
          title="Modular Software Design"
          desc="This software design technique emphasizes on separating the functionality of a program into independent components such that each component contains everything necessary to execute independently. We use this design pattern such as microservices with containerization to develop applications as a suite of independent functional components that can be ported to any environment. This design technique ensures higher application reliability, better maintainability, and expandability, as well as shorter development time."
          img={Software}
        />
      </div>
      {/* <Overview
        title="Software Development Process"
        title2=""
        desc="  At Innorik, our mission is to deliver high-quality software to our
              customers, so we tailor the software development process to your
              specific business needs to deliver the product on time and within
              budget."
        color="#D9E3FF"
      /> */}
      <AccordianSection title="SOFTWARE DEVELOPMENT PROCESS STEPS " />
      <Infographics />
      <FooterBanner title="Let's discuss your project" link="/contact-us" />
    </>
  );
}
