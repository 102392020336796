import React from "react";

export default function HelpingHand() {
  return (
    <div className="bg-[#010101]">
      <div
        className="m-auto max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px] py-[15%] sm:py-[5%] md:py-[5%] lg:py-[5%] text-center lg:text-left bg-cover bg-right h-full sm:h-[] md:h-[] lg:h-full xl:h-[]"
        style={{
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2Fhelping-hand.jpeg?alt=media&token=ebbd7c95-2cd2-4faf-9f51-3769bafb9fa6")`,
        }}
      >
        <h1 className=" lg:w-1/2  text-[24px] lg:text-[36px] sm:text-[40px] md:text-[40px]  text-white ">
          Your cloud transformation journey is unique, let’s give you a helping
          hand
        </h1>
      </div>
    </div>
  );
}
