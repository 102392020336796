import React from "react";
import Accordian from "../../components/Accordian";
// import List from "../../components/Cards/List";

export default function AccordianSection(props) {
  return (
    // oh man this is nice
    <div className="bg-[#F9F9F9]">
      <h1 className=" lg:pt-[50px] pt-[25px]  px-[28px] md:px-[40px] lg:px-[85px] text-center text-[22px] md:text-[28px] lg:text-[36px]">
        {props.title}
      </h1>
      <div className="conatiner max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] mx-auto flex lg:flex-row  flex-col-reverse md:flex-col-reverse w-full py-[5%]">
        <div className="conatiner md:px-8 lg:w-1/2 xl:1/2 text-black flex flex-col   ">
          <Accordian />
        </div>
        <div className="mx-auto  px-[5%]  lg:pr-0 pb-[10px]">
          <div className="mx-auto  mr-0">
            <img
              className="m-auto lg:h-[480px] lg:w-[520px] lg:mr-0 md:h-[300px] sm:h-[300px]  bg-fixed bg-no-repeat sm:w-[100vw] md:w-[100vw]  sm:object-cover md:object-cover md:center object-top	 lg:pr-[0%] pt-2"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudConsulting%2FOur%20cloud%20consulting%20offerings%20.jpg?alt=media&token=634517b5-3c50-421e-8a64-248dda2e2dbb"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
