import React from "react";
import Banner from "../../components/Banner";
import FooterBanner from "../../components/Footer/FooterBanner";
// import Industries from "../../components/Industries";
import NewIndustries from "../../components/NewIndustries";

import WhyChoose from "../../components/WhyChoose";
import Overview from "../Technologies/LatestThinking/ArtificialIntellegence/Overview";
// import Overview from "../Technologies/Programming/Php/Overview";
import AccordianSection from "../WebDevelopment/AccordianSection";
// import Overview from "./Overview";
import MobileTab from "./Tabs";
const Data = [
  {
    id: 1,
    title: "User Friendly",
  },
  {
    id: 2,
    title: "Responsive Design",
  },
  {
    id: 3,
    title: "Optimized for speed",
  },
  {
    id: 4,
    title: "Open source",
  },
];
export const Data4 = [
  {
    question: "Android Mobile App",
    answer:
      "Our Android mobile developers can build high quality applications that run on any android device aligned with your business and security requirements. You can launch our custom mobile apps on the operating system that has the capability of reaching out to more than a billion mobile devices around the world.",
    answer2:
      "Examine your current applications and services, find the right mix of cloud solutions for your workloads, identify gaps in technology and skills and develop a holistic migrating strategy and plan for individual workloads or an entire data center.",
  },

  {
    question: "IOS Mobile App",
    answer:
      "At Innorik we understand, it’s not just about the app, it’s about building apps that are exceptionally innovative and can charm your end-users and provide them with an immersive experience. We have all the expertise to produce a fully-fledged, stable, and scalable IOS app for any company.",
    answer2:
      "Depending on where you are in your cloud journey, our cloud consultants can get you started on analytic processes. We design, build, and enhance existing analytic architecture",
  },
  {
    question: "React Native App",
    answer:
      "Our vibrant development team stays up to date with the latest advancements in technology by offering React Native app development for reliable performance across multiple platforms.",
    answer2:
      "Our cloud advisors provide services that will monitor your cloud environment of any security threats    ",
  },
  {
    question: "Flutter App",
    answer:
      "Our creative Flutter developers deliver visually appealing and fast performing apps with enhanced features to help your business maximize your returns.",
    answer2:
      "Our cloud consultants ensures that workload performance, compliance, and cost are correctly and continually balanced against the best-fit infrastructure in real time.",
  },
  {
    question: "Xamarin App",
    answer:
      "We use the Xamarin tool to build cross-platform flawless mobile apps with very superb speed and functionality that allows our engineers to share up to 90 percent of code across major platforms.",
    answer2:
      "Maximize agility and elevate ROI by integrating infrastructure and apps with best architecture.",
  },
  {
    question: "Hybrid App Development ",
    answer:
      " Our creative and experienced app developers build cross-platform apps that work in different environments using a unique blend of native and web app technologies. Our experts use Ionic to deliver a stunning mobile experience across iOS, Android, and the web.",
    answer2:
      "Leverage the benefit of microservices architecture with containerization, flexible scaling, and better deployment",
  },
];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];
export default function MobileAppDevelopment() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FMobileDevelopment%2Fmobile-development.jpeg?alt=media&token=b6adde95-7110-46bc-88ec-996fae527a78"
        title="Mobile App Development"
        desc=""
      />
      <Overview
        textColor="#222222  bg-[#E0E5EA] "
        img2="/images/services/mobile/mobile-overview.jpeg"
        img="/images/services/mobile/mobile-app-development-overview.jpeg"
        title="Mobile App Development"
        desc="In every aspect of our lives today, smartphones play an important role. In fact, smartphones and their applications occupy an irreplaceable place in every aspect of our lives. Innorik aims at unifying world-class designs with a stellar performance to create customized smartphone or mobile applications. Our top-class team of mobile developers can help craft a defined strategy that helps transform your ideas into a well-performing application. Irrespective of the complexity of your idea or problem, our dynamic and competent mobile development team can build a custom mobile application that delivers high quality solutions to your end-users."
      />
      <h2 className=" text-center lg:text-[32px] text-[20px] md:text-[28px] py-[50px]">
        Mobile app development services
      </h2>
      <div className="invisible h-0 lg:w-auto lg:h-auto lg:visible xl:visible 2xl:visible">
        <MobileTab />
      </div>
      <div className="visible lg:invisible lg:h-0 ">
        <AccordianSection data={Data4} />
      </div>

      <WhyChoose
        textColor="text-white"
        head="Empower your business with the best mobile applications "
        data={Data}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FMobileDevelopment%2Fempower-your-business.jpg?alt=media&token=cc87cd81-ab3f-43a3-94af-63c35b564398"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FMobileDevelopment%2FEmpower-your-business-mobile.jpeg?alt=media&token=45671241-bdb9-45fc-b19f-9edd500d2010"
      />
      <NewIndustries
        title="Be ahead and stay connected to the world of innovation."
        data={Indust}
      />
      <FooterBanner title="Let's discuss your project" link="/contact-us" />
    </>
  );
}
