import { IoIosArrowForward } from "react-icons/io";

export default function BorderButton(props) {
  return (
    <div className=" pt-[20px] ">
      <button className="text-[16px] lg:text-[18px] py-[8px] px-[12px] lg:py-[15px] md:py-[10px] lg:px-[30px] md:px-[15px] flex text-white border border-white">
        {props.button}
        <div className="lg:pl-[20px] md:pl-[10px] pl-[5px] m-auto">
          <IoIosArrowForward />
        </div>
      </button>
    </div>
  );
}
