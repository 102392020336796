import React from "react";
import Banner from "../../../../components/Banner";
// import WhyChoose from "../../../../components/WhyChoose";
// import Overview from "./Overview";
// import ImplementationPorcess from "./ImplementationPorcess";
import WhatWeOffer from "./WhatWeOffer";
import OurDevelopers from "./OurDevelopers";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import Infographics from "../MicrosoftDynamic365/Infographics";
import FooterBanner from "../../../../components/Footer/FooterBanner";
import { phpDevelopers } from "../../../../data/programming";
// import WhyChooseUsIndustries from "../../../../components/WhyChooseUsIndustries";
import WhyChooseWhiteBackground from "../../../../components/WhyChooseWhiteBackground";
const weOffer = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FPlatforms%2Ficons%2Fconsultation.svg?alt=media&token=d9b9fedc-2d0f-40e3-932d-77e0bcb28332",
    title: "Magento consulting services",
    desc: "For prospective projects or maintenance of existing solutions, our Magento professionals will be happy to work with you in every step of the way by acting as light in the darkness. Expect a resolution from us regardless of the level of complexity of your Magento query!",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FPlatforms%2Ficons%2Fworld-wide-web.svg?alt=media&token=f98834b3-29bc-4dfc-a83a-19e9a33bf465",
    title: "Magento migration Services",
    desc: "Thinking of converting your current eCommerce site to Magento? Our Magento professionals will ensure a smooth conversion with the most appropriate features integrated.",
  },
];
const weOffer2 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FPlatforms%2Ficons%2Fonline-shop.svg?alt=media&token=19656f45-e3c3-4a0a-b23d-9f80e0f18e21",
    title: "E-Commerce app development",
    desc: "We are experts at modifying eCommerce programs to meet your needs and implementing unrivaled customizations. We offer everything from payment integration to an interactive user interface.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FPlatforms%2Ficons%2Fcustomer-service.svg?alt=media&token=464bac6b-dea6-45c3-946c-729ad59f29fa",
    title: "Magento support & maintenance",
    desc: "Our Magento support team will monitor and optimize your e-commerce portal to ensure a positive user experience 24 / 7. We can fix even the tiniest bugs and provide new features to keep your business afloat all year long. ",
  },
];
const weOffer3 = [
  {
    id: 1,
    img: "/images/technologies/platforms/magento/icons/extension.svg",
    title: "Magento extension services",
    desc: "Stand out from your competitors by adding a touch of individuality to your business with the best-customized extensions for the online storefront. Our professionals, who have successfully deployed numerous extensions, have your back!",
  },
  {
    id: 2,
    img: "/images/technologies/platforms/magento/icons/upgrade.svg",
    title: "Magento upgradation & integration",
    desc: "Our Magento upgradation services can help obsolete web companies running Magento to migrate custom themes, extensions, and other data to Magento with precision. In order to increase the store's versatility, we also provide powerful integration services.",
  },
];
const Data3 = [
  {
    id: 1,
    title: "Highly Skilled In-House Team",
  },
  {
    id: 2,
    title: "Integrity and Transparency",
  },
  {
    id: 3,
    title: "Comprehensive quality assurance",
  },
  {
    id: 4,
    title: "360-degree Requirement Analysis",
  },
  {
    id: 5,
    title: "Cost effective engagement model",
  },
];
const Why = [
  {
    id: "1",
    title: "Scalable solutions",
    desc: "Our experts, construct Magento e-commerce websites for your business that are feature-rich and scalable, with fantastic options for managing and controlling many areas of your site. Scalability makes it simple to adjust your website to changing business needs.",
  },
  {
    id: "2",
    title: "Secure websites",
    desc: "To ensure that your website is highly secured, we use security extensions and other safety precautions. Our developers go to great lengths to ensure that your website is secure from all types of hacking and malware attacks.",
  },
  {
    id: "3",
    title: "Experience ",
    desc: "We have a wide range of expertise and a strong technological aptitude. We can take on even the most difficult Magento development tasks and deliver high-quality results.",
  },
  {
    id: "4",
    title: "Responsive ",
    desc: " We design Magento websites that are totally responsive and function well on any device, including mobile, desktop, laptop, and tablet computers. To design your website, our experts follow a variety of coding standards and employ best coding practices. ",
  },
  {
    id: "5",
    title: "Fast speed ",
    desc: "We recognize the importance of website loading speed for a better user experience as well as a higher search engine ranking. During the Magento website building process, our engineers follow W3C coding standards and undertake website optimization to ensure that your site loads faster. ",
  },
  {
    id: "6",
    title: "SEO Friendly",
    desc: "To produce standards-compliant sites, our Magento eCommerce development services adhere to standards such as SEO, accessibility standards, WCAG 2.0, and others. Our engineers take every precaution to ensure that your site is search engine friendly.",
  },
];
export default function Magento() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FPlatforms%2Fmagneto.jpeg?alt=media&token=e9d15fbd-8343-4d99-8dbe-409b4e70b4fe"
        title="Magento"
        desc="Brand your E-commerce platform in style."
        button="Hire Mangento Developer"
      />
      <Overview
        textColor="text-[#222222] bg-[#f9f9f9]"
        title="Overview"
        desc="Magento is an open-source e-commerce platform that is used by some of the most well-known retailers, including Pepsi, Levis, and Macy's. Utilize or migrate to the platform that offers prolific features, unrivaled customizations, and a host of themes and extensions. Our Innorik team of experts will work with you to create the most attractive websites with simple user interfaces that are tailored to your specific needs for an online store."
        img="/images/technologies/platforms/magento/magento-overview.jpeg"
        img2="/images/technologies/platforms/magento/magento-overview-mobile.jpeg"
        alt="blockchain development"
      />
      {/* <Overview
        color=""
        textColor="text-[#222222]"
        title="Overview"
        desc="At Innorik we offer affordable Magento development solutions to our clients. We can help you decide, fine-tune and develop your projects. Better still you can hire our developers for your projects. This way we say with confidence that we are available to help you build experiences, not just on the storefronts but actually help you confidently launch, manage, and scale your business effortlessly."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FPlatforms%2Fmagento-overview.jpeg?alt=media&token=dd9bef66-59ec-4d0e-86df-351a3e6a0f57"
      /> */}
      <WhatWeOffer
        show="true"
        title="Our cutting-edge   "
        title2="Magento development offerings."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FPlatforms%2Fwhat-we-offer-for-magneto.jpeg?alt=media&token=4a7accc5-9e4c-415f-8727-4e2440393fc7"
        data={weOffer}
        data2={weOffer2}
        data3={weOffer3}
      />
      <Infographics />
      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FJava%2Fjava-developer.jpeg?alt=media&token=f0b6d1d3-f046-4554-837e-e3899e3e5722"
        title="Our Magento Experts"
        desc2="We are abreast with the latest technologies available in the world of commerce development and we can help you achieve greater heights with less cost but the best solutions"
        desc="We have an extremely competent team which consist of highly motivated engineers with over 20+ years’ in the IT business and over 10+ years practical and professional experience in Commerce spectrum and can offers services to cover all your platform-related needs."
        button="Hire Mangento Developer"
      /> */}
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our Expertise"
        desc="We have an extremely competent team that consists of highly motivated and committed developers with over 20+ years in the IT industry and over 10+ years of practical and professional experience in the Commerce spectrum and can offers services to cover all your platform-related needs. "
        button="Hire a Magento Expert"
        data={phpDevelopers}
      />
      {/* <WhyChoose
        textColor="text-white"
        desc="Extensive experience with the IoT technology to develop comprehensive IoT solutions. "
        head="Why choose Innorik for your Magento solutions?"
        data={Data3}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FPlatforms%2Fmagento-why-choose-us-linear-background.jpeg?alt=media&token=4174ad7f-cfa8-4e5e-9e2b-3bc66895cd21"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FPlatforms%2Fteam-of-skilled-people.jpeg?alt=media&token=8526f2a9-d0c2-4b5b-affb-fefd94b0dd24"
      /> */}
      <WhyChooseWhiteBackground data={Why} />

      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
