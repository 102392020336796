import React from "react";

export default function ImageCardHeight(props) {
  // console.log(props.img);
  return (
    <>
      <div className=" relative h-[300px]">
        <img
          className=" w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url("${props.img}")` }}
          alt=""
          // src={props.img}
        />
        <div className="absolute h-full bottom-0 group transition duration-150 ease-in-out text-white text-center leading-snug w-full hover:bg-black/70">
          <div className="h-full  grid justify-items-center px-5 sm:px-8 md:px-10 lg:px-4 xl:px-10 2xl:px-12">
            <p className=" m-auto hidden group-hover:block mb-2 text-[16px] md:text-[18px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px]  font-regular">
              {props.title}
            </p>
            <p className=" text-[14px] md:text-[18px] lg:text-[18px] xl:text-18px  m-auto mt-2 md:text-lg xl:text-xl 2xl:text-2xl">
              {props.desc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
