import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
const partnersData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Ftechnologies%2Fphp.svg?alt=media&token=8e5dc93f-a2ca-4cba-8015-0d520020abaa",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Ftechnologies%2Fangular.svg?alt=media&token=adab9c50-d914-4557-a0d7-de01c3d1c5c2",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Ftechnologies%2Fember.svg?alt=media&token=1f1845b7-e6ce-41a3-a3bb-5ac895dee090",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Ftechnologies%2Fjava.svg?alt=media&token=37e72a21-c41d-4155-b0f4-0388a0a1d62e",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Ftechnologies%2Fnet.svg?alt=media&token=0ef0cd8f-c95c-4800-8ef8-3d2870312365",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Ftechnologies%2Fnextjs.svg?alt=media&token=ab0180fc-1805-42d3-a8d0-fdcf0601e974",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Ftechnologies%2Fnodejs.svg?alt=media&token=b3cb2a69-3678-4eaa-8c57-57b2a6ccfbe7",
  },
  {
    id: 8,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Ftechnologies%2Fpython.svg?alt=media&token=5dcd3f83-04c7-46d7-8fb7-995b086d9d14",
  },
  {
    id: 9,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Ftechnologies%2Freact.svg?alt=media&token=b6c548a2-9d73-47e5-a68d-d7112ee1f5d6",
  },
  {
    id: 10,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Ftechnologies%2Fvue.svg?alt=media&token=835a7ce5-be78-492b-ab51-5be4edb9fee5",
  },
];

export default function TechnologiesScroll() {
  const renderSlides = () =>
    partnersData.map((num) => (
      <div className="m-auto py-[28px] px-[14px] ">
        <img
          className="m-auto my-auto h-[70px] w-[70px]"
          src={num.img}
          alt="parthership with microsoft"
        />
      </div>
    ));
  const renderSlides2 = () =>
    partnersData.map((num) => (
      <div className="m-auto py-[28px] px-[24px] ">
        <img
          className="m-auto my-auto h-[50px] w-[50px]"
          src={num.img}
          alt="parthership with microsoft"
        />
      </div>
    ));

  return (
    <>
      <div className="invisible md:visible lg:visible h-0 md:h-auto lg:h-auto max-w-[1400px] px-[28px] md:px-[40px] lg:px-[35px] mx-auto lg:py-[40px]">
        <Slider
          infinite={true}
          autoplay={true}
          slidesToShow={6}
          autoplaySpeed={2000}
          speed={500}
          dots={false}
        >
          {renderSlides()}
        </Slider>
      </div>
      <div className="visible md:invisible lg:invisible md:h-0 lg:h-0 px-[28px] mx-auto">
        <Slider
          infinite={true}
          autoplay={true}
          slidesToShow={3}
          autoplaySpeed={2000}
          speed={500}
          dots={false}
        >
          {renderSlides2()}
        </Slider>
      </div>
    </>
  );
}
