import React from "react";
import Banner from "../../components/Banner";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import BannerImage from "../../Assets/Images/company/career/careers-banner.jpeg";
const jobs = [
  {
    id: 1,
    job: "Full Stack Java Developer",
    link: "/career/job/java",
    location: "USA",
  },
  {
    id: 1,
    job: "Full Stack Java Developer",
    link: "/career/job/java",
    location: "Ghana",
  },
  {
    id: 1,
    job: "Full Stack Java Developer",
    link: "/career/job/java",
    location: "India",
  },

  {
    id: 1,
    job: "Full Stack .Net Developer",
    link: "/career/job/dot-net",
    location: "USA",
  },
  {
    id: 1,
    job: "Full Stack .Net Developer",
    link: "/career/job/dot-net",
    location: "Ghana",
  },
  {
    id: 1,
    job: "Full Stack .Net Developer",
    link: "/career/job/dot-net",
    location: "India",
  },

  {
    id: 1,
    job: "DevOps Engineer",
    link: "/career/job/devops",
    location: "USA",
  },
  {
    id: 1,
    job: "DevOps Engineer",
    link: "/career/job/devops",
    location: "Ghana",
  },
  {
    id: 1,
    job: "DevOps Engineer",
    link: "/career/job/devops",
    location: "India",
  },
];

const jobDesc = [
  {
    title: "full-stack-java",
    id: 1,
  },
  {
    title: "full-stack-java",
    id: 1,
  },
];
export default function career() {
  // const listJobDesc = jobDesc.map((list, index)=>{
  //   return(
  //     <div><a to='/users'><{it/a></div>
  //   )
  // })
  const listJobs = jobs.map((list, index) => {
    return (
      <Link key={index} to={list.link}>
        <div className="md:flex justify-between py-[20px] px-[20px] hover:shadow-md duration-300 cursor-pointer ">
          <div>
            <h1 className="text-[16px] md:text-[22px]">{list.job}</h1>
          </div>
          <div className="flex items-center text-[14px] md:text-[20px] text-[#666666]">
            {list.location}
            <div className="hidden md:block pl-[4px]">
              <IoIosArrowForward />
            </div>
          </div>
        </div>
      </Link>
    );
  });
  return (
    <>
      <Banner
        img={BannerImage}
        title="Become a member of our software engineering team"
        desc=""
      />
      <h2 className="text-center text-semibold text-[24px] lg:text-[36px] md:text-[26px] pt-[35px] lg:pt-[100px] md:pt-[50px] sm:pt-[35px]">
        Our Open Positions
      </h2>
      <div className="pt-[20px] md:pt-[40px] w-3/5 mx-auto">
        <hr />
      </div>

      <div className=" md:py-[50px] mx-auto max-w-[1000px] px-[28px] md:px-[40px] lg:px-[85px]  text-[#222222] ">
        {listJobs}
      </div>
      <div
        className=" bg- bg-no-repeat top-0 relative flex flex-col  bg-cover bg-center h-[350px] md:h-[300px]"
        style={{
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Career%2Fcareer-engineers-team.jpeg?alt=media&token=2c4e04b5-5de3-4224-b3f2-d5bb0a4f0c69")`,
        }}
      >
        <div className="bg-black/20 h-full">
          <div className=" max-w-[1000px] h-full px-[28px] md:px-[40px] lg:px-[85px] py-[20px] md:py-[30px] lg:py-[70px] text-center mx-auto">
            {/* <h1 className="text-[24px] md:text-[28px] lg:text-[36px]">
              {props.title}
            </h1> */}
            <div className="  md:py-4 lg:h-full m-auto">
              <div className="bg-white p-[20px]">
                <p className=" text-[16px] md:text-[18px]  text-[#222222]/80 lg:text-[18px]  lg:pt-[25px]">
                  Our people’s ideology aims at building a strong development
                  team of committed and motivated software professionals. Do you
                  have the skills to be part of the Innorik family? Do you have
                  the desire to build top-notch applications? Then apply to join
                  the family.
                </p>
                <p className="text-[16px] md:text-[18px]  text-[#5F8DFF]/90 lg:text-[18px] p-[0px] ">
                  career@innorik.com
                </p>
              </div>
            </div>

            <div className="flex justify-center">
              {/* <BorderButton button={props.button} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
