import React from "react";

export default function Overview(props) {
  return (
    <>
      <div className="bg-gradient-to-b from-[#02327A] to-[#001245] bg-black">
        <div className="conatiner max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px] text-white grid grid-cols-1 lg:grid-cols-12  justify-evenly w-full lg:py-[30px] md:py-[40px] py-[56px] ">
          <div className="m-auto lg:col-span-5 lg:ml-0 w-4/5 ">
            {/* <h2 className="m-auto text-semibold text-[24px] lg:text-[36px] text-center sm:mt-0 sm:mb-0 sm:px-[60px] lg:text-left lg:mr-0">
            Optimize Application Performance with Innorik’s Cloud Infrastructure
            Management Solution{" "}
          </h2> */}
            <img
              className="m-auto lg:ml-0 sm:w-3/5 md:w-3/5 lg:w-full"
              src={props.img}
            />
          </div>
          <div className="m-auto col-span-7 w-full pt-6 ">
            <h2 className=" mr-0 md:pt-[30px] font-semibold text-center lg:text-[left] text-[24px] md:text-[24px] sm:text-[26px] lg:text-[28px] sm:text-lg m-auto py-0 w-11/12 sm:py-2 lg:text-left">
              {props.title}
            </h2>
            <p className="text-center md:text-left  text-[16px] lg:text-[18px] m-auto pt-[16px] mr-0 lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-center">
              {props.desc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
