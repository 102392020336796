const pythonDevelopers = [
  {
    list: "Extensive experience in SDLC that includes Requirements Analysis, Design, Coding, Testing, Implementation, Maintenance with methodologies like Waterfall model and Agile methodology ",
  },
  {
    list: "Experience with designing, coding, debugging of web applications using Python. ",
  },
  {
    list: "Worked with Model-View-Whatever (MVW) frameworks like Angular JS, Django, HTML, CSS, XML, JavaScript, jQuery, and JSON",
  },
  {
    list: "Extensive experience in developing web-based applications with Python, PHP, MySQL, NoSQL, UNIX, and Linux.",
  },
  {
    list: "Extensive experience in LAMP (Linux, Apache, MySQL, and Python) and WAMP architecture ",
  },
  {
    list: "Good experience in building frameworks and automating complex workflows using Python for Test Automation.",
  },
  {
    list: "Good experience working in AGILE SCRUM environment. ",
  },
  {
    list: "Write Python modules to load data from various database sources including Oracle, MySQL, Sybase, and PostgreSQL. ",
  },
  {
    list: "Utilized Python unit test framework, PyUnit, for creating unit test for all codes",
  },
  {
    list: "Extensive experience in code management tools CVS, SVN, Amazon EC2, Git, Azure DevOps, and GitHub.",
  },
  {
    list: "Extensive experience building web applications using MYSQL, AWS, Flask, Jinga, REDIS, HTML, XML, JSON, CSS, Java Script & AJAX.",
  },
  {
    list: "Experience in working with Python ORM Libraries including Django ORM.",
  },
  {
    list: "Very good experience in application error troubleshooting, debugging, and fixing.",
  },
  {
    list: "Contribute to Python codebase used in the automated deployment of software on multiple virtual machines.",
  },
  {
    list: "Experienced in working with various Python IDEs using PyCharm, Spyder, NetBeans, PyStudio, PyScripter, Eric, Wing IDE and PyDev.",
  },
  {
    list: "Experienced in requirement gathering, use case development, business process flow, and business process modelling",
  },
  {
    list: "Strong contributor towards knowledge management activities including project documentation, user manuals, component user guides and other technical documentation.",
  },
  {
    list: "Excellent track record in dealing with stake holders in technology, business, and operations.",
  },
  {
    list: "Experience in leading both on-shore and off-shore development teams.",
  },
];

const javaDevelopers = [
  {
    list: "Extensive experience in .NET application development, software development and project management; including application design, requirements gathering, documentation, maintenance, testing, implementation, and agile project management. ",
  },
  {
    list: "Expertise in implementing Web Applications with n-tier service-oriented architecture  ",
  },
  {
    list: "Experience building REST APIs. ",
  },
  {
    list: "Experience in Microsoft .NET Technologies using .Net and .Net Core, Visual Studio, ASP.NET & ASP.NET Core MVC, C#, ADO.NET, XML, Web Services, Internet Information Server (IIS), and MS SQL Server.",
  },
  {
    list: "Installation and administration of application servers WebSphere, WebLogic, and Tomcat. ",
  },
  {
    list: "Extensive experience in exception handling and different ways of authentication and authorization of web APIs. ",
  },
  {
    list: "Extensive knowledge in developing applications in a single page (SPAs) using various JavaScript frameworks like Angular 6, 7, 8, React. ",
  },
  {
    list: "Proficient in using .NET Threading, Delegates, Events, Generics, Collections, Exception Handling, Transactions, Reflection, and Serialization. ",
  },
  {
    list: "Good knowledge in Data Structures & Design Patterns. ",
  },
  {
    list: "Exceptional skills in problem solving techniques, investigating, debugging & fixing the issues.  ",
  },
  {
    list: "Experience in working with RESTFUL Web Services and implementing RESTFUL API's ",
  },
  {
    list: "Experience in applying the latest software development approaches including MVC, event driven applications using AJAX, Object Oriented (OO) JavaScript, JSON, and XML. ",
  },
  {
    list: "Development of User Interfaces using HTML CSS, XML, AJAX, and JavaScript ",
  },
  {
    list: "Experience in building .Net Web Services using SOAP, UDDI, and WSDL.",
  },
  {
    list: "Expertise in writing Constraints, Indexes, Views, Stored Procedures and Functions. ",
  },
  {
    list: "Hands - on experience in Azure Cloud Services (PaaS & IaaS), Storage, Web Apps, Active Directory, Application Insights, Logic Apps, Data Factory, Service Bus, Traffic Manager, Azure Monitoring, OMS, Key Vault, Visual Studio Online (VSO), Cognitive Services (LUIS) and SQL Azure. ",
  },
  {
    list: "Design and develop Cloud Service projects and deploy them to Web Apps, PaaS, and IaaS. ",
  },
  {
    list: "Used SQL Azure extensively for database needs in various applications. ",
  },
  {
    list: "Implement Corp, 2FA and Role based authentication mechanism using Windows Azure Active Directory and dSTS (Datacenter Security Token Service) which uses WIF (Windows Identity Foundation). ",
  },
  {
    list: "Designed and implemented Data Access Layer (DAL) using ADO.NET objects like Data Adapter, Data Reader, Dataset, Data Table and Repeater to access databases like SQL Server. ",
  },
  {
    list: "Experience in legacy web application development using ASP.NET Web form (Master page, user controls, validation controls) and ASP.Net MVC, web services. ",
  },
  {
    list: "Coding and tuning of queries, stored procedures and functions, Triggers using PL/SQL. ",
  },
  {
    list: "Expertise with Unit testing Frameworks like NUnit and MS Test ",
  },
  {
    list: "Ability to coordinate and provide support within a team environment with problem solving and strong analytical skills.  ",
  },
  {
    list: "Experience in leading both on-shore and off-shore development teams. ",
  },
];
const netDevelopers = [
  {
    list: "Experience in SDLC that includes Requirements Analysis, Design, Coding, Testing, Implementation, Maintenance with methodologies like Waterfall model and Agile methodology  ",
  },
  {
    list: "Expert in core Java development using Multithreading, Collections, and Exception Handling mechanisms ",
  },
  {
    list: "Experienced in Object Oriented, data structures, collections framework API for framework design in Java. ",
  },
  {
    list: "Expert working in multithreaded Java applications and tuning applications for performance optimization and synchronization. ",
  },
  {
    list: "Installation and administration of application servers WebSphere, WebLogic, and Tomcat. ",
  },
  {
    list: "Excellent working knowledge in configuring multiple WebLogic domains including machines, manage servers, node managers, and cluster environments. ",
  },
  {
    list: "J2EE application architecture and design experience using design patterns like Singleton, Factory, Abstract Factory, Service Locator, DAO, DTO and Business Delegate. ",
  },
  {
    list: "Experience in developing applications using MVC Architecture, Struts, Spring, Hibernate, JSP, Servlets, JDBC, XML, XSLT, and Design Patterns. ",
  },
  {
    list: "Experienced working in relational databases like IBM DB2, Oracle, Sybase, MS SQL Server, and Teradata. ",
  },
  {
    list: "Experienced working in messaging systems (JMS/IBM MR Series) and REST/SOAP Web Services. ",
  },
  {
    list: "Exposure to IDEs like Eclipse, WSAD, Flex Builder and NetBeans and code management tools like SVN, IBM Synergy, VSS. ",
  },
  {
    list: "Experience in client-side Technologies such as HTML, CSS, JavaScript and jQuery, AJAX, JSON. ",
  },
  {
    list: "Hands on experience with scripting languages like Unix, Linux ",
  },
  {
    list: "Experience in implementing SOA using web services (SOAP, WSDL, Restful, and JAX-WS) and REST services ",
  },
  {
    list: "Experienced in production support activities for deployment, bug fixing and troubleshooting for highly critical applications. ",
  },
  {
    list: "Experienced working in AGILE SCRUM environment. ",
  },
  {
    list: "Familiar in deploying applications through web sphere server as part of CRUD process ",
  },
  {
    list: "Experience in creating test cases using Junit ",
  },
  {
    list: "Strong contributor towards knowledge management activities including project documentation, user manuals, component user guides and other technical documentation. ",
  },
  {
    list: "Excellent track record in dealing with stake holders in technology, business, and operations. ",
  },
  {
    list: "Experience in leading both on-shore and off-shore development teams. ",
  },
];
const phpDevelopers = [{}];
export { pythonDevelopers, phpDevelopers, javaDevelopers, netDevelopers };
