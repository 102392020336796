import React from "react";

export default function Overview(props) {
  return (
    <>
      <div className="invisible lg:visible lg:h-auto h-0 px-[20px] md:px-[40px] lg:py-[40px] lg:px-[85px] max-w-[1500px] mx-auto">
        <div className={`${props.color}`}>
          <div className="grid grid-cols-12 w-full">
            <div className=" col-span-5">
              <img
                className="  w-[100%] h-full object-cover"
                src={props.img}
                alt={props.alt}
              />
            </div>
            <div className={` col-span-7 my-auto py-[20px] px-[40px] `}>
              <h1 className={`text-[20px] md:text-[24px] ${props.titleColor}`}>
                {props.title}
              </h1>
              <p className={`pt-[20px] ${props.textColor}`}>
                {props.desc}
                {/* {props.desc2 === String ? (
                  <p>
                    <br /> <br /> {props.desc2}
                  </p>
                ) : null} */}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="visible  md:visible lg:invisible mx-auto lg:h-0">
        <div className="">
          <div className=" w-full">
            <div className="py-[20px] ">
              <img
                className=" mx-auto px-[20px] h-[280px] w-[400px] object-cover"
                src={props.img2}
              />
            </div>
            <div className="  my-auto px-[40px]">
              <h1 className="text-[20px] text-center md:text-[24px]">
                {props.title}
              </h1>
              <p className="py-[20px]  text-center">
                {props.desc} <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
