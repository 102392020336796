import React from "react";
import Banner from "../../../../components/Banner";
import FourColCard from "../../../../components/FourColCard";
import Industries from "../../../../components/NewIndustries";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import OurDevelopers from "../../Platforms/Magento/OurDevelopers";
import Offerings from "../Php/Offerings";
import { phpDevelopers } from "../../../../data/programming";

// import Overview from "../Php/Overview";
import Services from "../Php/Services";
import WhyChooseUs from "../Php/WhyChooseUs";
const Data2 = [
  {
    id: 1,
    title: "React Native expertise ",
  },
  {
    id: 2,
    title: "Comprehensive quality assurance ",
  },
  {
    id: 3,
    title: "Maximum Speed and flexibility ",
  },
  {
    id: 4,
    title: "Different software development methodologies  ",
  },
  {
    id: 5,
    title: "360-degree Requirement Analysis ",
  },
  {
    id: 6,
    title: "Post-development support ",
  },
  {
    id: 8,
    title: "Easy and regular communication ",
  },
  {
    id: 9,
    title: "Reduced cost to 50% of our competitors ",
  },
  {
    id: 10,
    title: "A pool of smart and experienced developers ",
  },
];

const offeringData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Ficons%2Ffast-time.svg?alt=media&token=13e52f4e-0ac7-4ba4-bc39-219b9fd85581",
    title: "Quick Time-To-Market",
    desc: "With React, a best-in-class JavaScript toolkit for designing user interfaces, React Native combines the best of Native development with React. The wide library of react components and support for third-party plugins speeds up the app development process. Because of code reusability, development time can be reduced by up to 50%.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Ficons%2Fopen-source.svg?alt=media&token=2b420f7b-ad3d-40e1-bd41-1727cc0be80b",
    title: "Open source, low cost, and stable",
    desc: "React Native includes tools and consoles that make it simple and quick to execute functions with little lag and latency. React Native has the second-highest number of contributions among all GitHub repositories.",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Ficons%2Flive.svg?alt=media&token=f8fcee02-18da-4a95-b51e-f2ec816a3834",
    title: "Live / Hot Reload ",
    desc: "As soon as you save, you'll be able to see your modifications. React Native allows you to iterate at breakneck speed, thanks to JavaScript. There's no need to wait for native builds to finish. Save, check, and repeat. ",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Ficons%2Fspeed.svg?alt=media&token=0f42130a-1734-4b89-8b12-814d2ecc4079",
    title: "Fast performance & speed",
    desc: " React Native makes use of GPU (Graphics Processing Unit) in the processing environment, which allows it to deliver fast results and stable performance.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Ficons%2Fmaintenance.svg?alt=media&token=1d0b4b95-c37a-45af-96c6-a59a00413c6c",
    title: "Streamlined Maintenance",
    desc: "One mobile app development team can handle two platforms while sharing a similar technology. React, one language across all the components.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Ficons%2Fplay.svg?alt=media&token=86b362cb-f73e-427b-aec2-7d4b6c785189",
    title: "Create Android and iOS apps with React Native ",
    desc: "React Native app developers reuse up to 75% of their code between iOS and Android platforms.",
  },
];
const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Fillustrations%2FReactNative-Consulting.svg?alt=media&token=df3bea4c-7b85-4ca2-8da8-28245cf9e38c",
    title: "React Native Consulting",
    desc: "Unless you have the correct strategy and framework in place, your app idea is just that: an idea. Our react native app development experts will analyze your app development objectives and devise a strategy to assist you achieve a cross-platform app with the feel and look of a native app.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Fillustrations%2FReactNative-CustomDevelopment.svg?alt=media&token=b7084d2a-8ee8-4a1c-a1db-88277d58e463",
    title: "Custom App Development",
    desc: "We ensure highly reliable and efficient web development by using the most popular front-end ReactJS modules and extensions. Our React Native app development experts handles all integrations with care, no matter how complicated your web project is.",
  },
  // {
  //   id: 3,
  //   img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Fillustrations%2FReactNative-Web-developmet.svg?alt=media&token=d177da76-8f87-4969-9cf9-83e277c265ce",
  //   title: "React Native Web Development",
  //   desc: "We ensure highly reliable and efficient web development by using the most popular front-end ReactJS modules and extensions. Our React Native app development experts handles all integrations with care, no matter how complicated your web project is.",
  // },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Fillustrations%2FReact-Native-React%20Native%20Migration%20%26%20Upgrade.svg?alt=media&token=a0654b8d-dfbe-46b5-a60f-e76490f50220",
    title: "React Native Migration & Upgrade",
    desc: "We're ready to assist you whenever you need us. Whether you need to rebuild a legacy mobile app or upgrade an existing one to a React Native app, our native development agency will provide you with the best options.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Fillustrations%2FReactNative-Full-cycle.svg?alt=media&token=a75a8fad-a5de-46f3-8d49-d0d9991b22fa",
    title: "Full cycle Product Development",
    desc: "From conception to launch, we make the most out of React Native's capabilities. The journey of React Native app development does not finish with the realization of the concept. We will continue to monitor your app after it's been released.",
  },
];
const Data4 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Fios.jpg?alt=media&token=9b4ead47-c2ba-4cfb-9e05-07259df3c5b3",
    title: "ios mobile apps",
    desc: "",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Fandroid.jpg?alt=media&token=2673e957-3803-4b65-a686-f9e153003281",
    title: "Android mobile apps",
    desc: "",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Fwebapp.jpg?alt=media&token=58a6e260-f0c5-4b80-bc05-719302806c7f",
    title: "Web Apps",
    desc: "",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Ftv.jpg?alt=media&token=56a3e935-1a4c-4d42-ba67-00be1e78aa41",
    title: "Smart TV apps",
    desc: "",
  },
];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];
export default function ReactNative() {
  return (
    <>
      <Banner
        button="Talk to Experts"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Freact-native.jpeg?alt=media&token=6b434fb8-2630-4cf6-8cb6-a7537f4003ec"
        title="React Native App Development"
        desc="Delivering end-to-end dynamic cross-platform applications to empower your business to achieve your goals."
      />
      <Overview
        textColor="text-white bg-[#222222]"
        title="What is React Native?"
        desc="React Native is a fast-growing cross-platform development framework for iOS and Android that leverages JavaScript to render native mobile apps. The framework, which was created and backed by Facebook Inc., has garnered significant popularity within the development community throughout the world. Innorik specializes in integrating cognitive services into react native apps to help businesses transform how they interact with their customers and employees. Our developers can create custom react native apps and deploy them using the framework that best meets your company's requirements."
        img="/images/technologies/programming/react-native/react-native-overview.jpeg"
        img2="/images/technologies/programming/react-native/react-native-overview-mobile.jpeg"
        alt="react native"
      />
      {/* <Overview
        color="bg-black"
        textColor="text-white"
        title="What is React Native?"
        desc="React Native is a fast-growing cross-platform development framework for iOS and Android that leverages javascript to render native mobile apps. The framework, which was created and backed by Facebook Inc., has garnered significant popularity within the development community throughout the world. React Native is used in 1 out of every 5 apps in the top 500."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2FreactNative%2Foverview.jpg?alt=media&token=8f806ca2-38c9-4f3d-bf52-15ef1f49f44c"
      /> */}
      <Offerings
        title="React Native as our cross-platform development framework of choice"
        desc="Innorik excels at building elegant, interactive, and functionally-rich cross-platform apps at speed and on budget. After moving to React Native, a lot of prominent brands are on top of their game and this is why"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2Fphp%2Fofferings.png?alt=media&token=d3fd7a68-2751-45e1-b71d-5e9fcce9184b"
        data={offeringData}
      />
      <Services
        title="Our custom React Native Development Services"
        desc=""
        img=""
        data={servicesData}
      />
      <FourColCard
        title="Software our React Native developers build"
        data={Data4}
      />
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our expertise"
        desc="Innorik has a large team of React Native developers who have extensive experience with the language due to working with various project domains such as banking, telecommunication, healthcare, insurance systems, and with different companies. Our developers have experience ranging from 1-20 years."
        button="Hire React Native Expert"
        data={phpDevelopers}
      />
      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FReactNative%2Freact-native-developer.jpeg?alt=media&token=4f23c553-1520-43e9-af48-5d4660f1c15e"
        title="Our React Native Experts"
        desc="Innorik has a large team of node js developers who have very good experience with the language from working with various project domains such as banking, telecommunication, healthcare, insurance systems and with different companies. Our developers have experience ranging from 1year to 20 years."
        button="Hire React Native Expert"
      /> */}

      <Industries
        title="Industies Served"
        desc="We are international company and we deliver technology solutions to clients globally. "
        data={Indust}
      />
      <WhyChooseUs
        head="Why Integrate with cloud? "
        title="Why choose us?"
        desc=""
        data={Data2}
      />
    </>
  );
}
