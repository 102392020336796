import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const latestTech = [
  {
    id: 1,
    number: "01",
    title: "Virtual Reality",
    desc: "Virtual Reality is helping to shape the future of education. It is extremely important in raising educational standards. As a result, it is gradually adopted by students and teachers. This is a system that uses immersive storytelling to train teachers and other instructors. ",
    link: "/latest-thinking/virtual-reality",
  },
  {
    id: 2,
    number: "02",
    title: "Big Data ",
    desc: "In this undeniably digitized world, how much information produced is developing at an exponential rate. All businesses have confronting difficulties in overseeing and figuring out this huge data and are seeking best practices to handle data meaningfully. ",
    link: "/latest-thinking/big-data",
  },
  {
    id: 3,
    number: "03",
    title: "Blockchain",
    desc: "Blockchain is changing the way the world lives and work, and it is gradually becoming the world’s most popular way to buy, sell, and trade crypto. In view of this, Innorik is ready to step in at any stage of your product development to assist you in designing, development, implementation, and deployment of your product successfully. ",
    link: "/latest-thinking/blockchain",
  },
  {
    id: 4,
    number: "04",
    title: "Internet of Things ",
    desc: "With the evolving and adoption of technology, our present era is fully graced with appliances which are connected one way or the other to the internet from our very homes and industries to our enterprises. We have reached the stage where geography does not command our use of these gadgets because they are operable even if they are far away from us; and this has been made possible by IoT.   ",
    link: "/latest-thinking/iot",
  },
  {
    id: 5,
    number: "05",
    title: "Computer Vision",
    desc: "With this technology you can add value to your business with ML algorithms for robust computer vision solutions with image analysis, object detection and video analysis. Our software development team at Innorik, provides Computer Vision solutions that can improve your business performance, and drive your revenue growth.   ",
    link: "/latest-thinking/computer-vision",
  },
  {
    id: 6,
    number: "06",
    title: "Artificial Intelligence ",
    desc: "Our expert AI consultants can help you conduct a feasibility study on the integration of AI solutions into your software and help you realize the potential benefits, risk, cost etc. that can come from the implementation process. We can also help outline a development, deployment and integration plan for building AI powered software, thus evaluating the real value of AI opportunity. ",
    link: "/latest-thinking/artificial-intellegence",
  },
];
export default function Innovate() {
  const listTech = latestTech.map((list, index) => {
    return (
      <div
        key={index}
        className="border-2 h-[450px] relative border-[#222222] duration-300	 hover:bg-black hover:text-white  bg-white"
      >
        <div className="py-[40px] px-[15px]">
          <h1 className="text-[36px] font-semibold">{list.number}</h1>
          <h1 className="text-[24px] pt-[12px] hover:text-white text-center font-semibold">
            {list.title}
          </h1>
          <p className="text-[14px] pt-[12px] text-center pb-[50px]">
            {`${list.desc.split(" ").splice(0, 45).join(" ")}...`}
          </p>
          <div className="pb-[30px] ml-[-14px] absolute bottom-0 w-full">
            <Link to={list.link}>
              <button className="text-[16px]  mx-auto pt-[40p] lg:text-[18px] py-[8px] px-[12px] lg:py-[10px] md:py-[10px] lg:px-[30px] md:px-[15px] flex  border border-[#222222] hover:border-white hover:bg-white hover:text-[#222222] duration-150">
                Learn More
                <div className="lg:pl-[20px] md:pl-[10px] pl-[5px] m-auto">
                  <IoIosArrowForward />
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  });
  return (
    <>
      <div
        style={{
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Home%2FhomepageThinking.png?alt=media&token=a0d02950-bbb9-40f2-ae02-716a5dabcb87")`,
        }}
      >
        <div className="pt-[80px]">
          {" "}
          <h1 className="text-[28px] lg:text-[36px] text-[#222222] lg:w-3/5 mx-auto text-center bg-white ">
            Innovate with our latest technology thinking
          </h1>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-[60px] py-[50px] max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px]">
          {listTech}
        </div>
      </div>
    </>
  );
}
