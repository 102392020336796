import React from "react";
import Banner from "../../components/Banner";
// import TextWithIcons from "../../components/TextWithIcons";
import LifeAtInnorik from "./Overview";
import "./culture.css";
import OurMission from "./OurMission";
import CoreValues from "./CoreValues";
// import operational from "../../Assets/Images/Icons/administration.png";
// import customerNeeds from "../../Assets/Images/Icons/help.png";
// import teamWork from "../../Assets/Images/Icons/teamwork.png";
// import thinking from "../../Assets/Images/Icons/thinking.png";
// import idea from "../../Assets/Images/Icons/idea.png";
// import innovation from "../../Assets/Images/Icons/innovation.png";
import Culture from "../../Assets/Images/company/talent-world-wide/team.svg";
// import Mission from "../../Assets/Images/company/culture-and-values/our-mission.jpg";
// import Vision from "../../Assets/Images/company/culture-and-values/our-vision.jpg";
// import Values from "../../Assets/Images/company/culture-and-values/our-value.jpg";

import BannerImage from "../../Assets/Images/company/culture-and-values/culture-and-values-banner.jpg";
// import  from '../.../Assets/Images/company/cul'

import Problem from "../../Assets/Images/company/culture-and-values/icons/problem-solving.svg";
import Operations from "../../Assets/Images/company/culture-and-values/icons/management.svg";
import Teamwork from "../../Assets/Images/company/culture-and-values/icons/team.svg";
import Innovation from "../../Assets/Images/company/culture-and-values/icons/innovation.svg";
import Thinking from "../../Assets/Images/company/culture-and-values/icons/brainstorming.svg";
import Needs from "../../Assets/Images/company/culture-and-values/icons/customer-needs.svg";
import FooterBanner from "../../components/Footer/FooterBanner";

const Data = [
  {
    id: 1,
    img: Problem,
    title: "Diversity and Inclusion",
    desc: "Our differences make us stronger, united, and cohesive",
  },
  {
    id: 2,
    img: Operations,
    title: "Operational excellence",
    desc: "Focusing on customer’s needs, keeping associates positive and empowered, continuous improvement and learning",
  },

  {
    id: 3,
    img: Teamwork,
    title: "Teamwork & Dedication",
    desc: "Problem-solving, determination",
  },
  {
    id: 4,
    img: Innovation,
    title: "Innovation",
    desc: "Critical thinking, creativity, and new discoveries,",
  },
  {
    id: 5,
    img: Needs,
    title: "Integrity",
    desc: "Reputation, customer satisfaction, quality, long-term and honesty",
  },
  {
    id: 6,
    img: Thinking,
    title: "Focused Approach",
    desc: "Agile, extreme programing, rapid apps, testing and delivery",
  },
];

export default function CultureAndValues() {
  return (
    <>
      <Banner
        img={BannerImage}
        title="Culture And Values"
        desc="Our mission, vision, and values steer us."
      />
      <LifeAtInnorik img={Culture} />
      <OurMission
        mission="/images/company/culture-and-valuse/mission.jpeg"
        vision="/images/company/culture-and-valuse/vision.jpeg"
        value="/images/company/culture-and-valuse/values.jpeg"
      />

      {/* <TextWithIcons /> */}

      {/* <div className="grid grid-cols- justify-center w-full my-10">
        <div className="m-auto relative ">
          <img
            className="ml-auto lg:w-10/12"
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CultureAndValues%2FourMission.png?alt=media&token=d2698253-15be-42eb-ad1a-6b4857221bef"
          />
          <div className="h-1/2 2xl:h-2/5 lg:px-5 lg:py-2 absolute bottom-0 inset-x-0 text-white ml-auto leading-snug xl:p-7 lg:w-10/12 bg-black opacity-70 xl:text-xl 2xl:text-[21px] 2xl:leading-8">
            <span className="text-xl 2xl:text-2xl">Our Mission</span> is to provide
            innovative world class and enterprise software products and services
            globally, thereby enabling them to leverage technology for business
            growth and sustainability.
          </div>
        </div>

        <div className=" relative ">
          <img
            className="m-auto lg:w-10/12"
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CultureAndValues%2FourVision.png?alt=media&token=750b921d-886b-4695-af71-9fc9f5ea36ba"
          />
          <div className="h-1/2 2xl:h-2/5 lg:px-5 lg:py-2 absolute bottom-0 inset-x-0 text-white m-auto leading-snug xl:p-7 lg:w-10/12 bg-black opacity-70 xl:text-xl 2xl:text-[20px] 2xl:leading-8">
            <span className="text-xl 2xl:text-2xl">Our Vision</span> is to help both
            local and international organisations overcome the ever trending
            technological challenges of digital transformation by delivering
            eminent software solutions and services.
          </div>
        </div>

        <div className="m-auto relative ">
          <img
            className="mr-auto lg:w-10/12"
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CultureAndValues%2FourValues.png?alt=media&token=e8422f89-1f77-424d-822f-648b3146b14f"
          />
          <div className="h-1/2 2xl:h-2/5 lg:px-5 lg:py-2 absolute bottom-0 inset-x-0 text-white mr-auto leading-snug xl:p-7 lg:w-10/12 bg-black opacity-70 xl:text-xl 2xl:text-[21px] 2xl:leading-8">
            <span className="text-xl 2xl:text-2xl">Our Values</span> forms the
            building blocks of our culture and defines how we work together to
            serve our customers and to change the world.
          </div>
        </div>
      </div> */}
      <CoreValues title="Our Core Values" data={Data} />
      <FooterBanner title="Join our team" link="/career" />
    </>
  );
}
