import React from "react";
import Accordian from "./Accordian";
// import List from "../../components/Cards/List";

export default function AccordianSection(props) {
  return (
    // oh man this is nice
    <div className="">
      <div className="conatiner bg-[#F9F9F9]  py-[5%]">
        <div className="conatiner md:px-8 x text-black flex flex-col   ">
          <Accordian data={props.data} />
        </div>
        {/* <div className="m-auto px-[5%]  pb-[10px]">
          <div className="m-auto ">
            <img
              className="m-auto lg:pr-[5%] md:h-[300px] sm:h-[300px]  bg-fixed bg-no-repeat sm:w-[100vw] md:w-[100vw] lg:w-auto sm:object-cover md:object-cover md:center object-top	 lg:pl-[0%] pt-2"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudConsulting%2FRectangle%201903.png?alt=media&token=d0fcffb8-362a-4cde-92c3-1801ea78b38f"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
