import React from "react";

export default function LifeAtInnorik(props) {
  return (
    <>
      <div className="mx-auto max-w-[1400px]">
        <div className="conatiner lg:px-[85px] md:px-[40px] px-[28px] flex flex-col-reverse  justify-evenly w-full lg:flex-row  lg:py-[50px]">
          <div className="m-auto w-full ">
            <h2 className=" font-medium text-center text-[#3066EA] lg:text-[left] text-[24px] md:text-[24px] sm:text-[24px] lg:text-[32px] sm:text-lg  py-0  sm:py-2 lg:text-left">
              Life At Innorik
            </h2>
            <p className="text-justify text-[16px] pt-[16px] lg:text-[18px] sm:text-lg  sm:py-1 lg:text-left">
              Let us be driven by operational excellence, integrity, trust, and
              commitment! This is our work at Innorik. Each member of the
              Innorik team imbibes these values while working towards a common
              mission.
            </p>
            <p className="text-justify text-[16px] pt-[6px] lg:text-[18px] sm:text-lg  sm:py-1 lg:text-left">
              Our diverse community of over 500 exceptional people are always
              working together to make our clients experience innovation with a
              single touch.
            </p>
            <p className="text-justify text-[16px] pt-[6px] lg:text-[18px] sm:text-lg  sm:py-1 lg:text-left">
              At Innorik, we believe that continuous improvement is the catalyst
              for growth, creativity and innovation and we provide our
              associates at all levels the support, training, and the
              opportunities they need to be fully equipped for any future
              challenges and to take their lives and careers to new heights.
            </p>
          </div>
          <div className="m-auto  w-full  lg:w-3/5">
            <div className="m-auto w-9/12 sm:w-7/12 md:w-6/12 lg:w-full">
              <img
                className="m-auto mt-10 mb-2 w-full ld:ml-13"
                src={props.img}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="conatiner flex flex-col justify-evenly w-full md:flex-row lg:pl-[85px] lg:pr-[85px]">
        <div className="m-auto w-full md:w-2/3 lg:w-1.5/3  sm:py-10">
            <h2>Life At Innorik</h2>
          <p className="text-justify text-[16px] lg:text-[18px]  sm:text-left ">
          Our diverse community of over 200 exceptional people are always working together to make our clients, experience innovation in a single touch. <br/>
At Innorik, we believe that continuous improvement is the catalyst for growth, creativity and innovation and we provide our associates at all levels the support, the training, and the opportunities they need to be fully equipped for any future challenges and also to take their lives and careers to new heights.

          </p>
        </div>
        <div className="m-auto w-full md:w-1/2 lg:w-1/3">
          <div className="m-auto w-9/12 sm:w-7/12 md:w-9/12 lg:w-14/12">
            <img
              className="m-auto mt-10 mb-5 md:mt-0 md:mb-0 md:ml-12 md:w-1/2   "
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CultureAndValues%2FlifeAtInnorikIllustration.png?alt=media&token=54f34e10-8517-4a30-90f6-7df5822bb93e"
            />
          </div>
        </div>
      </div> */}
    </>
  );
}
