import React from "react";
// import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TabBox from "./TabBox";
// import TabBoxMobile from "./TabBoxMobile";

export default function MobileTechTab({ data }) {
  const listingNewData = data.map((item, index) => (
    <div key={index} className="mt-[20px]">
      <div className="grid grid-cols-3 gap-10 ">
        {item.details.map((value) => (
          <TabBox img={value.img} desc={value.desc} />
        ))}
      </div>

      <p className="text-[20px] w-full text-center pt-[60px] text-[#22222]">
        {item.tab}
      </p>
    </div>
  ));

  return (
    <div className="bg-blue-100 pt-[20px] pb-[50px]">
      <div className=" px-[28px] mx-auto grid grid-cols-1 md:grid-row lg:grid-cols-4 lg:w-full"></div>
      <div className="visible md:invisible lg:invisible h-auto md:h-0 lg:h-0  mx-auto md:col-span-3 pl-[20px]">
        <Slider
          infinite={true}
          autoplay={false}
          arrows={false}
          slidesToShow={1}
          autoplaySpeed={2000}
          speed={500}
          dots={true}
        >
          {listingNewData}
        </Slider>
      </div>
    </div>
  );
}
