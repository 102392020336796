import React from "react";

export default function Advantages(props) {
  const total = props.total;
  const data = props.data;
  const listData = data.map((list, index) => {
    return (
      <div
        className={
          total === "4" ? "bg-[#EDF2FF] p-4" : "bg-[#EDF2FF] py-10 px-6"
        }
      >
        <img className="mx-auto h-[64px]" src={list.img} />
        <h2 className=" text-[18px] font-medium lg:text-[20px] pt-[20px]">
          {list.title}
        </h2>
        <p className="text-[#6D6D6D] ">{list.desc}</p>
      </div>
    );
  });
  return (
    <>
      <div className="max-w-[1500px] mx-auto py-[50px] lg:px-[85px] md:px-[40px] px-[28px]">
        <div>
          <h1 className="text-center text-[24px]  text-[#3066EA]  lg:text-[36px]">
            {props.head}
          </h1>
        </div>
        <div
          className={
            total === "4"
              ? "grid  text-center md:pt-[25px] pt-[20px] lg:pt-[50px] md:grid-cols-2 lg:grid-cols-3 gap-[50px] "
              : "grid  text-center md:pt-[25px] pt-[20px] lg:pt-[50px] md:grid-cols-2 lg:grid-cols-3 gap-[50px] "
          }
        >
          {listData}
        </div>
      </div>
    </>
  );
}
