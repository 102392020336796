import React from "react";

export default function Infographics(props) {
  return (
    <>
      <div className="justify-center max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px]">
        <h2 className=" pt-8  md:pt-12 lg:pt-16 xl:pt-16 font-semibold  text-xl text-center pb-4 ms:text-2xl ms:pb-6 md:text-3xl md:pb-8 lg:text-4xl lg:pb-10">
          {props.title}
        </h2>
        <img
          className="invisible lg:visible md:visible h-0 lg:h-auto md:h-full mx-auto  pt-8 pb-0 md:pb-18 lg:pb-16"
          src={props.img}
        ></img>
        <img
          className="visible lg:h-0 md:invisible md:h-0 lg:invsible sm:visible  mx-auto px-4 pb-8"
          src={props.img2}
        ></img>
      </div>
    </>
  );
}
