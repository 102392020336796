import React from "react";
import { Link } from "react-router-dom";
const nextData = [
  {
    id: 1,
    img: "/images/company/about/icons/next/artificial-intelligence.svg",
    title: "Artificial Intellegence",
    desc: "Organisations in this era have more data at their disposal than ever before and the most advanced companies out of the whole have now accepted that the cloud is the most secured way to process and store such huge data. Now here comes the interesting part. How do you think these companies convert such data into business value?  Well, that’s where AI comes in. The AI technology has the ability to enhance your business productivity and operational efficiency and can help you avoid production loss or insufficient product quality.",
    link: "/latest-thinking/artificial-intellegence",
  },
  {
    id: 1,
    img: "/images/company/about/icons/next/iot.svg",
    title: "Internet of things",
    desc: "With the evolving and adoption of technology, our present era is fully graced with appliances which are connected one way or the other to the internet from our very homes and industries to our enterprises. We have reached the stage where geography does not command our use of these gadgets because they are operable even if they are far away from us; and this has been made possible by IoT. The focus of the IoT market spans across all walks of life be it manufacturing, healthcare, travel, transport and logistics, energy and utilities, retail etc. with no or limited implications in areas like security and privacy on your business.",
    link: "/latest-thinking/iot",
  },
  {
    id: 1,
    img: "/images/company/about/icons/next/computer-vision.svg",
    title: "Computer Vision",
    desc: "Organisations in this era have more data at their disposal than ever before and the most advanced companies out of the whole have now accepted that the cloud is the most secured way to process and store such huge data. Now here comes the interesting part. How do you think these companies convert such data into business value?  Well, that’s where AI comes in. The AI technology has the ability to enhance your business productivity and operational efficiency and can help you avoid production loss or insufficient product quality.",
    link: "/latest-thinking/computer-vision",
  },
  {
    id: 1,
    img: "/images/company/about/icons/next/data-flow.svg",
    title: "Big Data",
    desc: "In this undeniably digitised world, how much information produced is developing at an exponential rate. All businesses have confronting difficulties in overseeing and figuring out this huge data and are seeking best practices to handle data meaningfully. Inability to accurately address these big data challenges can bring about heightening expenses, as well as reduced productivity and competitiveness. In general, an organisation is probably going to profit from big data advances if it is leveraged and well utilised. Some of the Industries where big data can be integrated are Manufacturing, Healthcare, Financial services, Retail and e-commerce, Telecommunications etc.",
    link: "/latest-thinking/big-data",
  },
  {
    id: 1,
    img: "/images/company/about/icons/next/blockchain.svg",
    title: "Blockchain",
    desc: "Blockchain is changing the way the world lives and works, and it is gradually becoming the world’s most popular way to buy, sell, and trade crypto. In view of this, we as a blockchain development company is ready to step in at any stage of your product development to assist you design, develop, implement and deploy successfully.",
    link: "/latest-thinking/blockchain",
  },
  {
    id: 1,
    img: "/images/company/about/icons/next/virtual-reality.svg",
    title: "Virtual Reality",
    desc: "Recreating memories to make the impossible, possible. With the Recent advances in innovative technology, giving more current and exciting ways for us to interact with each other and the world around us, one can contend that we have made a trip on schedule to what's to come. As of now, one greatest headway that has come into the bright lights is Virtual Reality. Virtual Reality (VR) creates amazing three-dimensional (3D) artificial/counterfeit worlds and immersive experiences for users by putting an intelligent layer over tangible reality. The development services of VR focuses on the design and implementation of software that allows users interact with these simulated environments.",
    link: "/latest-thinking/virtual-reality",
  },
  {
    id: 1,
    img: "/images/company/about/icons/next/microsoft.svg",
    title: "Microsoft Dynamics",
    desc: "One outstanding benefit of Dynamics 365 is tight integration with other Microsoft business applications such as Office 365 and Outlook. Pricing of Dynamics 365, as compared to its main competitors is also a benefit, with bundle and role-based licensing which is preferable to many clients in any case.",
    link: "/platforms/microsoft-dynamics",
  },
  {
    id: 1,
    img: "/images/company/about/icons/next/big-data.svg",
    title: "Microsoft Power BI",
    desc: "Microsoft Power BI is a collection of software services, apps, and connectors used to transform data into a form of immersive live dashboards and easy-to-digest reports and interactive insights. Power BI offers extensive modelling, real-time analytics, and custom visualisations and can help your organisation find clarity when you need it most to Empower team members to discover insights hidden in your data. It can also help you create a data-driven culture with business intelligence for all enabling everyone at every level of your organisation make confident decisions using up-to-the-minute analytics.",
    link: "/platforms/power-bi",
  },
];
export default function NextGen() {
  const listNextTech = nextData.map((list, index) => {
    return (
      <div
        key={index}
        className="w-full text-center lg:text-left  grid grid-rows-12 h-[400px]  md:w-auto  shrink-0 pt-5 pb-4  mx-4"
      >
        <img
          className="row-span-1 h-[48px] w-[48px] mx-auto lg:ml-0"
          src={list.img}
          alt=""
        />
        <p className="pt-5 row-span-1 text-[18px]  md:text-[20px] lg:text-[20px] ">
          {list.title}{" "}
        </p>
        <p className="row-span-6 text-[14px] text-[#767676] pt-[12px]">
          {`${list.desc.split(" ").splice(0, 43).join(" ")}...`}
        </p>
        <Link className="row-span-1" to={list.link}>
          <p className="text-[#3066EA] pt-[px] cursor-pointer">Learn More</p>
        </Link>
        <div className="row-span-2"></div>
      </div>
    );
  });
  return (
    <>
      <div className="px-[28px] md:px-[40px] lg:px-[85px] max-w-[1500px] mx-auto py-[25px] md:py-[50px] lg:py-[100px]">
        <h1 className="text-center text-[24px] md:text-[28px] lg:text-[36px]">
          Next-Generation IT solutions we deliver
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-[25px] md:pt-[40px]">
          {listNextTech}
        </div>
      </div>
    </>
  );
}
