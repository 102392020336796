import React from "react";
import Banner from "../../../components/Banner";
import FooterBanner from "../../../components/Footer/FooterBanner";
import RightImageSection from "../../../components/RightImageSection";
import WhyChooseUsIndustries from "../../../components/WhyChooseUsIndustries";
import AccountingSystem from "./AccountingSystem";
import BankingSoftware from "./BankingSoftware";
import MobileBanking from "./MobileBanking";
import PaymentGateway from "./PaymentGateway";
import TwoImage from "./TwoImage";
const Data = [
  {
    id: 1,
    title: "Security",
  },
  {
    id: 2,
    title: "Easy Ingegration",
  },
  {
    id: 3,
    title: "Detailed Reporting",
  },
  {
    id: 4,
    title: "Invoicing Options",
  },
  {
    id: 5,
    title: "Multiple Payment Options",
  },
  {
    id: 6,
    title: "Fast Processing Speed",
  },
];
const BankingSoftwareData = [
  {
    id: 1,
    title: "Security",
  },
  {
    id: 2,
    title: "Easy Ingegration",
  },
  {
    id: 3,
    title: "Detailed Reporting",
  },
  {
    id: 4,
    title: "Invoicing Options",
  },
  {
    id: 5,
    title: "Multiple Payment Options",
  },
  {
    id: 6,
    title: "Fast Processing Speed",
  },
];
const MobileBankingData = [
  {
    id: 1,
    title: "Bank Account Management.",
  },
  {
    id: 2,
    title: "Advanced Security & Fraud Alerts.",
  },
  {
    id: 3,
    title: "CORE Banking Features.",
  },
  {
    id: 4,
    title: "QR Code Payments.",
  },
  {
    id: 5,
    title: "Mobile Check Deposits.",
  },
  {
    id: 6,
    title: "Peer-to-Peer Payments.",
  },
  {
    id: 7,
    title: "Bill Payments.",
  },
  {
    id: 8,
    title: "Intelligent Chatbots..",
  },
];
const Why = [
  {
    id: "1",
    title: "Technology",
    desc: "We use the latest and most sophisticated technology to ensure that you get the optimum benefits out of your investment. Aside from building a strong foundation for robust solutions, we also invest tremendously in future technologies to ensure future-ready developments.",
  },
  {
    id: "2",
    title: "Intuitive Interfaces",
    desc: "Our Fintech software solutions are built to offer the most dynamic solutions. With that in mind we ensure that users get the best intuitive interface that can be used instantly with no or minimum supervision.",
  },
  {
    id: "3",
    title: "Lower cost than our competitors",
    desc: "We take the pain to build cost effective and yet innovative solutions which delivers extreme value for the money because we understand that the Fintech spectrum overwhelms a more noteworthy populace in this age.",
  },
  {
    id: "4",
    title: "Multiple testing",
    desc: "Our products go through several stages of both intensive and extensive testing, quality assurance and practical usability testing to ensure the best end results. Great commitment and responsibility from our team are key factors to achieving this.",
  },
  {
    id: "5",
    title: "Quality first",
    desc: "We onboard the highest code quality and functionality of the product keeping in mind of all quality assurance standards throughout the software development life cycle (SDLC).",
  },
  {
    id: "6",
    title: "Dedicated team ",
    desc: " Our dedicated team comprises of industry inclined professionals and experts with extensive experience in the banking and finance operations. They all bring onboard their combined qualifications, experience, and expertise to develop the intended software at hand.",
  },
];
export default function Fintech() {
  return (
    <>
      <Banner
        img="/images/industries/fintech/fintech-banner.jpeg"
        title="Financial Services"
        desc="Developing intuitive business solutions for safe, digitally enabled financial transactions."
      />
      <RightImageSection
        img="/images/industries/fintech/your-worry-our-priority.jpeg"
        title="Custom Fintech Software Development"
        desc="It’s our priority to assist you with matching the range by giving most extreme systems planned through ceaseless innovative work. We help financial institutions leverage advanced technologies like Blockchain services, and analytic software solutions to enable them to drive insightful financial forecasting and budgeting for faster and better decision making, more accurate data, and less repetitive tasks than ever. With the changing customer needs and global competition, we convey effective, top notch client encounters with human-driven computerized arrangements."
      />
      <h2 className="lg:text-[32px] md:text-[28px] text-[20px] text-center py-[25px] md:py-[40px] font-medium lg:py-[50px]">
        Our Fintech App Development Expertise
      </h2>
      <PaymentGateway
        head="Payment gateways"
        desc="Cashless economy and online payments methods are continuously gaining standard practice in the world of economy and our system can be trusted entirely because of
        the following features."
        data={Data}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FFintech%2FpaymentGatewayBackground.jpg?alt=media&token=c3c9339d-de05-4be4-9c8b-3999f5c1f3a7"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      />
      <AccountingSystem
        head="Accounting System"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FFintech%2FAccountingScreem.jpg?alt=media&token=5ec9beb5-ad4a-4d83-8990-7511feeb9fa7"
        title="Accounting System"
        desc="Our accounting system covers all walks of the accounting lifecycle right from accounts payables, billings and accounts receivables, fixed assets, inventory, payroll, and financing activities, such as obtaining debt, selling shares, and paying interest to lenders etc. Depending on the volume of transactions being processed by your firm, we can make the necessary adjustments to suit your preference."
      />
      <BankingSoftware
        data={BankingSoftwareData}
        head="Banking Software"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FFintech%2FBankingSoftware1.png?alt=media&token=2bcbbe11-c042-469b-916f-4151e89e3d8f"
        title="Banking Software"
        desc="Our banking solutions provide the open, end-to-end functionality and model bank configurations, extend with ease, and deploy with flexibility. Features common to our banking software solution includes real-time account and transaction processing, multi-currency funds management, financial instrument workflow, and financial product builder and among many others. We also provide ancillary products based on your compliance policies. These are customizable interface and product workflow, retail banking, corporate Banking, wealth management and private banking, fund administration, credit unions, Islamic banking etc."
      />
      <MobileBanking
        head="Mobile Banking"
        desc="We utilize an exhaustive arrangement of functionalities to make the best-in-class answers for your end-clients by architecting and building custom online mobile banking apps with robust security features as its backbone.  We also give users the best online banking UI/UX and online banking system integration. This software features:"
        data={MobileBankingData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FFintech%2FpaymentGatewayBackground.jpg?alt=media&token=c3c9339d-de05-4be4-9c8b-3999f5c1f3a7"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      />
      <TwoImage />
      <WhyChooseUsIndustries
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FMask%20group.png?alt=media&token=739958f7-6b86-4578-b39c-9e929dc17804"
        data={Why}
      />
      <FooterBanner title="Send us your requirements" link="/contact-us" />
    </>
  );
}
