import React from "react";

export default function EHR(props) {
  return (
    <>
      <div
        style={{
          backgroundImage: `url("${props.img}")`,
        }}
        className=" text-white bg-cover bg-center bg-no-repeat"
      >
        <div className="conatiner max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px] flex lg:flex-row  flex-col md:flex-row w-full  py-[40px]">
          <div className=" m-auto w-full md:w-1/2 lg:w-2/3 pb-[10px]">
            <div className="m-auto w-full">
              <h2 className="m-auto  md:w-5/5 text-semibold  text-[24px] lg:text-[36px] text-left sm:mt-0 sm:mb-0 lg:text-left lg:mr-0">
                {props.title}
              </h2>
            </div>
          </div>
          <div className="conatiner text-white flex flex-col  w-full ">
            <div className="md:m-auto  w-full justify-center  ">
              <p className="m-auto lg:pl-[30%]   text-[16px] lg:text-[16px] text-left sm:mt-0 sm:mb-0 lg:text-left lg:mr-0">
                {props.desc}
              </p>
              {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
            </div>
            <div className="m-auto lg:pl-[30%] md:pl-[0%]  w-full pt-2 ">
              <ul className=" list-outside grid grid-cols-1 md:w-[100%]  md:grid-cols-2 lg:grid-cols-1  text-[16px] lg:text-[18px] m-auto  w-11/12 sm:py-2 leading-loose">
                {/* {dataList} */}
              </ul>
              {/* <p className="text-justify md:text-left  text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-center">
              Cloud technologies are the key enabler to achieve digital
              transformation. Innorik’s infrastructure management solution help
              businesses deliver high-quality, cost-effective, and secure
              application services on-demand. At Innorik, our digital
              transformation goal is to help your business achieve your desired
              cloud computing state across private, public and hybrid cloud
              environments. Our comprehensive cloud digitalization plan will
              enable your business to expand your application delivery chain
              including your mission-critical legacy systems into the cloud.
            </p> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="m-auto text-left lg:text-left bg-cover bg-right h-full sm:h-[] md:h-[] lg:h-full xl:h-[]"
        style={{
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Devops%2FdevopsProcess.jpg?alt=media&token=21baa78e-2ba2-4a66-aa51-ef38cd6dc9c9")`,
        }}
      >
        <div className="conatiner text-white md:center flex flex-col md:flex-row lg:flex-row md:flex-row-2 justify-evenly w-full lg:py-[30px] md:py-[40px] py-[56px] ">
          <div className="md:m-auto w-full justify-start">
            <h2 className="m-auto px-[5%] md:w-3/5 text-semibold  text-[24px] lg:text-[36px] text-left sm:mt-0 sm:mb-0 lg:text-left lg:mr-0">
              {props.heading}
            </h2>

          </div>
          <div className="m-auto lg:pl-[30%] md:pl-[0%]  w-full pt-2 ">
            <ul className=" list-outside grid grid-cols-1 md:w-[100%]  md:grid-cols-2 lg:grid-cols-1  text-[16px] lg:text-[18px] m-auto  w-11/12 sm:py-2 leading-loose">
              {dataList}
            </ul>
           
          </div>
        </div>
      </div> */}
    </>
  );
}
