import React from "react";
import { Link } from "react-router-dom";
import List from "./List";
import { FiInstagram, FiLinkedin, FiTwitter, FiFacebook } from "react-icons/fi";
import { AiOutlineYoutube } from "react-icons/ai";

const servicesData = [
  {
    id: 1,
    title: "Web Development",
    link: "/web-development",
  },
  {
    id: 2,
    title: "Mobile Development",
    link: "/mobile-development",
  },
  {
    id: 3,
    title: "Custom Software",
    link: "/custom-software",
  },
  {
    id: 4,
    title: "UI/UX Design",
    link: "/ui-ux-design",
  },
  {
    id: 14,
    title: "Responsive Design",
    link: "/responsive-design",
  },
  {
    id: 5,
    title: "Cloud Application Integration",
    link: "/cloud-application-integration",
  },
  {
    id: 6,
    title: "Application Migration",
    link: "/application-migration",
  },
  {
    id: 7,
    title: "Software Quality Engineering",
    link: "/testing",
  },
  {
    id: 8,
    title: "Cloud Enablement",
    link: "/cloud-enablement",
  },
  {
    id: 9,
    title: "DevOps",
    link: "/devops",
  },
  {
    id: 10,
    title: "Cloud Infrastructure",
    link: "/cloud-infrastructure",
  },
  {
    id: 11,
    title: "Cloud Consulting",
    link: "/cloud-consulting",
  },
  {
    id: 12,
    title: "Business Process Outsourcing",
    link: "/business-process-outsourcing",
  },
  {
    id: 13,
    title: "It Consulting",
    link: "/it-consulting",
  },
];

const industriesData = [
  {
    id: 1,
    title: "Education",
    link: "/industries/education",
  },
  {
    id: 2,
    title: "Manufacturing",
    link: "/industries/manufacturing",
  },
  {
    id: 3,
    title: "Retail",
    link: "/industries/retail",
  },
  {
    id: 4,
    title: "Healthcare",
    link: "/industries/healthcare",
  },
  {
    id: 5,
    title: "Insurance",
    link: "/industries/insurance",
  },
  {
    id: 6,
    title: "Professional Services",
    link: "/industries/professional-services",
  },
  {
    id: 7,
    title: "Transportation",
    link: "/industries/transportation",
  },
  {
    id: 8,
    title: "Fintech",
    link: "/industries/fintech",
  },
  {
    id: 9,
    title: "Real Estate",
    link: "/industries/real-estate",
  },
];
const solutionsData = [
  {
    id: 1,
    title: "ERP",
    link: "/technologies/erp",
  },
];
const companyData = [
  {
    id: 1,
    title: "About",
    link: "/about",
  },
  {
    id: 2,
    title: "Culture and Values",
    link: "/culture-and-values",
  },
  {
    id: 3,
    title: "Talent Worldwide",
    link: "/talent-world-wide",
  },
  {
    id: 4,
    title: "Our Partners",
    link: "/partnership",
  },
  {
    id: 5,
    title: "Contact us",
    link: "/contact-us",
  },
  {
    id: 5,
    title: "Career",
    link: "/career",
  },
];
const technologiesData = [
  {
    id: 1,
    title: "Virtual Reality ",
    link: "/latest-thinking/virtual-reality",
  },
  {
    id: 2,
    title: "IOT",
    link: "/latest-thinking/iot",
  },
  {
    id: 3,
    title: "Big Data",
    link: "/latest-thinking/big-data",
  },
  {
    id: 4,
    title: "Artificial Intellegence",
    link: "/latest-thinking/artificial-intellegence",
  },
  {
    id: 5,
    title: "Computer Vision",
    link: "/latest-thinking/computer-vision",
  },
  {
    id: 6,
    title: "Blockchain",
    link: "/latest-thinking/blockchain",
  },
];
export default function Footer() {
  const listServices = servicesData.map((list, index) => {
    return (
      <Link key={index} to={list.link}>
        <List title={list.title} />
      </Link>
    );
  });
  const listIndustries = industriesData.map((list, index) => {
    return (
      <Link key={index} to={list.link}>
        <List title={list.title} />
      </Link>
    );
  });
  const listSolutions = solutionsData.map((list, index) => {
    return (
      <Link key={index} to={list.link}>
        <List title={list.title} />
      </Link>
    );
  });
  const listCompany = companyData.map((list, index) => {
    return (
      <Link key={index} to={list.link}>
        <List title={list.title} />
      </Link>
    );
  });
  const listTechnologies = technologiesData.map((list, index) => {
    return (
      <Link key={index} to={list.link}>
        <List title={list.title} />
      </Link>
    );
  });
  return (
    <>
      <div className="pt-[50px]">
        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-[50px] lg:h-[550px] max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px]">
          <div>
            <a href="/">
              <img
                className=" my-auto h-[62px] py-[2px]"
                src="/images/logo-dark.svg"
              />
            </a>
            <p className="pt-[20px] text-[16px] text-[#444444]">
              Innorik is a North American based Information Technology company
              that specializes in the provision of IT consulting and software
              development services.
            </p>
            <p className="text-[20px] text-[#444444] pt-[80px]">
              info@innorik.com
            </p>
            <p className="text-[18px] text-[#444444] pt-[px]">
              +1 855-221-9979
            </p>
            <p className="text-[16px] text-[#444444] pt-[20px]">
              80 Broad Street, 5th Floor, New York City, New York 10004, USA
            </p>
            <div className="pt-[50px] ">
              <div className="flex">
                <p className="">
                  <a
                    href="https://www.instagram.com/innorik_llc/"
                    target="_target"
                  >
                    <FiInstagram className="text-[24px] text-[#444444] hover:text-[#bc2a8d]" />
                  </a>
                </p>
                <p className="pl-[20px]">
                  <a
                    href="https://www.facebook.com/Innorik-108665761835288/"
                    target="_target"
                  >
                    <FiFacebook className="text-[24px] text-[#444444] hover:text-[#0072b1]" />
                  </a>
                </p>
                <p className="pl-[20px]">
                  <a
                    href="https://www.linkedin.com/company/innorik/"
                    target="_target"
                  >
                    <FiLinkedin className="text-[24px] text-[#444444] hover:text-[#0072b1]" />
                  </a>
                </p>
                <p className="pl-[20px]">
                  <a
                    href="https://www.youtube.com/channel/UCccvbU6-s7riInJEbN_Kyeg"
                    target="_target"
                  >
                    <AiOutlineYoutube className="text-[24px] text-[#444444] hover:text-[#FF0000]" />
                  </a>
                </p>
                <p className="pl-[20px]">
                  <a href="https://twitter.com/Innorik1" target="_target">
                    <FiTwitter className="text-[24px] text-[#444444] hover:text-[#00acee]" />
                  </a>
                </p>
              </div>
              <p className="text-[16px] text-[#444444] pt-[20px]">
                Join Our Comunity
              </p>
            </div>
          </div>
          <div>
            <h2 className="text-[20px] text-[#444444] font-medium">Services</h2>
            <ul className="pt-[20px] leading-6	">{listServices}</ul>
          </div>
          <div className="grid lg:grid-rows-2 ">
            <div>
              <h2 className="text-[20px] text-[#444444] font-medium">
                Industries
              </h2>
              <ul className="pt-[20px] leading-6	">{listIndustries}</ul>
            </div>
            <div className="pt-[50px]">
              <h2 className="text-[20px] text-[#444444] font-medium">
                Solutions
              </h2>
              <ul className="pt-[20px] leading-6	">{listSolutions}</ul>
            </div>
          </div>
          <div className="grid lg:grid-rows-3">
            <div className="row-span-1">
              <h2 className="text-[20px]  text-[rgb(68,68,68)] font-medium">
                Company
              </h2>
              <ul className="pt-[20px] leading-6	">{listCompany}</ul>
            </div>
            <div className="pt-[50px] row-span-2">
              <h2 className="text-[20px] text-[#444444] font-medium">
                Technologies
              </h2>
              <ul className="pt-[20px] leading-6	">{listTechnologies}</ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-[40px] max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px]">
        <hr />
        <div className="grid lg:grid-cols-4	 py-[20px]">
          <p className="text-[16px] col-span-1 text-[#444444]">
            Copyright @2022 Innorik Solutions
          </p>
          <div className="col-span-2"></div>
          <p className="text-[16px] lg:pl-[40px] col-span-1 text-[#444444]">
            {/* <span>Privace Policy | </span> */}
            <Link to="/terms-and-conditions">Terms And Conditions</Link>
          </p>
        </div>
      </div>
    </>
  );
}
