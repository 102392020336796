import React from "react";
import Banner from "../../../../components/Banner";
import FooterBanner from "../../../../components/Footer/FooterBanner";
import WhyChoose from "../../../../components/WhyChoose";
import Overview from "../ArtificialIntellegence/Overview";
// import Overview from "../BigData/Overview";
import Services from "../BigData/Services";

const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fiot%2Fiot-consulting.jpeg?alt=media&token=78049640-2fd1-4fd9-8b6f-2c6f76a40d40",
    alt: "IoT consulting services ",
    title: "IoT consulting services ",
    desc: "We are here to assist clients in evaluating the technology landscape for Smart use of IoT in their businesses to improve their productivity. We listen and recommend the best solutions that best fit your requirements be it hardware or software in order to enhance your product and services.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fiot%2Ffull-stack-iot-development.jpeg?alt=media&token=a232e60b-dbb2-46a8-9a7a-9090e3bf2c07",
    alt: "iot development ",
    title: "Full Stack IoT Development",
    desc: " With our expertise we can offer IoT development services such as IoT Sensor Node Development, IoT Gateway Development, IoT Cloud, Analytics and Reporting, IoT Mobile and Web App Development and many more using the best frameworks available.",
  },

  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fiot%2Fiot-smart-solutions.jpeg?alt=media&token=f665267e-1214-4ced-a67d-f7438431f61e",
    alt: "iot solutions",
    title: " IoT Smart Solutions",
    desc: "Thinking about turning your home or office into a smart one? We can help you build smart applications to suit both your indoor and outdoor needs. We have different devices ready which can seamlessly integrate and connect to others to work together for a goal.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fiot%2Fiot-application-management.jpeg?alt=media&token=df3d5477-b6c0-4461-a31a-1b9db764d36b",
    alt: "application management",
    title: " Application management",
    desc: "We offer technical support and troubleshooting in relation to data quality and usage issues, cloud management, security management thus vulnerability assessment and prevention, application updates, upgrades and 24 x 7 support.",
  },

];
const Data3 = [
  {
    id: 1,
    title:
      "Extensive experience with IoT technology to develop comprehensive IoT solutions.",
  },
  {
    id: 2,
    title: "Flexible and versatile ",
  },
  {
    id: 3,
    title: "Easy and fast to install and activate ",
  },
  {
    id: 7,
    title: "Post-delivery services ",
  },
  {
    id: 4,
    title:
      "Cloud based solution – Highly available in real time, anytime, anywhere.  ",
  },
  {
    id: 5,
    title: "Adaptation to specific business needs ",
  },
  {
    id: 6,
    title: "24/7 support services ",
  },
  {
    id: 7,
    title: "Results oriented ",
  },
];


export default function Iot() {
  return (
    <>
      <Banner
        img="/images/technologies/latest-thinking/iot/iot-banner.jpeg"
        title="Internet of Things (IoT)"
        desc="Blurring the lines between your digital world and you innovatively."
      />
      <Overview
        textColor="text-white"
        title="Partner with us for your end-to-end IoT solutions"
        desc="With the evolving and adoption of technology, our present era is fully graced with appliances which are connected one way or the other to the internet from our very homes and industries to our enterprises. We have reached the stage where geography does not command our use of these gadgets because they are operable even if they are far away from us; and this has been made possible by IoT.  Our objective at Innorik is to use IoT as a medium for helping clients transform their businesses by taking the pain to understand your business needs and offering you the best IoT solutions that will accelerate your time and productivity on the market."
        img="/images/technologies/latest-thinking/iot/iot-overview.jpeg"
        img2="/images/technologies/latest-thinking/iot/iot-overview-mobile.jpeg"
        alt="Internet of things"
      />
      {/* <Overview
        title="Partner with us for your end-to-end IoT solutions"
        desc="With the evolving and adoption of technology, our present era is fully graced with appliances which are connected one way or the other to the internet from our very homes and industries to our enterprises. We have reached the stage where geography does not command our use of these gadgets because they are operable even if they are far away from us; and this has been made possible by IoT.  The focus of the IoT market spans across all walks of life be it manufacturing, healthcare, travel, transport and logistics, energy and utilities, retail etc. with no or limited implications in areas like security and privacy on your business.         "
        desc2="At Innorik, our objective is to use IoT as a medium for helping clients transform their businesses by taking the pain to understand your business needs and offering you the best IoT needs and solutions, thus accelerating your time and productivity on the market. We work hard to create custom solutions that fits your business square in the best possible way. "
      /> */}
      <Services title="Custom IoT services we offer" data={servicesData} />
      <WhyChoose
        textColor="text-white"
        desc="Extensive experience with the IoT technology to develop comprehensive IoT solutions. "
        head="Why choose Innorik for your IOT solutions?"
        data={Data3}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fiot%2Fwhy-choose-iot-linear.jpeg?alt=media&token=d8371275-5e83-4ba0-984f-0497d83b13b5"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fiot%2Fmobile%2Fiot-image.jpeg?alt=media&token=73fb0050-71cb-4d05-83fc-5ef97c806be8"
        alt=""
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
