import React from "react";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <>
      <div className="bg-fixed text-[#222222]   text-[] lg:py-[40px] bg-black"></div>
      <div className="grid  py-[50px] lg:grid-cols-2  max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px]">
        <img className="h-3/4" src="/images/404.svg" alt="" />
        <div className=" text-center m-auto text-[#444444]">
          <p className="text-[160px] text-[#2A467C]">404</p>
          <p className="text-[36px]">Page Not Found</p>
          <p className="text-[18px] pt-[10px]">
            we're sorry the page you requested could not be found
          </p>
          <p className="text-[18px]">Please go back to the homepage</p>
          <Link to="/">
            <button>
              <div className="py-[10px] px-[21px] mt-[30px] bg-[#BEC6ED] text-white">
                GO TO HOMEPAGE
              </div>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
