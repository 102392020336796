import React from "react";
import Banner from "../../components/Banner";
// import CardsWithImage from "../../components/CardsWithImage";
import WhyChoose from "../../components/WhyChoose";
import RightImageSection from "../../components/RightImageSection";
import HelpingHand from "./HelpingHand";
import ThreeFlipCol from "../../components/ThreeFlipCol";
import FooterBanner from "../../components/Footer/FooterBanner";
// import LinearImageBanner from "./LinearImageBanner";
// import ThreeFlipCol from "../../components/ThreeColCards";

const Data = [
  {
    id: 1,
    title: "Reduced cost to 50% of our competitors",
  },
  {
    id: 2,
    title: "Strategic partnership",
  },
  {
    id: 3,
    title: "Modernization",
  },
  {
    id: 4,
    title: "Client-first Approach",
  },
  {
    id: 5,
    title: "Large pool of experienced tech talents",
  },
  {
    id: 6,
    title: "Client collaboration with enhanced communication",
  },
  {
    id: 7,
    title:
      "We have all the skills and the domain expertise to get your job done",
  },
  {
    id: 8,
    title:
      "We increase customers engagement at all levels in the project life cycle ",
  },
];
const Data2 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2FCloud%20Migration-1.jpg?alt=media&token=bf57f920-48e9-4b4d-bc77-988cd4020eac",
    title: "Cloud Migration",
    desc: "Onsite data center to cloud, Cloud to cloud migration",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2FCloud%20App%20Development-1.jpg?alt=media&token=d8d78941-436e-4456-ab73-27e4533e871e",
    title: "Cloud App Development",
    desc: "Involved in building, and managing cloud apps on any cloud service provider",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2FCloud%20Security-1.jpg?alt=media&token=2bc0ff8f-dd23-442c-8ca8-9be90f4d7b43",
    title: "Cloud Security",
    desc: "We are security conscious, and we develop best strategies with technologies, polices, and services to protect cloud data, applications, and infrastructure from threats",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2Fcloud-integration.jpeg?alt=media&token=d126559d-b63e-46b5-9f6a-6726a60be70b",
    title: "Integration & Consolidation",
    desc: "Innovative strategies and architectures to help connect your heterogeneous systems",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2FCloud%20Consultancy-1.jpg?alt=media&token=98ff7beb-4791-4388-8e93-0d99d078d123",
    title: "Cloud Consulting",
    desc: "	With our proven cloud-first strategy and our expertise in cloud transformation, Innorik enables companies to accelerate their growth in digital transformation.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2FCloud%20Strategy-1.jpg?alt=media&token=ef692a36-40fe-41a0-b59e-abcaec9dd556",
    title: "Cloud Strategy",
    desc: "Innorik’s strategic cloud migration roadmap has enabled companies to successfully migrate to the cloud.",
  },
];

export default function CloudEnablement() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2FCloud%20Enablement%20banner-1.jpg?alt=media&token=7d726dbf-969d-46e0-81b3-a0dfea6ce8b6"
        title="Cloud Enablement"
        desc="Tap into the power of cloud computing"
      />

      <RightImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2Fcloud-enablement-overview.svg?alt=media&token=14f11026-4eea-45d4-a733-7d1f4391abac"
        title="Getting and staying ahead with Innorik’s cloud technologies"
        desc="Cloud computing has become the optimal way to deliver enterprise applications and the most preferred solution for organizations extending their IT infrastructure or introducing new innovations. Cloud technologies provide the speed, scalability, and flexibility that enables businesses to develop, innovate, and support their IT solutions. With the right cloud solutions, businesses can leverage a modern cloud computing architecture to innovate faster, increase productivity, and lower costs. "
        desc2="As your true strategic cloud partner, Innorik will work with you in every step of the way to quickly migrate, build, deploy, and scale applications both web, mobile and API. With our industry expertise, customer driven and cloud-first approach, we will develop the best strategy for your company to take advantage of cloud-native technologies and approaches, such as containers, kubernetes, microservices architecture, API-driven communications, and DevOps. "
      />
      <HelpingHand />
      <ThreeFlipCol title="Our Cloud Services" data={Data2} />
      {/* <CardsWithImage /> */}
      <WhyChoose
        textColor="text-white"
        head="Why choose Innorik for your Cloud solutions?"
        // img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
        data={Data}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2FWhy%20choose%20Cloud%20solutions%20with%20strip-1.jpg?alt=media&token=abedce8c-b733-43ac-9f50-ab04c1bc6212"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudEnablement%2Fwhy-us-for-cloud-enablement.jpeg?alt=media&token=f9a8dc54-3e5a-4a5d-892f-cd1ddbb0ac25"
      />
      {/* <LinearImageBanner/> */}
      <FooterBanner title="Got a project? Let's talk" link="/contact-us" />
    </>
  );
}
