import React from "react";

export default function TextSection() {
  return (
    <div className="bg-[#3066EA] ">
      <div className="conatiner mx-auto text-white flex  max-w-[1400px] px-[28px] lg:px-[85px] md:px-[40px]  flex-col  justify-evenly w-full lg:py-[50px] md:py-[40px] py-[56px] ">
        <div className="m-auto w-full ">
          <div className="">
            <h2 className="m-auto text-semibold text-[24px] lg:text-[36px] text-center sm:mt-0 sm:mb-0 sm:px-[60px]">
              Take a deep dive into the power of partnership
            </h2>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
        </div>
        <div className="m-auto w-full ">
          <p className="text-justify md:text-center lg:text-center text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] sm:py-10 sm:text-ceenter">
            Our continuous collaboration with the top established leaders of
            technology leads to new growth opportunities and brings to our
            clients all the advantages of the most current technologies. We
            partner with the most trusted names in technology which enable us to
            provide top-notch products and services to our customers.
          </p>
        </div>
      </div>
      {/* <div className="invisible lg:visible h-0 lg:h-[340px] text-left bg-black text-white grid lg:grid-cols-2 flex content-center justify-center pr-[85px] pl-[85px] ">
        <h2 className=" font-semibold text-[36px] w-[478px]">
          Take a deep drive into the power of partnership
        </h2>
        <p className=" pl-[29px] pr-[29px] text-[18px] ">
          Our continuous collaboration with the top established leaders of
          technology leads to new growth opportunities and brings to our clients
          all the advantages of the most current technologies. We partner with
          the most trusted names in technology which enable us to provide
          top-notch product and services to our customers.
        </p>
      </div>
      <div className="visible lg:invisible lg:h-0 text-center bg-black text-white pt-[56px] pb-[56px] lg:pb-0 lg:md:pt-0 md:pt-[40px]  md:pb-[40px] grid lg:grid-rows-2 lg:gap-4 items-center ">
        <h2 className=" font-semibold text-[16px] md:pl-[140px] md:pr-[140px] md:text-[26px]">
          Take a deep drive into the power of partnership
        </h2>
        <p className="pt-[16px] pl-[29px] pr-[29px] text-[14px] ">
          Our continuous collaboration with the top established leaders of
          technology leads to new growth opportunities and brings to our clients
          all the advantages of the most current technologies. We partner with
          the most trusted names in technology which enable us to provide
          top-notch product and services to our customers.
        </p>
      </div> */}
    </div>
  );
}
