import React from "react";
import Banner from "../../../../components/Banner";
import FooterBanner from "../../../../components/Footer/FooterBanner";
// import WhyChoose from "../../../../components/WhyChoose";
import WhyChooseText from "../../../../components/WhyChooseText";
import Overview from "../ArtificialIntellegence/Overview";
// import Overview from "../BigData/Overview";
import Services from "../BigData/Services";

const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fvirtual-reality%2Fvirtual-reality-consulting.jpeg?alt=media&token=9d33543d-0b95-4e42-9bac-ddfe2fb67864",
    alt: "consultation services",
    title: "VR consultation",
    desc: "Our consultants are well-versed in virtual reality technology and can assist you in determining the ideal VR apps for your business with the necessary functionalities.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fvirtual-reality%2F3d-modeling-and-visualisation.jpeg?alt=media&token=8f2ac7ad-b717-45af-b37b-9b1f17e3040b",
    alt: "3d modeling",
    title: "3D modelling and visualisation",
    desc: "Considering the potential for creativity, we make maximum use of VR to create content that contains highly realistic visuals, proprioceptive attributes, and engaging animations to stimulate your viewers engagement with the 3D models.  ",
  },

  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fvirtual-reality%2Fmulti-platform-vr-software-development.jpeg?alt=media&token=f101cdb5-51ba-4a94-8061-6364446249ae",
    alt: "VR integration and deployment",
    title: "VR integration and deployment",
    desc: "Our VR integration services assist our clients in integrating a number of work-related technologies in order to take advantage of VR and boost user performance dramatically.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fvirtual-reality%2Fvirtual-reality-support.jpeg?alt=media&token=6bcb64d8-f530-4562-8a87-c2af34a41f5b",
    alt: "support ",
    title: "VR support and maintenance",
    desc: "Our team of VR developers provides immediate support and maintenance services to deliver app continuity and optimize business processes. ",
  },
];
const Data3 = [
  {
    id: 1,
    title: "Customised and affordable VR price structure",
  },
  {
    id: 2,
    title: " Extensive experience in 3D modelling",
  },
  {
    id: 3,
    title: " 20+ years’ experience in IT",
  },
  {
    id: 4,
    title: "Ensuring asset authenticity",
  },
  {
    id: 5,
    title: "Post-delivery services",
  },
  {
    id: 6,
    title: "Cost-effective models and solutions",
  },
  {
    id: 7,
    title: "Proven competency & results oriented",
  },
];
const Why = [
  {
    id: "1",
    title: "Fast and cost-effective development",
    desc: "We are committed to providing excellent and dynamic solutions that are tailored to your company's goals, vision, and budget using our finest structural standards. ",
  },
  {
    id: "2",
    title: "Clear communication",
    desc: "Our professionals will assist you throughout the development process. We develop a communication strategy for our clients and team to help them stay up to date on project progress, feedbacks, and make modifications as needed.",
  },
  {
    id: "3",
    title: "Efficient project management ",
    desc: "We provide you with a project manager who prepares a document with a detailed product feature list, deadlines, and responsibilities. Thus, you’re continually aware of your project progress and ensuring the team will meet deadlines. ",
  },
  {
    id: "4",
    title: "Consistent delivery ",
    desc: "We employ agile technique to improve our development process's flexibility, speed, quality, and transparency. As a consequence, our team and clients benefit from a well-structured, iterative development process that ensures timely delivery of a useful final product.",
  },
  // {
  //   id: "5",
  //   title: "Client engagement and leveragability",
  //   desc: "We take a long-term approach to involvement with our clients, both during and after their transformation journeys. If our clients require it, we can use all or part of our enterprise level digital transformation platform (SmartOps) to ensure that an end-to-end digital transformation is possible.",
  // },
  // {
  //   id: "6",
  //   title: "Professional team",
  //   desc: "large team of highly qualified, certified, and experienced Artificial Intelligence professionals capable of providing best-in-class artificial intelligence services.",
  // },
];
export default function VirtualReality() {
  return (
    <>
      <Banner
        button="Talk to Experts"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fvirtual-reality%2Fvirtual-reality.jpeg?alt=media&token=d81b3373-801b-4f19-8e23-f00711399704"
        title="Virtual Reality (VR) Application Development"
        desc="Redefining the future with an interactive stimulated environment."
      />
      <Overview
        textColor="text-[#222222] bg-[#f9f9f9]"
        title="Future of Reality"
        desc="Virtual reality is a revolutionary technology that is proven to be an effective sales and marketing tool in various businesses. We have highly skilled and experienced VR app developers who can construct virtual reality experiences and environments that make you feel as if you are truly 'inside' that world. Our VR studio team is made up of both creative and technical professionals that can provide new VR solutions to tackle complicated business problems and also leverage platforms including Unity, ARCore, ARKit, Vuforia, etc. to design, develop and deploy these unique and engaging VR applications."
        img="/images/technologies/latest-thinking/virtual-reality/virtual-reality-overview.jpeg"
        img2="/images/technologies/latest-thinking/virtual-reality/virtual-reality-overview-mobile.jpeg"
        alt="Virtual reality"
      />
      {/* <Overview
        title="Top-notch Blockchain Development Company"
        desc="Recreating memories to make the impossible, possible.
        With the Recent advances in innovative technxology, giving more current and exciting ways for us to interact with each other and the world around us, one can contend that we have made a trip on schedule to what's to come. As of now, one greatest headway that has come into the bright lights is Virtual Reality. Virtual Reality (VR) creates amazing three-dimensional (3D) artificial/counterfeit worlds and immersive experiences for users by putting an intelligent layer over tangible reality.  The development services of VR focuses on the design and implementation of software that allows users interact with these simulated environments."
        desc2="At Innorik, we provide the medical, manufacturing, entertainment, educational and many other industries with immersive and interactive experiences like virtual tours, VR games, Surgery simulations, Flight simulators etc. We leverage platforms including Unity, ARCore, ARKit, Vuforia etc. to design, develop and deploy these unique and engaging VR applications. We cooperate with you on any level, right from providing you with great ideas to developing these applications to delivering a viable product rapidly and cost-effectively."
      /> */}
      <Services title="Our VR offerings " data={servicesData} />
      {/* <WhyChoose
        textColor="text-white"
        desc="Extensive experience with the IoT technology to develop comprehensive IoT solutions. "
        head="Why choose Innorik for your Virtual Reality?"
        data={Data3}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fvirtual-reality%2Fvirtual-reality-background.jpeg?alt=media&token=b8886f8a-f5e2-481c-ac6a-013908e9a7a1"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fvirtual-reality%2Ftwo-men-with-vr-headset.jpeg?alt=media&token=243e3e30-24a2-4aaa-8288-f78f10fde740"
      /> */}
      <WhyChooseText
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FProfessionalServices%2FwhyBackground.png?alt=media&token=03d970d1-9dd2-403e-8549-9c63d23568dd"
        data={Why}
        title="Why choose us?"
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
