import React from "react";
import Banner from "../../components/Banner";
import FooterBanner from "../../components/Footer/FooterBanner";
import FourColCard from "../../components/FourColCard";
import RightImageSection from "../../components/RightImageSection";
import WhyChoose from "../../components/WhyChoose";
// import Overview from "../Technologies/Programming/Php/Overview";
import OurCustomSoftware from "./OurCustomSoftware";
import WhyCustomSoftware from "./WhyCustomSoftware";
const Data = [
  {
    id: 1,
    title: "Full Ownership",
  },
  {
    id: 2,
    title: "Full Flexibility",
  },
  {
    id: 3,
    title: "Competitive Advantages",
  },
  {
    id: 4,
    title: "Technology Independent",
  },
];
const Data2 = [
  {
    id: 1,
    title: "Web Development",
  },
  {
    id: 2,
    title: "Mobile App Development",
  },
  {
    id: 3,
    title: "Api Development",
  },
  {
    id: 4,
    title: "Desktop App Development",
  },
];
const Data3 = [
  {
    id: 1,
    title: "No matter your budget, we are ready to work with you.",
  },
  {
    id: 2,
    title:
      "We have all the skills and the domain expertise to get your job done",
  },
  {
    id: 3,
    title: "We use latest technologies and best development approaches",
  },
  {
    id: 4,
    title: "We personalize and deliver extraordinary customer service",
  },
  {
    id: 5,
    title: "360 application maintenance and support",
  },
  {
    id: 6,
    title: "Knowledge transfer and easy exit process for customers.",
  },
  {
    id: 3,
    title: "We use latest technologies and best development approaches",
  },
  {
    id: 3,
    title: "Pool of experienced tech talents ",
  },
  // {
  //   id: 3,
  //   title: "We increase customers",
  // },
];
const Data4 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2FInternet-of-things.jpg?alt=media&token=0cb96ad5-f5e1-4890-a65a-540ec446870c",
    title: "Internet Of Things (IOT)",
    desc: "",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Fdata-science.jpg?alt=media&token=22da3769-50b2-49d1-b775-eb973478b8ef",
    title: "Data Science",
    desc: "",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Fartificial-intelligence.jpg?alt=media&token=5524ba4e-07e1-4a37-9ffa-e2f208d7672d",
    title: "Artificial Intellegence",
    desc: "",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Fcomputer-vision.jpg?alt=media&token=11097f47-a765-4d9c-96d5-90d2276e2d05",
    title: "Computer Vision",
    desc: "",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Fbig-data.jpg?alt=media&token=34f2fb00-c598-4ac3-ae5b-0dbc27bdc112",
    title: "Big Data",
    desc: "",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Faugmented-reality.jpg?alt=media&token=844c276a-5a7d-4a97-8b90-10f0b47b4367",
    title: "Argumented Reality",
    desc: "",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Fblock-chain.jpg?alt=media&token=9b93bc36-81ab-425a-a875-87e501c06623",
    title: "Blockchain",
    desc: "",
  },
  {
    id: 8,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Fvirtual-reality.jpg?alt=media&token=54b9d053-4ff7-4a88-88f3-ca07325161fb",
    title: "Virtual Reality",
    desc: "",
  },
];
export default function CustomSoftware() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Fcustom-software%20-development%20banner.jpg?alt=media&token=64dc61cc-69b8-4766-90bb-7e4e3ea98f57"
        title="Custom Software Development"
        desc="Winning in the digital age with a top-grade custom software that feels like there’s a soul inside."
      />

      <RightImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Fleverage-modern-technology.png?alt=media&token=37e614fa-f822-4ba2-ab59-49606432bb4c"
        title="Leverage modern technology to out-compete your competitors"
        desc="Companies looking for software to conduct business and to propel growth face a challenging puzzle, whether to invest in fully customized software solutions or go with an off-the-shelf application? What is your situation? Are you looking to build a reliable software to transform your internal business process or to solve a unique problem? At Innorik, our highly experienced software developers provide a world-class custom software development service to startups, small-to-midsize, and enterprise-size businesses. We convert your ideas into a solid, safe, reliable, and trustworthy custom software with our full life cycle software development services."
      />
      <WhyCustomSoftware
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Finvest%20in-custom-software0.png?alt=media&token=84180057-ca5e-49e9-9991-8e27eddedce4"
        head="Why Invest in Custom Software?"
        data={Data}
      />
      <OurCustomSoftware
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Finvest%20in-custom-software.png?alt=media&token=1f1702fb-a54b-45f7-a9a6-51521ca2dc7b"
        head="Our custom software services"
        data={Data2}
      />
      <FourColCard
        title="We are empowered by these innovative technologies"
        data={Data4}
      />
      <div className="justify-center">
        <h2 className=" md:pt-10 lg:pt-10 xl:pt-10 font-semibold  text-xl text-center pb-6 ms:text-2xl ms:pb-6 md:text-3xl md:pb-9 lg:text-4xl lg:pb-12">
          Our Software engineering Apporach
        </h2>
        <img
          className="invisible h-0 md:h-auto lg:h-auto xl:h-auto 2xl:h-auto md:visible lg:visible xl:visible 2xl:visible mx-auto px-4 lg:py-8"
          src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Four-approch.svg?alt=media&token=117c5080-6064-475b-8777-fab0c13b1d1d"
        ></img>
        <img
          className="visible h-auto md:h-0 lg:h-0 xl:h-0 2xl:h-0 md:invisible lg:invisible xl:invisible 2xl:invisible mx-auto px-4 py-4"
          src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Four-approch-mobile.svg?alt=media&token=77ea1819-9be1-4553-9c4e-2fc3c9f53b0f"
        ></img>
      </div>

      {/* <WhyChoose img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CustomSoftware%2FwhyUsforCustomSoftware.jpg?alt=media&token=6f6b7c3f-9731-4bdd-b6e8-ee340b5374b8" title="Why choose Innorik for your cloud consultancy services? " desc=""/> */}
      <WhyChoose
        textColor="text-white"
        head="Why choose Innorik?"
        data={Data3}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2Fwhy-choose-custom-software-development.jpg?alt=media&token=86a40d7c-4b15-417c-bd2d-dc29cca593cd"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCustomSoftware%2FWhy-choose-cloud-consultancyservices-mobile.jpeg?alt=media&token=6e107ac9-d7fc-41c5-a460-15f65624a687"
      />
      <FooterBanner title="Let's discuss your project" link="/contact-us" />
    </>
  );
}
