import React from "react";

export default function WhatWeOffer(props) {
  const data = props.data;
  const data2 = props.data2;
  const data3 = props.data3;
  const listData3 = data3.map((list, index) => {
    return (
      <div
        key={index}
        className="pb-[40px] md:pb-[50px] lg:pt-0 grid grid-cols-12"
      >
        <div className="col-span-2">
          <img className="h-[70px] w-[70px]" src={list.img} />
        </div>
        <div className="col-span-10 pl-[15px]">
          <h1 className="text-[20px] text-[#3066EA]">{list.title}</h1>
          <p className="text-[16px] pt-[25px] text-[#222222]">{list.desc}</p>
        </div>
      </div>
    );
  });
  const listData2 = data2.map((list, index) => {
    return (
      <div
        key={index}
        className="pb-[40px] md:pb-[50px] lg:pt-0 grid grid-cols-12"
      >
        <div className="col-span-2">
          <img className="h-[70px] w-[70px]" src={list.img} />
        </div>
        <div className="col-span-10 pl-[15px]">
          <h1 className="text-[20px] text-[#3066EA]">{list.title}</h1>
          <p className="text-[16px] pt-[25px] text-[#222222]">{list.desc}</p>
        </div>
      </div>
    );
  });
  const listData = data.map((list, index) => {
    return (
      <div
        key={index}
        className=" pt-[40px] md:pt-[50px] lg:pt-0 grid grid-cols-12"
      >
        <div className="col-span-2">
          <img className="h-[70px] w-[70px]" src={list.img} />
        </div>
        <div className="col-span-10 pl-[15px]">
          <h1 className="text-[20px] text-[#3066EA]">{list.title}</h1>
          <p className="text-[16px] pt-[25px] text-[#222222]">{list.desc}</p>
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="">
        <div
          className=" z-0 md:h-[300px]  lg:h-[470px] lg:bg- bg-no-repeat  bg-cover bg-center "
          style={{
            backgroundImage: `url("${props.img}")`,
          }}
        >
          <div className="bg-black/40 h-full">
            <h2 className=" w-4/5 mx-auto font-medium md:pt-[50px] py-[80px] lg:pt-[115px] text-white text-[26px] md:text-[36px] lg:text-[48px] text-center">
              {props.title}
              <span className="text-[#3066EA]">{props.title2}</span>
            </h2>
          </div>
        </div>
        <div className="md:mt-[-120px] lg:mt-[-180px] max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] mx-auto ">
          <div className="lg:py-[60px] bg-white md:shadow-xl md:px-[25px] lg:px-[50px]">
            <div className="grid  grid-cols-1 lg:grid-cols-2 lg:gap-[80px]">
              {listData}
            </div>
            <div className="invisible h-0 lg:h-auto lg:visible pt-[50px]">
              <hr className="pt-[50px]" />
            </div>
            <div className="grid  lg:grid-cols-2 lg:gap-[80px]">
              {listData2}
            </div>
            {props.show === "true" ? (
              <div>
                <div className="invisible h-0 lg:h-auto lg:visible pt-[50px]">
                  <hr className="pt-[50px]" />
                </div>
                <div className="grid  lg:grid-cols-2 lg:gap-[80px]">
                  {listData3}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
