import React from "react";
import Banner from "../../../components/Banner";
import FooterBanner from "../../../components/Footer/FooterBanner";
import RightImageSection from "../../../components/RightImageSection";
import WhyChoose from "../../../components/WhyChoose";
import WhyChooseUsIndustries from "../../../components/WhyChooseUsIndustries";
// import WhyChoose from "../../../components/WhyChoose";
// import WhyChooseWhiteBackground from "../../../components/WhyChooseWhiteBackground";
import BankingSoftware from "../Fintech/BankingSoftware";
// import PaymentGateway from "../Fintech/PaymentGateway";
import AssesmentSoftware from "./AssesmentSoftware";
// import EducationKey from "./EducationKey";
import Lms from "./Lms";
import UtilitySoftware from "./UtilitySoftware";
// import WhyChoose from "./WhyChoose";

// const Data = [
//   {
//     id: 1,
//     title: "User Management",
//   },
//   {
//     id: 2,
//     title: "Branding & Design",
//   },
//   {
//     id: 3,
//     title: "Content Management ",
//   },
//   {
//     id: 4,
//     title: "Responsive, Mobile-Friendly Design",
//   },
//   {
//     id: 5,
//     title: "Ensuring Compliance",
//   },
//   {
//     id: 6,
//     title: "Data Security & Intelligent interoperability",
//   },
// ];
const keyFeature = [
  {
    id: 1,
    title: "User Management",
  },
  {
    id: 2,
    title: "Branding & Design",
  },
  {
    id: 3,
    title: "Content Management",
  },
  {
    id: 4,
    title: "Responsive, Mobile-Friendly Design",
  },
  {
    id: 5,
    title: "Ensuring Compliance",
  },
  {
    id: 6,
    title: "Data Security & Intelligent interoperability",
  },
];
const Why = [
  {
    id: "1",
    title: "Timely project delivery",
    desc: "We guarantee that our processes are on point and your project will be delivered on time.",
  },
  {
    id: "2",
    title: "Flexible Cooperation",
    desc: "Choose a time or a dedicated team model of your choice. We are ready to always work with you",
  },
  {
    id: "3",
    title: "Dedicated team",
    desc: "If you are looking for a dedicated development team, our model is perfect for your needs. Hire a team from us to take full control of your resources",
  },
  {
    id: "4",
    title: "Quality first",
    desc: "We onboard the highest code quality and functionality of the product keeping in mind of all quality assurance standards throughout the software development life cycle (SDLC).",
  },
  {
    id: "5",
    title: "Experienced team & qualified developers",
    desc: "We have great and competent developers who go through thorough background checks through series of interviews both internally and externally and technical leads before employment. ",
  },
  {
    id: "6",
    title: "Lower cost compared to our competitors",
    desc: "All prices depend on the number of functionalities to be implemented and business analysis conducted.",
  },
];
export default function Education() {
  return (
    <>
      <Banner
        img="/images/industries/education/education-banner.jpg"
        title="Education"
        desc="Developing custom software in the education industry to transform the workforce of the future."
      />
      <RightImageSection
        img="/images/industries/education/education.svg"
        title="Our Proficiency"
        desc=" Recognizing the great potential of online knowledge and learning distribution systems in the world of advanced technology, Innorik focuses on building and maintaining the capabilities of e-learning solutions in the education industry. We have integrated the latest technology into the traditional form of learning to enhance and reinforce learning processes. We guarantee that all educational software development solutions offer the most engaging learning strategies and most up-to-date eLearning technologies. They are the best of their kind. The integration of sophisticated design concepts and seamless navigation gives so much exposure to our users with user-friendly design solutions for educational websites and e-learning systems. This enables our users to perform tasks with minimum time and efforts."
      />
      {/* <h1 className="py-[50px] text-[24px] md:text-[28px] lg:text-[32px] text-center">
        Our Offerings
      </h1> */}
      <h2 className="lg:text-[32px] md:text-[28px] text-[20px] text-center py-[25px] md:py-[40px] font-medium lg:py-[50px]">
        Our Offerings{" "}
      </h2>
      <BankingSoftware
        img="/images/industries/education/screens/student-information.png"
        title="Student Information System (SIS)"
        desc="Our SIS system comprises of curriculum, student, timetable attendance, gradebook managements, students portal, teachers’ portal, and guardian portal as well. This system provides not only administrators with required functionality but also helps improve their processes with regards to all parties involved"
      />
      <Lms
        img="/images/industries/education/screens/LarningManagementSoftware.png"
        title="Learning management system"
        desc="Our education software services assist you in creating an education ecosystem that smoothly combines all processes related to the design and construction of educational and training modules, the delivery of online courses, and the virtual delivery of education, to empower continuous improvement with deep insights in teaching and learning."
      />
      <AssesmentSoftware
        img="/images/industries/education/screens/assessmentSoftware.png"
        title="Assessment Software"
        desc="This software has unique features comprising of remote proctoring platform, user management, tracking and monitoring performance, managing, and exporting applicant data, assessment management, test designing tool, real-time reporting, and online marking."
      />
      <UtilitySoftware
        desc="Extensive experience with the IoT technology to develop comprehensive IoT solutions. "
        head="Utility Software"
        img="/images/industries/education/utility-software.jpg"
        img2="/images/industries/education/utility-software-mobile.jpg"
      />

      <WhyChoose
        mobileColor="bg-[#f9f9f9]"
        textColor="bg-[#f9f9f9] lg:bg-transparent text-[#222222]"
        head="Our Educational Software Key Features"
        img="/images/industries/education/our-educational-software-key-features.jpg"
        img2="/images/industries/education/our-educational-software-key-features-mobile.jpg"
        data={keyFeature}
      />
      <WhyChooseUsIndustries
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FMask%20group.png?alt=media&token=739958f7-6b86-4578-b39c-9e929dc17804"
        data={Why}
      />
      <FooterBanner title="Send us your requirements" link="/contact-us" />
    </>
  );
}
