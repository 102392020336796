import React from "react";
// import List from "../../components/Cards/List";

export default function Overview(props) {
  // const newData = props.data;
  // const dataList = newData.map((data, index) => {
  //   return <div>sdfsdf</div>;
  // });
  return (
    <>
      {/* change image for this section for mobile screen because it's hard to read text on image */}
      <div className=""></div>
      <div
        className="invisible h-0 md:h-auto lg:h-[407px] sm:h-0 lg:visible md:visible m-auto py-4 text-center lg:text-left bg-cover bg-right "
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <div className=" max-w-[1500px] grid grid-cols-12	 px-[85px] mx-auto  lg:flex-row  lg:py-[30px] md:py-[40px] py-[56px] ">
          <div className="col-span-6"></div>
          <div
            className={`md:m-auto w-full justify-start col-span-6 ${props.color}`}
          >
            <h2 className=" e  text-[24px] md:text-[24px] sm:text-[24px] lg:text-[28px] ">
              {props.title}
            </h2>
            <p className="pt-[4%] text-[16px] lg:text-[18px] ">
              Innorik has been delivering impactful and engaging top-notch web
              applications for leading companies across majority of industries
              globally. Leverage our unique and broad-ranging expertise to build
              any type of web products. We deliver comprehensive web development
              services with clear and trustworthy results and a clear
              development process. Our web application solutions will enable
              your organization to easily sail through the continuously changing
              technology and business ramifications.
            </p>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
        </div>
      </div>
      <div className="bg-black visible md:invisible lg:invisible xl:invisible md:h-0 lg:h-0 grid m-auto gap-0 w-full">
        <img
          className="w-full sm:h-[400px] object-cover object-center"
          src={props.img2}
        />
        <div className=" text-white pt-8 ">
          <div className="md:m-auto w-full justify-start px-[5%]">
            <h2 className="  text-semibold  text-[24px] text-left ">
              {props.head}
            </h2>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
        </div>
        <div className="bg-black text-white py-4 mt-0">
          <ul className=" text-left list-outside text-[16px] lg:text-[18px] m-auto  w-11/12 sm:py-2 leading-loose">
            jkjkj{" "}
          </ul>
        </div>
      </div>

      {/*       
      <div
        className="m-auto py-[15%] sm:py-[5%] md:py-[5%] lg:py-[5%] text-center lg:text-left bg-cover bg-right h-full sm:h-[] md:h-[] lg:h-full xl:h-[]"
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <h1 className="px-[20px]  lg:w-1/2  text-[24px] lg:text-[36px] sm:text-[40px] md:text-[40px]  text-white ">
          Your cloud transformation journey is unique, let’s give you a helping
          hand
        </h1>
        
      </div> */}
    </>
  );
}
