import React from "react";

export default function Overview() {
  return (
    <>
      <div className="conatiner  text-white flex flex-col lg:flex-row bg-gradient-to-b  bg-black/60 justify-evenly w-full lg:py-[30px] md:py-[40px] py-[56px] ">
        <div className="m-auto w-4/5 ">
          <div className="">
            <h2 className="m-auto text-white text-semibold text-[24px] lg:text-[36px] text-center sm:mt-0 sm:mb-0 sm:px-[60px] lg:text-left lg:mr-0">
              Inspiring innovation and discovery
            </h2>
            <img src=""></img>
          </div>

          {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
        </div>
        <div className="m-auto w-full ">
          <p className="text-justify md:text-left  text-[16px] lg:text-[18px] m-auto pt-[16px] lg:pt-[25px] md:pt[25px] w-11/12 sm:py-10 sm:text-center">
            Innorik is a North American based Information Technology company
            that specializes in the provision of IT consulting and software
            development services. We are not just another IT company, we are
            simply a think-tank that thinks outside the box and puts the wow in
            technology. We are powered by innovation, and it is the core of
            everything we do. Our relentless software engineering team of
            experts are pushing limits. At Innorik, our goal is to deliver with
            an unwavering integrity, “Best in Class” IT solutions, IT
            consultancy services and systems that help our customers stay one
            step ahead and out-compete their competition.
          </p>
        </div>
      </div>
    </>
  );
}
