import React from "react";
import Banner from "../../../components/Banner";
import RightImageSection from "../../../components/RightImageSection";
import Ecommerce from "../Retail/Ecommerce";
import CenterScreen from "../ProfessionalServices/CenterScreen";
import ProjectManagement from "../ProfessionalServices/ProjectManagement";
import CustomerRelationship from "./CustomerRelationship";
import WhyChooseWhiteBackground from "../../../components/WhyChooseWhiteBackground";
import FooterBanner from "../../../components/Footer/FooterBanner";
const ProjectData = [
  {
    id: 1,
    title: "Policy Management",
  },
  {
    id: 2,
    title: "Claims Management",
  },
  {
    id: 3,
    title: "Billing",
  },
  {
    id: 4,
    title: "Rating And Underwriting",
  },
  {
    id: 5,
    title: "Analysis and reporting",
  },
];
const MobileBankingData = [
  {
    id: 1,
    title: "Interoperability (Third-party integrations)",
  },
  {
    id: 2,
    title: "Customer service ",
  },
  {
    id: 3,
    title: "Employee tracking",
  },
  {
    id: 4,
    title: "Social media",
  },
  {
    id: 5,
    title: "Lead management",
  },
  {
    id: 6,
    title: "Real-time data",
  },
  {
    id: 7,
    title: "Analytics & Reporting",
  },
  {
    id: 8,
    title: "Sales forecasting & Email Services",
  },
];
const Why = [
  {
    id: "1",
    title: "Technology",
    desc: "We use the latest and most sophisticated technology to ensure that you get the optimum benefits out of your investment. Aside from building a strong foundation for robust solutions, we also invest tremendously in future technologies to ensure future-ready developments.",
  },
  {
    id: "2",
    title: "Intuitive interfaces",
    desc: "Our Fintech software solutions are built to offer the most dynamic solutions and with that in mind we ensure that users get the best intuitive interface that can be used instantly with no or minimum supervision.",
  },
  {
    id: "3",
    title: "Cost-effective solutions",
    desc: "We take the pain to build cost effective yet innovative solutions which delivers extreme value for your money because we understand that the Fintech spectrum overwhelms a more noteworthy populace in this age.",
  },
  {
    id: "4",
    title: "Multiple testing",
    desc: "Our products go through several stages of both intensive and extensive testing, quality assurance and practical usability to ensure the best end results. Great commitment and responsibility from our team are key factors to achieving this.",
  },
  {
    id: "5",
    title: "Quality first",
    desc: "We onboard the highest code quality and appropriate functioning of the end product keeping in mind of all quality assurance standards throughout the Software Development Life Cycle (SDLC)",
  },
  {
    id: "6",
    title: "Dedicated team",
    desc: "If you are looking for a dedicated development team, our model is perfect for your needs. Hire a team from us to take full control of your resources.",
  },
];
export default function Insurance() {
  return (
    <>
      <Banner
        img="/images/industries/insurance/insurance-banner.jpeg"
        title="Insurance"
        desc="Building intelligent custom insurance applications to accelerate the growth of your insurance business."
      />
      <RightImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FInsurance%2Fimage%20326.jpg?alt=media&token=b897fbd5-8594-4a12-bfa8-5020c8f298f2"
        title="Insurance applications tailored to your needs"
        desc="Insurance industry spans a wider spectrum as it touches life, health, property, and travel but keeps facing big blockers between great ideas and great customer experiences. We help insurance companies align with stakeholders on software development plans, to facilitate a change in broker and agent driven sales, education prospects, policyholder support, among others. to improve the customer experience and map out steady developments that results in positive momentum."
        // desc2="At Innorik we can help you(insurance companies) align stakeholders on software development plans, to facilitate a change for your broker- and agent-driven sales, education prospects, policyholder support, etc. to improve the customer experience and map out steady developments that gather positive momentum. The good news is that even small experience upgrades can make your insurance brand stand out and we at Innorik stand for."
      />
      <h2 className="lg:text-[32px] md:text-[28px] text-[20px] text-center py-[25px] md:py-[40px] font-medium lg:py-[50px]">
        Our app development offerings for Insurance
      </h2>
      <Ecommerce
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FPolicy%20management%20software%202%201.png?alt=media&token=25079c33-e890-4582-96c5-67dab713065a"
        title="Policy management software"
        desc="Our policy management software aids in creating a personal list of documents for quick and easy reference (documents bookmarks), custom metadata & replacement, access rights and security groups, reports and dashboards, reminders, attestation, document history, editing workflow, search documents among many others."
      />
      <CenterScreen
        title="Claims management software (CMS)"
        desc="Our CMS serves to automate workflows by helping our clients to manage their claims process efficiently, quickly, and with the highest possible security level. Our solutions include:"
        desc2="Effectively predicting fraud and maintaining a history for reference, Providing industry-leading business process maps that help enhance processes and reduce costs, Enabling integrated workflow management"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FInsurance%2FcrmBackground.jpg?alt=media&token=3aa19ffc-ffcf-44cc-8cb8-c7934a0e4eb6"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FworkManagement.png?alt=media&token=4f21ae80-ab6f-475f-b9d4-8ecc9b351f88"
        color="white"
      />
      <ProjectManagement
        head="Insurance Software"
        desc=" Our Insurance software solution is intended to assist insurance carriers, organizations or financiers with managing their everyday operations. The managerial aspect assists you with monitoring your policy and claims information, and also manage the administrative affairs of your team etc. There's a client side to this application, which permits your clients to sign in and do things like checking their policy information, fill out forms and make online installments. This fills in as a center to work with all of your organization's essential protection processes. Some of the major components of our solution are:"
        data={ProjectData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FInsurance%2FinsuranceSoftware.jpg?alt=media&token=8e1447ec-5f5a-45cd-88a6-417772c85b59"
      />
      <CustomerRelationship
        head="Customer Relationship Management Software (CRM)"
        desc="Our CRM solutions can do much more than organizing contacts. Our software can help you boost sales by keeping records of customers, from nurturing leads to closing deals and maintaining customer loyalty. It can also save you tons of time and money through sales force automation. Our CRM software features:"
        data={MobileBankingData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2Fcustomer%20relationship%20management.png?alt=media&token=f215f9f9-f795-4c8c-a31f-f3378a2fa379"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2Fcustomer%20relationship%20management.png?alt=media&token=f215f9f9-f795-4c8c-a31f-f3378a2fa379"
      />
      <WhyChooseWhiteBackground data={Why} />
      <FooterBanner title="Send us your requirements" link="/contact-us" />
    </>
  );
}
