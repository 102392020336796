import React from "react";
import Banner from "../../components/Banner";
import FooterBanner from "../../components/Footer/FooterBanner";
import ThreeColCards from "../../components/ThreeColCards";
import WhyChoose from "../../components/WhyChoose";
// import CloudInfrastructureManagement from "./cloudInfrastructureManagement";
import ImageTabs from "./ImageTab";
import Overview from "./Overview";
// import "./tab.css";

const Data = [
  {
    id: 1,
    title: "Scalibility",
  },
  {
    id: 2,
    title: "Cost-Effective",
  },
  {
    id: 3,
    title: "Integration",
  },
  {
    id: 4,
    title: "Flexibility",
  },
  {
    id: 5,
    title: "Zero risk faliure",
  },
  {
    id: 6,
    title: "Agility",
  },
];
const Data2 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudInfrastructure%2FEfficient%20IT%20Service%20Delivery-1.jpg?alt=media&token=d5e69313-0886-4cf2-991d-e5fd1102c4ed",
    title: "Efficient IT Service Delivery",
    desc: "",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudInfrastructure%2FCompliance%20with%20the%20industry%20standards-1.jpg?alt=media&token=6485f417-64d3-4c44-bae5-4013d4397df2",
    title: "Compliance with the industry standards",
    desc: "",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudInfrastructure%2FClient%20First-1.jpg?alt=media&token=e21d6d64-fba6-4cc8-9d78-17a7ec79a183",
    title: "Customer first approach ",
    desc: "",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudInfrastructure%2FLowest%20Cost-1.jpg?alt=media&token=372c53f4-94b1-488c-9698-7bb63c0ef2ba",
    title: "Lowest unimaginable cost",
    desc: "",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudInfrastructure%2FTransparency-1.jpg?alt=media&token=ba13430a-9017-42a1-b185-a4f3bd1a01ba",
    title: "Transparency",
    desc: "",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudInfrastructure%2F360%20support%20and%20maintenance-1.jpg?alt=media&token=4162c9ae-914c-4bba-b109-b014146425cd",
    title: "360 support and maintenance",
    desc: "",
  },
];

export default function CloudInfrastructure() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudInfrastructure%2FCloud%20Infrastructure%20Management%20Banner.jpeg?alt=media&token=85b51eeb-952f-4436-95ef-24c7c29759c8"
        title="Cloud Infrastructure Management"
        desc="Building resilient cloud infrastructure from the ground up "
      />
      <Overview
        title="Optimize Application Performance"
        desc="Cloud technologies are the key enabler to achieve digital transformation. Innorik’s infrastructure management solution helps businesses deliver high-quality, cost-effective, and secure application services on-demand. At Innorik, our digital transformation goal is to help your business achieve your desired cloud computing state across private, public and hybrid cloud environments. Our comprehensive cloud digitalization plan will enable your business to expand your application delivery chain including your mission-critical legacy systems into the cloud."
      />
      <ThreeColCards
        title="What you get from our Cloud Infrastructure Management"
        data={Data2}
      />
      <div className="py-16 px-6">
        <h1 className="text-center text-[26px] lg:text-[36px]">
          Snapshot of our Cloud Infrastructure Services
        </h1>
        {/* <p className="text-center text-[#222222] pt-6 text-[16px] lg:text-[18px] xl:text-[18px] lg:px-14">Our highly skilled cloud experts are ready to help your business use the latest cloud tools and technologies to connect your legacy applications, systems, repositories, and IT environments for the real-time exchange of data and process on all the major cloud service providers.</p> */}
      </div>

      <ImageTabs />
      <WhyChoose
        textColor="text-white"
        head="Why your company need cloud infrastructure management services?"
        data={Data}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudInfrastructure%2Fcloud%20infrastructure%20management%20services.jpeg?alt=media&token=29438ce7-ab66-4899-8db1-0a4ad4b6d46e"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FCloudInfrastructure%2Fcloud-in-hand.jpeg?alt=media&token=a2a79961-b29c-44a6-a34d-77ad8228e0ae"
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
