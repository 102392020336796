import React from "react";

export default function EagleView() {
  return (
    <div
      className="bg-[#042129] bg-center"
      style={{
        backgroundImage: `url("/images/services/testing/software-quality-assurance.jpeg")`,
      }}
    >
      <div className=" m-auto max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] py-[15%] sm:py-[5%] md:py-[5%] lg:py-[5%] text-center lg:text-left bg-cover bg-right h-full sm:h-[] md:h-[] lg:h-full xl:h-[]">
        <h1 className="lg:w-1/2   text-[20px] lg:text-[24px] sm:text-[20px] md:text-[24px]  text-white ">
          Our Software Quality Assurance team is equipped with industry’s best
          testing tools, techniques, practices, and QA strategies that have the
          eagle’s eye view to catch any defect in any application.
        </h1>
      </div>
    </div>
  );
}
