import React from "react";

export default function DesignInfographic(props) {
  return (
    <div className="conatiner flex-col-reverse max-w flex lg:flex-row  max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px]   w-full   py-10">
      <div className="m-auto text-[#222222]  w-full md:w-11/12 lg:w-[50%]">
        <h2
          style={{ lineHeight: "2.5rem" }}
          className=" text-[#3066EA]	 md:pt-[30px] font-medium text-center lg:text-[left] text-[24px] md:text-[24px] sm:text-[24px] lg:text-[28px] sm:text-lg m-auto py-0  sm:py-2 lg:text-left"
        >
          {props.title}
        </h2>
        <p className="text-center pt-[4%] md:text-center lg:text-left text-[16px] lg:text-[18px] m-auto   sm:py-2 sm:text-left ">
          At Innorik, our designers embark on iterative user experience design
          process that helps you continuously improve and polish your designs.
          We go through different stages repeatedly while evaluating designs on
          each stage. Each stage involves relevant stakeholders in your
          organization that take part in the process to make your products
          highly efficient and usable.
        </p>
      </div>
      <div className="m-auto w-full md:w-3/5  pb-[10px]">
        <div className="m-auto w-full sm:w-`7/12 md:w-full lg:w-4/5">
          <img
            className="m-auto w-full md:mt-0  md:mb-0 md:w-full ld:ml-13 lg-full"
            src={props.img}
            alt={props.alt}
          />
        </div>
      </div>
    </div>
  );
}
