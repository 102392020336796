import React from "react";
import Banner from "../../components/Banner";
import RightImageSection from "../../components/RightImageSection";
import NewTab from "./NewTab";
import WhatCard from "./WhatCards";
import AccordianSection from "./AccordianSection";
import TechnologiesScroll from "../../components/TechnologiesScroll";
// import Overview from "./Overview";
import FooterBanner from "../../components/Footer/FooterBanner";
// import { faJava } from "@fortawesome/free-solid-svg-icons";

const Data = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Ficosn%2FMaintenance%20and%20Support.svg?alt=media&token=8a8181a9-6677-4574-8f29-21fa254eeea5",
    title: "",
    desc: " Maintenance and support ",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Ficosn%2Fanalyzed.svg?alt=media&token=4657146a-9bc2-4d23-a6ec-d6a94320a672",
    title: "",
    desc: "Carefully analyzed requirements",
  },

  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Ficosn%2FStand%20out%20UI%20%26%20UX%20design.svg?alt=media&token=9f000a45-9322-444d-adc6-f09877d10093",
    title: "",
    desc: "Stand-out, easy-to-navigate UI & UX design",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Ficosn%2FScalable.svg?alt=media&token=3c6ddfa3-58c1-45ca-9e39-49cea474c1f2",
    title: "",
    desc: "Scalable and secure solution that works fast",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Ficosn%2FResponsive%20design.svg?alt=media&token=7186f8f2-007a-4654-b9a6-e89419e42b64",
    title: "",
    desc: "Responsive design",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Ficosn%2FEngaging%20Visuals.svg?alt=media&token=3a5eb5d7-03d1-42e1-805e-35562737a0b3",
    title: "",
    desc: "Rich and engaging visuals",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Ficosn%2Faffordable.svg?alt=media&token=4f4ccfa0-972a-45e0-8305-086b4b29ef80",
    title: "",
    desc: "Very much affordable than our competitors",
  },
  {
    id: 6,
    img: "/images/services/web-development/icons/experience.svg",
    title: "",
    desc: "We personalize and deliver extraordinary customer experience",
  },
];
export const Data4 = [
  {
    question: "Web Application Development  ",
    answer:
      "From design to integration to maintenance we offer full-cycle web application development services. We build apps using PHP, Ruby on Rails, Python, Nodejs and Go that are secure, scalable, fast, and work well across all devices and platforms.",
    answer2:
      "Examine your current applications and services, find the right mix of cloud solutions for your workloads, identify gaps in technology and skills and develop a holistic migrating strategy and plan for individual workloads or an entire data center.",
  },

  {
    question: "Ecommerce Web Development ",
    answer:
      " Build an online ecommerce store with seamless and user-friendly experience, the perfect extensions, and reliable integration with your other processes. Our ecommerce web development services include building full-scale ecommerce platforms.",
    answer2:
      " Build an online ecommerce store with seamless and user-friendly experience, the perfect extensions, and reliable integration with your other processes. Our ecommerce web development services include building full-scale ecommerce platforms.",
  },
  {
    question: "Java web development ",
    answer:
      "Our experienced Java developers can create functional and scalable web applications to accelerate the digital growth of your business.",
    answer2:
      "Our cloud advisors provide services that will monitor your cloud environment of any security threats    ",
  },
  {
    question: "Custom Web Development  ",
    answer:
      "Build a custom solution for your business from the group-up. Our custom web development services allow you to create cross-platform solutions for any industry regardless of scale or complexity.",
    answer2:
      "Our cloud consultants ensures that workload performance, compliance, and cost are correctly and continually balanced against the best-fit infrastructure in real time.",
  },
  {
    question: "CMS Web Development ",
    answer:
      "Build web site or web application of any complexity with our experienced CMS web developers from comprehensive module management to building it from scratch.",
    answer2:
      "Maximize agility and elevate ROI by integrating infrastructure and apps with best architecture.",
  },
  {
    question: "Dot Net Web Development ",
    answer:
      "We help businesses create their enterprise web applications using Dot Net Web development.",
    answer2:
      "Leverage the benefit of microservices architecture with containerization, flexible scaling, and better deployment",
  },
];
export default function WebDevelopment() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Fweb-application-development.jpeg?alt=media&token=ba39b4f4-1436-490a-91d9-b11e6bfe2b86"
        title="Web Application Development"
        desc=""
      />
      {/* <Overview
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Fweb-development.jpeg?alt=media&token=808fd6a2-b8d7-427b-8d33-3cb31bbd634d"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Fweb-development.jpeg?alt=media&token=808fd6a2-b8d7-427b-8d33-3cb31bbd634d"
        head="Web Development Services"
      /> */}
      <RightImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FwebDevelopment%2Fweb-dev.png?alt=media&token=fdb99437-214b-4def-b62d-380cf874ebb9"
        title="Web development services"
        desc="Innorik has been delivering impactful and engaging top-notch web applications for leading companies across many industries globally. Leverage our unique and broad-ranging expertise to build any type of web products. We deliver comprehensive web development services with clear and trustworthy results and a clear development process. Our web application solutions will enable your organization to easily sail through the continuously changing technology and business ramifications."
      />

      {/* <FontAwesomeIcon icon={faJava}/> */}
      {/* <Tab /> */}
      <h2 className=" text-center lg:text-[32px] text-[20px] md:text-[28px] py-[50px]">
        TYPES OF WEB DEVELOPMENT SERVICES
      </h2>
      <div className="invisible h-0  lg:h-auto lg:visible xl:visible 2xl:visible">
        <NewTab />
      </div>
      <div className="visible lg:invisible lg:h-0 ">
        <AccordianSection data={Data4} />
      </div>

      <WhatCard
        title="WHAT YOU GET?"
        desc="Our sleek development process and experience allow us to deliver outstanding web development services that tick all the boxes."
        data={Data}
      />
      {/* <CoreValues title="" data={Data} /> */}
      {/* <Technologies/> */}
      {/* <WhatYouGet
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/tempImages%2Fwhatyouget%204.jpg?alt=media&token=1d595a2a-7871-40f5-af3b-17ff729f8b06"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/tempImages%2Fwhatyougetnolinear.jpg?alt=media&token=69a9952a-37c6-4677-9796-b727a35a5a30"
      /> */}
      <div>
        <h1 className="text-center pt-[50px] text-[24px] md:text-[26px] lg:text-[36px]">
          Our Technologies
        </h1>
        <TechnologiesScroll />
      </div>
      <FooterBanner title="Let's discuss your project" link="/contact-us" />
    </>
  );
}
