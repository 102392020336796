import React from "react";

export default function WhyChooseWhiteBackground(props) {
  const Data = props.data;
  const listData = Data.map((list, index) => {
    return (
      <div key={index} className="lg:py-[30px] md:py-[15px]">
        <h2 className="pt-[25px] text-[18px] md:text-[20px] lg:text-[24px] ">
          {list.title}
        </h2>
        <p className="text-[16px] text-[#7B7B7B] pt-[25px] lg:text-[18px] ">
          {list.desc}
        </p>
      </div>
    );
  });
  return (
    <>
      <div className=" ">
        <div className="max-w-[1500px] text-[#222222] lg:px-[85px] md:px-[40px] px-[28px] mx-auto py-[50px]">
          <h1 className="text-[36px] "> Why choose us?</h1>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-x-[35px]">
            {listData}
          </div>
        </div>
      </div>
    </>
  );
}
