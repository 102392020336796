import React from "react";
import Banner from "../../../../components/Banner";
import Industries from "../../../../components/NewIndustries";
import Offerings from "../Php/Offerings";
// import Overview from "../Php/Overview";
import Services from "../Php/Services";
import WhyChooseUs from "../Php/WhyChooseUs";
// import Advantages from "../Java/Advantages";
import OurDevelopers from "../../Platforms/Magento/OurDevelopers";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import { phpDevelopers } from "../../../../data/programming";

// const advantageData = [
//   {
//     id: 1,
//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Fidea.png?alt=media&token=172c733f-269c-4584-b8ca-6fd7e228b6e9",
//     title: "Client-side Execution",
//     desc: "Javascript codes needs not to be submitted to server for processing and this reduces load time significantly",
//   },
//   {
//     id: 2,
//     img: "",
//     title: "Interface Interactivity",
//     desc: "JavaScript's speed is a significant advantage. The user interface interaction of JavaScript is fully utilized by our professional JavaScript developers",
//   },
//   {
//     id: 3,
//     img: "",
//     title: "Responsive Design",
//     desc: " Provision of the greatest experience across all platforms by unifying HTML, CSS, and JavaScript in a single code base.",
//   },
//   {
//     id: 4,
//     img: "",
//     title: "Platform Independent",
//     desc: "JavaScript frameworks are simple to install and maintain, regardless of platform.",
//   },
//   {
//     id: 4,
//     img: "",
//     title: "Versatile and Updated",
//     desc: "JavaScript apps work in a variety of browsers and operating systems.",
//   },
//   {
//     id: 4,
//     img: "",
//     title: "Offline Support",
//     desc: "You can enjoy responsive and usable app features even if you don't have an active internet connection.",
//   },
// ];
const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fillustrations%2FC%2B%2B-System-Programming.svg?alt=media&token=b2800803-8c4f-4f03-8ac4-5b7cd8b73ef7",
    title: "C++ System Programming",
    desc: "With Innorik's C++ system programming service, your business is guaranteed to get a stable and trustworthy system.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fillustrations%2FC%2B%2B%20application-development.svg?alt=media&token=7655aaad-d9fb-410f-be89-0ad3a2dd634e",
    title: "C++ Application Development",
    desc: "We create high performance, efficient, cost-effective C++ applications for your business to help you leverage the powerful performance of the robust C++ language",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fillustrations%2FC%2B%2B-Application-testing.svg?alt=media&token=85ac3bfb-772a-4763-be1b-abbaa9486dfa",
    title: "C++ Application Testing ",
    desc: "We provide reliable C++ application testing services for your app.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fillustrations%2FC%2B%2Bdesktop-software.svg?alt=media&token=1c754f69-6b4c-4e62-926c-3f29a93dcb60",
    title: "C++ Desktop Software Development",
    desc: "A solid desktop software platform can serve as a backbone for your company, and our programmers have very good experience to help your business achieve its digitalization goals.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fillustrations%2FC%2B%2BEmbeddedSoftware.svg?alt=media&token=6b9e832f-a88f-4e8b-86d7-94ae812a9c21",
    title: "C++ Embedded Software Development",
    desc: "When it comes to embedded software development, C++ is a solid choice, and our programmers excel in this area.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fillustrations%2FC%2B%2B-Server-side.svg?alt=media&token=73bf3067-e01b-4f4e-a765-522566c875d6",
    title: "C++ Server-side Software Development",
    desc: "The development of server-side software necessitates a robust language, such as C++ with the expertise of Innorik.",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fillustrations%2FC%2B%2B-Mobile-Application.svg?alt=media&token=ab81af0b-7458-4942-a943-6fdeaf369dcf",
    title: "C++ Mobile Application Development",
    desc: "Mobile apps are the need of the hour for any business. Innorik has smart C++ mobile app developers for building any mobile app of any complexity to elevate your business.",
  },
];
const Data2 = [
  {
    id: 1,
    title: "Highly skilled in-house team of C++ developers",
  },
  {
    id: 2,
    title: "Cost effective engagement model",
  },
  {
    id: 3,
    title: "Portal development",
  },
  {
    id: 4,
    title: "Comprehensive quality assurance ",
  },

  {
    id: 6,
    title: "Maximum Speed and flexibility",
  },
  {
    id: 8,
    title: "Agile software development",
  },
  {
    id: 9,
    title: "360-degree Requirement Analysis",
  },

  {
    id: 10,
    title: "Reduced cost to 50% of our competitors",
  },
];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];

const offeringData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2FCpp%2FIcons%2Fportablility.png?alt=media&token=739038e4-79aa-4e0f-863e-673b8e1eb09a",
    title: "Portability",
    // desc: "C++ is a highly portable language and can be used to write both large-scale applications and performance-critical code.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2FCpp%2FIcons%2Fobject%20-oriented.png?alt=media&token=50371988-25dd-4aa0-a969-ff9b5f589b9c",
    title: "Object-oriented",
    // desc: "OOP is faster and easier to execute. OOP provides a clear structure for the programs. ",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2FCpp%2FIcons%2Fscalability.png?alt=media&token=730ec98a-7f0e-4535-8c5d-cc9ddcaf8ab6",
    title: "Scalability",
    // desc: "if a new requirements come to you, in how much of a change, you can adapt to that requirement.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2FCpp%2FIcons%2Fbooks%201.png?alt=media&token=0fa74a54-a239-40e6-a21f-e0c14c106148",
    title: "Extensive libraries",
    // desc: "C++  have large collection of libraries which can be used at different types of project.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2FCpp%2FIcons%2Ffast%20development.png?alt=media&token=72da8922-3921-4b35-94c3-548e8aa094da",
    title: "Fast development",
    // desc: "C++ work in a variety of browsers and operating systems.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2FCpp%2FIcons%2FPerformance.png?alt=media&token=bc49d9df-630f-437c-828a-de6a6cfe2123",
    title: "Performance Speed",
    // desc: "Code performance is important because it limits the amount of data a program can handle. ",
  },
];
export default function Cpp() {
  return (
    <>
      <Banner
        button="Talk to Experts"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fcpp.jpeg?alt=media&token=4451dfa9-5331-43ee-b3b7-b4d30671ce59"
        title="C++ Development Services"
        desc="Building highly scalable enterprise solutions with next-gen C++ development."
      />
      <Overview
        textColor="text-[#222222] bg-white"
        title="C++ Overview"
        desc="C++ is one of the most widely used object-oriented programming languages for developing cross-platform and multi-device apps. C++ is commonly utilized for creating well-organized programs because it combines higher capacity and better software performance. Furthermore, this programming language is compiled and may be used on a variety of platforms, with the best compatibility with C of any other language. Our C++ programmers understand how to take advantage of the languages' major features. Because of its flexibility, speed, and scalability, it may be used to create both simple high-level applications and high-performance systems. "
        img2="/images/technologies/programming/cpp/cpp-overview-mobile-white.jpeg"
        img="/images/technologies/programming/cpp/cpp-overview-white.jpeg"
        alt="cpp"
      />
      {/* <Overview
        color="bg-black"
        textColor="text-white"
        title="C++ Overview"
        desc="C++ is one of the most widely used object-oriented programming languages for developing cross-platform and multi-device apps. C++ is commonly utilized for creating well-organized programs because it combines higher capacity and better software performance. Furthermore, this programming language is compiled and may be used on a variety of platforms, with the best compatibility with C of any other language."
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fcpp-no-linear.jpeg?alt=media&token=eeadf471-4856-4454-b930-9189c354fe0b"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fcpp-overview.jpeg?alt=media&token=d3f0874d-483f-4d7f-814f-affc42b656b1"
      /> */}
      {/* <Advantages
        total="6"
        head="Why choose C++"
        data={advantageData}
      /> */}
      <Offerings
        title="Why choose C++?"
        desc=""
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2Fphp%2Fofferings.png?alt=media&token=d3fd7a68-2751-45e1-b71d-5e9fcce9184b"
        data={offeringData}
      />
      <Services
        title=" Our C++ Development Offerings"
        desc=""
        img=""
        data={servicesData}
      />
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our expertise"
        desc="Innorik has a large team of C++ developers who have extensive experience with the language due to working with various project domains such as banking, telecommunication, healthcare, insurance systems, and with different companies. Our developers have experience ranging from 1-20 years.  "
        button="Hire C++ Expert"
        data={phpDevelopers}
      />
      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FCpp%2Fcpp-developers-profile.jpeg?alt=media&token=b1725ce9-0888-4360-97d7-f37055e5c718"
        title="Our C++ Experts"
        desc="Innorik has a large team of C++ developers who have very good experience with the language from working with various project domains such as banking, telecommunication, healthcare, insurance systems and with different companies. Our developers have experience ranging from 1year to 20 years."
        button="Hire C++ Expert"
      /> */}
      <WhyChooseUs title="Why choose us?" desc="" data={Data2} />
      <Industries
        title="Industies Served"
        desc="We are international company and we deliver technology solutions to clients globally."
        data={Indust}
      />
    </>
  );
}
