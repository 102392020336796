import React, { useState, useEffect } from "react";

export default function Services(props) {
  const data = props.data;

  const dataCount = data.filter((item) => item.id);
  const dataNumber = dataCount.length;
  // console.log(dataNumber);

  const [items, setItems] = useState([]);
  const [showMoreItems, setShowMoreItems] = useState(6);
  const [showButton, setShowButton] = useState(true);
  const [hide, setHide] = useState(0);
  // console.log(showMoreItems + "before");

  const showMoreDataHandler = () => {
    // setShowButton()
    setShowMoreItems((prevValue) => prevValue + 6);
    // console.log(showMoreItems + "inside");
    if (showMoreItems <= dataNumber) {
      // console.log(showMoreItems + "OnClick");
      setShowButton(false);
    }

    // setHide(prevValue => prevValue +
  };
  useEffect(() => {
    setItems(data);
  }, []);

  // const [items, setItems] = useState(4);

  const listData = items.slice(0, showMoreItems).map((item, index) => {
    return (
      <div key={index}>
        <img
          className="h-[240px] mx-auto w-4/5"
          src={item.img}
          alt={item.alterText}
        />
        <h2 className=" text-center pt-[20px] text-[18px] text-[#3066EA] font-medium lg:text-[20px]">
          {item.title}
        </h2>
        <p className="text-center text-[#6D6D6D] ">{item.desc}</p>
      </div>
    );
  });
  // D: 964 198 6649
  // Passcode: Global123
  return (
    <>
      <div className="">
        <div className="max-w-[1500px] mx-auto px-[28px]  md:px-[40px] lg:py-[50px] py-[25px] lg:px-[85px]">
          <div className="text-center  lg:px-[100px]">
            {props.title ? (
              <h1 className="text-[24px]  text-[#3066EA]  lg:text-[36px]">
                {props.title}
              </h1>
            ) : null}
            {props.desc ? (
              <p className="text-[16px] lg:text-[18px] text-[#222222] pt-[16px] lg:pt-[26px]">
                {props.desc}
              </p>
            ) : null}
          </div>

          <div className="grid lg:py-[40px] md:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-8 md:gap-[40px] lg:gap-[60px] ">
            {listData}
          </div>
          {showButton ? (
            <div>
              {dataNumber > 3 ? (
                <div className="  pt-[20px]   mx-auto text-center	">
                  <button
                    onClick={showMoreDataHandler}
                    className="text-center text-white pl-auto mx-center py-[8px] px-[20px] bg-[#428f66]"
                  >
                    Show More
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
