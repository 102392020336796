import React from "react";
import Banner from "../../../../components/Banner";
import FooterBanner from "../../../../components/Footer/FooterBanner";
// import WhyChoose from "../../../../components/WhyChoose";
import WhyChooseText from "../../../../components/WhyChooseText";
// import WhyChooseUsIndustries from "../../../../components/WhyChooseUsIndustries";
// import Offerings from "../../Programming/Php/Offerings";
// import Overview from "../BigData/Overview";
// import Overview from "../../Programming/Php/Overview";
import Services from "../BigData/Services";
import Overview from "./Overview";
const offeringData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fai%2Fai-technology-consulting.jpeg?alt=media&token=b0cd9246-ad2d-4e3e-b683-9bf268b355f3",
    alt: "Artificial Intellengence consulting",
    title: "AI technology consulting",
    desc: "Our expert AI consultants can help you conduct a feasibility study on the integration of AI solutions into your software and help you realise the potential benefits and risk, cost etc. that can come from the implementation process. We can also help outline a development, deployment and integration plan for building AI powered softwares thus evaluating the real value of the AI opportunity.  ",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fai%2Fcomputer-vision.jpeg?alt=media&token=699546e1-f3a2-4203-a5a9-ec3e71278a49",
    alt: "Computer vision services",
    title: "Computer Vision Services",
    desc: "Computer vision enables text-image-video analytics services for quick text and image-based classification. In that line, our AI solutions use computer vision to speed up smarter automation with simple tools for image processing, image recognition and object detection whether living or non-living objects in the images or scenes. We can achieve this by integrating our own solutions or helping you build your own.",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fai%2Fai-ml-development.jpeg?alt=media&token=860191d3-2cdc-4119-b112-7991c31cabf4",
    alt: "Machine Learning Development",
    title: "Machine Learning Development",
    desc: "Through our development approach, our solid team can assist you in driving better changes of your workflows and technologies. From conception to production, we can assist you in deciding on, designing, developing, running, maintaining, and scaling AI and machine learning solutions.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fai%2Fanalytics-services.jpeg?alt=media&token=613c3d78-6468-4326-abdb-0ef8070ce95b",
    alt: "Mobile application solutions ",
    title: "Mobile application solutions ",
    desc: "We create cutting-edge mobile application solutions that include speech, picture, and facial recognition, as well as cloud computing, machine learning, and autonomous analysis software, to bring mobility to all types of company activities.  ",
  },
];
const Data3 = [
  {
    id: 1,
    title: "Top AI Experts, Business analysts, Data scientists etc.",
  },
  {
    id: 2,
    title: "Transparent Cooperation",
  },
  {
    id: 3,
    title: "Ensuring asset authenticity",
  },
  {
    id: 4,
    title: "Post-delivery services",
  },
  {
    id: 5,
    title: "Client-Centric Approach",
  },
  {
    id: 6,
    title: "Transparent workflow",
  },
  {
    id: 7,
    title: "Cost-Effective Models and Solutions",
  },
];
const Why = [
  {
    id: "1",
    title: "Cross technology applications",
    desc: "Because we are not solely an AI consulting firm, we can provide cloud technologies, as well as superior design and engineering techniques, by utilizing our knowledge in other areas of technology.",
  },
  {
    id: "2",
    title: "Results oriented",
    desc: "We aren't just interested in modeling. Every solution is fully automated, making it simple to deploy, maintain, and retire. Infrastructure is set up correctly, CI/CD processes are in place, and security risks are minimized.",
  },
  {
    id: "3",
    title: "Proven competency",
    desc: "Our AI experts are trained in the newest technologies, machine learning techniques, and deep learning architectures and are certified in them.",
  },
  {
    id: "4",
    title: "On time delivery",
    desc: "We have a robust set of internal frameworks and best-in-class infrastructure, as well as a decade of expertise in the IT software development business, which enables us to consistently offer high-quality solutions.",
  },
  {
    id: "5",
    title: "Client engagement and leveragability",
    desc: "We take a long-term approach to involvement with our clients, both during and after their transformation journeys. If our clients require it, we can use all or part of our enterprise level digital transformation platform (SmartOps) to ensure that an end-to-end digital transformation is possible.",
  },
  {
    id: "6",
    title: "Professional team",
    desc: "large team of highly qualified, certified, and experienced Artificial Intelligence professionals capable of providing best-in-class artificial intelligence services.",
  },
];
export default function ArtificialIntellegence() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fai%2Fartificial-intellegence.jpeg?alt=media&token=0af9e803-666e-4135-b49c-8692d52ae2d0"
        title="Artificial intelligence services"
        desc="Transitioning from manual workflow to smarter automated solutions for better business proficiency."
      />
      <Overview
        textColor="text-white"
        title="Automating Business processes"
        desc="AI has become the most revolutionary and much-needed technology for global companies due to its potential to entirely automate important jobs and give efficiency to business operations. Whether it's improving the customer experience or automating the most tedious jobs, AI development has revolutionized the world in the most effective way possible. Innorik offers the best AI solutions and services to help you achieve your business objectives faster, while setting you up for sustainable growth. "
        img="/images/technologies/latest-thinking/ai/ai-overview.jpeg"
        img2="/images/technologies/latest-thinking/ai/ai-overview-mobile.jpeg"
        alt="artificial intellegence"
      />
      {/* <Overview
        title=" "
        desc="Organisations in this era have more data at their disposal than ever before and the most advanced companies out of the whole have now accepted that the cloud is the most secured way to process and store such huge data. Now here comes the interesting part. How do you think these companies convert such data into business value?  Well, that’s where AI comes in. The AI technology has the ability to enhance your business productivity and operational efficiency and can help you avoid production loss or insufficient product quality. "
        // desc2="At Innorik, our objective is to use IoT as a medium for helping clients transform their businesses by taking the pain to understand your business needs and offering you the best IoT needs and solutions, thus accelerating your time and productivity on the market. We work hard to create custom solutions that fits your business square in the best possible way. "
      /> */}
      <Services title="Our AI offerings " data={offeringData} />
      {/* <WhyChoose
        textColor="text-white"
        desc="Extensive experience with the IoT technology to develop comprehensive IoT solutions. "
        head="Why choose Innorik for your Artificial Intellegence?"
        data={Data3}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fai%2Fwhy-choose-ai-linear.jpeg?alt=media&token=8bf82e7e-bec5-466d-ad91-0462c7f74325"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fai%2Fmobile%2Fartificial-intellegence-brain-design.jpeg?alt=media&token=1a19dc01-44be-42fd-91dd-21184602d6a2"
      /> */}
      <WhyChooseText
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FProfessionalServices%2FwhyBackground.png?alt=media&token=03d970d1-9dd2-403e-8549-9c63d23568dd"
        data={Why}
        title="Why partner with us?"
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
