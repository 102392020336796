import React, { useState } from "react";
// import { Data4 } from "../../components/Data";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { FiPlus, FiMinus } from "react-icons/fi";

const AccordionSection = styled.div`
  flex-direction: column;
  align-items: start;

  justify-content: center;
  position: relative;
`;

const Container = styled.div`
  left: 4%;
  top: 10%;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #e7e7e7;

  h1 {
    padding: 1rem;
  }
  span {
    margin-right: 1.5rem;
  }
`;

const Dropdown = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export default function Accordian(props) {
  const Data = props.data;
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <IconContext.Provider value={{ color: "#222222", size: "25px" }}>
      <AccordionSection className="md:px-[4% ]">
        <Container className="">
          {Data.map((item, index) => {
            return (
              <>
                <Wrap onClick={() => toggle(index)} key={index}>
                  <div className="flex px-4">
                    {/*                         
                      <svg
                        width="40"
                        height="50"
                        viewBox="0 0 40 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.1875 0C25.9375 6.36328 13.3281 10.293 12.1562 15.5938C11.082 20.4648 19.6484 26.125 19.6562 26.125C18.3555 24.1094 17.3984 22.4492 16.0938 19.3125C13.8867 14.0078 29.5352 9.20703 23.1875 0ZM31.5625 8.8125C31.5625 8.8125 20.5 9.52344 19.9375 16.5938C19.6875 19.7422 22.8477 21.3984 22.9375 23.6875C23.0117 25.5586 21.0625 27.125 21.0625 27.125C21.0625 27.125 24.6094 26.4492 25.7188 23.5938C26.9492 20.4258 23.3203 18.2852 23.6875 15.75C24.0391 13.3242 31.5625 8.8125 31.5625 8.8125ZM14.1875 25.1562C14.1875 25.1562 4.0625 25.0117 4.0625 27.875C4.0625 30.8672 17.3164 31.0898 26.7812 29.25C26.7812 29.25 29.2969 27.5195 29.9688 26.875C23.7656 28.1406 9.625 28.2812 9.625 27.1875C9.625 26.1797 14.1875 25.1562 14.1875 25.1562ZM33.6562 25.1562C32.6641 25.2344 31.5938 25.6172 30.625 26.3125C32.9062 25.8203 34.8438 27.2344 34.8438 28.8438C34.8438 32.4688 29.5938 35.875 29.5938 35.875C29.5938 35.875 37.7188 34.9531 37.7188 29C37.7188 26.2969 35.8398 24.9844 33.6562 25.1562ZM11.75 30.7188C10.1953 30.7188 7.875 31.9375 7.875 33.0938C7.875 35.418 19.5625 37.207 28.2188 33.8125L25.2188 31.9688C19.3516 33.8477 8.54688 33.2344 11.75 30.7188ZM13.1875 35.9375C11.0586 35.9375 9.65625 37.2227 9.65625 38.1875C9.65625 41.1719 22.3711 41.4727 27.4062 38.4375L24.2188 36.4062C20.457 37.9961 11.0156 38.2383 13.1875 35.9375ZM6.09375 38.625C2.625 38.5547 0.375 40.1133 0.375 41.4062C0.375 48.2812 35.875 47.9648 35.875 40.9375C35.875 39.7695 34.5273 39.2031 34.0312 38.9375C36.9336 45.6562 4.96875 45.1211 4.96875 41.1562C4.96875 40.2539 7.32031 39.3906 9.5 39.8125L7.65625 38.75C7.11328 38.668 6.58984 38.6367 6.09375 38.625ZM39.625 43.25C34.2266 48.3672 20.5469 50.2227 6.78125 47.0625C20.543 52.6953 39.5586 49.5352 39.625 43.25Z"
                          fill="#909090"
                        />
                      </svg> */}
                    <h1 className=" text-[18px] lg:text-[22px] text-left">
                      {item.question}
                    </h1>
                  </div>

                  <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                </Wrap>
                {clicked === index ? (
                  <Dropdown className="text-left px-8">
                    {/* <p className="text-[16px]">{item.answer}</p> */}
                    <ul className="text-[#686868]">
                      <li>{item.answer}</li> <br />
                      {/* <li>{item.answer2}</li> */}
                    </ul>
                  </Dropdown>
                ) : null}
              </>
            );
          })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
  );
}
