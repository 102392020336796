import React from "react";
import List from "../../components/Cards/List";

export default function OurItConsulting(props) {
  const newData = props.data;
  const dataList = newData.map((data, index) => {
    return <List key={index} title={data.title} />;
  });
  return (
    <div className="bg-[#D9E3FF]  pt-6 pb-8 ">
      <div className="max-w-[1500px] mx-auto  lg:px-[185px]">
        <div className="md:m-auto  w-full  ">
          <h2 className="m-auto  pt-[2%]  text-semibold  text-[24px] lg:text-[36px] text-center sm:mt-0 sm:mb-0  lg:mr-0">
            {props.head}
          </h2>
        </div>
        <div className="conatiner   lg:flex-row flex flex-col-reverse w-full py-[2%]">
          <div className="conatiner col-span-5 text-black flex flex-col">
            <div className="m-auto md:pt-[3%] pl-[5%] lg:pl-0  w-full pt-2 ">
              <ul className="   list-outside grid grid-cols-1   md:grid-cols-2 lg:grid-cols-1  text-[16px] lg:text-[18px] m-auto  w- sm:py-2 leading-loose">
                {dataList}
              </ul>
            </div>
          </div>
          <div className="lg:my-auto col-span-7 lg:mx-auto lg:mr-0 px-[4%]  justify-end pb-[10px]">
            <div className="my-auto ">
              <img
                className="my-auto object-cover lg:h-auto lg:w-[580px] md:h-[300px] sm:h-[300px] w-full pt-2"
                src={props.img}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
