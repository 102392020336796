import React from "react";

export default function TabBox({ img, alt = "", desc }) {
  return (
    <>
      <div className=" w-[86px] h-[56px] ">
        <div className="bg-white  py-[14px] ">
          <img className="w-[38px] h-[38px] m-auto" src={img} alt={alt} />
        </div>
        <p className="text-[14px] pt-[4px] text-center">{desc}</p>
      </div>
    </>
  );
}
