import React from "react";
import Banner from "../../../components/Banner";
import LeftImageSection from "../../../components/LeftImageSection";
import AssesmentSoftware from "../Education/AssesmentSoftware";
import Telemedecine from "./Telemedecine";
// import Lms from "../Education/Lms";
import HealthTracking from "./HealthTracking";
import ProjectManagement from "../ProfessionalServices/ProjectManagement";
import EHR from "./EHR";
import FourIcons from "../../../components/FourIcons";
import WhyChooseUsIndustries from "../../../components/WhyChooseUsIndustries";
import FooterBanner from "../../../components/Footer/FooterBanner";

const IconsData = [
  {
    id: 1,
    img: "/images/industries/healthcare/icons/technology.svg",
    title: "",
    desc: "Technology-Driven",
  },
  {
    id: 2,
    img: "/images/industries/healthcare/icons/healthcare.svg",
    title: "",
    desc: "Accountable healthcare",
  },

  {
    id: 3,
    img: "/images/industries/healthcare/icons/growth-report.svg",
    title: "",
    desc: "Catalyzing growth",
  },
  {
    id: 4,
    img: "/images/industries/healthcare/icons/handshake.svg",
    title: "",
    desc: "Accessible networks",
  },
];
const ProjectData = [
  {
    id: 1,
    title: "Billing (integration of variety of payments software)",
  },
  {
    id: 2,
    title: "Charting",
  },
  {
    id: 3,
    title: "Workflow",
  },
  {
    id: 4,
    title: "E-Prescribing",
  },
  {
    id: 5,
    title: "Lab integration",
  },
  {
    id: 6,
    title: "Patient Portal",
  },
  {
    id: 7,
    title: "Cloud hosting",
  },
];
const Why = [
  {
    id: "1",
    title: "Technology",
    desc: "We use the latest and most sophisticated technology to ensure that you get the optimum benefits out of your investment. Aside from building a strong foundation for robust solutions, we also invest tremendously in future technologies to ensure future-ready developments.",
  },
  {
    id: "2",
    title: "Intuitive interfaces",
    desc: "Our healthcare solutions are built to offer the most dynamic solutions and with that in mind we ensure our users get the best intuitive interface that can be used instantly with no or minimum supervision.",
  },
  {
    id: "3",
    title: "Cost-effective solutions",
    desc: "We understand that healthcare is the need of the majority in this era. To this effect, our healthcare technology solutions are built to be most cost effective and delivers extreme value for your money!",
  },
  {
    id: "4",
    title: "Multiple testing",
    desc: " Our products go through several stages of both intensive and extensive testing, quality assurance and practical usability to ensure the best end results. Great commitment and responsibility from our team are key factors to achieving this.",
  },
  // {
  //   id: "5",
  //   title: "Transparent pricing",
  //   desc: "Approximate or total cost of project is clear. All prices depend on the number of functionalities to be implemented and business analysis conducted",
  // },
  // {
  //   id: "6",
  //   title: "Quality first",
  //   desc: "We onboard the highest code quality and appropriate functioning of the end product keeping in mind of all quality assurance standards throughout the Software Development Life Cycle (SDLC)",
  // },
];
export default function HealthCare() {
  return (
    <>
      <Banner
        img="/images/industries/healthcare/healthcare-banner.jpeg"
        title="Healthcare"
        desc="Integrating value-based technologies into the ever-changing healthcare spectrum around the globe."
      />
      <LeftImageSection
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FHealthCare%2FhealthcareOverview.jpg?alt=media&token=ef227ac6-45eb-4552-a75c-f3f43a5a7c9c"
        title="Exploiting the Digital Front"
        desc="The importance of fitness is being increasingly understood by people today, everybody is becoming health conscious. The health industry has flourished a lot over the past few years with the likes of Gyms, Yoga and many others. This has won a lot of recognition amongst health enthusiast as they flip to the online world in search of healthy living. Traditional fitness consultation from medical doctors is now transferring to online platforms and consumers are purchasing drugs from online pharmacies. It’s now a necessity to grasp the technical innovations into our practices. At Innorik, we are here to help you match the reach by providing maximum strategies designed through continuous research and development."
      />
      <h2 className="lg:text-[32px] md:text-[28px] text-[20px] text-center py-[25px] md:py-[40px] font-medium lg:py-[50px]">
        Our Healthcare Systems
      </h2>
      <Telemedecine
        color="grey"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FtememedecineSoftware.png?alt=media&token=b1b8d36b-6421-4026-b42f-d5838e63881d"
        title="Telemedicine software"
        desc="Our telemedicine software is a robust system that enhances virtual doctor’s appointment and physician-to-physician consultations using digital devices such as computers and smartphones with the help of video conferencing. This system has electronic health records integration where utilization of secure messaging and encrypted file transfer protocols comes to play."
      />
      <AssesmentSoftware
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FHospitalManagementSoftwaresMockup%201.png?alt=media&token=7bec026d-9e6c-4293-bd7e-403a3a04fe32"
        title="Hospital management software"
        desc="Our hospital management software has the following features and capabilities; Patients management, medical records, medical forms, medical scheduling, medical workflows, medical billing and claims, inventory management, wards management, nurse’s modules and reporting and statistics."
      />
      <HealthTracking
        img="/images/industries/healthcare/health-tracking.png"
        title="Health Tracking Apps "
        desc="Our health tracking apps comprises of five major types. They are the fitness tracking app, diet tracking app, health conditions monitoring app, mental health tracking app and women’s health tracking app. The apps use special features like Geolocation, personal information, device connectivity, social media integration and user activity tracking making it easier and convenient to use."
      />
      <ProjectManagement
        head="Electronic medical record software (EMR)"
        desc=" Our EMR software provides you with a way to maintain the standard medical and clinical data that is gathered about a patient. These seven (7) features make our EMR software very unique and reliable:"
        data={ProjectData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FmecialRecords.png?alt=media&token=ba08903f-199e-4603-ba14-44e87eb6bd58"
      />
      <EHR
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FHealthCare%2FEHR.jpg?alt=media&token=45d81080-09c7-4de9-b7df-9846765607ad"
        title="Electronic health records Software(EHR)"
        desc="This software provides a comprehensive record of patient care to support decision-making, ensuring continuity of care across visits and clinicians among others. The following features; Health information and data, results management, order / entry management, decision support, patient support and administrative processes are embedded in our software."
      />
      <div className="pb-8  pt-4 lg:py-8">
        <FourIcons
          title="Benefits Related to Digital Healthcare Solutions"
          data={IconsData}
        />
      </div>
      <WhyChooseUsIndustries
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FMask%20group.png?alt=media&token=739958f7-6b86-4578-b39c-9e929dc17804"
        data={Why}
      />
      <FooterBanner title="Send us your requirements" link="/contact-us" />
    </>
  );
}
