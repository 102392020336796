import React from "react";
import { Link } from "react-router-dom";

export default function FooterBanner(props) {
  return (
    <>
      <div className="bg-[#252525]  py-[20px] md:py-[40px] shadow-xl">
        <Link to={props.link} className="flex justify-center">
          <p className="md:text-[28px] px-[2px] text-[24px] lg:text-[36px] text-center text-white ">
            {props.title}
          </p>
          <div className="pl-[20px]">
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.09521 11L11.2329 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.09521 1H11.2329V11"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </Link>
      </div>
    </>
  );
}
