import React from "react";

export default function OurMission(props) {
  return (
    <>
      <div className="lg:px-[85px] md:px-[40px] px-[28px] max-w-[1400px] mx-auto">
        {" "}
        <div className="grid grid-cols-1 lg:grid-cols-3 mx-auto py-10 w-full gap-6 sm:gap-8 md:gap-14 lg:gap-4 xl:gap-10 ">
          <div className="relative  h-[450px] lg:h-[480px] ">
            <img
              className="w-full h-full object-cover object-center"
              src={props.mission}
            />
            <div className=" lg:h-3/5 md:h-1/3 h-1/2 sm:px-5 lg:pb-9 lg:pt-12 absolute bottom-0 inset-x-0 text-white w-full  bg-black opacity-70 text-[16px] md:text-[18px] lg:text-[18px]">
              <div className="h-full mt-10 sm:mt-14 mx-5 sm:mx-10 lg:m-0  pb-4">
                <span className="sm:text-[18px] text-[16px] md:text-[20px] lg:text-[20px] ">
                  Our Mission{" "}
                </span>
                is to provide innovative, world class and enterprise software
                products and services globally, thereby enabling them to
                leverage technology for business growth and sustainability.
              </div>
            </div>
          </div>

          <div className=" relative  h-[450px] lg:h-[480px]">
            <img
              className="w-full h-full object-cover object-center"
              src={props.vision}
            />
            <div className=" lg:h-3/5 md:h-1/3 h-1/2 sm:px-5 lg:pb-9 lg:pt-12 absolute bottom-0 inset-x-0 text-white w-full  bg-black opacity-70 text-[16px] md:text-[18px] lg:text-[18px]">
              <div className="h-full mt-10 sm:mt-14 mx-5 sm:mx-10 lg:m-0  pb-4">
                <span className="sm:text-[18px] text-[16px] md:text-[20px] lg:text-[20px] ">
                  Our vision{" "}
                </span>
                is to help both local and international businesses overcome the
                ever trending technological challenges of digital transformation
                by delivering eminent software solutions and services.
              </div>
            </div>
          </div>

          <div className="relative  h-[450px] lg:h-[480px]">
            <img
              className="w-full h-full object-cover object-center"
              src={props.value}
            />
            <div className=" lg:h-3/5 md:h-1/3 h-1/2 sm:px-5 lg:pb-9 lg:pt-12 absolute bottom-0 inset-x-0 text-white w-full  bg-black opacity-70 text-[16px] md:text-[18px] lg:text-[18px]">
              <div className="h-full mt-10 sm:mt-14 mx-5 sm:mx-10 lg:m-0  pb-4">
                <span className="sm:text-[18px] text-[16px] md:text-[20px] lg:text-[20px] ">
                  Our Values{" "}
                </span>
                form the building blocks of our culture and defines how we work
                together to serve our customers and to change the world.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
