import React from "react";
import List from "../../../components/Cards/List";

export default function DocumentManagementSection(props) {
  const newData = props.data;
  const dataList = newData.map((data, index) => {
    return <List key={index} title={data.title} />;
  });

  return (
    <>
      {/* change image for this section for mobile screen because it's hard to read text on image */}
      {/* <div className=""></div> */}
      <div
        className="hidden h-0 md:h-auto lg:h-auto sm:h-0 lg:block  m-auto py-4 text-center lg:text-left bg-cover bg-right "
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <div className=" max-w-[1500px] px-[85px] mx-auto   lg:flex-row  lg:py-[30px] md:py-[40px] py-[56px] ">
          <div
            className={`${props.textColor} md:m-auto w-full justify-start pl-[%]`}
          >
            <h2 className=" px-[%] text-semibold  lg:w-1/2 sm:w-2/5 md:w-1/2 text-[24px] text-[#3066EA] lg:text-[36px] sm:mt-0 sm:mb-0 text-left lg:mr-0">
              {props.head}
            </h2>
            <p className=" lg:w-1/2  md:text-justify  text-[#222222] pt-[4%] text-left lg:text-left text-[16px] lg:text-[18px]    sm:py-2  ">
              With our DMS module, businesses can go an extra mile by securing
              its documents from unauthorized access, allowing users to
              collaborate with the help of file sharing, and allowing users to
              access documents from anywhere. This module features;
            </p>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
            <div className="pt-4">
              <ul className=" text-left list-outside text-[16px] lg:text-[18px] m-auto ml-0 lg:w-7/12  w-12/12 sm:py-2 leading-loose">
                {dataList}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${props.textColor} block  lg:hidden xl:invisiblegrid m-auto gap-0 w-full`}
      >
        <img
          className="w-full  sm:h-[400px] object-cover object-center"
          src={props.img2}
          alt="Document Management"
        />
        <div className="  pt-8 ">
          <div className="md:m-auto w-full justify-start px-[5%]">
            <h2 className=" text-[#3066EA] text-semibold  text-[24px] text-left ">
              {props.head}
            </h2>
            <p className=" lg:w-1/2  md:text-justify  text-[#222222] pt-[4%] text-left lg:text-left text-[16px] lg:text-[18px]    sm:py-2  ">
              With our DMS module, businesses can go an extra mile by securing
              its documents from unauthorized access, allowing users to
              collaborate with the help of file sharing, and allowing users to
              access documents from anywhere. This module features;
            </p>
          </div>
        </div>
        <div className="  py-4 mt-0">
          <ul className=" text-left list-outside text-[16px] lg:text-[18px] m-auto  w-11/12 sm:py-2 leading-loose">
            {dataList}
          </ul>
        </div>
      </div>

      {/*       
      <div
        className="m-auto py-[15%] sm:py-[5%] md:py-[5%] lg:py-[5%] text-center lg:text-left bg-cover bg-right h-full sm:h-[] md:h-[] lg:h-full xl:h-[]"
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      >
        <h1 className="px-[20px]  lg:w-1/2  text-[24px] lg:text-[36px] sm:text-[40px] md:text-[40px]  text-white ">
          Your cloud transformation journey is unique, let’s give you a helping
          hand
        </h1>
        
      </div> */}
    </>
  );
}
