import React from "react";

export default function ImageCard(props) {
  // console.log(props.img);
  return (
    <>
      <div className=" relative aspect-square">
        <img
          className="aspect-square w-full h-full object-cover object-center"
          src={props.img}
          alt={props.alt}
        />
        <div className="absolute h-full group bottom-0 text-white text-center leading-snug w-full transition duration-150 ease-in-out hover:bg-black/70">
          <div className="h-full grid justify-items-center px-2 sm:px-">
            <p className="m-auto mb-2 text-xl hidden group-hover:block md:text-2xl 2xl:text-3xl group">
              {props.title}
            </p>
            <p className=" text-[14px] md:text-[18px] lg:text-[18px] xl:text-18px  m-auto mt-2 md:text-lg xl:text-xl 2xl:text-2xl">
              {props.desc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
