import React, { useState } from "react";
import Banner from "../../components/Banner";
import LinearImageSection from "../../components/LinearImageSection";
import Requirements from "./Requirements";
import Section2 from "./Section2";
// import Text from "../../components/Test";
// import BannerImage from "../../Assets/Images/company/talent-world-wide/talent-worldwide-banner.jpg";
import Brain from "../../Assets/Images/company/talent-world-wide/structure-of-organizations-mobile.jpg";
import Skills from "../../Assets/Images/company/talent-world-wide/skills.jpeg";
import Learn from "../../Assets/Images/company/talent-world-wide/Learn-anytime-and-any-where.jpg";
import Computer from "../../Assets/Images/company/talent-world-wide/computer-resources.jpg";
import STEM from "../../Assets/Images/company/talent-world-wide/STEM-Education.jpg";
import Internet from "../../Assets/Images/company/talent-world-wide/Internet-access.jpg";
// import FooterBanner from "../../components/Footer/FooterBanner";
// import Apply from "./Apply";
// import FileUpload from "./FileUpload";
import BootCampForm from "./BootCampForm";
// import BootCampForm from "./BootCampForm";

export default function TalentWorldWide() {
  const [showForm, setShowForm] = useState(false);
  const handelShowForm = () => {
    setShowForm(!showForm);
  };

  return (
    // className="2xl:w-4/5 lg:w-full mx-auto justify-items-center"
    <>
      <Banner
        img="/images/company/culture-and-valuse/culture-banner.jpeg"
        title="Developing talents for our digital transformation"
        desc=""
      />
      <Section2 img={Brain} />
      <LinearImageSection img={Skills} />

      <div
        className="m-auto   py-[15%] sm:py-[5%] md:py-[5%] lg:py-[5%] text-center bg-cover bg-center h-full sm:h-[] md:h-[] lg:h-full xl:h-[]"
        style={{
          backgroundImage: `url("${Learn}")`,
        }}
      >
        <h1 className="px-[70px] text-[24px] flex items-center justify-center lg:text-[60px] sm:text-[40px] md:text-[40px]  text-white ">
          Learn anytime and anywhere
        </h1>
      </div>

      <Requirements computer={Computer} stem={STEM} internet={Internet} />
      <button
        className="w-full"
        onClick={() => {
          handelShowForm();
        }}
      >
        <div className="bg-[#252525] flex mx-auto  py-[20px] md:py-[40px]  justify-center shadow-xl">
          <p className="md:text-[28px] text-[24px] lg:text-[36px] text-center text-white ">
            Apply now{" "}
          </p>
          <div className="pl-[20px]">
            {" "}
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.09521 11L11.2329 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.09521 1H11.2329V11"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>{" "}
      </button>
      {/* <Apply /> */}
      {/* <FileUpload /> */}
      {showForm ? <BootCampForm /> : null}
      {/* <Apply /> */}
    </>
  );
}
