import React from "react";

export default function RightImageSection(props) {
  return (
    <div className="max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[85px]">
      <div className="conatiner flex lg:flex-row  flex-col  w-full  py-[20px]">
        <div className="m-auto w-full md:w-4/5 lg:w-1/3 pb-[10px]">
          <div className="m-auto w-9/12 sm:w-7/12 md:w-9/12 lg:w-full">
            <img
              className="m-auto ml-0 w-full  md:mt-0  md:mb-0 md:w-11/12  lg:w-11/12 "
              src={props.img}
            />
          </div>
        </div>
        <div className="m-auto ml-0  w-full md:w-3/3 lg:w-2/3">
          <h2
            style={{ lineHeight: "2.5rem" }}
            className=" text-[#3066EA]	lg:ml-0 md:pt-[30px] lg:pt-0 font-medium text-center  text-[24px] md:text-[24px] sm:text-[24px] lg:text-[28px] sm:text-lg m-auto py-0 w-11/12 sm:py-2 lg:text-left"
          >
            {props.title}
          </h2>
          <p className=" md:text-justify mr-0 text-[#222222] pt-[4%] text-center lg:text-left text-[16px] lg:text-[18px] m-auto   sm:py-2  ">
            {props.desc} <br /> {props.desc2}
          </p>
        </div>
      </div>
    </div>
  );
}
