import React from "react";
import Banner from "../../../../components/Banner";
import FooterBanner from "../../../../components/Footer/FooterBanner";
import WhyChoose from "../../../../components/WhyChoose";
import Overview from "../ArtificialIntellegence/Overview";
// import Overview from "../BigData/Overview";
import Services from "../BigData/Services";

const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fblockchain%2Fblockchain-consulting.jpeg?alt=media&token=8a51a013-58a6-4bf4-8e0e-b6f8cd4c2f59",
    alt: "blockchain consulting",
    title: "Blockchain Consulting",
    desc: "Our Blockchain consultants can answer all your questions about Blockchain, including how to build it, how to comprehend finance trading in the domain, how to start the payment process, how to manage it, and how to demonstrate it.    ",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fblockchain%2Fdapp-development.jpeg?alt=media&token=2d377116-06f3-4d2d-a47c-623b9ea9b6c3",
    alt: "dapp development",
    title: " dApp Development",
    desc: "We can design DApps to unlock tangible consequences for your potential consumers, whether you're picturing a new coin or desire a new decentralized software. MVP Application consulting, MVP development, custom and cloud Dapp development, Dapp porting, and Dapp upgrade services are just a few of the services we provide.",
  },

  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fblockchain%2Fsmart-contract-development.jpeg?alt=media&token=01ebf871-9f80-4ff1-a0cc-c56d2640c507",
    alt: "smart contracts",
    title: "Smart Contract Development",
    desc: "Our professionals write secure smart contract programming in Ethereum and HyperLedger fabric to automate the execution of procedures. ",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fblockchain%2Foptimum-security.jpeg?alt=media&token=1478787a-7584-42f5-9bc3-c2d6f6ba24f5",
    alt: "security",
    title: "HyperLedger and multichain",
    desc: "We create decentralized apps for finance, healthcare, retail, manufacturing, and other industries using permissioned Blockchains such as HyperLedger fabric and multichain.",
  },
  {
    id: 4,
    img: "/images/technologies/latest-thinking/blockchain/digital-wallet.jpeg",
    alt: "digital wallet",
    title: "Wallets development ",
    desc: " Our blockchain development team develops decentralized and secure desktop and mobile wallets for Bitcoin, Ethereum, and Altcoins, which are secure.",
  },
  {
    id: 4,
    img: "/images/technologies/latest-thinking/blockchain/crypto-development.jpeg",
    alt: "crypto development",
    title: "Cryptocurrency development",
    desc: "We create several customized coin platforms in order to create a unique cryptocurrency that may be used in cryptocurrency-based applications. ",
  },
];
const Data3 = [
  {
    id: 1,
    title:
      "Experienced with leading blockchain frameworks: Ethereum, Hyperledger Fabric, Graphene etc.",
  },
  {
    id: 2,
    title: "Industry expertise ",
  },
  {
    id: 3,
    title: "Asset authenticity & security",
  },
  {
    id: 4,
    title: "Client-centric approach & transparency",
  },
  {
    id: 5,
    title: " Post-delivery services (Maintenance)",
  },
  {
    id: 6,
    title: " Cost-effective approach for solutions",
  },
];
export default function Blockchain() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fblockchain%2Fblockchain.jpeg?alt=media&token=34e7798a-f73f-4d3d-9c05-a6b73b553dfb"
        title="Blockchain Services"
        desc="Power up business operations with Blockchain technologies"
      />
      <Overview
        textColor="text-white"
        title="Top-notch Blockchain Development Company"
        desc="Blockchain is changing the way the world lives and work, and it is gradually becoming the world’s digital ledger of transactions (buy, sell, and trade crypto). In view of this, Innorik is ready to step in at any stage of your product development to assist you in designing, developing, implementing, and deployment of your product successfully. Our development team takes control of Blockchain development services ranging from blockchain consulting to decentralized application development (dApp) in blockchain networks, which provides enhanced traceability, scalability, security, and cost reduction. Our custom blockchain development services are robust, and our main goal is to help you build reliable blockchain solutions. "
        img="/images/technologies/latest-thinking/blockchain/blockchai-overview.jpeg"
        img2="/images/technologies/latest-thinking/blockchain/blockchai-overview-mobile.jpeg"
        alt="blockchain development"
      />
      {/* <Overview
        title="Top-notch Blockchain Development Company"
        desc="Blockchain is changing the way the world lives and works, and it is gradually becoming the world’s most popular way to buy, sell, and trade crypto. In view of this, we as a blockchain development company is ready to step in at any stage of your product development to assist you design, develop, implement and deploy successfully."
        desc2="At Innorik, we leverage Blockchain development services ranging from blockchain consulting to decentralised application development (dApp) in the blockchain networks that provides enhanced traceability, scalability, security, and cost reduction. Our custom blockchain development services are robust, and our main goal is to help you build reliable blockchain solutions be it wallet applications and exchange platforms for your desktops, mobile devices, and browser applications based on  services like blockchain based tokens, auto-generating public and private keys, Initial Coin Offerings (ICO) etc. to boost your business’ productivity."
      /> */}
      <Services title="Blockchain Services We Offer" data={servicesData} />
      <WhyChoose
        textColor="text-white"
        desc="Extensive experience with the IoT technology to develop comprehensive IoT solutions. "
        head="Why choose Innorik for your Blockchain solutions?"
        data={Data3}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fblockchain%2Fblockchain-linear.jpeg?alt=media&token=27d73009-b2cb-49cc-a4fc-09cd868cb7ec"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2Flatest-thinking%2Fblockchain%2Fblockchain-mobile-background.jpeg?alt=media&token=fb6cfd11-4b3b-46da-ad2b-b05c403faa5c"
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
