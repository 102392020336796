const javaRequirement = [
  {
    list: "Proven experience as a Full Stack Java Developer or similar role.",
  },
  {
    list: "5+ years of programming experience with Java, J2EE and XML.",
  },
  {
    list: "Extensive experience developing applications with Core Java and frameworks (e.g. Spring Data JPA)",
  },
  {
    list: "Expert in building REST APIs, implementing persistence layer and solid understanding SOA.",
  },
  {
    list: "Strong knowledge of Microservices, Spring, Spring Boot and Hibernate",
  },
  {
    list: "Expert in UI frameworks ( React.JS, Angular.js)",
  },
  {
    list: "Experience with developing relational database and NoSQL using MSSQL, Oracle, PostgreSQL and MongoDB",
  },
  {
    list: "Strong AWS experience including AWS CodeBuild, AWS CodeCommit, AWS CodePipeline, AWS Lambda, API Gateway, AWS CLI/Yaml/CloudFormation and Serverless Deployment.",
  },
  {
    list: "Expert with design patterns, SOA design & web services development",
  },
  {
    list: "Strong experience in developing for high-availability clustered environments",
  },
  {
    list: "Strong communication and writing skills",
  },
  {
    list: "Comfortable working in a flexible Agile development environment",
  },
];
const javaResponsibilities = [
  {
    list: "Develop custom code in Core Java",
  },
  {
    list: "Translate application storyboards and use cases into functional applications",
  },
  {
    list: "Design, build and maintain efficient, reusable, and reliable codes",
  },
  {
    list: "Develop Java backend APIs and workflows",
  },
  {
    list: "Develop front end components and layout for our applications (utilizing JavaScript, Typescript, jQuery, React, Angular)",
  },
  {
    list: "Ensure the best possible performance, quality, and responsiveness of applications",
  },
  {
    list: "Identify bottlenecks and bugs, and devise solutions to mitigate and address these issues",
  },
  {
    list: "Participate in scrum meetings, grooming, planning, and retrospective ceremonies with agile development teams. ",
  },
  {
    list: "Collaborate with team members on designing and developing creative solutions to complex problems.",
  },
  {
    list: "Participate in developing and maintaining new and existing applications by writing clean code on web application frameworks.",
  },
  {
    list: "Troubleshoot and fix application-level bugs ",
  },
];
const deopsResponsibilities = [
  {
    list: "Develop and maintain code delivery systems",
  },
  {
    list: "Designing and documenting deployment methods and procedures",
  },
  {
    list: "Execute the code delivery and upgrade of the following based systems: Web, Linux(C), Oracle (SQL) and Windows Desktop Applications",
  },
  {
    list: "Creating and maintain DevOps & CI/CD pipelines",
  },
  {
    list: "Troubleshooting issues",
  },
  {
    list: "Writing and maintaining Ansible playbooks",
  },
  {
    list: "Kubernetes cluster administration",
  },
  {
    list: "Creation and maintenance of standard Docker images for Java & Scala Microservices",
  },
  {
    list: "Creation and maintenance of Kubernetes workload including deployments, PVCs, Load balancers, config-maps, secrets, services, and ingresses",
  },
  {
    list: "You will be responsible for deploying and operating core services for our client applications and products.",
  },
  {
    list: "Implement advanced DevOps technologies and best practices for deployment",
  },
];
const deopsRequirement = [
  {
    list: "Bachelor's degree in Computer Science or related field",
  },
  {
    list: "Hands on experience with Ansible, Kubernetes and Containers like Docker",
  },
  {
    list: "Excellent knowledge of Unix/Linux Operating Systems and shell programming",
  },
  {
    list: "Familiarity with DevOps and CICD tools like Jenkins or Gitlab CI",
  },
  {
    list: " In-depth experience with AWS EC2, VPC, EBS, S3",
  },
  {
    list: "Strong understanding of web fundamentals including HTTP, TCP/IP, DNS, servers,",
  },
  {
    list: " Experience with AWS and Azure cloud services, including web servers, database servers, load balancers and CDNs.",
  },
  {
    list: "Understanding of web standards, including HTML, CSS, JavaScript, XML, and Web APIs.",
  },
  {
    list: "Solid troubleshooting experience within a large-scale production environment",
  },
  {
    list: "Experience with virtualization, such as VMware, KVM, Xen",
  },
  {
    list: "Familiarity with Agile development methodology",
  },
  {
    list: "Familiarity with version control systems like GIT",
  },
];
export {
  javaRequirement,
  javaResponsibilities,
  deopsResponsibilities,
  deopsRequirement,
};
