import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, ValidationError } from "@formspree/react";
import { ToastContainer, toast } from "react-toastify";

function ApplyForJobForm() {
  const [showEducation, setShowEducation] = useState(false);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [state, handleSubmit] = useForm("mvoldaro");
  const [state, handleSubmit] = useForm("mvoldara");

  // const { isSubmitting } = handleSubmit;

  // if (state.succeeded) {
  //   return null;
  // }
  if (state.succeeded) {
    toast.success("Message Sent!", {
      toastId: "success1",

      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }

  return (
    <>
      <div className=" z0 md:mt-[-120px] relative lg:mt-[-180px] max-w-[1500px] text-[#222222] px-[28px] md:px-[40px] lg:px-[85px] mx-auto ">
        <div className="lg:py-[30px] bg-white md:shadow-lg md:px-[25px] lg:px-[50px]">
          <div className="grid  lg:pt-[0px] grid-cols-1 lg:grid-cols-3 lg:gap-[80px]"></div>
          <form onSubmit={handleSubmit} enctype="multipart/form-data">
            {/* <p className="text-[24px] py-[10px] ">Personal Information</p>
            <hr /> */}
            <div className=" flex-wrap -mx-3 mb-6 grid grid-cols-1 lg:grid-cols-2 pt-[30px]">
              <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="grid-first-name"
                >
                  Full Name*
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="full-name"
                  type="text"
                  name="name"
                  required="true"
                />
                <ValidationError
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />
              </div>

              <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="email"
                >
                  Email*
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  type="email"
                  name="email"
                  required="true"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
            </div>
            <div className=" flex-wrap -mx-3 mb-6 grid-cols-1 grid lg:grid-cols-2">
              <div className="w-full md:w-full px-3">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="phone"
                >
                  Phone Number
                  <span className="text-[14px] text-[#666666]/80">
                    (Optional)
                  </span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="phone"
                  type="number"
                  name="phone"
                />
                <ValidationError
                  prefix="Phone"
                  field="phone"
                  errors={state.errors}
                />
              </div>
              <div className="w-full md:w-full pt-[30px] md:pt-0 px-3">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="address"
                >
                  Address
                  <span className="text-[14px] text-[#666666]/80">
                    (Optional)
                  </span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="address"
                  type="text"
                  name="address"
                />
                <ValidationError
                  prefix="Address"
                  field="address"
                  errors={state.errors}
                />
              </div>
            </div>
            {/* <p className="text-[24px] pt-[20px] pb-[10px]">Profile</p>
            <hr /> */}

            <div className="pt-[30px] flex justify-between ">
              <h2 className="text-[20px] ">
                Education
                <span className="text-[14px] text-[#666666]/80">
                  (Optional)
                </span>
              </h2>
              <button
                onClick={(e) => {
                  e.preventDefault();

                  setShowEducation(true);
                }}
              >
                <p
                  className={
                    showEducation
                      ? `px-[12px] rounded-[4px]  py-[4px] cursor-not-allowed text-[#376ef8] flex items-center text-[18px] border-[#376ef8]  border-solid border-2`
                      : `px-[12px] rounded-[4px]  hover:bg-[#376ef8]/30 py-[4px] cursor-pointer text-[#376ef8] flex items-center text-[18px] border-[#376ef8]  border-solid border-2 `
                  }
                >
                  <FiPlus className="pr-[4px] text-[24px] " /> Add
                </p>
              </button>
            </div>
            {showEducation === true ? (
              <div className=" bg-[#f9f9f9] mt-[30px] flex-wrap -mx-3 mb-6 grid grid-cols-1 lg:grid-cols-2 py-[20px]">
                <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                    for="school"
                  >
                    School*
                  </label>
                  <input
                    className="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 leading-tight focus:outline-none bg-white border-gray-500"
                    id="school"
                    type="text"
                    name="school"
                  />
                  <ValidationError
                    prefix="School"
                    field="school"
                    errors={state.errors}
                  />
                </div>
                <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                  <label
                    className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                    for="study"
                  >
                    Field of Study
                  </label>
                  <input
                    className="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 leading-tight focus:outline-none bg-white border-gray-500"
                    id="study"
                    type="text"
                    name="study"
                  />
                  <ValidationError
                    prefix="Study"
                    field="study"
                    errors={state.errors}
                  />
                </div>
                <div className="w-full md:w-full px-3 mb-6 md:mb-0 pt-[20px]">
                  <label
                    className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                    for="degree"
                  >
                    Degree
                  </label>
                  <input
                    className="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 leading-tight focus:outline-none bg-white border-gray-500"
                    id="degree"
                    type="text"
                    name="degree"
                  />
                  <ValidationError
                    prefix="Address"
                    field="address"
                    errors={state.errors}
                  />
                </div>
                <div className="grid grid-cols-2 gap-[20px] px-3 pt-[20px]">
                  <div>
                    <label
                      className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                      for="grid-state"
                    >
                      Start Year
                    </label>
                    <select
                      className="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 leading-tight focus:outline-none bg-white border-gray-500"
                      name="state"
                      required=""
                    >
                      <option value="" selected="" disabled="">
                        Select
                      </option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                      <option value="1998">1998</option>
                      <option value="1997">1997</option>
                      <option value="1996">1996</option>
                      <option value="1995">1995</option>
                      <option value="1994">1994</option>
                      <option value="1993">1993</option>

                      <option value="1992">1992</option>
                      <option value="1991">1991</option>
                      <option value="1990">1990</option>
                      <option value="1989">1989</option>
                      <option value="1988">1988</option>
                      <option value="1987">1987</option>
                      <option value="1986">1986</option>
                      <option value="1985">1985</option>
                      <option value="1984">1984</option>
                      <option value="1983">1983</option>
                      <option value="1982">1982</option>
                      <option value="1981">1981</option>
                      <option value="1980">1980</option>
                      <option value="1979">1979</option>
                      <option value="1978">1978</option>
                      <option value="1977">1977</option>
                      <option value="1976">1976</option>
                      <option value="1975">1975</option>
                      <option value="1974">1974</option>
                      <option value="1973">1973</option>
                      <option value="1972">1972</option>
                      <option value="1971">1971</option>
                      <option value="1970">1970</option>

                      <option value="1969">1969</option>
                      <option value="1968">1968</option>
                      <option value="1966">1966</option>
                      <option value="1966">1966</option>
                      <option value="1965">1965</option>
                      <option value="1964">1964</option>
                      <option value="1963">1963</option>
                      <option value="1962">1962</option>
                      <option value="1961">1961</option>
                      <option value="1960">1960</option>

                      <option value="1959">1959</option>
                      <option value="1958">1958</option>
                      <option value="1955">1955</option>
                      <option value="1955">1955</option>
                      <option value="1955">1955</option>
                      <option value="1954">1954</option>
                      <option value="1953">1953</option>
                      <option value="1952">1952</option>
                      <option value="1951">1951</option>
                      <option value="1950">1950</option>
                    </select>
                  </div>
                  <div>
                    <label
                      className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                      for="grid-state"
                    >
                      End Year
                    </label>
                    <select
                      className="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 leading-tight focus:outline-none bg-white border-gray-500"
                      name="state"
                      required=""
                    >
                      <option value="" selected="" disabled="">
                        Select
                      </option>
                      <option value="2022">2030</option>
                      <option value="2022">2029</option>
                      <option value="2022">2028</option>
                      <option value="2022">2027</option>
                      <option value="2022">2026</option>
                      <option value="2022">2025</option>
                      <option value="2022">2024</option>
                      <option value="2022">2023</option>

                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                      <option value="1998">1998</option>
                      <option value="1997">1997</option>
                      <option value="1996">1996</option>
                      <option value="1995">1995</option>
                      <option value="1994">1994</option>
                      <option value="1993">1993</option>

                      <option value="1992">1992</option>
                      <option value="1991">1991</option>
                      <option value="1990">1990</option>
                      <option value="1989">1989</option>
                      <option value="1988">1988</option>
                      <option value="1987">1987</option>
                      <option value="1986">1986</option>
                      <option value="1985">1985</option>
                      <option value="1984">1984</option>
                      <option value="1983">1983</option>
                      <option value="1982">1982</option>
                      <option value="1981">1981</option>
                      <option value="1980">1980</option>
                      <option value="1979">1979</option>
                      <option value="1978">1978</option>
                      <option value="1977">1977</option>
                      <option value="1976">1976</option>
                      <option value="1975">1975</option>
                      <option value="1974">1974</option>
                      <option value="1973">1973</option>
                      <option value="1972">1972</option>
                      <option value="1971">1971</option>
                      <option value="1970">1970</option>

                      <option value="1969">1969</option>
                      <option value="1968">1968</option>
                      <option value="1966">1966</option>
                      <option value="1966">1966</option>
                      <option value="1965">1965</option>
                      <option value="1964">1964</option>
                      <option value="1963">1963</option>
                      <option value="1962">1962</option>
                      <option value="1961">1961</option>
                      <option value="1960">1960</option>

                      <option value="1959">1959</option>
                      <option value="1958">1958</option>
                      <option value="1955">1955</option>
                      <option value="1955">1955</option>
                      <option value="1955">1955</option>
                      <option value="1954">1954</option>
                      <option value="1953">1953</option>
                      <option value="1952">1952</option>
                      <option value="1951">1951</option>
                      <option value="1950">1950</option>
                    </select>
                  </div>
                </div>
                <div className=" pt-[20px] px-3">
                  <button
                    onClick={() => setShowEducation(false)}
                    className="text-[16px] lg:text-[18px] py-[6px] px-[10px] lg:py-[10px] md:py-[10px] lg:px-[30px] md:px-[15px] flex text-[#222222] border border-black"
                  >
                    Cancel
                    <div className="lg:pl-[20px] md:pl-[10px] pl-[5px] m-auto">
                      <IoIosArrowForward />
                    </div>
                  </button>
                </div>
              </div>
            ) : null}
            <div className="flex justify-left">
              <div className="mb-3 w-96">
                <label
                  for="formFile"
                  className="form-label inline-block mb-2 text-gray-700"
                >
                  Resume
                </label>
                <input
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  type="file"
                  name="attachment"
                  accept="xlsx, .xls, .pdf, .docx"
                />
              </div>
            </div>
            {/* <div className="mt-[20px]">
              <label className="flex justify-center w-full h-24 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                <span className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="font-regular text-gray-600">
                    Drop CV to Attach, or
                    <span className="text-blue-600 underline"> browse</span>
                  </span>
                </span>
                <input type="file" name="file_upload" className="hidden" />
              </label>
            </div>

            <div className=" flex-wrap -mx-3 mb-6 pt-4">
              <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="message"
                >
                  Cover Letter
                  <span className="text-[14px] text-[#666666]/80">
                    (Optional)
                  </span>
                </label>
                <textarea
                  id="message"
                  rows="6"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder=""
                ></textarea>
              </div>
            </div> */}

            <div className=" pt-[15px] ">
              <button
                type="submit"
                disabled={state.submitting}
                className="text-[16px] lg:text-[18px] py-[8px] px-[12px] lg:py-[10px] md:py-[10px] lg:px-[30px] md:px-[15px] flex text-[#222222] border border-black"
              >
                Submit Application
                <div className="lg:pl-[20px] md:pl-[10px] pl-[5px] m-auto">
                  {state.submitting ? (
                    <svg
                      role="status"
                      className="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-300 fill-green-500"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    <IoIosArrowForward />
                  )}
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default ApplyForJobForm;
