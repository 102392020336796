import React from "react";

export default function TwoImage() {
  return (
    <>
      <div className="grid max-w-00px] mx-auto lg:grid-cols-2 md:grid-cols-1 md:gap-6 gap-4 lg:gap-0 grid-cols-1">
        <div
          className="bg  h-full bg-cover"
          style={{
            backgroundImage: `url("/images/industries/fintech/pay-tech.jpeg")`,
          }}
        >
          <div className="my-auto  py-[50px] bg-black/50 px-[28px] lg:pl-[85px] lg:pr-[25px]">
            <div className="ml-auto max-w-[630px]">
              <h2 className=" font-medium text-[18px] text-white lg:text-[26px]">
                PayTech
              </h2>
              <p className="pt-[20px] text-white text-[16px] lg:text-[18px]">
                We build and develop digital wallet systems that offer services
                for all the players in the PayTech industry (Electronic money
                institutions (EMI), card network, acquiring and issuing bank,
                payment gateways, payment service providers (PSP)) like P2P
                exchange, digital money transfer, digital payments, In-app
                currency etc.
              </p>
            </div>
          </div>
        </div>
        <div
          className="bg h-full bg-cover"
          style={{
            backgroundImage: `url("/images/industries/fintech/persona-software.jpeg")`,
          }}
        >
          <div className="my-auto h-full bg-black/50 py-[50px] px-[28px] lg:pl-[85px] lg:pr-[25px]">
            <div className="mr-auto max-w-[630px]">
              <h2 className=" font-medium text-[18px] text-white lg:text-[26px]">
                Personal Software
              </h2>
              <p className="pt-[20px] text-white text-[16px] lg:text-[18px]">
                We can help you monitor your monetary asset with our
                state-of-the-art lending and payment software. This facilitates
                safer transactions, and improves proper account reconciliation
                and regulatory reporting
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
