export const Data = [
  {
    question: "Cloud Advisory & Cloud Migration",
    answer:
      "Cloud advisory services help answer key questions, readiness assessment, define strategy, manage change within an organization and provide impartial advice on a different aspects of cloud modernization including organizational, process, and technical issues.",
    answer2:
      "Examine your current applications and services, find the right mix of cloud solutions for your workloads, identify gaps in technology and skills and develop a holistic migrating strategy and plan for individual workloads or an entire data center.",
  },

  {
    question: "Data & Analytics",
    answer:
      "Data and analytics services transform your organization to be data driven.",
    answer2:
      "Depending on where you are in your cloud journey, our cloud consultants can get you started on analytic processes. We design, build, and enhance existing analytic architecture",
  },
  {
    question: "Cloud Operations & Optimization",
    answer:
      "Cloud security is our top-most concern and so we believe that security should be a top priority and be incorporated at every layer of your cloud environment.    ",
    answer2:
      "Our cloud advisors provide services that will monitor your cloud environment of any security threats    ",
  },
  {
    question: "Compliance, Security & Business Continuity",
    answer:
      "Our optimization strategy ensures that your cloud environments are running as efficiently as possible.",
    answer2:
      "Our cloud consultants ensures that workload performance, compliance, and cost are correctly and continually balanced against the best-fit infrastructure in real time.",
  },
  {
    question: "Cloud architecture Consulting",
    answer:
      "Leverage cloud tech for maximum business outcomes by using the best architecture for security, reliability, performance optimization and cost effectiveness",
    answer2:
      "Maximize agility and elevate ROI by integrating infrastructure and apps with best architecture.",
  },
  {
    question: "Containerization Consulting",
    answer:
      "Achieve agility with containerization to simplify monolith apps migration to cloud",
    answer2:
      "Leverage the benefit of microservices architecture with containerization, flexible scaling, and better deployment",
  },
  {
    question: "Application Modernization & DevOps",
    answer:
      "We provide the best advice on the best tools and strategies to reimagine and rearchitect your legacy applications to be cloud ready",
    answer2: "Application modernization assessment are done by our experts",
  },
];
export const Data4 = [
  {
    question: "Web Application Development  ",
    answer:
      "From design to integration to maintenance we offer full-cycle web application development services. We build apps using PHP, Ruby on Rails, Python, Nodejs and Go that are secure, scalable, fast, and work well across all devices and platforms.",
    answer2:
      "Examine your current applications and services, find the right mix of cloud solutions for your workloads, identify gaps in technology and skills and develop a holistic migrating strategy and plan for individual workloads or an entire data center.",
  },

  {
    question: "Ecommerce Web Development ",
    answer:
      "Data and analytics services transform your organization to be data driven.Build an online ecommerce store with seamless and user-friendly experience, the perfect extensions, and reliable integration with your other processes. Our ecommerce web development services include building full-scale ecommerce platforms",
    answer2:
      "Depending on where you are in your cloud journey, our cloud consultants can get you started on analytic processes. We design, build, and enhance existing analytic architecture",
  },
  {
    question: "Java web development ",
    answer:
      "Our experienced Java developers can create functional and scalable web applications to accelerate the digital growth of your business.",
    answer2:
      "Our cloud advisors provide services that will monitor your cloud environment of any security threats    ",
  },
  {
    question: "Custom Web Development  ",
    answer:
      "Build a custom solution for your business from the group-up. Our custom web development services allow you to create cross-platform solutions for any industry regardless of scale or complexity.",
    answer2:
      "Our cloud consultants ensures that workload performance, compliance, and cost are correctly and continually balanced against the best-fit infrastructure in real time.",
  },
  {
    question: "CMS Web Development ",
    answer:
      "Leverage cloud tech for maximum business outcomes by using the best architecture for security, reliability, performance optimization and cost effectivenessBuild web site or web application of any complexity with our experienced CMS web developers from comprehensive module management to building it from scratch.",
    answer2:
      "Maximize agility and elevate ROI by integrating infrastructure and apps with best architecture.",
  },
  {
    question: "Dot Net Web Development ",
    answer:
      "We help businesses create their enterprise web applications using Dot Net Web development.",
    answer2:
      "Leverage the benefit of microservices architecture with containerization, flexible scaling, and better deployment",
  },

];
export const TestingData2 = [
  {
    question: "Functional testing",
    li1: "• Smoke testing",
    li2: "• Unit testing ",
    li3: "• Integration ",
    li4: "• System testing",
    li5: "• Regression testing ",
    li6: "• User acceptance testing ",
    li7: "",
    li8: "",
  },
];
export const TestingData = [
  {
    question: "Functional testing",
    li1: "• Smoke testing",
    li2: "• Unit testing ",
    li3: "• Integration ",
    li4: "• System testing",
    li5: "• Regression testing ",
    li6: "• User acceptance testing ",
    li7: "",
    li8: "",
  },
  {
    question: "Integration testing",
    li1: "• Component integration test cases",
    li2: "• System integration test cases",
    li3: "",
    li4: "",
    li5: "",
    li6: "",
    li7: "",
    li8: "",
  },
  {
    question: "Compatibility testing",
    li1: "• Hardware",
    li2: "• Operating System",
    li3: "• Software",
    li4: "• Network",
    li5: "• Browser",
    li6: "• Devices",
    li7: "• Mobile",
    li8: "• Versions",
  },
  {
    question: " Accessibility testing",
    li1: "• Automated testing",
    li2: "• Manual code review",
    li3: "• User experience (UX) review",
    li4: "• User testing",
    li5: "",
    li6: "",
    li7: "",
    li8: "",
  },
  {
    question: "Regression testing",
    li1: "• Corrective regression testing",
    li2: "• Retest-all regression testing",
    li3: "• Selective regression testing",
    li4: "• Progressive regression testing",
    li5: "• Complete regression testing",
    li6: "• Partial regression testing",
    li7: "• Unit regression testing",
    li8: "",
  },
  {
    question: "Exploratory testing",
    li1: "• Freestyle exploratory testing",
    li2: "• Scenario-based exploratory testing",
    li3: "• Strategy-based exploratory testing",
    li4: "",
    li5: "",
    li6: "",
    li7: "",
    li8: "",
  },
  {
    question: "Performance testing",
    li1: "• Load testing",
    li2: "• Stress testing",
    li3: "• Capability testing",
    li4: "• Soak testing",
    li5: "• Volume testing",
    li6: "",
    li7: "",
    li8: "",
  },
  {
    question: "Security testing",
    li1: "• Vulnerability scanning",
    li2: "• Security scanning",
    li3: "• Penetration testing",
    li4: "• Risk assessment",
    li5: "• Security auditing",
    li6: "• Ethical hacking",
    li7: "• Posture assessment.",
    li8: "",
  },
  {
    question: "Usability testing",
    li1: "• Moderated vs. unmoderated",
    li2: "• Remote vs. in person",
    li3: "• Explorative vs. comparative",
    li4: "",
    li5: "",
    li6: "",
    li7: "",
    li8: "",
  },
];
