import React from "react";
import Banner from "../../../components/Banner";
import Overview from "../Retail/Overview";
import PSASoftware from "./PSASoftware";
import CenterScreen from "./CenterScreen";
import ProjectManagement from "./ProjectManagement";
import WhyChooseUsIndustries from "../../../components/WhyChooseUsIndustries";
import FooterBanner from "../../../components/Footer/FooterBanner";
const PSAData = [
  {
    id: 1,
    title: "Full project accounting & project financials management",
  },
  {
    id: 2,
    title: "Calendar management",
  },
  {
    id: 3,
    title: "Team collaboration",
  },
  {
    id: 4,
    title: "Proposal generation",
  },
  {
    id: 5,
    title: "Resource management",
  },
  {
    id: 6,
    title: "Expense management and budgeting",
  },
  {
    id: 7,
    title: "Invoicing & billing",
  },
  {
    id: 8,
    title: "Business intelligence",
  },
  {
    id: 9,
    title: "Forecasting",
  },
];
const ProjectData = [
  {
    id: 1,
    title: "Robust Task Management",
  },
  {
    id: 2,
    title: "Time tracking",
  },
  {
    id: 3,
    title: "Resource Management",
  },
  {
    id: 4,
    title: "User Role Management",
  },
  {
    id: 5,
    title: "Kanban board",
  },
  {
    id: 6,
    title: "Report & Analysis",
  },
  {
    id: 7,
    title: "Third-party app integration",
  },
];
const Why = [
  {
    id: "1",
    title: "Timely project delivery",
    desc: "We guarantee that our processes are on point and your project will be delivered on time.",
  },
  {
    id: "2",
    title: "Flexible Cooperation",
    desc: "Choose a time or a dedicated team model of your choice. We are ready to always work with you.",
  },
  {
    id: "3",
    title: "Dedicated team",
    desc: " If you are looking for a dedicated development team, our model is perfect for your needs. Hire a team from us to take full control of your resources",
  },
  {
    id: "4",
    title: "Experienced team & qualified developers",
    desc: "Our developers pass a series of interviews both internally and externally and technical leads before employment. Each of them has a professional development plan.",
  },
  {
    id: "5",
    title: "Transparent pricing",
    desc: "Approximate or total cost of the project is clear. All prices depend on the number of functionalities to be implemented and business analysis conducted",
  },
  {
    id: "6",
    title: "Quality first",
    desc: "We onboard the highest code quality and functionality of the product keeping in mind of all quality assurance standards throughout the software development life cycle (SDLC).",
  },
];
export default function ProfessionalServices() {
  return (
    <>
      <Banner
        img="/images/industries/professional-services/professional-services-banner.jpeg"
        title="Professional Services"
        desc="Leverage advanced professional service applications for growth to out-compete your competition in this ever-changing client-focused marketplace."
      />
      <Overview
        title="Overview of our professional services"
        desc="Innorik’s extensive industry process expertise, next-generation software engineering best practices, and integration across the professional service industry’s value chain has enabled companies to create new growth, deliver digital services and engage the greatest talent to head off the software challenges in this digital transformation age."
      />
      <h2 className="lg:text-[32px] md:text-[28px] text-[20px] text-center py-[25px] md:py-[40px] font-medium lg:py-[50px]">
        Our Systems
      </h2>
      <PSASoftware
        head="Professional Services Automation (PSA) software"
        // desc="This software makes it easy for business owners to set up a merchant account and accept a variety of payment methods from customers. This software has the following features;"
        data={PSAData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FProfessionalServices%2FpsaSoftware.jpg?alt=media&token=f37b1ee7-4e6e-4a7c-8f86-f56bafd18095"
      />
      <CenterScreen
        title="Work management software"
        desc="Our work management software helps both employers and employees to enter work hours and run work reports online. This software streamlines timesheet collection, attendance tracking, time analysis and payroll calculation."
        img="/images/industries/professional-services/work-management-software-background.jpeg"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2Fworkmanagementprofessional.png?alt=media&token=6a33822a-c85d-441e-b4b9-148c76469f3f"
      />
      <ProjectManagement
        head="Project management system"
        desc="Our project management system integrates these essential features:"
        data={ProjectData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FProfessionalServices%2FprojectManagement.jpg?alt=media&token=d7733e95-f0f9-413e-bce8-086faa149cc7"
      />
      <WhyChooseUsIndustries
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FProfessionalServices%2FwhyBackground.png?alt=media&token=03d970d1-9dd2-403e-8549-9c63d23568dd"
        data={Why}
      />
      <FooterBanner title="Send us your requirements" link="/contact-us" />
    </>
  );
}
