import React from "react";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { Link } from "react-router-dom";
import BorderButton from "./Buttons/BorderButton";

export default function Banner(props) {
  const button = props.button;
  // console.log(props);
  return (
    <div
      className="md:bg-fixed bg-center	 bg-no-repeat bg-cover bg-"
      style={{
        backgroundImage: `url("${props.img}")`,
      }}
    >
      <div className=" bg-black/40   top-0  flex flex-col   lg:h-[700px] 2xl:h-[800px] h-[500px] md:h-[500px]">
        <div className="m-auto mx-auto max-w-[1500px] lg:px-[85px] md:px-[40px] px-[28px] w-full   ">
          <div className=" pt-[40px] lg:w-3/5 md:w-4/5 ">
            <h1 className=" font-semibold text-white text-[26px] md:text-[36px] lg:text-[48px] text-left">
              {props.title}
            </h1>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
          <div className="m-auto ml-0  md:w-7/12 ">
            <p className="text-left  text-white text-[16px] md:text-[18px] lg:text-[18px] m-auto  pt-[6px] lg:pt-[15px] md:pt[0px]  sm:py-3 ">
              {props.desc}
            </p>
          </div>

          {button ? (
            <Link to="/contact-us">
              <BorderButton button={props.button} />
            </Link>
          ) : null}
        </div>

        {/* <h1 className="md:text-[36px] md:w-[368px] w-[300px] lg:w-[665px] lg:text-[48px] text-[26px]  text-white text-left pl-[25px] lg:pt-[40vh] md:pt-[90px] pt-[150px] lg:pl-20 font-semibold	">
          {props.title}
        </h1>
        <p className="md:text-[18px] lg:text-[18px] md:w-[368px] w-[300px] lg:w-[665px] text-[16px]  text-white text-left pl-[25px] lg:pt-[10px] pt-[10px] lg:pl-20 ">
          {props.desc}
        </p> */}
      </div>
    </div>
  );
}
