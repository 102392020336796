import React from "react";

import NoHoverCard from "../../components/Cards/NoHoverCard";

export default function CoreValues(props) {
  const listData = props.data;
  // console.log(listData);

  const newData = listData.map((data, index) => {
    return (
      <NoHoverCard
        key={index}
        image={data.img}
        title={data.title}
        desc={data.desc}
      />
    );
  });
  return (
    <>
      <div className="max-w-[1400px] md:py-[50px] mx-auto px-[]">
        <h1 className="pt-8 lg:pt-16 mt-0 font-semibold text-xl text-center mb-6 ms:text-2xl ms:mb-6 md:text-3xl md:mb-9 lg:text-4xl lg:mb-12">
          {props.title}
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mx-auto w-8/12 sm:w-10/12 lg:w-11/12 2xl:w-11/12 gap-6 sm:gap-8 md:gap-14 lg:gap-5 xl:gap-10 2xl:gap-14">
          {newData}
        </div>
      </div>
    </>
  );
}
