import React from "react";
import Banner from "../../../components/Banner";
import FourIcons from "../../../components/FourIcons";
import Overview from "./Overview";
import PaymentProcessing from "./PaymentProcessing";
import RetailManagement from "./RetailManagement";
import Ecommerce from "./Ecommerce";
import InventoryManagement from "./InventoryManagement";
// import WhyChooseUsIndustries from "../../../components/WhyChooseUsIndustries";
import FooterBanner from "../../../components/Footer/FooterBanner";
import WhyChooseNumber from "../../../components/WhyChooseNumber";

const IconsData = [
  {
    id: 1,
    img: "/images/industries/retail/icons/experience.svg",
    title: "",
    desc: "Personalized customer experience",
  },
  {
    id: 2,
    img: "/images/industries/retail/icons/agile.svg",
    title: "",
    desc: "Agile delivery",
  },

  {
    id: 3,
    img: "/images/industries/retail/icons/social-media.svg",
    title: "",
    desc: "Digital marketing deliveries",
  },
  {
    id: 4,
    img: "/images/industries/retail/icons/shopping-cart.svg",
    title: "",
    desc: "Omnichannel retail",
  },
];
const InventoryData = [
  {
    id: 1,
    title: "Centralized inventory management",
  },
  {
    id: 2,
    title: "Tagging and Barcoding",
  },
  {
    id: 3,
    title: "Demand Forecasting",
  },
  {
    id: 4,
    title: "Inventory Tracking",
  },
  {
    id: 5,
    title: "Inventory Security",
  },
  {
    id: 6,
    title: "Purchase order management",
  },
  {
    id: 7,
    title: "Optimized inventory",
  },
];
const PaymentData = [
  {
    id: 1,
    title: "Online Payment Processing",
  },
  {
    id: 2,
    title: "Billing and Invoicing",
  },
  {
    id: 3,
    title: "Returns and Discrepancy Management",
  },
  {
    id: 4,
    title: "Sales Performance Reporting",
  },
  {
    id: 5,
    title: "Tokenization",
  },
];
const RetailData = [
  {
    id: 1,
    title: "Inventory",
  },
  {
    id: 2,
    title: "Invoicing and billing",
  },
  {
    id: 3,
    title: "Customer relationship management ",
  },
  {
    id: 4,
    title: "Stock verification",
  },
  {
    id: 5,
    title: "Data analysis and report generation",
  },
];
const Why = [
  {
    id: "1",
    title: "",
    desc: "We integrate unique IoT devices such as smart shelving, customer service robots, among many others, equipped with smart sensors and persistent connectivity that leads to unique retail experiences.",
  },
  {
    id: "2",
    title: "",
    desc: "Aside from building a strong foundation for robust solutions, we also invest tremendously in future technologies to ensure future-ready developments.",
  },
  {
    id: "3",
    title: " ",
    desc: "Our retail software is built to offer the most dynamic solutions and with that in mind we ensure that users get the best intuitive interface that can be used instantly with no or minimum supervision.",
  },
  {
    id: "4",
    title: "",
    desc: "We help retail companies gain in-depth knowledge into customer buying preferences using new or existing data by employing big data for highly personalized shopping experiences.",
  },
  {
    id: "5",
    title: " ",
    desc: "Our products go through several stages of both intensive and extensive testing, quality assurance and practical usability to ensure the best end results. Great commitment and responsibility from our team are key factors to achieving this.",
  },
  {
    id: "6",
    title: " ",
    desc: "Our dedicated team comprises of professional retailers and technical professionals. They all bring onboard their combined qualifications, experiences, and expertise to develop the application.",
  },
];
export default function Retail() {
  return (
    <>
      <Banner
        img="/images/industries/retail/retail-banner-2.jpeg"
        title="Retail"
        desc="Buttress your customer relationships and give shoppers the best experience they deserve with engaging brand experiences powered by innovative technologies."
      />
      <Overview
        title="Your retail tech problems, our tech mission"
        desc="Customers are currently demanding an excellent shopping experience that are personalized, hyper-connected and engaging, since emerging technologies are paving the way for new opportunities for retailers. The challenge in the way goods are marketed, purchased, delivered, and sold can be grasped with technologies like cloud, IoT, AI, etc, to give shopping another level of experience. At Innorik, our goal is to build the best retail applications using the most innovative ideals coupled with our experience and expertise in the retail industry for our clients."
      />

      <div className="pb-8  pt-4 lg:py-8">
        <FourIcons title="Custom retail software from us" data={IconsData} />
      </div>
      <h2 className="lg:text-[32px] md:text-[28px] text-[20px] text-center py-[25px] md:py-[40px] font-medium lg:py-[50px]">
        Our retail applications{" "}
      </h2>
      <PaymentProcessing
        head="Payment processing software"
        desc="This software makes it easy for business owners to set up a merchant account and accept a variety of payment methods from customers. This software has the following features:"
        data={PaymentData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2Fpayment%20Prodessing%20Software.png?alt=media&token=3a1fff26-808b-45d2-8dd7-03ecf97d5b4a"
        // img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      />
      <Ecommerce
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FEcommerce%201.png?alt=media&token=53a71485-8361-4a29-8e8a-323a9c50b2c3"
        title="E-Commerce platforms"
        desc="Our e-commerce platforms integrate the most important features including; In-built SEO capabilities, business intelligence, mobile supported e-commerce, order management system, multi-channel functionality, product management and third party systems and plug-ins"
      />
      <RetailManagement
        head="Retail management software"
        // desc="This software makes it easy for business owners to set up a merchant account and accept a variety of payment methods from customers. This software has the following features;"
        data={RetailData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Industries%2FRetail%2FretailManagement.jpg?alt=media&token=2dc13517-efc1-47b9-8a59-e2bfaa0d58b4"
        // img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      />
      <InventoryManagement
        head=" Inventory management software"
        desc="The following are some features embedded in our software:"
        data={InventoryData}
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/quickScreens%2FInventory%20Mangement.png?alt=media&token=3adf9c70-a017-4b1c-95b7-31f628e3cd16"
        // img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      />
      <WhyChooseNumber
        img="/images/industries/retail/why-choose-us-retail.jpeg"
        data={Why}
      />
      {/* <WhyChooseUsIndustries
        img="/images/industries/retail/why-choose-us-retail.jpeg"
        data={Why}
      /> */}
      <FooterBanner title="Send us your requirements" link="/contact-us" />
    </>
  );
}
