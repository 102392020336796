import React from "react";

export default function WhyApplicationMigration(props) {
  // const Data = props.data;
  // const dataList = Data.map((data, index) => {
  //   return (
  //     <div className="flex group h-[400px] sm:h-[360px] lg:h-[380px] bg-slate-900/[.04]">
  //       <div className="w-[8px] shrink-0 group-hover:bg-[#6793ff]"></div>
  //       <div className="flex flex-col">
  //         <div className="font-medium pl-4 pr-10 pt-6 pb-5 text-lg xl:text-xl 2xl:text-2xl">
  //           {data.title}
  //         </div>
  //         <div className="px-5 text-[14px] ">{data.desc}</div>
  //       </div>
  //     </div>
  //   );
  // });

  return (
    <>
      <div className="max-w-[1500px] mx-auto px-[28px] md:px-[40px] lg:px-[86px] ">
        <h1 className="mt-10 font-semibold text-xl text-center mb-8 ms:text-2xl ms:mb-6 md:text-3xl md:mb-9 lg:text-4xl lg:mb-12">
          Our Application Modernization Roadmaps
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mx-auto pb-10  gap-6 sm:gap-8 md:gap-14 lg:gap-5 xl:gap-10 2xl:gap-14">
          <div className="flex group h-[400px] sm:h-[360px] lg:h-[380px] bg-slate-900/[.04]">
            <div className="w-[8px] shrink-0 group-hover:bg-[#6793ff]"></div>
            <div className="flex flex-col">
              <div className="font-medium pl-4 pr-10 pt-6 pb-5 text-lg xl:text-xl 2xl:text-2xl">
                Cloud Migration
              </div>
              <div className="px-5 text-[14px] ">
                <ul className="space-y-2 list-disc">
                  <li>
                    <span className="font-semibold">
                      Onsite Data Centers to Cloud -
                    </span>
                    Moving some or all of a company’s onsite data center
                    capabilities into the cloud to run on the cloud
                    infrastructure.
                  </li>
                  <li>
                    <span className="font-semibold">
                      Cloud to Cloud Migration -
                    </span>
                    Migrating between different cloud providers
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex group h-[400px] sm:h-[360px] lg:h-[380px] bg-slate-900/[.04]">
            <div className="w-[8px] shrink-0 group-hover:bg-[#6793ff]"></div>
            <div className="flex flex-col">
              <div className="font-medium pl-4 pr-10 pt-6 pb-5 text-lg xl:text-xl 2xl:text-2xl">
                Replace
              </div>
              <div className="px-5 text-[14px] ">
                Completely decommission and replace an existing legacy system
                with a new system.{" "}
              </div>
            </div>
          </div>

          <div className="flex group h-[400px] sm:h-[360px] lg:h-[380px] bg-slate-900/[.04]">
            <div className="w-[8px] shrink-0 group-hover:bg-[#6793ff]"></div>
            <div className="flex flex-col">
              <div className="font-medium pl-4 pr-10 pt-6 pb-5 text-lg xl:text-xl 2xl:text-2xl">
                Rebuild
              </div>
              <div className="px-5 text-[14px] ">
                Restructure and optimize legacy systems into a cloud native
                environment. A full rebuild, and rearchitects of the legacy
                system into a fully modernized, cloud native environment. This
                includes implementing new methodologies and adopting new
                technologies like containers, microservices, immutable
                infrastructure and declarative API's
              </div>
            </div>
          </div>

          <div className="flex group h-[400px] sm:h-[360px] lg:h-[380px] bg-slate-900/[.04]">
            <div className="w-[8px] shrink-0 group-hover:bg-[#6793ff]"></div>
            <div className="flex flex-col">
              <div className="font-medium pl-4 pr-10 pt-6 pb-5 text-lg xl:text-xl 2xl:text-2xl">
                Refactor & Rearchitect
              </div>
              <div className="px-5 text-[14px] ">
                This involves implementing new technologies in tiers of the
                application to take advantage of cloud-based or other systems
                with some code changes in selective tiers. This option leverages
                changing parts of the legacy system to optimize code for better
                performance
              </div>
            </div>
          </div>

          {/* {dataList} */}
        </div>
      </div>
    </>
  );
}
