import React from "react";
// import { IoIosArrowForward } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./developers.css";
import Slider from "react-slick";
import BorderButton from "../../../../components/Buttons/BorderButton";
import { Link } from "react-router-dom";
// import { phpDevelopers } from "../../../../data/programming";
// const profile = [
//   {
//     id: 1,
//     desc: "Expertise in developing applications using Visual Studio 2017.",
//   },
//   {
//     id: 2,
//     desc: "Expertise in Microsoft SQL SERVER 2012 and 2016, Sap Crystal Report, Windows PowerShell, SSMS, Windows Server 2008,2012 and 2016.",
//   },
//   {
//     id: 3,
//     desc: "Experience in applying the latest software development approaches including MVC, event driven applications using AJAX, Object Oriented (OO) JavaScript, JSON and XML.",
//   },
//   {
//     id: 4,
//     desc: "Development of User Interfaces using HTML5, CSS, XML, AJAX, JavaScript and Angular, React",
//   },
//   {
//     id: 5,
//     desc: "Experience in building .Net Web Services using SOAP, UDDI, and WSDL..",
//   },
//   {
//     id: 6,
//     desc: "Experienced with building REST API/Micro-services.",
//   },
//   {
//     id: 7,
//     desc: "Develop REST API services in an Agile environment.",
//   },
//   {
//     id: 8,
//     desc: "Experience with SSIS tasks such as Conditional Split, Multicast, Fuzzy Lookup, etc.",
//   },
//   {
//     id: 9,
//     desc: "Experience with developing windows applications using C#.NET, WPF, WinForms, Visual Studio and SQL Server. Good knowledge in Data Structures and Algorithms.",
//   },
//   {
//     id: 10,
//     desc: "Experience with developing web applications using ASP.NET MVC, Entity Framework, LINQ Queries",
//   },
//   {
//     id: 11,
//     desc: "Experience with creating applications using using .NET Core, VB.NET and C#.NET.",
//   },
// ];
// const skillsetData = [
//   {
//     id: 1,
//     desc: "Programming and Scripting Languages Known: Visual C#.NET, Visual Basic.NET, HTML, CSS.",
//   },
//   {
//     id: 1,
//     desc: "Relational Database Platforms Known Microsoft SQL Server (2008/2008R2, 2012/2014, 2016), Microsoft",
//   },
// ];
// const educationData = [
//   {
//     id: 1,
//     desc: "Programming and Scripting Languages Known: Visual C#.NET, Visual Basic.NET, HTML, CSS.",
//   },
//   {
//     id: 2,
//     desc: "Relational Database Platforms Known Microsoft SQL Server (2008/2008R2, 2012/2014, 2016), Microsoft",
//   },
// ];
const partnersData = [
  {
    id: 1,
    img: "/images/technologies/profile/1.jpeg",
  },
  {
    id: 2,
    img: "/images/technologies/profile/2.jpeg",
  },
  {
    id: 3,
    img: "/images/technologies/profile/3.jpeg",
  },
  {
    id: 4,
    img: "/images/technologies/profile/4.jpeg",
  },
  {
    id: 5,
    img: "/images/technologies/profile/5.jpeg",
  },
  {
    id: 6,
    img: "/images/technologies/profile/6.jpeg",
  },
  {
    id: 7,
    img: "/images/technologies/profile/7.jpeg",
  },
  {
    id: 8,
    img: "/images/technologies/profile/8.jpeg",
  },
  {
    id: 9,
    img: "/images/technologies/profile/9.jpeg",
  },
  {
    id: 10,
    img: "/images/technologies/profile/10.jpeg",
  },
  {
    id: 11,
    img: "/images/technologies/profile/11.jpeg",
  },
  {
    id: 12,
    img: "/images/technologies/profile/12.jpeg",
  },
  {
    id: 13,
    img: "/images/technologies/profile/13.jpeg",
  },
];

export default function OurDevelopers(props) {
  const listProfile = props.data.map((item, index) => {
    return <li className=""> {item.list}</li>;
  });
  // const listSkillset = skillsetData.map((item, index) => {
  //   return <li className="pt-[5px]">{item.desc}</li>;
  // });
  // const listEducation = skillsetData.map((item, index) => {
  //   return <li>{item.desc}</li>;
  // });

  const renderSlides = () =>
    partnersData.map((num) => (
      <div className="m-auto ">
        <img
          className="m-auto h-[100px] px-[4px] object-cover w-[150px]  "
          src={num.img}
          alt="developers"
        />
      </div>
    ));
  return (
    <>
      {props.banner === "true" ? (
        <div
          className=" bg- bg-no-repeat top-0 relative flex flex-col  bg-cover bg-center "
          style={{
            backgroundImage: `url("${props.img}")`,
          }}
        >
          <div className="bg-black/70">
            <div className=" max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] py-[20px] md:py-[30px] lg:py-[70px] text-center text-white mx-auto">
              <h1 className="text-[24px] md:text-[28px] lg:text-[36px]">
                {props.title}
              </h1>
              <p className="text-[16px] lg:text-[18px] pt-[20px] lg:pt-[35px]">
                {props.desc}
              </p>
              {Text != null ? (
                <div>
                  <br /> <p className="text-[16px] lg:text-[]">{props.desc2}</p>
                </div>
              ) : null}
              <div className="flex justify-center">
                <Link to="/contact-us">
                  <BorderButton button={props.button} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div>
        <div className="max-w-[1500px] md:px-[40px] lg:px-[85px] mx-auto invisible md:visible h-0 md:h-auto">
          {props.devs === "true" ? (
            <div className=" md:py-[20px] lg:pt-[50px]">
              <Slider
                infinite={true}
                autoplay={true}
                arrows={false}
                slidesToShow={9}
                autoplaySpeed={2000}
                speed={500}
                dots={false}
              >
                {renderSlides()}
              </Slider>
            </div>
          ) : null}
          {props.profile === "true" ? (
            <div>
              <div className="pb-[50px] py-[50px]">
                <div className="grid grid-cols-12 ">
                  <div className="col-span-3 	 bg-[#252525] text-white text-left px-[30px] ">
                    <p className="pt-[50px] text-[18px]">
                      {" "}
                      Our Developers Profile
                    </p>
                  </div>
                  <div className="col-span-9 bg-[#f9f9f9] py-[50px] px-[30px]">
                    <ul className="text-[14px] space-y-2 list-disc	text-[#222222]">
                      {listProfile}
                    </ul>
                  </div>
                </div>
                {/* <div className="grid grid-cols-12 ">
                  <div className="col-span-3 	 bg-[#252525] text-white text-left px-[30px] ">
                    <p className="pt-[20px] text-[18px]"> Technical Skillset</p>
                  </div>
                  <div className="col-span-9 bg-[#f9f9f9] py-[20px] px-[30px]">
                    <ul className="text-[14px] list-disc	text-[#222222]">
                      {listSkillset}
                    </ul>
                  </div>
                </div> */}
                {/* <div className="grid grid-cols-12 ">
                  <div className="col-span-3 	 bg-[#252525] text-white text-left px-[30px] ">
                    <p className="pt-[20px] text-[18px]">
                      Education of Our Developers
                    </p>
                  </div>
                  <div className="col-span-9 bg-[#f9f9f9] pt-[20px] pb-[50px] px-[30px] text-[#222222]">
                    <p>
                      Our developers have education from High school diploma to
                      PHD level
                    </p>
                    <p> Technical Certification: MCSE, MTA, CCNA</p>
                  </div>
                </div> */}
              </div>
            </div>
          ) : null}
        </div>
        {props.profile === "true" ? (
          <div className="visible md:h-0 md:invisible px-[28px] py-[28px]">
            <h1 className="text-[#3066EA] text-[18px]">
              Our Developer’s Profile
            </h1>
            <ul className="list-outside list-disc space-y-2 pl-[25px] pt-[20px]">
              {listProfile}
            </ul>
            {/* <h1 className="text-[#3066EA] text-[18px] pt-[40px]">
              Technial Skillset
            </h1>
            <ul className="list-outside space-y-2 list-disc pl-[25px] pt-[20px]">
              {listSkillset}
            </ul>
            <h1 className="text-[#3066EA] text-[18px] pt-[40px]">Education</h1>
            <p className="text-[16px] pt-[20px]">
              Our developers have education from High school diploma to PHD
              level
            </p>
            <p className="text-[16px] pt-[10px]">
              Technical Certification: MCSE, MTA, CCNA
            </p> */}
          </div>
        ) : null}
      </div>
    </>
  );
}
