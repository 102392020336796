import React from "react";
import List from "../../components/Cards/List";

export default function WhyCustomSoftware(props) {
  const newData = props.data;
  const dataList = newData.map((data, index) => {
    return <List key={index} title={data.title} />;
  });
  return (
    <div className=" max-w-[1500px] px-[28px] md:px-[40px] lg:px-[85px] mx-auto   pt-6 lg:pt-8 pb-8 ">
      <div className="conatiner bg-[#D9E3FF] flex lg:flex-row  flex-col md:flex-col w-full lg:pl-[60px] lg:pr-[85px] py-[5%] md:py-[3%]">
        <div className="m-auto w-full md:w-1/2 lg:w-3/5 pb-[10px]">
          <div className="m-auto w-full">
            <img className="m-auto h-[300px]  w-[300px] pt-2" src={props.img} />
          </div>
        </div>
        <div className="conatiner text-black flex flex-col  w-full ">
          <div className="md:m-auto  w-full justify-center lg:pl-0 pl-[5%] ">
            <h2 className="m-auto lg:pl-[40%]  text-semibold  text-[24px] lg:text-[36px] text-left sm:mt-0 sm:mb-0 lg:text-left lg:mr-0">
              {props.head}
            </h2>
            {/* <img
              className="m-auto mt-10 mb-5 w-2/5 sm:mt-0 sm:mb-0 sm:ml-12 sm:w-1/2"
              src={props.img}
            /> */}
          </div>
          <div className="m-auto lg:pl-[40%] md:pl-[5%]  w-full pt-2 ">
            <ul className=" list-outside grid grid-cols-1 md:w-[100%]  md:grid-cols-2 lg:grid-cols-1  text-[16px] lg:text-[18px] m-auto  w-11/12 sm:py-2 leading-loose">
              {dataList}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
