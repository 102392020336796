import React, { useState, useEffect } from "react";
import "./Navbar.css";
import Logo from "../../Assets/Images/logo.svg";
import { AiOutlineArrowRight } from "react-icons/ai";
import { a, Nava } from "react-router-dom";

export default function Navbar() {
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringOnApp, setIsHoveringOnApp] = useState(false);
  const [isHoveringOnCompany, setIsHoveringOnCompany] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [string, setString] = useState(null);
  // const [stringApproach, setStringApproach] = useState(null);

  const [colorChange, setColorchange] = useState(false);

  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false);

  //logo scroll when active
  //  const [navbarLogo, setNavbarLogo] = useState(logo)

  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  //logo scroll function
  //  const changeLogo = () => {
  //    if (window.scrollY >= 60) {
  //      setNavbarLogo(logoBlue)
  //    } else {
  //      setNavbarLogo(logo)
  //    }
  //  }

  //  useEffect(() => {
  //    changeLogo()
  //    // adding the event when scroll change Logo
  //    window.addEventListener("scroll", changeLogo)
  //  })
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const handelMouseEnter = (e, str) => {
    // setIsHovering(true);
    setString(str);
    // console.log("fuck the fucker");
  };
  // const handelMouseLeave = (e) => {
  //   setIsHovering(false);
  //   console.log("fuck the fucker");
  // };
  const handelMouseEnterOnApp = (e, str) => {
    setIsHoveringOnApp(true);
    // setStringApproach(str);

    // console.log("fuck the fucker");
  };
  // const handelMouseLeaveOnApp = (e) => {
  //   setIsHoveringOnApp(false);
  //   console.log("fuck the fucker");
  // };
  const handelMouseEnterOnCompany = (e) => {
    setIsHoveringOnCompany(true);
    // console.log("fuck the fucker");
  };
  const handelMouseLeaveOnCompany = (e) => {
    setString(null);
    // console.log("fuck the fucker");
  };
  return (
    <>
      <div
        onMouseLeave={(e) => {
          handelMouseEnter(e, null);
        }}
        className={
          navbar
            ? "  mx-auto placeholder: fixed z-20 top-0 left-0 right-0    w-full group justify-around text-white  hover:bg-black delay-150	duration-200 ease-in-out bg-black "
            : "  placeholder: fixed z-20 top-0 left-0 right-0  mx-auto  w-full group justify-around text-white hover:bg-black 	"
        }
      >
        {/* <div
        onMouseLeave={(e) => {
          handelMouseEnter(e, null);
        }}
        className={
          colorChange
            ? "  mx-auto placeholder: fixed z-20 top-0 left-0 right-0  mx-auto  w-full group justify-around text-white  bg-black "
            : "   fixed z-20 w-full group justify-around  top-0 left-0 right-0 mx-auto text-white  hover:bg-black"
        }
      > */}
        <div
          className={
            " main w-full mx-auto py-[4px] max-w-[1500px] px-[85px]  z-10  grid grid-cols-4 group justify-around text-white "
          }
        >
          <div className="my-auto text-2xl ">
            <a href="/">
              <img
                className=" my-auto h-[62px] py-[2px]"
                src="/images/logo.png"
              />
              {/* <div className="m-auto col-span-2 ml-0">Innorik</div> */}
            </a>
          </div>

          <div className="col-span-2 pl-6 text-lg">
            <div className="dropdown overflow-hidden  float-left">
              <button
                onMouseEnter={(e) => {
                  handelMouseEnter(e, "company");
                }}
                // onMouseLeave={handelMouseLeaveOnCompany}
                id="company"
                className="hover:text-[#3066ea] mx-5 py-5"
              >
                Company
              </button>

              <div className="dropmenu max-w-[1400px]  hidden left-0 right-0 mx-auto w-full  bg-[#282828] absolute">
                <div className=" w-full pr-[85px] grid grid-cols-4 l mx-auto max-w-[1400px] ">
                  <div className="flex pl-[85px]  flex-col justify-items-start  pl-auto leading-10 pt-5 pb-10 bg-black">
                    <button
                      id="abt"
                      onMouseEnter={(e) => {
                        handelMouseEnter(e, "about-us");
                      }}
                      // onMouseEnter={handelMouseLeave}
                      className="hover:text-[#3066ea] text-left"
                    >
                      About Us
                    </button>

                    <button
                      onMouseEnter={(e) => {
                        handelMouseEnter(e, "approach");
                      }} // onMouseEnter={handelMouseLeaveOnApp}
                      id="app"
                      className="hover:text-[#3066ea] text-left"
                    >
                      Approach
                    </button>

                    <button className="hover:text-[#3066ea] text-left">
                      <a href="/career">Career</a>
                    </button>
                    <div className="w-[225px] h-[162px]">
                      <img
                        className="pt-5 pr-8"
                        src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/ApplicationMigrationCloudMigration.jpg?alt=media&token=96cc8c24-8fff-42e6-a533-54e14b0d0910"
                      />
                    </div>
                  </div>
                  {/**
                   * This is the approach section
                   */}

                  {string === "approach" ? (
                    <div
                      id="approach"
                      style={{ display: "grid" }}
                      className="hidden col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-2 py-5 px-8">
                        <div className="">
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/engagement-module">Engagement Module</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            At Innorik, our mission is to deliver high-quality
                            software to our customers...
                          </div>
                        </div>
                        <div>
                          {/* <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                          Term Of Use
                        </div>
                        <div className="text-[14px] leading-6	 pb-[50px]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Gravida facilisi ligula feugiat fusce dolor
                          velit nec.
                        </div> */}
                        </div>
                      </div>

                      <div className="grid grid-rows-2 py-5 px-8">
                        <div className="">
                          {/* <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                          Privacy Policy
                        </div>
                        <div className="text-[14px] leading-6	 pb-[50px]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Gravida facilisi ligula feugiat fusce dolor
                          velit nec.
                        </div> */}
                        </div>
                      </div>
                      {/* <div className="grid py-5 pr-[85px]">
                        <div>
                          <div className="w-[285px] h-[120px]">
                            <img
                              className="w-[285px] h-[120px] object-cover object-center"
                              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CustomSoftware%2FCustomSoftwareHero.jpg?alt=media&token=26522c53-a59e-4fbd-94fb-992e5e0c2568"
                            />
                          </div>
                          <div className="text-[14px] opacity-80 leading-5 pt-5	 pb-[50px]">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}

                  {/**
                   * This is the about us section
                   */}
                  {string === "company" ? (
                    <div
                      id="aboutus"
                      style={{
                        display: "grid",
                      }}
                      className="col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-2 py-5 pb-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/about">About Company</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc] leading-6	 pb-[50px]">
                            Innorik is a North American based Information
                            Technology...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/talent-world-wide">Talent Worldwide</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik is building a community of talented and
                            diverse...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-2 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/culture-and-values">Culture & Value</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Let us be driven by operation excellence, integrity,
                            trust,...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/contact-us">Contact Us</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            If you can dream it, we can make it, get in
                            touch………….
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-rows-2  py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/partnership">Partnership</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            With our partnership with Microsoft, we have
                            helped...
                          </div>
                        </div>
                        <div>
                          {/* <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a    href="/partnership">Partnership</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {string === "about-us" ? (
                    <div
                      id="aboutus"
                      style={{
                        display: "grid",
                      }}
                      className="col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-2 py-5 pb-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/about">About Company</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik is a North American based Information
                            Technology...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/talent-world-wide">Talent Worldwide</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik is building a community of talented and
                            diverse...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-2 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/culture-and-values">Culture & Value</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Let us be driven by operation excellence, integrity,
                            trust,...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/contact-us">Contact Us</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            If you can dream it, we can make it, get in
                            touch………….
                          </div>
                        </div>
                      </div>

                      <div className="grid py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/partnership">Partnership</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            With our partnership with Microsoft, we have
                            helped...
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="dropdown overflow-hidden float-left">
              <button
                onMouseEnter={(e) => {
                  handelMouseEnter(e, "services");
                }} // onMouseEnter={handelMouseLeaveOnApp} id="services"
                className="hover:text-[#3066ea] mx-5 py-5"
              >
                Services
              </button>
              <div className="dropmenu max-w-[1400px]  hidden left-0 right-0 mx-auto w-full  bg-[#282828] absolute">
                <div className=" w-full pr-[85px] grid grid-cols-4 l mx-auto max-w-[1400px] ">
                  <div className="flex pl-[85px]  flex-col justify-items-start  pl-auto leading-10 pt-5 pb-10 bg-black">
                    <a
                      onMouseEnter={(e) => {
                        handelMouseEnter(e, "application-services");
                      }} // onMouseEnter={handelMouseLeaveOnApp}
                      id="application"
                      className="hover:text-[#3066ea] text-left"
                      href="#"
                    >
                      Application Services
                    </a>
                    <a
                      onMouseEnter={(e) => {
                        handelMouseEnter(e, "it-services");
                      }} // onMouseEnter={handelMouseLeaveOnApp}
                      className="hover:text-[#3066ea]"
                      href="#"
                    >
                      IT Services
                    </a>
                    <a
                      onMouseEnter={(e) => {
                        handelMouseEnter(e, "infrastructure-services");
                      }}
                      className="hover:text-[#3066ea]"
                      href="#"
                    >
                      Infrastructure Services
                    </a>
                    {/* <a    className="hover:text-[#3066ea]" href="#">
                    Software Development
                  </a> */}
                    <a
                      onMouseEnter={(e) => {
                        handelMouseEnter(e, "outsourcing-services");
                      }}
                      className="hover:text-[#3066ea]"
                      href="#"
                    >
                      Outsourcing Services
                    </a>
                    {/* <a     className="hover:text-[#3066ea]" href="#">
                    Cloud Consulting
                  </a> */}
                  </div>
                  {string === "application-services" ? (
                    <div
                      id="aboutus"
                      style={{
                        display: "grid",
                      }}
                      className="col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-3 py-5 pb-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a
                              // onClick={(e) => {
                              //   setIsHovering();
                              //   setString();
                              // }}
                              href="/web-development"
                            >
                              Web Development
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik has been delivering impactful and engaging
                            top-notch web...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/mobile-development">Mobile Development</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            In every aspect of our lives today smartphones play
                            an...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/custom-software">Custom Software</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Leverage modern technology to out-compete your...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/ui-ux-design">UI/UX Design</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            At innorik, we have a very experienced team of
                            designers...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/responsive-design">
                              Responsive Web Design
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Responsive web designs ensure a web solution’s...{" "}
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/application-migration">
                              Application Migration
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Speed up modernization with software and platform...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/testing">Software quality engineering</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Reimagine your business with our top-quality
                            assurance...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/cloud-application-integration">
                              Cloud Application Integration
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik has been delivering impactful and
                            engaging...
                          </div>
                        </div>
                        {/* <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a    href="/technologies/nodejs">NodeJs</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Because Node.js runs on a single thread and use...
                          </div>
                        </div> */}
                      </div>
                      {/* <div className="grid grid-rows-1  px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a    href="/technologies/programming/dot-net">.Net</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Microsoft .Net Framework is a software
                            development...
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                  {/* {string === "application-services" ? (
                    <div
                      style={{
                        display: "grid",
                      }}
                      className="hidden col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-2 py-5 px-8 xl:px-16">
                        <div className="pb-12">
                          <div className="text-lg pb-4 ">Development</div>
                          <div className="text-xs leading-7 ">
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="/web-development">Web Development</a>
                            </p>
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="/mobile-development">
                                Mobile Development
                              </a>
                            </p>
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="/custom-software">Custom Software</a>
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 ">Design</div>
                          <div className="text-xs leading-6">
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="ui-ux-design">UI/UX Design </a>
                            </p>
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="responsive-design">
                                Responsive Web Design
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-rows-2 py-5 px- xl:px-4">
                        <div className="pb-12">
                          <div className="text-lg pb-4 ">Modenization</div>
                          <div className="text-xs leading-6">
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="/application-migration">
                                Application Migration
                              </a>
                            </p>
                         
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="/testing">
                                Software Quality Engineering
                              </a>
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 ">Integration</div>
                          <div className="text-xs leading-6">
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="cloud-application-integration">
                                Cloud Application Integration
                              </a>
                            </p>
                           
                          </div>
                        </div>
                      </div>
                      <div className="grid py-5 pr-[85px]">
                        <div>
                          <div className="w-[285px] h-[120px]">
                            <img
                              className="w-[285px] h-[120px] object-cover object-center"
                              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CustomSoftware%2FCustomSoftwareHero.jpg?alt=media&token=26522c53-a59e-4fbd-94fb-992e5e0c2568"
                            />
                          </div>
                          <div className="text-[14px] leading-5 pt-5	 pb-[50px]">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {string === "services" ? (
                    <div
                      id="aboutus"
                      style={{
                        display: "grid",
                      }}
                      className="col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-3 py-5 pb-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a
                              // onClick={(e) => {
                              //   setIsHovering();
                              //   setString();
                              // }}
                              href="/web-development"
                            >
                              Web Development
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik has been delivering impactful and engaging
                            top-notch web...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/mobile-development">Mobile Development</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            In every aspect of our lives today smartphones play
                            an...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/custom-software">Custom Software</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Leverage modern technology to out-compete your...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/ui-ux-design">UI/UX Design</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            At innorik, we have a very experienced team of
                            designers...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/responsive-design">
                              Responsive Web Design
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Responsive web designs ensure a web solution’s...{" "}
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/application-migration">
                              Application Migration
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Speed up modernization with software and platform...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/testing">Software quality engineering</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Reimagine your business with our top-quality
                            assurance...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/cloud-application-integration">
                              Cloud Application Integration
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik has been delivering impactful and
                            engaging...
                          </div>
                        </div>
                        {/* <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a    href="/technologies/nodejs">NodeJs</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Because Node.js runs on a single thread and use...
                          </div>
                        </div> */}
                      </div>
                      {/* <div className="grid grid-rows-1  px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a    href="/technologies/programming/dot-net">.Net</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Microsoft .Net Framework is a software
                            development...
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                  {/* show if we hover on services */}
                  {/* {string === "services" || "" ? (
                    <div
                      style={{
                        display: "grid",
                      }}
                      className="hidden col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-2 py-5 px-8 xl:px-16">
                        <div className="pb-12">
                          <div className="text-lg pb-4 ">Development</div>
                          <div className="text-xs leading-7 ">
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="/web-development">Web Development</a>
                            </p>
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="/mobile-development">
                                Mobile Development
                              </a>
                            </p>
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="/custom-software">Custom Software</a>
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 ">Design</div>
                          <div className="text-xs leading-6">
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="ui-ux-design">UI/UX Design </a>
                            </p>
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="responsive-design">
                                Responsive Web Design
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-rows-2 py-5 px- xl:px-4">
                        <div className="pb-12">
                          <div className="text-lg pb-4 cursor-pointer	 ">
                            Modenization
                          </div>
                          <div className="text-xs leading-6">
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="/application-migration">
                                Application Migration
                              </a>
                            </p>
                           
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="/testing">
                                Software Quality Engineering
                              </a>
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 ">Integration</div>
                          <div className="text-xs leading-6">
                            <p className="text-[16px] cursor-pointer hover:text-white/80	 hover:underline">
                              <a    href="cloud-application-integration">
                                Cloud Application Integration
                              </a>
                            </p>
                          
                          </div>
                        </div>
                      </div>
                      <div className="grid py-5 pr-[85px]">
                        <div>
                          <div className="w-[285px] h-[120px]">
                            <img
                              className="w-[285px] h-[120px] object-cover object-center"
                              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CustomSoftware%2FCustomSoftwareHero.jpg?alt=media&token=26522c53-a59e-4fbd-94fb-992e5e0c2568"
                            />
                          </div>
                          <div className="text-[14px] leading-5 pt-5	 pb-[50px]">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* if it services is on hover show this */}
                  {string === "it-services" ? (
                    <div
                      style={{
                        display: "grid",
                      }}
                      className="gap-[30px] col-span-3 py-5 px-8 grid grid-cols-3 "
                    >
                      <div className="">
                        <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                          <a href="/cloud-enablement">Cloud Enablement</a>
                        </div>
                        <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                          Cloud computing has become the optimal way to deliver
                          enterprise applications and the most preferred
                          solution...{" "}
                        </div>
                      </div>
                      <div>
                        <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                          <a href="/devops">Devops Development</a>
                        </div>
                        <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                          Cloud technologies are the key enabler to achieve
                          digital transformation. Innorik’s infrastructure
                          management...
                        </div>
                      </div>

                      {/* <div className="grid grid-rows-2 py-5 px-8">
                      <div className="">
                        <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                          Privacy Policy
                        </div>
                        <div className="text-[14px] leading-6	 pb-[50px]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Gravida facilisi ligula feugiat fusce dolor
                          velit nec.
                        </div>
                      </div>
                    </div> */}
                      {/* <div className="grid  pr-[85px]">
                        <div>
                          <div className="w-[285px] h-[120px]">
                            <img
                              className="w-[285px] h-[120px] object-cover object-center"
                              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CustomSoftware%2FCustomSoftwareHero.jpg?alt=media&token=26522c53-a59e-4fbd-94fb-992e5e0c2568"
                            />
                          </div>
                          <div className="text-[14px] leading-5 pt-5	 pb-[50px]">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                  {string === "infrastructure-services" ? (
                    <div
                      style={{
                        display: "grid",
                      }}
                      className="gap-[30px] col-span-3 py-5 px-8 grid grid-cols-3 "
                    >
                      <div className="">
                        <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                          <a href="/cloud-infrastructure">
                            Cloud Infrastructure
                          </a>{" "}
                        </div>
                        <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                          Cloud technologies are the key enabler to achieve
                          digital transformation. Innorik’s infrastructure
                          management...
                        </div>
                      </div>

                      <div>
                        <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                          <div className="text-lg cursor-pointer hover:text-[#3066ea]">
                            <a href="/cloud-consulting">Cloud Consulting</a>
                          </div>{" "}
                        </div>
                        <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                          Cloud technology has the potential to revolutionize
                          business, offering agility, scale, and favorable
                          economics...
                        </div>{" "}
                      </div>

                      {/* <div className="grid grid-rows-2 py-5 px-8">
                      <div className="">
                        <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                          Privacy Policy
                        </div>
                        <div className="text-[14px] leading-6	 pb-[50px]">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Gravida facilisi ligula feugiat fusce dolor
                          velit nec.
                        </div>
                      </div>
                    </div> */}
                      {/* <div className="grid  pr-[85px]">
                        <div>
                          <div className="w-[285px] h-[120px]">
                            <img
                              className="w-[285px] h-[120px] object-cover object-center"
                              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CustomSoftware%2FCustomSoftwareHero.jpg?alt=media&token=26522c53-a59e-4fbd-94fb-992e5e0c2568"
                            />
                          </div>
                          <div className="text-[14px] leading-5 pt-5	 pb-[50px]">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}

                  {string === "outsourcing-services" ? (
                    <div
                      style={{
                        display: "grid",
                      }}
                      className="hidden gap-[30px] col-span-3 py-5 px-8 grid grid-cols-3 "
                    >
                      <div className="">
                        <div className="text-lg pb-4 cursor-pointer hover:text-[rgb(48,102,234)]">
                          <a href="/business-process-outsourcing">
                            Business Process Outsourcing
                          </a>
                        </div>
                        <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                          In today’s world of digital transformation, a
                          successful business is often an efficient one and the
                          difference...
                        </div>
                      </div>
                      <div>
                        <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                          <a href="/it-consulting">It Consulting</a>
                        </div>
                        <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                          Technology is the central driving force of progress in
                          every industry that leads to achieving...
                        </div>
                      </div>

                      {/* <div className="grid grid-rows-2 py-5 px-8">
                     <div className="">
                       <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                         Privacy Policy
                       </div>
                       <div className="text-[14px] leading-6	 pb-[50px]">
                         Lorem ipsum dolor sit amet, consectetur adipiscing
                         elit. Gravida facilisi ligula feugiat fusce dolor
                         velit nec.
                       </div>
                     </div>
                   </div> */}
                      {/* <div className="grid  pr-[85px]">
                        <div>
                          <div className="w-[285px] h-[120px]">
                            <img
                              className="w-[285px] h-[120px] object-cover object-center"
                              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CustomSoftware%2FCustomSoftwareHero.jpg?alt=media&token=26522c53-a59e-4fbd-94fb-992e5e0c2568"
                            />
                          </div>
                          <div className="text-[14px] leading-5 pt-5	 pb-[50px]">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="dropdown overflow-hidden float-left">
              <button
                onMouseEnter={(e) => {
                  handelMouseEnter(e, "solutions");
                }}
                id="solution"
                className="hover:text-[#3066ea] mx-5 py-5"
              >
                Solution
              </button>

              <div className="dropmenu max-w-[1400px]  hidden left-0 right-0 mx-auto w-full  bg-[#282828] absolute">
                <div className=" w-full pr-[85px] grid grid-cols-4 l mx-auto max-w-[1400px] ">
                  <div className="flex pl-[85px]  flex-col justify-items-start  pl-auto leading-10 pt-5 pb-10 bg-black">
                    <a
                      onMouseEnter={(e) => {
                        handelMouseEnter(e, "industries");
                      }}
                      className="hover:text-[#3066ea]"
                      href="#"
                    >
                      Industries
                    </a>
                    <a href="/technologies/erp">ERP</a>
                    {/* <div className="w-[240px] h-[120px] pt-5 shrink-0">
                      <img
                        className="w-[240px] h-[120px] object-cover object-center"
                        src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CustomSoftware%2FCustomSoftwareHero.jpg?alt=media&token=26522c53-a59e-4fbd-94fb-992e5e0c2568"
                      />
                    </div>
                    <div className="text-sm pt-10 mr-4 shrink-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div> */}
                  </div>
                  {string === "solutions" ? (
                    <div
                      id="aboutus"
                      style={{
                        display: "grid",
                      }}
                      className="col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-3 py-5 pb-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/education">Education</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            At Innorik, recognizing the great potential of...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/healthcare">Healthcare</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            The importance of fitness is being increasingly...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/real-estate">Real Estate</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            A variety of sectors have adopted advanced...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/manufacturing">
                              Manufacturing
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            The evolution in the manufacturing industries
                            have...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/insurance">Insurance</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Insurance industry spans a wider spectrum as it...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/fintech">Fintech</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            At Innorik, we are here to assist you with
                            matching...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/transportation">
                              Tranportation & Logistics
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            A hyper-connected ecosystem has resulted from the...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/professional-services">
                              Professional Services
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik’s extensive industry process expertise...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/retail">Retail</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Customers are currently demanding an excellent
                            shopping...
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* when you hover over Industries */}
                  {string === "industries" ? (
                    <div
                      id="aboutus"
                      style={{
                        display: "grid",
                      }}
                      className="col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-3 py-5 pb-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/education">Education</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            At Innorik, recognizing the great potential of...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/healthcare">Healthcare</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            The importance of fitness is being increasingly...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/real-estate">Real Estate</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            A variety of sectors have adopted advanced...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/manufacturing">
                              Manufacturing
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            The evolution in the manufacturing industries
                            have...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/insurance">Insurance</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Insurance industry spans a wider spectrum as it...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/fintech">Fintech</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            At Innorik, we are here to assist you with
                            matching...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/transportation">
                              Tranportation & Logistics
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            A hyper-connected ecosystem has resulted from the...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/professional-services">
                              Professional Services
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik’s extensive industry process expertise...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/industries/retail">Retail</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Customers are currently demanding an excellent
                            shopping...
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="dropdown overflow-hidden float-left">
              <button
                onMouseEnter={(e) => {
                  handelMouseEnter(e, "technologies");
                }}
                id="solution"
                className="hover:text-[#3066ea] mx-5 py-5"
              >
                Technologies
              </button>

              <div className="dropmenu max-w-[1400px]  hidden left-0 right-0 mx-auto w-full  bg-[#282828] absolute">
                <div className=" w-full pr-[85px] grid grid-cols-4 l mx-auto max-w-[1400px] ">
                  <div className="flex pl-[85px]  flex-col justify-items-start  pl-auto leading-10 pt-5 pb-10 bg-black">
                    <a
                      onMouseEnter={(e) => {
                        handelMouseEnter(e, "programming");
                      }}
                      className="hover:text-[#3066ea]"
                      href="#"
                    >
                      Programming
                    </a>
                    <a
                      onMouseEnter={(e) => {
                        handelMouseEnter(e, "latest-thinking");
                      }}
                      className="hover:text-[#3066ea]"
                      href="#"
                    >
                      Latest Thinking
                    </a>
                    <a
                      onMouseEnter={(e) => {
                        handelMouseEnter(e, "platforms");
                      }}
                      className="hover:text-[#3066ea]"
                      href="#"
                    >
                      Platforms
                    </a>

                    {/* <div className="w-[240px] h-[120px] pt-5 shrink-0">
                      <img
                        className="w-[240px] h-[120px] object-cover object-center"
                        src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CustomSoftware%2FCustomSoftwareHero.jpg?alt=media&token=26522c53-a59e-4fbd-94fb-992e5e0c2568"
                      />
                    </div>
                    <div className="text-sm pt-10 mr-4 shrink-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div> */}
                  </div>
                  {string === "technologies" ? (
                    <div
                      id="aboutus"
                      style={{
                        display: "grid",
                      }}
                      className="col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-3 py-5 pb-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a
                              // onClick={(e) => {
                              //   setIsHovering();
                              //   setString();
                              // }}
                              href="/technologies/php"
                            >
                              Php
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            At Innorik, we put together a handful of amazing
                            ideas...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/Angular">Angular</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            When it comes to creating a dynamic single-page
                            app...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/cpp">C++</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            C++ is one of the most widely used
                            object-oriented...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/golang">GoLang</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Golang works well on a variety of operating
                            platforms...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/java">Java</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            The most popular object-oriented and open-source...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/javascript">Javascript</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            JavaScript is a scripting language that relieves the
                            server...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/python">Python</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            When it comes to coding, Python is one of the
                            most...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/react-native">
                              React Native
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik excels at building elegant, interactive,
                            and...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/nodejs">NodeJs</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Because Node.js runs on a single thread and uses...
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-rows-1  px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/dot-net">.Net</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Microsoft .Net Framework is a software
                            development...
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* when you hover over Programming */}
                  {string === "programming" ? (
                    <div
                      id="aboutus"
                      style={{
                        display: "grid",
                      }}
                      className="col-span-3 grid grid-cols-3 "
                    >
                      <div className="grid grid-rows-3 py-5 pb-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/php">Php</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            At Innorik, we put together a handful of amazing
                            ideas...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/Angular">Angular</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            When it comes to creating a dynamic single-page
                            app...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/cpp">C++</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            C++ is one of the most widely used
                            object-oriented...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/golang">GoLang</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Golang works well on a variety of operating
                            platforms...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/java">Java</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            The most popular object-oriented and open-source...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/javascript">Javascript</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            JavaScript is a scripting language that relieves the
                            server...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-rows-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/python">Python</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            When it comes to coding, Python is one of the
                            most....
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/react-native">
                              React Native
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Innorik excels at building elegant, interactive,
                            and....
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/nodejs">NodeJs</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Because Node.js runs on a single thread and uses...
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-rows-1  px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/technologies/dot-net">.Net</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Microsoft .Net Framework is a software
                            development...
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {string === "latest-thinking" ? (
                    <div
                      id="aboutus"
                      style={{
                        display: "grid",
                      }}
                      className="col-span-3 grid grid-rows-1 gap-0 "
                    >
                      <div className="grid grid-cols-3 gap-[10px] py-5 px-8">
                        <div>
                          <div className="text-lg  cursor-pointer hover:text-[#3066ea]">
                            <a href="/latest-thinking/artificial-intellegence">
                              Artificial Intellegence
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	">
                            Organisations in this era have more data at their
                            disposal...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg  cursor-pointer hover:text-[#3066ea]">
                            <a href="/latest-thinking/iot">IOT</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6">
                            With the evolving and adoption of technology, our...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg  cursor-pointer hover:text-[#3066ea]">
                            <a href="/latest-thinking/computer-vision">
                              Computer Vision
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6">
                            Computer vision (CV) is an artificial
                            intelligence-based...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-3 py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/latest-thinking/big-data">Big Data</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            In this undeniably digitised world, how much
                            information...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/latest-thinking/blockchain">Blockchain</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Blockchain is changing the way the world lives
                            and...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/latest-thinking/virtual-reality">
                              Virtual Reality
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Recreating memories to make the impossible,
                            possible....
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {string === "platforms" ? (
                    <div
                      id="aboutus"
                      style={{
                        display: "grid",
                      }}
                      className="col-span-3 grid grid-rows-1 gap-0 "
                    >
                      <div className="grid grid-cols-3 gap-[10px] py-5 px-8">
                        <div>
                          <div className="text-lg  cursor-pointer hover:text-[#3066ea]">
                            <a href="/platforms/magento">Magento</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	">
                            At Innorik we offer affordable Magento
                            development...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg  cursor-pointer hover:text-[#3066ea]">
                            <a href="/platforms/microsoft-dynamics">
                              Microsoft Dynamics
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6">
                            One outstanding benefit of Dynamics 365 is tight...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg  cursor-pointer hover:text-[#3066ea]">
                            <a href="/platforms/services-now">Services Now</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6">
                            ServiceNow is a robust cloud-based system for...
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-[10px] py-5 px-8">
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/platforms/salesforce">Salesforce</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Salesforce suite of products offers revenue...
                          </div>
                        </div>
                        <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a href="/platforms/power-bi">Power BI</a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Microsoft Power BI is a collection of software
                            services...
                          </div>
                        </div>
                        {/* <div>
                          <div className="text-lg pb-4 cursor-pointer hover:text-[#3066ea]">
                            <a    href="/latest-thinking/virtual-reality">
                              Virtual Reality
                            </a>
                          </div>
                          <div className="text-[14px] text-[#bbbcbc]  leading-6	 pb-[50px]">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className=" my-auto  ml-auto  ">
            <a href="/contact-us">
              <div className="flex items-center	group pl-5 pr-8 py-2 text-sm rounded-sm border border-[#3066ea] hover:border-transparent hover:outline-none hover:bg-[#3066ea] hover:duration-800 transition ease-out delay-100">
                <button className="">
                  Hire Developers
                  {/* <i
             className="ml-3 fa fa-arrow-right transition transform duration-500 ease-out group-hover:translate-x-[10px]"
             aria-hidden="true"
           ></i> */}
                </button>
                <AiOutlineArrowRight
                  className="ml-3 fa fa-arrow-right transition transform duration-500 ease-out group-hover:translate-x-[10px]"
                  aria-hidden="true"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
