const mobileNavData = [
  {
    tab: "Company",
    details: [
      {
        id: 1,
        title: "About Company",
        link: "/about",
      },
      {
        id: 1,
        title: "Culture and Values",
        link: "/culture-and-values",
      },
      {
        id: 1,
        title: "Partnership",
        link: "/partnership",
      },
      {
        id: 1,
        title: "Talent World wide",
        link: "/talent-world-wide",
      },
      {
        id: 1,
        title: "Contact Us",
        link: "/contact-us",
      },
      {
        id: 1,
        title: "Career",
        link: "/career",
      },
    ],
  },
  {
    tab: "Application Services",
    details: [
      {
        id: 1,
        title: "Web Development",
        link: "/web-development",
      },
      {
        id: 1,
        title: "Mobile Development",
        link: "/mobile-development",
      },
      {
        id: 1,
        title: "Custom Software",
        link: "/cusotom-software",
      },
      {
        id: 1,
        title: "UI/UX Design",
        link: "/ui-ux-design",
      },
      {
        id: 1,
        title: "Responsive Web Design",
        link: "/responsive-design",
      },
      {
        id: 1,
        title: "Application Migration",
        link: "/application-migration",
      },
      {
        id: 1,
        title: "Testing",
        link: "/testing",
      },
      {
        id: 1,
        title: "Cloud Integration",
        link: "/cloud-application-integration",
      },
    ],
  },
  {
    tab: "IT Services",
    details: [
      {
        id: 1,
        title: "Cloud Enablement",
        link: "/cloud-enablement",
      },
      {
        id: 1,
        title: "Devops Development",
        link: "/devops",
      },
      {
        id: 1,
        title: "Cloud Infrastructure",
        link: "/cloud-infrastructure",
      },
      {
        id: 1,
        title: "Cloud Consulting",
        link: "/cloud-consulting",
      },
      {
        id: 1,
        title: "Business Process Outsourcing",
        link: "/business-process-outsourcing",
      },
      {
        id: 1,
        title: "IT Consulting",
        link: "/it-consulting",
      },
    ],
  },
  {
    tab: "Industries",
    details: [
      {
        id: 1,
        title: "Education",
        link: "/industries/education",
      },
      {
        id: 1,
        title: "Manufacturing",
        link: "/industries/manufacturing",
      },
      {
        id: 1,
        title: "Healthcare",
        link: "/industries/healthcare",
      },
      {
        id: 1,
        title: "Transportation",
        link: "/industries/transportation",
      },
      {
        id: 1,
        title: "Insurance",
        link: "/industries/insurance",
      },
      {
        id: 1,
        title: "Professional Services",
        link: "/industries/professional-services",
      },
      {
        id: 1,
        title: "Real Estate",
        link: "/industries/real-estate",
      },
      {
        id: 1,
        title: "Fintech",
        link: "/industries/fintech",
      },
      {
        id: 1,
        title: "Retail",
        link: "/industries/retail",
      },
    ],
  },
  {
    tab: "Technologies",
    details: [
      {
        id: 1,
        title: "Programming",
        link: "/programming/php",
      },
      {
        id: 1,
        title: "Latest Thinking",
        link: "/latest-thinking/virtual-reality",
      },
      {
        id: 1,
        title: "Platforms",
        link: "/platforms/microsoft-dynamics",
      },
    ],
  },
];

const newMobileNavData = [
  {
    tab: "Company",
    details: [
      {
        title: "About",
        data: [
          {
            id: 1,
            title: "About Company",
            link: "/about",
          },
          {
            id: 1,
            title: "Culture and Values",
            link: "/culture-and-values",
          },
          {
            id: 1,
            title: "Partnership",
            link: "/partnership",
          },
          {
            id: 1,
            title: "Talent World wide",
            link: "/talent-world-wide",
          },
          {
            id: 1,
            title: "Contact Us",
            link: "/contact-us",
          },
          {
            id: 1,
            title: "Career",
            link: "/career",
          },
        ],
      },
      {
        title: "Approach",
        data: [
          {
            id: 1,
            title: "Engagement Module",
            link: "/engagement-module",
          },
        ],
      },
    ],
  },

  {
    tab: "Services",
    details: [
      {
        title: "Application Services",
        data: [
          {
            id: 1,
            title: "Web Development",
            link: "/web-development",
          },
          {
            id: 1,
            title: "Mobile Development",
            link: "/mobile-development",
          },
          {
            id: 1,
            title: "Custom Software",
            link: "/custom-software",
          },
          {
            id: 1,
            title: "UI/UX Design",
            link: "/ui-ux-design",
          },
          {
            id: 1,
            title: "Responsive Web Design",
            link: "/responsive-design",
          },
          {
            id: 1,
            title: "Application Migration",
            link: "/application-migration",
          },
          {
            id: 1,
            title: "Testing",
            link: "/testing",
          },
          {
            id: 1,
            title: "Cloud Integration",
            link: "/cloud-application-integration",
          },
        ],
      },
      {
        title: "IT Services",
        data: [
          {
            id: 1,
            title: "Cloud Enablement",
            link: "/cloud-enablement",
          },
          {
            id: 1,
            title: "Devops",
            link: "/devops",
          },
        ],
      },
      {
        title: "Infrastructure Services",
        data: [
          {
            id: 1,
            title: "Cloud Infrastructure",
            link: "/cloud-infrastructure",
          },
          {
            id: 1,
            title: "Cloud Consulting",
            link: "/cloud-consulting",
          },
        ],
      },
      {
        title: "Outsourcing Services",
        data: [
          {
            id: 1,
            title: "Business Process Outsourcing",
            link: "/business-process-outsourcing",
          },
          {
            id: 1,
            title: "IT Consulting",
            link: "/it-consulting",
          },
        ],
      },
    ],
  },
  {
    tab: "Solutions",
    details: [
      {
        title: "Industries",
        data: [
          {
            id: 1,
            title: "Education",
            link: "/industries/education",
          },
          {
            id: 1,
            title: "Manufacturing",
            link: "/industries/manufacturing",
          },
          {
            id: 1,
            title: "Healthcare",
            link: "/industries/healthcare",
          },
          {
            id: 1,
            title: "Transportation",
            link: "/industries/transportation",
          },
          {
            id: 1,
            title: "Insurance",
            link: "/industries/insurance",
          },
          {
            id: 1,
            title: "Professional Services",
            link: "/industries/professional-services",
          },
          {
            id: 1,
            title: "Real Estate",
            link: "/industries/real-estate",
          },
          {
            id: 1,
            title: "Fintech",
            link: "/industries/fintech",
          },
          {
            id: 1,
            title: "Retail",
            link: "/industries/retail",
          },
        ],
      },
      {
        title: "ERP",
        data: [
          {
            id: 1,
            title: "ERP",
            link: "/technologies/erp",
          },
        ],
      },
    ],
  },

  {
    tab: "Technologies",

    details: [
      {
        title: "Programming",
        data: [
          {
            id: 1,
            title: "PHP",
            link: "/technologies/php",
          },
          {
            id: 1,
            title: "Angular",
            link: "/technologies/angular",
          },
          {
            id: 1,
            title: "C++",
            link: "/technologies/cpp",
          },
          {
            id: 1,
            title: ".Net",
            link: "/technologies/dot-net",
          },
          {
            id: 1,
            title: "GoLang",
            link: "/technologies/golang",
          },
          {
            id: 1,
            title: "Java",
            link: "/technologies/java",
          },
          {
            id: 1,
            title: "Javascript",
            link: "/technologies/javascript",
          },
          {
            id: 1,
            title: "Python",
            link: "/technologies/python",
          },
          {
            id: 1,
            title: "React Native",
            link: "/technologies/react-native",
          },
          {
            id: 1,
            title: "NodeJs",
            link: "/technologies/nodejs",
          },
        ],
      },
      {
        title: "Latest Thinking",
        data: [
          {
            id: 1,
            title: "Artificial Intellegence",
            link: "/latest-thinking/artificial-intellegence",
          },
          {
            id: 1,
            title: "Big Data",
            link: "/latest-thinking/big-data",
          },
          {
            id: 1,
            title: "Internet of things",
            link: "/latest-thinking/iot",
          },
          {
            id: 1,
            title: "Blockchain",
            link: "/latest-thinking/blockchain",
          },
          {
            id: 1,
            title: "Computer Vision",
            link: "/latest-thinking/computer-vision",
          },
          {
            id: 1,
            title: "Virtual Reality",
            link: "/latest-thinking/virtual-reality",
          },
        ],
      },
      {
        title: "Platforms",
        data: [
          {
            id: 1,
            title: "Magento",
            link: "/platforms/magento",
          },
          {
            id: 1,
            title: "Salesforce",
            link: "/platforms/salesforce",
          },
          {
            id: 1,
            title: "Microsoft Dynamics",
            link: "/platforms/microsoft-dynamics",
          },
          {
            id: 1,
            title: "Power BI",
            link: "/platforms/power-bi",
          },
          {
            id: 1,
            title: "Services Now",
            link: "/platforms/services-now",
          },
        ],
      },
    ],
  },
];

export { mobileNavData, newMobileNavData };
