import React from "react";

export default function Agile() {
  return (
    <div>
      <div className="conatiner flex flex-col justify-evenly w-full md:flex-row lg: md:pt-[50px]">
        <div className="m-auto w-full md:w-1/2 lg:w-1/3">
          <div className="m-auto w-9/12 sm:w-7/12 md:w-9/12 lg:w-11/12">
            <img
              className="m-auto w-full mt-10 mb-5  md:mt-0 md:mb-0  ld:ml-13 lg:px-[40px] "
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/EngagementModule%2Fagile.jpg?alt=media&token=75f9f61f-87ad-413c-8640-10d312548a07"
              alt="parthership with microsoft"
            />
          </div>
        </div>
        <div className="m-auto w-full md:w-[45%] lg:w-3/3">
          <h2 className="font-semibold text-center lg:text-left text-[20px] lg:text-[28px] m-auto w-11/12 sm:text-center">
            Agile
          </h2>
          <p className="text-justify text-[16px] md:text-[18px] lg:text-[18px] m-auto py-[16px] w-11/12  ">
            The Agile methodology allows us to release reliable software fast in
            iteration and introduce changes easily. Iteration releases improve
            efficiency by allowing the team to find and fix defects and align
            expectations early on. The development process consists of 1- 2
            weeks which could potentially extend to 4 weeks iterations resulting
            in an evolved version of software shipped each time. After each
            iteration, we get real feedback from customers that drives informed
            decisions on the next development steps. There are many different
            forms of the agile development method we use including scrum,
            extreme programming (XP), and feature-driven development (FDD).
          </p>
        </div>
      </div>
      {/* <div className="conatiner flex flex-col justify-evenly w-full lg:flex-row md:flex-row sm:flex-col lg:mt-[90px] ">
        <div className="m-auto w-full md:w-1/2 ">
          <div className="m-auto w-9/12 sm:w-7/12 md:w-9/12 lg:w-11/12">
            <img
              className="m-auto mt-10 mb-5 md:mt-0 md:mb-0 md:ml-12  lg:mr-[100px] w-full pr-[100px]"
              src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/EngagementModule%2Fagile.jpg?alt=media&token=75f9f61f-87ad-413c-8640-10d312548a07"
              alt="parthership with microsoft"
            />
          </div>
        </div>
        <div className="m-auto w-full md:w-2/3 lg:w-2/3">
          <h2 className="text-justify text-[24px] lg:text-[28px] m-auto w-11/12 sm:text-left">
            Agile
          </h2>
          <p className="text-justify text-lg m-auto w-11/12 mt-[16px] md:-mt[25px] lg:mt-[]  sm:text-left">
            The Agile methodology allows us to release reliable software fast in
            iteration and introduce changes easily. Iteration releases improve
            efficiency by allowing the team to find and fix defects and align
            expectation early on. The development process consists of 1- 2 week
            which could potentially extends to 4 weeks iterations resulting in
            an evolved version of software shipped each time. After each
            iteration, we get real feedback from customer that drives informed
            decisions on the next development steps. There are many different
            forms of the agile development method we use including scrum,
            extreme programming (XP), and feature-driven development (FDD).
          </p>
        </div>
      </div> */}
    </div>
  );
}
