import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./techtab.css";
import TabBox from "./TabBox";
// import { tabsData } from "./data";
// import TabPannelBox from './TabPannelBox'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function TechTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="border-solid border-2 border-[#CFCFCF]">
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "#F3F3F3",
          display: "flex",
          boxShadow: "10px",
        }}
      >
        <div className="shadow-[2px_0px_8px_0px_rgba(42,67,113,0.15)]">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            Tab={{
              style: {
                background: "#ffffff",
              },
            }}
            TabIndicatorProps={{
              style: {
                background: "#796EFF",
                //   height: "40px",
              },
            }}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              color: "#222222",
              bgcolor: "#ffffff",

              paddingLeft: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              width: "370px",
              //   paddingRight: "60px",
            }}
          >
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
                paddingRight: "60px",
              }}
              label="Frameworks "
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
                paddingRight: "60px",
              }}
              label="Web Services"
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
                paddingRight: "60px",
              }}
              label="Security "
              {...a11yProps(2)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Front End"
              {...a11yProps(3)}
            />

            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Database"
              {...a11yProps(4)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Deployment"
              {...a11yProps(5)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Architecture"
              {...a11yProps(6)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Products"
              {...a11yProps(7)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Testing"
              {...a11yProps(8)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Cashing"
              {...a11yProps(9)}
            />
            {/* <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="Project Management Tools"
              {...a11yProps(10)}
            />
            <Tab
              sx={{
                alignItems: "start",
                color: "#222222",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
              label="User Interface"
              {...a11yProps(11)}
            /> */}
          </Tabs>
        </div>

        <TabPanel style={{ width: "70%" }} value={value} index={0}>
          <h1 className="text-[32px] text-[#3066EA]">Frameworks</h1>
          <div className="grid grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fframeworks%2Fdot-net.svg?alt=media&token=85c04eb4-c13e-431b-8daf-1568235e4d54"
              desc=".NET"
              alt="microsoft dot net"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fframeworks%2Fnet-core.svg?alt=media&token=27d39033-145d-4aee-9103-49615a693fae"
              desc=".NET Core"
              alt="microsoft dot net core"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fframeworks%2Fasp.svg?alt=media&token=086a1a19-076f-4465-8bae-deaaef74f259"
              desc="ASP.NET"
              alt="microsoft asp dot net"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fframeworks%2Fc-sharp.svg?alt=media&token=b5dfe313-4cfd-4042-ab11-74089489e2be"
              alt="c sharp"
              desc="c#"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fframeworks%2Fvb-net.svg?alt=media&token=0431a0a5-005b-4726-9c67-9b676e74c008"
              alt="microsoft Vb dot net"
              desc="VB.NET"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fframeworks%2Ff-sharp.svg?alt=media&token=698eb45e-4716-4034-b9d3-d089e71c8d48"
              alt="f sharp"
              desc="f#"
            />

            {/* <div className=" w-[106px] h-[106px]">
              <div className="bg-white border-solid border-2 border-[#CFCFCF] p-[24px] ">
                <img className="w-[58px] h-[58px] m-auto" />
              </div>
            </div> */}
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={1}>
          <h1 className="text-[32px] text-[#3066EA]">Web Services</h1>
          <div className="grid grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fweb-service%2Fnet-core.svg?alt=media&token=ee287e0e-3d41-41ab-995f-afe7c54d3aca"
              alt=".Net core"
              desc=".Net Core"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fweb-service%2Fgrpc.svg?alt=media&token=8d98d05b-d5a0-41fb-bdcd-46f3cc33c632"
              alt="GRPC"
              desc="GRPC"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fweb-service%2Fgraphql.svg?alt=media&token=d6bcbc9c-54ad-4bb8-a092-bec05ad5796d"
              alt="GraphQL"
              desc="GraphQL"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fweb-service%2FOdata.svg?alt=media&token=4f91459f-4123-441d-8732-1058e62a7172"
              alt="OData"
              desc="OData"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fweb-service%2Fsoap.svg?alt=media&token=8241716e-4cd3-4548-b0a9-ad54654c2e00"
              alt="SOAP"
              desc="SOAP"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fweb-service%2Frest.svg?alt=media&token=a17c7639-06d3-409b-870c-fea0425ab411"
              alt="REST"
              desc="REST"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fweb-service%2Fnet.svg?alt=media&token=041fed71-e50a-4ffb-83d8-42dff3648cbc"
              alt=".NET"
              desc=".NET"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fweb-service%2Fwfc.svg?alt=media&token=22e94114-1fdd-43ba-aed1-8824e6a92046"
              alt="WFC Data Service"
              desc="WFC"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={2}>
          <h1 className="text-[32px] text-[#3066EA]">Security</h1>
          <div className="grid grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fsecurity%2FOauth.svg?alt=media&token=a8232d9c-3adb-4915-b4dd-8479a6ecd6d3"
              desc="OAuth 2.0"
              alt="Oauth 2.0"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fsecurity%2Fssl.svg?alt=media&token=91b4ea3f-6223-48d8-9a24-082022888716"
              desc="HTTPS/SSL"
              alt="https ssl"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fsecurity%2Fntlm.svg?alt=media&token=7924a8ce-60eb-4d4c-9e0b-2d51badcd7e8"
              desc="NTLM"
              alt="NT LAN Manager"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fsecurity%2Fx509.svg?alt=media&token=c1d804c2-6a07-4b9a-8855-52bdfa5021ef"
              desc="X.509"
              alt="X.509 Cryptography"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fsecurity%2Fsso.svg?alt=media&token=9bcb5d2c-912a-4d3e-9b8a-c9df22487edd"
              alt="SSO"
              desc="SSO"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fsecurity%2Fencryption.svg?alt=media&token=705055c2-3149-469c-a743-be533ce610e1"
              alt="Encryption"
              desc="Ecnryption"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fsecurity%2FKerberos.svg?alt=media&token=7edcdcea-d1e5-4c23-935a-82bbe889cfda"
              alt="Kerberos network protocol"
              desc="Kerbores"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fsecurity%2Faudits.svg?alt=media&token=0b513524-03ee-48c2-8e9f-bdde55de4429"
              alt="auditing"
              desc="Auditing"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fsecurity%2Flogs.svg?alt=media&token=7147e6e0-2ac5-410a-95ea-98ecd81d8236"
              alt="logging"
              desc="Logging"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={3}>
          <h1 className="text-[32px] text-[#3066EA]">Frontend</h1>
          <div className="grid grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2FUWP-apps.svg?alt=media&token=5b873616-9453-4918-be45-c3900a0d2f09"
              desc="UWP Apps"
              alt="UWP Apps"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2FWPF.svg?alt=media&token=991d9267-742d-467a-b4b5-a812d2cf14bb"
              desc="WPF"
              alt="WPF"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2Fangular.svg?alt=media&token=fefaca94-457a-404a-961d-4820e8f97030"
              desc="Angular"
              alt="Angular Js"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2Fbootstrap.svg?alt=media&token=306c3297-ea51-4ce9-b334-4ff32c94ef4e"
              desc="Bootstrap"
              alt="BootStrap"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2Fjquery.svg?alt=media&token=e60467a6-297a-40ff-8ae5-bce319ea6f91"
              alt="JQuery"
              desc="JQuery"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2FreactJs.svg?alt=media&token=da531666-491b-4cd4-ae12-3aa738d52a3a"
              alt="React Js"
              desc="React"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2Fvue.svg?alt=media&token=2369c3c7-ae3d-4aef-97e4-c41aa3ea596c"
              alt="Vue Js"
              desc="Vue"
            />
          </div>
        </TabPanel>

        <TabPanel style={{ width: "70%" }} value={value} index={4}>
          <h1 className="text-[32px] text-[#3066EA]">Database</h1>
          <div className="grid grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdatabase%2FMS-SQL.svg?alt=media&token=3f813513-a2cb-4548-8d04-da3048a09619"
              desc="MS-SQL"
              alt="MS SQL"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdatabase%2Felasticsearch.svg?alt=media&token=9182006a-6b16-48dd-bd13-c52134698a0b"
              desc="Elastisearch"
              alt="elastic search"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdatabase%2FMariaDB.svg?alt=media&token=d1ebb1b8-3589-4077-81d3-d75ad7d7bd18"
              desc="Maria DB"
              alt="Maria db"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdatabase%2FMySQL.svg?alt=media&token=251477b6-59f4-456f-b5fd-00ee9ed4e79f"
              desc="MySQL"
              alt="My SQL"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdatabase%2FOracle.svg?alt=media&token=8e3653f9-6f42-40b4-b8f7-7575f1465af7"
              alt="Oracle"
              desc="Oracle"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdatabase%2FPostgreSQL.svg?alt=media&token=689d6c0c-9671-4820-98dc-b5e0c741a33d"
              alt="PostgreSQL"
              desc="PostgreSQL"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdatabase%2Ffirebase-icon%201.svg?alt=media&token=198cc92d-ffcb-45ae-9ca8-40aa30b05891"
              alt="Firebase"
              desc="Firebase"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdatabase%2FmongoDB.svg?alt=media&token=9c489f4f-9334-4a91-9b83-83b256cf1406"
              alt="MongoDB"
              desc="mongo db"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={5}>
          <h1 className="text-[32px] text-[#3066EA]">Deployment</h1>
          <div className="grid grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdeployment%2Faws.svg?alt=media&token=957ec9e1-6ae9-49ce-b132-252f4048cf7e"
              desc="AWS"
              alt="amazon web service"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdeployment%2Fazure.svg?alt=media&token=be774a0f-6735-4adc-827f-55e3cae93910"
              desc="Azure"
              alt="Microsoft Azure"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdeployment%2Fdocker.svg?alt=media&token=684ce6fd-530f-464f-a2fc-7ec082459c83"
              desc="Docker"
              alt="docker"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdeployment%2Fgoogle-cloud.svg?alt=media&token=3e80e60e-46ed-4fad-864e-503e8e6bfb5e"
              desc="Google Cloud"
              alt="google cloud"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fdeployment%2Fkubernetes.svg?alt=media&token=df3a4c5b-d5d9-4eb9-9b82-1c7a70711689"
              desc="Kubernates"
              alt="Kubernates"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={6}>
          <h1 className="text-[32px] text-[#3066EA]">Deployment</h1>
          <div className="grid grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Farchitecture%2Fmvc.svg?alt=media&token=c7d2f6a9-6859-4765-99ca-18a8875e02dd"
              desc="MVC"
              alt="mvc"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Farchitecture%2Fmvp.svg?alt=media&token=ce3b41d6-db47-4069-90c8-ea576b35a8a1"
              desc="MVP"
              alt="MVP"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Farchitecture%2Fmvvm.svg?alt=media&token=61013acf-7e62-4b97-ab67-f8504646acfd"
              desc="MVVM"
              alt="MVVM"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Farchitecture%2Fmicroservices.svg?alt=media&token=228d11d5-384d-4549-aaaa-65b0fd6dca43"
              desc="Microservices"
              alt="microservices"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Farchitecture%2Fclean-coding.svg?alt=media&token=5cde80d7-1ee0-4f0d-8938-09275ce41430"
              desc="Clean"
              alt="clean coding"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={7}>
          <h1 className="text-[32px] text-[#3066EA]">Products</h1>
          <div className="grid grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fproducts%2Fmicrosoft-sharepoint.svg?alt=media&token=2cad7273-e126-40e2-90df-5f1d00c98411"
              desc="Sharepoint"
              alt="Microsoft Sharepoint"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fproducts%2FBiztalk.svg?alt=media&token=0e892c00-9f8c-468e-b690-55b7621517ef"
              desc="BizTalk"
              alt="Microsoft BizTalk"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fproducts%2Foffice.svg?alt=media&token=25939481-d365-49b3-9dcd-1fb361ee7be1"
              desc="Office 365"
              alt="Microsoft Office 365"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fproducts%2Fpower-bi.svg?alt=media&token=a0faa559-f9ca-4ae3-9f52-17dba620ac5e"
              desc="PowerBI"
              alt="power bi"
            />
          </div>
        </TabPanel>

        <TabPanel style={{ width: "70%" }} value={value} index={8}>
          <h1 className="text-[32px] text-[#3066EA]">Products</h1>
          <div className="grid grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ftesting%2Fbrowserstack.svg?alt=media&token=bb551b89-1e1c-4fdf-9c08-191bec8d07c8"
              desc="Browserstack"
              alt="browswerstack logo"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ftesting%2FJmeter.svg?alt=media&token=37a24305-c8ff-4604-91ad-7803cd58fc6a"
              desc="Apache JMeter"
              alt="Apache jMeter"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ftesting%2FKatalon.svg?alt=media&token=517e3a67-955f-40ba-9f1e-493d5d0e4c7b"
              desc="Katalon TS"
              alt="Katalon Test Studios"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ftesting%2Fowasp.png?alt=media&token=d0c64f2c-bc44-4530-879a-486043382ce5"
              desc="OWASP ZAP"
              alt="OWASP ZAP"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ftesting%2Fpostman.svg?alt=media&token=b89932c8-ad99-4443-9a53-5c44e5691aa2"
              desc="Postman"
              alt="postman"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ftesting%2Fselenium.svg?alt=media&token=6040be1a-2152-4d20-a11c-fe441c6d4bf7"
              desc="Selemium"
              alt="Selemium"
            />
          </div>
        </TabPanel>
        <TabPanel style={{ width: "70%" }} value={value} index={9}>
          <h1 className="text-[32px] text-[#3066EA]">Caching</h1>
          <div className="grid grid-cols-6 gap-[48px] py-[36px] text-center text-[#646464]">
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fcaching%2Fmemcached.svg?alt=media&token=f9fe3f3f-2d8e-463b-903d-92169159e351"
              desc="Memcached"
              alt="Memcached logo"
            />
            <TabBox
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Fcaching%2Fredis.svg?alt=media&token=2d04e541-78f5-4543-bd9c-e8d7186b9616"
              desc="Redis"
              alt="redis logo"
            />
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
