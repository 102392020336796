import React from "react";

export default function DevopsApproach(props) {
  return (
    <div>
      <div className="conatiner flex lg:flex-row  flex-col-reverse justify-evenly w-full md:flex-row lg:py-[60px] md:pt-[50px] ">
        <div className="m-auto w-full md:w-[45%] lg:w-3/3">
          <h2 className="font-semibold text-center lg:text-left text-[20px] lg:text-[28px] m-auto w-11/12 sm:text-center">
            {props.title}
          </h2>
          <p className="text-justify text-[16px] md:text-[18px] lg:text-[18px] m-auto py-[16px] w-11/12  ">
            {props.desc}
          </p>
        </div>
        <div className="m-auto w-full md:w-1/2 lg:w-1/3">
          <div className="m-auto w-9/12 sm:w-7/12 md:w-9/12 lg:w-11/12">
            <img
              className="m-auto w-full mt-10 mb-5  md:mt-0 md:mb-0  ld:ml-13 lg:px-[40px] "
              src={props.img}
              alt="parthership with microsoft"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
