import React from "react";
import Banner from "../../components/Banner";
// import RightImageSection from "../../components/RightImageSection";
import WhyChoose from "../../components/WhyChoose";
// import Overview from "../Technologies/Programming/Php/Overview";
import Overview from "./Overview";
import DesignInfographic from "./DesignInfographic";
import FooterBanner from "../../components/Footer/FooterBanner";
const Data = [
  {
    id: 1,
    title: "Experience in the design field",
  },
  {
    id: 2,
    title: "Broad-ranging expertise",
  },
  {
    id: 3,
    title: "Significantly reduced cost",
  },
  {
    id: 4,
    title: "Established Innorik standards",
  },
  {
    id: 5,
    title: "We address customers pain points",
  },
  {
    id: 6,
    title: "We personalize and deliver extraordinary customer service",
  },
];
export default function Design() {
  return (
    <>
      <Banner
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FUIux%2FUIUX-banner.jpg?alt=media&token=001f05d6-45cb-4bd8-a5d1-daea842ca88e"
        alt="ui ux services"
        title="Design Services"
        desc="Want to build your product with a team that establishes a clear design process, meets deadlines, and delivers that perfect product you’ve been dreaming of? Turn to Innorik UI and UX services. We have very creative designers who can help you build an engaging and stunning product easily and quickly."
      />
      <Overview
        color="bg-[#f9f9f9]"
        img="/images/services/uiux/ui-design.png"
        title="UI and UX Design Services "
        desc="At Innorik, we have a very experienced team of designers globally who understand project deadlines, and this has helped us to perfect the process of delivering UI and UX services to our clients. We follow established design standards, workflows, and guidelines and we don’t miss deadlines."
      />
      <DesignInfographic
        alt="out ui ux services"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FUIux%2FDelivering%20UI%20and%20UX%20Services.svg?alt=media&token=e233f2db-8ac8-466e-ab2f-042d9a67d015"
        title="Our Development Process"
      />
      <WhyChoose
        textColor="text-white"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FUIux%2Fwhy-choose-UIUX.jpg?alt=media&token=ded68a3f-358b-465f-9962-40d791c6a50f"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FUIux%2FWhy-choose-UIUX-mobile.jpeg?alt=media&token=3cda8093-817e-4360-a575-8c63eb68da45"
        head="Why choose Innorik for your UI/UX designs "
        data={Data}
      />
      <FooterBanner title="Start a dialog" link="/contact-us" />
    </>
  );
}
