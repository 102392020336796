import React from "react";
import Banner from "../../../../components/Banner";
import WhyChoose from "../../../../components/WhyChoose";
import OurDevelopers from "../Magento/OurDevelopers";
// import Overview from "../Magento/Overview";
import WhatWeOffer from "../Magento/WhatWeOffer";
import BannerImage from "../../../../Assets/Images/technologies/platforms/dynamics/microsoft-dynamics-360-banner.jpg";
// import Overview from "../../../CloudAppliationIntegration/Overview";
// import OverviewImage from "../../../../Assets/Images/technologies/platforms/dynamics/microsoft-dynamics-360-overview.jpg";
// import OverviewImageMobile from "../../../../Assets/Images/technologies/platforms/dynamics/microsoft-dynamics-overview-mobile.jpg";
import WhatWeOfferImage from "../../../../Assets/Images/technologies/platforms/dynamics/what-we-offering.jpg";
import Infographics from "./Infographics";
import ConsultImage from "../../../../Assets/Images/technologies/platforms/dynamics/icons/consultation.svg";
import CustomImage from "../../../../Assets/Images/technologies/platforms/dynamics/icons/custom.svg";
// import LowPrice from "../../../../Assets/Images/technologies/platforms/dynamics/icons/low-price.svg";
import ManagementImage from "../../../../Assets/Images/technologies/platforms/dynamics/icons/management.svg";
import WhyUsImage from "../../../../Assets/Images/technologies/platforms/dynamics/why-choose-Microsoft.jpg";
import WhyUsImageMobile from "../../../../Assets/Images/technologies/platforms/dynamics/why-choose-Microsoft-mobile.jpg";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import { phpDevelopers } from "../../../../data/programming";
import FooterBanner from "../../../../components/Footer/FooterBanner";

const weOffer = [
  {
    id: 1,
    img: ConsultImage,
    title: "Dynamics 365 consulting",
    desc: "We understand your evolving business needs to thrive in the digital era, we are here to help you decide and see how best you can transform your business journey with Microsoft Dynamics 365. Our primary goal is to bring a paradigm shift in your business to make it more robust and grow and also reduce costs in all your business processes.  ",
  },
  {
    id: 2,
    img: ManagementImage,
    title: "Dynamics 365 integration",
    desc: "We can help you improve collaboration across your teams, improve your team's productivity and reduce risks and time for your customer's projects. We can offer aid in the integration of familiar third-party applications like Skype, Outlook, Excel, etc. using the native connectors. We can also help you integrate with any Microsoft tool of your choice, from Office 365 to Azure's services for IoT, blockchain, and many more.",
  },
];
const weOffer2 = [
  {
    id: 1,
    img: "/images/technologies/platforms/dynamics/exchange.svg",
    title: "Dynamics 365 migration ",
    desc: "Our Dynamics 365 development team can help you migrate from legacy or inefficient systems to Dynamics 365 by applying the best migration strategies. We assist organizations to transfer data securely, efficiently, and effectively, causing minimal disruptions while boosting user adoption at the same time..",
  },
  {
    id: 2,
    img: CustomImage,
    title: "Dynamics 365 customization ",
    desc: "Each business is unique and has distinct requirements and processes specific to its industries, hence Microsoft Dynamics 365 is hardly used off the shelf in any organization. Our experts assist you in analyzing and understanding specific requirements and provide you with the best possible customized solution. ",
  },
];
const weOffer3 = [
  {
    id: 1,
    img: "",
    title: "",
    desc: "",
  },
  {
    id: 2,
    img: "",
    title: "",
    desc: "",
  },
];
const Data3 = [
  {
    id: 1,
    title: "Easy integration ",
  },
  {
    id: 2,
    title: "Data privacy ",
  },
  {
    id: 3,
    title: "Analytics for your business  ",
  },
  // {
  //   id: 4,
  //   title: "Experience",
  // },
  {
    id: 5,
    title: "Reduced cost to 50% of our competitors ",
  },
  {
    id: 5,
    title: "A pool of smart and experienced Dynamics 365 Professionals",
  },
];
export default function MicrosoftDynamics() {
  return (
    <>
      <Banner
        img={BannerImage}
        title="MICROSOFT DYNAMICS 365"
        desc="Bringing your clients and business together with the aid of Microsofts next generation CRM and ERP applications"
        // button="Hire Dynamic 365 Developer"
      />
      <Overview
        textColor="text-white bg-black"
        title="Take actions that drive your business with Microsoft Dynamics 365 "
        desc="Microsoft Dynamics 365 is a cloud-based business applications platform that enables you to simplify data management and allows for seamless integration across apps and business processes by combining components of customer relationship management (CRM) and enterprise resource planning (ERP) to help you run your entire business and deliver greater results through predictive and AI-driven insights. It comes with purpose-built applications that enable you to manage core business functions like sales, finance and operations, marketing, customer service, field service, project service automation, etc. to engage, empower and optimize how you conduct business. This intelligent business application is what Innorik seeks to offer you on a high-level scale at a relatively cheaper rate. "
        img="/images/technologies/platforms/dynamics/microsoft-dynamics-360-overview.jpg"
        img2="/images/technologies/platforms/dynamics/microsoft-dynamics-overview-mobile.jpg"
        alt="Microsoft Dynamics"
      />
      {/* <Overview
        color=""
        textColor="text-[#222222]"
        title="Overview"
        desc2="This intelligent business applications is what Innorik seeks to offer you on a high-level scale at a relatively cheaper rate.  "
        desc="One outstanding benefit of Dynamics 365 is tight integration with other Microsoft business applications such as Office 365 and Outlook. Pricing of Dynamics 365, as compared to its main competitors is also a benefit, with bundle and role-based licensing which is preferable to many clients in any case."
        img={OverviewImage}
        img2={OverviewImageMobile}
      /> */}
      <WhatWeOffer
        show="false"
        img={WhatWeOfferImage}
        title="Our Dynamics 365 offerings"
        title2=""
        data={weOffer}
        data2={weOffer2}
        data3={weOffer3}
      />
      <Infographics />
      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2Fphp%2FphpHero.jpg?alt=media&token=67832652-836b-4594-ace0-22fe0e18cc6a"
        title="Our Magento Experts"
        desc2=" Our Team also consist of highly motivated and committed engineers with over 20+ years’ in the IT business and over 10+ years practical and professional experience in Dynamics 365 and can offers services to cover all your platform-related needs."
        desc="Our team of Microsoft certified professionals and engineers can assist you in understanding the need to have a taste of Dynamics 365 and also help you customise and implement such, based on your organisational needs."
        button="Hire Dynamic 365 Developer"
      /> */}
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our Expertise"
        desc="Our team of Microsoft-certified professionals and engineers can assist you in understanding the need to have a taste of Dynamics 365 and also help you customize and implement such, based on your organizational needs. "
        button="Hire Microsoft 365 Professional"
        data={phpDevelopers}
      />
      <WhyChoose
        textColor="text-white"
        desc="Extensive experience with the IoT technology to develop comprehensive IoT solutions. "
        head="Why choose us?"
        data={Data3}
        img={WhyUsImage}
        img2={WhyUsImageMobile}
      />
      <FooterBanner title="Get started" link="/contact-us" />
    </>
  );
}
