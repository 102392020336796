import React from "react";

export default function NoHoverCard(props) {
  return (
    <>
      <div className="pt-4 pb-5  ">
        <div>
          <img className="mx-auto h-[62px]" src={props.image} />
        </div>
        <div>
          <p className="text-center text-[#222222] font-semibold text-lg px-5 py-2 xl:text-xl 2xl:text-[22px]">
            {props.title}
          </p>
          <p className="text-center text-[#767676] px-4 xl:text-lg 2xl:text-xl">
            {props.desc}
          </p>
        </div>
      </div>
    </>
  );
}
