import React from "react";
// import TestBanner from "../../components/TestBanner";
import FiveColCard from "../../components/FiveColCard";
import WhyChoose from "../../components/WhyChoose";
import ImageTabs from "../CloudInfrastructure/ImageTab";
import CoreValues from "../CultureAndValues/CoreValues";
import Overview from "./Overview";
import Banner from "../../components/Banner";

const Data = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationRequirement.jpg?alt=media&token=e5444656-5740-406a-80d1-1155cb0b0e66",
    title: "Requirement assessment",
    desc: "",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationPlanning.jpg?alt=media&token=ebfd9617-b923-4d33-9da6-8567c27bc702",
    title: "Planning",
    desc: "",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationDesign.jpg?alt=media&token=ce244d12-1ef6-4ccd-96fe-bfb23d384e0d",
    title: "Design",
    desc: "",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationImplementation.jpg?alt=media&token=27de31dd-3fc3-47a5-a590-7448047e496b",
    title: "Implementation",
    desc: "",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationSupport.jpg?alt=media&token=74d79d47-3366-4567-80b9-f174f7f161a3",
    title: "Maintenance and Support",
    desc: "",
  },
];
const Data2 = [
  {
    id: 1,
    title: "Improved operational efficiency",
  },
  {
    id: 2,
    title: "Faster time-to-market",
  },
  {
    id: 3,
    title: "Increased flexibility and scalability",
  },
  {
    id: 4,
    title: "Increased competitive edge",
  },
  {
    id: 5,
    title: "Reduced operational costs and increased revenue",
  },
  {
    id: 6,
    title:
      "The ability to combine all cloud applications and on-premises systems",
  },
  {
    id: 7,
    title: "Flexibility in how data is shared, stored, and accessed",
  },
];
const Data3 = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Fidea.png?alt=media&token=172c733f-269c-4584-b8ca-6fd7e228b6e9",
    title: "",
    desc: "Reduced cost to 50% of our competitors",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Foperational.png?alt=media&token=c1084799-14ff-4e3b-b5da-c1f5d2d4dff8",
    title: "",
    desc: "Clients’ happiness and satisfaction is our goal",
  },

  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Fteamwork.png?alt=media&token=7aa9b078-c42c-4fd5-b061-ec0b1da977d3",
    title: "",
    desc: "Highly skilled, experienced, and certified cloud practitioners",
  },
];

export default function CloudApplicationIntegration() {
  return (
    <>
      <div className="bg-white ">
        <div className="max-w-[1400px] mx-auto left-0 right-0">
          <div className="shadow-xl 		shadow-slate-200		">
            <div className="">
              <Banner
                img="https://firebasestorage.googleapis.com/v0 /b/innorik-1c0f9.appspot.com/o/Images%2Fservices%2FcloudApplication%2Fcloud-application-integration-banner.jpg?alt=media&token=d4be7d9d-d3eb-465d-a37e-ef8c7a38e9f7"
                title="Getting and staying ahead
with cloud technologies"
                desc="Build a highly performant, secure, cloud complaint platforms for greater scalability, stability, reliability, and efficiency "
              />
            </div>
            <div className="bg-white">
              <Overview
                img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationOverviewImage.jpg?alt=media&token=81ae935d-a76a-49f7-b830-a55520f86106"
                title="Cloud Application Integration "
                desc="The cloud is transforming the way businesses address data integration and helping them win the battle against data silos. Cloud application integration tools have open opportunities for organizations wanting to connect heterogeneous systems."
              />
            </div>
            <div className="bg-white">
              <FiveColCard
                title="We are empowered by these innovative technologies"
                data={Data}
              />
            </div>

            <div className="py-16 px-6 bg-white">
              <h1 className="text-center text-[26px] lg:text-[36px]">
                Cloud application services
              </h1>
              <p className="text-center text-[#222222] pt-6 text-[16px] lg:text-[18px] xl:text-[18px] lg:px-14">
                Our highly skilled cloud experts are ready to help your business
                use the latest cloud tools and technologies to connect your
                legacy applications, systems, repositories, and IT environments
                for the real-time exchange of data and process on all the major
                cloud service providers.
              </p>
            </div>
            <div className="bg-white">
              <ImageTabs />
            </div>
            <WhyChoose
              head="Why Integrate with cloud? "
              data={Data2}
              img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationWhyInnorik.jpg?alt=media&token=6fc3ecdd-478c-4578-8e95-139b86c8bb7f"
              img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
            />
            <div className="">
              <div className="bg-white pb-[80px]">
                <CoreValues title="Our Core Values" data={Data3} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
