import React from "react";

export default function LinearImageSection(props) {
  return (
    <div>
      <div className="conatiner lg:px-[85px] mx-auto max-w-[1400px] flex justify-between lg:flex-row  flex-col w-full   py-[40px]">
        <div className="m-auto w-full md:w-1/2 pb-[10px]">
          <div className="m-auto lg:w-full">
            <img
              className="m-auto h-[300px] object-cover w-full ml-0"
              src={props.img}
            />
          </div>
        </div>
        <div className="m-auto px-[28px] md:px-[40px] lg:pr-0 lg:mr-0  lg:w-full  ">
          <h2 className=" md:pt-[30px] font-semibold text-center lg:text-[left] text-[24px] md:text-[24px] sm:text-[24px] lg:text-[28px] sm:text-lg m-auto lg:mr-0 py-0 sm:py-2 lg:text-left">
            Skills Accelerator Training Program
          </h2>
          <p className="text-justify md:text-center lg:text-left text-[16px] lg:text-[18px] m-auto lg:mr-0  py-4 sm:py-2 sm:text-left ">
            Innorik is building a community of talented and diverse
            professionals who are passionate about technology and exceptional
            client service. We are searching for talented students and
            non-students to join our exciting, online training program with no
            out-of-pocket cost to you that will accelerate your career in a
            technology related field. This fast-track technology training
            program bootcamp is designed for college grads and graduating
            college seniors with STEM degrees who are seeking to advance their
            skills and prepare for a career in high demand technology areas not
            limited to software development, cloud computing, data analytics,
            computer networking, machine learning etc. We are committed to
            investing in new talents worldwide and preparing people to make the
            most out of technology.
          </p>
        </div>
      </div>
      {/* <div className="conatiner flex flex-col justify-evenly w-full md:flex-row ">
      <div className="m-auto w-full md:w-1/2 lg:w-1/3">
      <div className="m-auto w-9/12 sm:w-7/12 md:w-9/12 lg:w-11/12">
        <img className="m-auto mt-10  w-full md:mt-0 md:mb-0 md:ml-6 md:w-full lg:ml-12 lg:w-11/12" src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/TalentWorldwide%2Flinear.png?alt=media&token=4e39ecf2-789e-4adb-9ee8-7f027898e9f9" alt='parthership with microsoft'/>
      </div>
    </div>
     
        <div className="m-auto w-full md:w-2/3 lg:w-2/3 py-[40px]">
          <h2 className=" font-semibold text-center lg:text-[left] text-[24px] md:text-[24px] sm:text-[24px] lg:text-[28px] sm:text-lg m-auto py-0 w-11/12 sm:py-2 lg:text-left">
            Skills Accelerator Training Program
          </h2>
          <p className="text-justify text-[14px] md:text-[16px] lg:text-[18px] m-auto py-5 w-11/12 sm:py-10 sm:text-left">
            Innorik is building a community of talented and diverse
            professionals that are passionate about technology and exceptional
            client service. We are searching for talented students and
            non-students to join an exciting, online training program with no
            out-of-pocket cost to you that will accelerate your career in a
            technology related field. This fast-track technology training
            program bootcamp designed for college grads and graduating college
            seniors with STEM degrees who are seeking to advance their skills
            and prepare for a career in high demand technology areas not limited
            to software development, cloud computing, data analytics, computer
            networking, machine learning etc. We are committed to investing in
            new talent worldwide and preparing people to make the most out of
            technology.
          </p>
        </div>
      </div> */}
      {/* <div className="invisible w-full h-full lg:visible lg:bg-[#181818] grid grid-cols-2 gap-4 items-center lg:space-x-10">
        <div className="relative">
          <img
            className=" inset-0 w-full h-full object-cover object-center "
            src="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/TalentWorldwide%2Flinear.png?alt=media&token=4e39ecf2-789e-4adb-9ee8-7f027898e9f9"
          ></img>
        </div>
        <div className="py-[50px]">
          <h1 className="text-white text-[36px] font-semibold ">
            Skills Accelator Training Program
          </h1>
          <p className=" text-white pr-[86px] text-left pt-[25px]">
            Innorik is building a community of talented and diverse
            professionals that are passionate about technology and exceptional
            client service. We are searching for talented students and
            non-students to join an exciting, online training program with no
            out-of-pocket cost to you that will accelerate your career in a
            technology related field. This fast-track technology training
            program bootcamp designed for college grads and graduating college
            seniors with STEM degrees who are seeking to advance their skills
            and prepare for a career in high demand technology areas not limited
            to software development, cloud computing, data analytics, computer
            networking, machine learning etc. We are committed to investing in
            new talent worldwide and preparing people to make the most out of
            technology.
          </p>
        </div>
      </div>

      <div className="visible lg:invisible lg:h-0 h-[614px] md:h-[808px] gap-0 items-center ">
        <div className="">
          <img
            className="object-cover  h-[150px] md:h-[250px] w-[100%] "
            src="https://images.unsplash.com/photo-1617791160536-598cf32026fb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80"
          ></img>
        </div>
        <div className=" py-[36px] md:pt-[px]  bg-[#181818] text-center">
          <h1 className="text-white text-[18px] md:text-[24px] font-semibold ">
            Skills Accelator Training Program
          </h1>
          <p className=" text-[14px] md:text-[18px] text-white pt-[30px] pr-[27px] pl-[27px]  md:pt-[30px] md:pr-[40px] md:pl-[40px]  text-center">
            Innorik is building a community of talented and diverse
            professionals that are passionate about technology and exceptional
            client service. We are searching for talented students and
            non-students to join an exciting, online training program with no
            out-of-pocket cost to you that will accelerate your career in a
            technology related field. This fast-track technology training
            program bootcamp designed for college grads and graduating college
            seniors with STEM degrees who are seeking to advance their skills
            and prepare for a career in high demand technology areas not limited
            to software development, cloud computing, data analytics, computer
            networking, machine learning etc. We are committed to investing in
            new talent worldwide and preparing people to make the most out of
            technology.
          </p>
        </div>
      </div> */}
    </div>
  );
}
