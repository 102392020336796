import React from "react";
// import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// import "./component.css";
// const Indust = [
//   {
//     id: 1,
//     nav: "/industries/healthcare",
//     title: "Healthcare",
//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
//   },
//   {
//     id: 2,
//     nav: "/industries/manufacturing",

//     title: "Manufacturing",
//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
//   },
//   {
//     id: 3,
//     title: "Insurance",
//     nav: "/industries/insurance",

//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
//   },
//   {
//     id: 4,
//     title: "Fintech",
//     nav: "/industries/fintech",

//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
//   },
//   {
//     id: 5,
//     title: "Retail",
//     nav: "/industries/retail",

//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
//   },
//   {
//     id: 6,
//     nav: "/industries/transportation",

//     title: "Transportation & Logistics",

//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
//   },
//   {
//     id: 7,
//     nav: "/industries/professional-services",

//     title: "Professional services",
//     img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
//   },
// ];

export default function MobileTab(props) {
  // const settings = {
  //   infinite: true,
  //   autoplay: true,
  //   arrows: false,
  //   slidesToShow: 2,
  //   autoplaySpeed: 2000,
  //   speed: 500,
  //   dots: true,
  //   appendDots: (dots) => (
  //     <div>
  //       <ul> {dots} </ul>
  //     </div>
  //   ),
  // };
  const data = props.data;

  const renderSlides2 = () =>
    data.map((num, index) => (
      <div key={index}>
        <div>
          <p className="text-[16px] text-center text-[#222222] pt-[0px]">
            {num.desc}
          </p>
          <p className="text-[20px] pt-[20px] text-center text-[#22222]">
            {num.title}
          </p>
        </div>
      </div>
    ));

  return (
    <div className="bg-blue-100 py-[80px]">
      <div className=" max-w-[1500px] lg:px-[85px]  md:px-[40px] px-[28px] mx-auto grid grid-cols-1 md:grid-row lg:grid-cols-4 lg:w-full">
        <div className="md:col-span-1 my-auto pb-5  md:pb-0">
          <p className="leading-normal	 m-auto text-[24px] sm:text-[26px] md:text-[36px] xl-[36px] 2xl:text-3xl font-[500] text-center md:text-center md:pb-[20px] lg:text-left">
            {props.title}
          </p>
          <p className="leading-normal pr-4 font-regular text-[#222222]	 m-auto text-xl sm:text-[16px] md:text-[18px]  lg:[18px] xl:text-[18px] 2xl:text-[18px] pt-[20px] text-center md:text-left ">
            {props.desc}
          </p>
        </div>
        <div className="invisible md:visible lg:visible h-0 md:h-auto lg:h-auto  md:col-span-3 pl-[20px]">
          {/* <Slider {...settings}>{renderSlides()}</Slider> */}
        </div>
        <div className="hiddden lg:block  md:col-span-3 pl-[20px]">
          <Slider
            infinite={true}
            autoplay={false}
            arrows={false}
            slidesToShow={1}
            autoplaySpeed={2500}
            speed={500}
            dots={true}
          >
            {renderSlides2()}
          </Slider>
        </div>
      </div>
    </div>
  );
}
