import React from "react";
import Banner from "../../../../components/Banner";
import Industries from "../../../../components/NewIndustries";
import Offerings from "../Php/Offerings";
// import Overview from "../Php/Overview";
// import Services from "../Php/Services";
import WhyChooseUs from "../Php/WhyChooseUs";
import Advantages from "../Angular/Advantages";
import OurDevelopers from "../../Platforms/Magento/OurDevelopers";
import TechTab from "./TechTab";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import MobileTechTab from "./MobileTechTabs";
import {
  // mobileData2,
  // advantageData,
  offeringData,
  Data2,
  servicesData,
  Indust,
} from "../../../../data/dotnet";
import { netDevelopers } from "../../../../data/programming";
import { mobileData } from "../../../../data/about";

// console.log(mobileData2[0].details);
export default function DotNet() {
  return (
    <>
      <Banner
        button="Talk to Experts"
        img="/images/technologies/programming/net/net-banner.jpeg"
        title=".NET Development Services"
        desc="Mobilize enterprise-grade solutions through our complete suite of .NET software development services."
      />
      <Overview
        textColor="bg-[#f9f9f9] text-[#222222]"
        title=".NET Overview"
        desc="Microsoft .Net Framework is a software development framework that is used to create and execute windows applications. Despite being a windows-based framework, it can also be used to create iOS and Android apps. It's a flexible framework that can be used to create websites, desktop apps, web programs, web portals, and video games. At Innorik, our certified .Net developers have extensive experience in any industry domain using Microsoft technologies and they can develop any application of any complexity."
        img="/images/technologies/programming/net/net-overview.jpeg"
        img2="/images/technologies/programming/net/net-overview-mobile.jpeg"
        alt="python"
      />
      {/* <Overview
        color=""
        textColor="text-[#222222]"
        title=".NET Overview "
        desc="When it comes to creating a dynamic single-page app, AngularJS is one of the most popular front-end frameworks. Angular is a popular JavaScript framework that was created with the goal of simplifying scripts, addressing single-page application (SPA) difficulties, and increasing interactivity. AngularJS is the greatest framework for creating interactive single-page applications, but it can be used to create any form of web application."
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fdot-net-laptop-overview.jpeg?alt=media&token=2a4431c9-45a0-41df-93a3-c39b4a2669af"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fdot-net-laptop-overview.jpeg?alt=media&token=2a4431c9-45a0-41df-93a3-c39b4a2669af"
      /> */}
      <Advantages
        total="6"
        head="Our .NET development offerings"
        data={offeringData}
      />
      {/* <Services
        title="Advantages of .NET Services"
        // desc="AngularJS is a structural framework for dynamic web apps. It lets you use HTML as your template language and lets you extend HTML's syntax to express your application's components clearly and succinctly"
        img=""
        data={servicesData}
      /> */}
      <div className="hidden md:block lg:px-[85px] max-w-[1500px] mx-auto py-[50px]">
        <div>
          <h1 className="text-[36px] pb-[20px]">Our .Net Technologies Stack</h1>
        </div>
        <TechTab />
      </div>
      <div className="block md:hidden lg:px-[85px] max-w-[1500px] mx-auto py-[50px]">
        <div>
          <h1 className="text-[26px] text-center pb-[20px]">
            Our Technologies Stack
          </h1>
        </div>
        <MobileTechTab title="" desc="" data={mobileData} />
      </div>
      <Offerings
        title="Advantages of .NET application services"
        desc=""
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fillustrations%2Fpana.svg?alt=media&token=d9404f06-e66e-4da6-b796-ae9cf19e323d"
        data={servicesData}
      />
      <OurDevelopers
        banner="true"
        devs="true"
        profile="true"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our expertise"
        desc="Innorik has a large team of .NET developers who have extensive experience with the language due to working with various project domains such as banking, telecommunication, healthcare, insurance systems, and with different companies. Our developers have experience ranging from 1-20 years.  "
        button="Hire a .NET Developer"
        data={netDevelopers}
      />
      {/* <OurDevelopers
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fnet-developers.jpeg?alt=media&token=2380e077-24fd-4fe9-b02a-f715c9788c52"
        title="Our .NET Experts"
        desc="Innorik has a large team of .NET developers who have very good experience with the language from working with various project domains such as banking, telecommunication, healthcare, insurance systems and with different companies. Our developers have experience ranging from 1year to 20 years."
        button="Hire .NET Expert"
      /> */}
      <Industries
        title="Industies Served"
        desc="We are international company and we deliver technology solutions to clients globally."
        data={Indust}
      />
      <WhyChooseUs title="Why choose us?" desc="" data={Data2} />
    </>
  );
}
