const advantageData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Icons%2Fidea.png?alt=media&token=172c733f-269c-4584-b8ca-6fd7e228b6e9",
    title: "Client-side Execution",
    desc: "Javascript codes needs not to be submitted to server for processing and this reduces load time significantly",
  },
  {
    id: 2,
    img: "",
    title: "Interface Interactivity",
    desc: "JavaScript's speed is a significant advantage. The user interface interaction of JavaScript is fully utilized by our professional JavaScript developers",
  },
  {
    id: 3,
    img: "",
    title: "Responsive Design",
    desc: " Provision of the greatest experience across all platforms by unifying HTML, CSS, and JavaScript in a single code base.",
  },
  {
    id: 4,
    img: "",
    title: "Platform Independent",
    desc: "JavaScript frameworks are simple to install and maintain, regardless of platform.",
  },
  {
    id: 4,
    img: "",
    title: "Versatile and Updated",
    desc: "JavaScript apps work in a variety of browsers and operating systems.",
  },
  {
    id: 4,
    img: "",
    title: "Offline Support",
    desc: "You can enjoy responsive and usable app features even if you don't have an active internet connection.",
  },
];
const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fillustrations%2Fsoftware-development-model.svg?alt=media&token=4da507df-acd5-497d-a87a-90d8263a16ba",
    title: "Object-oriented software development model",
    desc: "Perhaps there’s no other technology company with such a full and coherent ecosystem that checks off all boxes for engineers, from IDEs and frameworks to cloud services.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fillustrations%2Fcross-platform.svg?alt=media&token=e5df17e4-d810-4501-ac1b-748a0be94034",
    title: "Cross-platform design of .NET Core",
    desc: "NET Core is a cross-platform implementation that runs on macOS, Windows, and Linux.",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fillustrations%2Fflexible-development.svg?alt=media&token=35b60667-a273-4b2d-afac-c5b544d4c887",
    title: "Flexible deployment and easy maintenance",
    desc: "The deployment itself is as easy as copying a folder.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fillustrations%2Flarge-community.svg?alt=media&token=5d8e845c-fbb6-4b18-af27-00fa04b44beb",
    title: "Large Community",
    desc: "One of the most widely used web frameworks on the planet. It's also free and open source.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fillustrations%2Funiversal-standards.svg?alt=media&token=9e1aaca4-e8e7-424d-a714-c5a540de9bb4",
    title: "Universal .NET Standard",
    desc: "Graphics rendering, database interaction, and XML manipulations are all shared throughout the .NET Framework, .NET Core, and Xamarin.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fillustrations%2Fhigh-throughput-and-productivity.svg?alt=media&token=fc3fd621-5f86-46db-a97a-353763a36923",
    title: "High throughput and productivity",
    desc: "From the very beginning, .NET has relied on a just-in-time compiler (JIT) to translate Intermediate Language (IL) code to optimized machine code.",
  },
  {
    id: 7,

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fillustrations%2Fincreased-reliability.svg?alt=media&token=0d5de35c-0d47-4dd5-878d-2e7e69b0c2d1",
    title: "Increased reliability",
    desc: " .NET is one of the most trusted frameworks due to its stable and reliable performance on Microsoft Windows Server.",
  },
  {
    id: 8,

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Fillustrations%2Fsoftware-development-model.svg?alt=media&token=4da507df-acd5-497d-a87a-90d8263a16ba",
    title: "Enhanced security",
    desc: "Developers have greater control over security in the .NET framework due to granular controls over applications and resources. They can implement robust authentication, authorization, and cryptographic routines using easy-to-use built-in tools.",
  },
];
const Data2 = [
  {
    id: 1,
    title: "Expertise in powerful .NET migration",
  },
  {
    id: 2,
    title: ".NET consultants aligned as per your time zone",
  },
  {
    id: 3,
    title: "Seamless communication",
  },
  {
    id: 4,
    title: "Transparent development process",
  },
  {
    id: 5,
    title: "Dedicated .NET Developers",
  },
  {
    id: 6,
    title: "Rapid product development and quick deployment",
  },
  {
    id: 8,
    title: "A pool of smart and experienced developers",
  },
  {
    id: 9,
    title: "Workflow management with top project management tools",
  },
  {
    id: 9,
    title: "Reduced cost to 50% of our competitors",
  },
];
const Indust = [
  {
    id: 1,
    nav: "/industries/healthcare",
    title: "Healthcare",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fhealthcare.jpg?alt=media&token=ef2098e0-7dbb-4bc5-81fe-3f2c99f44f20",
  },
  {
    id: 2,
    nav: "/industries/manufacturing",

    title: "Manufacturing",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fmanufacturing.jpg?alt=media&token=94fcb51e-eba5-4646-8bb9-52221ad04b67",
  },
  {
    id: 3,
    title: "Insurance",
    nav: "/industries/insurance",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Finsurance.jpg?alt=media&token=f8930880-230b-4467-af78-4756e2d37f8c",
  },
  {
    id: 4,
    title: "Fintech",
    nav: "/industries/fintech",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ffintech.jpg?alt=media&token=371e3d38-a117-470c-8788-b56306cf5384",
  },
  {
    id: 5,
    title: "Retail",
    nav: "/industries/retail",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fretail.jpg?alt=media&token=3664e142-1148-488d-9f55-96220a583874",
  },
  {
    id: 6,
    nav: "/industries/transportation",

    title: "Transportation & Logistics",

    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Ftransport.jpg?alt=media&token=3a11d414-242f-4af8-bcfa-6c041244b4b5",
  },
  {
    id: 7,
    nav: "/industries/professional-services",

    title: "Professional services",
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/industries%2Fprofessionalservices.jpg?alt=media&token=da68fd3f-f47a-41ac-b326-6b26f859be87",
  },
];

const offeringData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Ficons%2Fbackend.svg?alt=media&token=80996e94-e76d-4f39-8b07-8916cdc3f84c",
    title: "ASP .NET Application Development",
    desc: "For robust and scalable ASP.NET apps and backend development, Innorik is a trusted ASP.NET application development firm.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Ficons%2Fcore.svg?alt=media&token=6830911d-fd34-4d7f-933d-78b7fc75555e",
    title: ".NET Core Development",
    desc: "To create user-friendly and dynamic web apps, our.NET developers use.NET Core and other front-end technologies.",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Ficons%2Fmigration.svg?alt=media&token=9c100464-f8c1-4ec5-9f6c-19c0080c9f95",
    title: ".NET Migration Services",
    desc: "Migrate your existing application to the.NET framework and use the tools to update the version of your program.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Ficons%2Fcloud-computing.svg?alt=media&token=bad3e4f1-5142-4741-a381-d07fb4543b58",
    title: ".NET Cloud Deployment",
    desc: "To help you exploit real-time data for.NET applications, we provide cloud-native solutions leveraging Azure, AWS, and Google Cloud.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Ficons%2Fcross-platform.svg?alt=media&token=e17eb052-fdf2-457a-b620-a5bf4f7b05bd",
    title: "Cross-Platform Application",
    desc: "We combine.NET with Xamarin to create sophisticated, cross-platform mobile applications that help you reach more customers.",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2Ficons%2Fteam.svg?alt=media&token=9e1cbdcb-1de9-4aa5-9e80-dba434ab0694",
    title: "Dedicated .NET Developers ",
    desc: "Get a team of dedicated.NET developers to work as your in-house team and develop solutions that meet your business needs.",
  },
];
const mobileData = [
  {
    id: 1,
    title: "Decreased Operational Costs",
    desc: "He flexible nature of iGlimpse ERP makes it very easy to integrate with other common systems like Oracle E-Business Suite, Microsoft Dynamics ERP and Computron Financial System. These systems have been integrated with iGlimpse out of the box without the need to do any further customization to achieve integration requirements, except in very extreme cases",
  },
  {
    id: 2,
    title: "Performance Monitoring",
    desc: "It ensures that the system is able to capture 360 degrees view of all transactions on the system and report on them as such, giving management team members the ability to monitor each and every task and assignments just at the click of a button, as and when they happen.",
  },
  {
    id: 3,
    title: "Availability",
    desc: "This system has accurate and timely access to reliable information, has the ability to share information between all components of the organization and you can be able to login from anywhere and anytime.",
  },
  {
    id: 4,
    title: "Efficiency",
    desc: "Optimization Of Business Processes - The system eliminates repetitive processes and greatly reduces the need to manually enter information. The system will also streamline business processes and makes it easier and more efficient to collect data.",
  },
  {
    id: 5,
    title: "Real time information",
    desc: "Innoriks' one-of-a-kind custom ERP software allows businesses to generate real-time information reports. The ability to access real-time data reduces the risk of data duplication and improves the workflow of all departments.",
  },
  {
    id: 6,
    title: "integrity and security",
    desc: "A rise in unnecessary operating costs might be caused by misplaced, inaccurate, or out-of-date data. Through effective data movement and regular data duplication analysis, iGlimpse ERP increases data integrity.",
  },
];
const mobileData2 = [
  {
    tab: "Framework",
    details: [
      {
        id: 1,
        img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2FUWP-apps.svg?alt=media&token=5b873616-9453-4918-be45-c3900a0d2f09",
        desc: "UWP Apps",
        alt: "UWP Apps",
      },
      {
        id: 1,
        img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2FUWP-apps.svg?alt=media&token=5b873616-9453-4918-be45-c3900a0d2f09",
        desc: "UWP Apps",
        alt: "UWP Apps",
      },
    ],
  },
  {
    tab: "Security",
    details: [
      {
        id: 1,
        img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2FUWP-apps.svg?alt=media&token=5b873616-9453-4918-be45-c3900a0d2f09",
        desc: "UWP Apps",
        alt: "UWP Apps",
      },
      {
        id: 1,
        img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FDotNet%2FtechStack%2Ffrontend%2FUWP-apps.svg?alt=media&token=5b873616-9453-4918-be45-c3900a0d2f09",
        desc: "UWP Apps",
        alt: "UWP Apps",
      },
    ],
  },
];

export {
  mobileData,
  mobileData2,
  advantageData,
  offeringData,
  Data2,
  Indust,
  servicesData,
};
