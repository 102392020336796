import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./App.css";
// import Navbar from "./components/Navbar/Navbar";
import TalentWorldWide from "./pages/TalentWorldWide/talent-world-wide";
import Parther from "./pages/Partner/partner";
// import Test from "./components/Test";
// import PartnerCompanies from "./pages/Partner/PartnerCompanies";
import CultureAndValues from "./pages/CultureAndValues/culture-and-values";
import EngagementModule from "./pages/EngagementModule/engagement-module";
import Career from "./pages/Career/career";
import About from "./pages/About/about";
import CloudEnablement from "./pages/CloudEnablement/cloud-enablement";
import Devops from "./pages/Devops/devops";
import CloudInfrastructure from "./pages/CloudInfrastructure/cloud-infrastructure";
import CloudConsulting from "./pages/CloudConsulting/cloud-consulting";
import CustomSoftware from "./pages/CustomSoftware/custom-software";
import BusinessOutsourcing from "./pages/BusinessOutsourcing/business-outsourcing";
import ItConsulting from "./pages/ItConsulting/it-consulting";
import WebDevelopment from "./pages/WebDevelopment/web-development";
import MobileAppDevelopment from "./pages/MobileApp/mobile-app-development";
import Design from "./pages/Design/design";
import ResponsiveDesign from "./pages/ResponsiveDesign/responsive-design";
import CloudApplicationIntegration from "./pages/CloudAppliationIntegration/cloud-application-integration";
import CloudApplicationIntegrationTest from "./pages/CloudAppliationIntegration/cloud-application-integration-test";

import ApplicationMigration from "./pages/ApplicationMigration/application-migration";
import Testing from "./pages/Testing/Testing";
import Fintech from "./pages/Industries/Fintech/fintech";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Education from "./pages/Industries/Education/education";
import Retail from "./pages/Industries/Retail/retail";
import ProfessionalServices from "./pages/Industries/ProfessionalServices/professional-services";
import Insurance from "./pages/Industries/Insurance/insurance";
import RealEstate from "./pages/Industries/RealEstate/realestate";
import Manufacturing from "./pages/Industries/Manufacturing/manufacturing";
import HealthCare from "./pages/Industries/HealthCare/healthcare";
import Transportation from "./pages/Industries/Transportation/transportation";
import Php from "./pages/Technologies/Programming/Php/php";
import ReactNative from "./pages/Technologies/Programming/ReactNative/react-native";
import Python from "./pages/Technologies/Programming/Python/python";
import GoLang from "./pages/Technologies/Programming/GoLang/go-lang";
import Java from "./pages/Technologies/Programming/Java/java";
import JavaScript from "./pages/Technologies/Programming/JavaScript/java-script";
import Cpp from "./pages/Technologies/Programming/cpp/cpp";
import Angular from "./pages/Technologies/Programming/Angular/angular";
import BigData from "./pages/Technologies/LatestThinking/BigData/big-data";
import Iot from "./pages/Technologies/LatestThinking/Iot/iot";
import Blockchain from "./pages/Technologies/LatestThinking/Blockchain/blockchain";
import ArtificialIntellegence from "./pages/Technologies/LatestThinking/ArtificialIntellegence/artificial-intellegence";
import Magento from "./pages/Technologies/Platforms/Magento/magento";
import MicrosoftDynamics from "./pages/Technologies/Platforms/MicrosoftDynamic365/microsoft-dynamics-365";
import ServicesNow from "./pages/Technologies/Platforms/ServicesNow/services-now";
import Salesforce from "./pages/Technologies/Platforms/SalesForce/sales-force";
import PowerBi from "./pages/Technologies/Platforms/PowerBi/power-bi";
import ComputerVision from "./pages/Technologies/LatestThinking/ComputerVision/computer-vision";
import VirtualReality from "./pages/Technologies/LatestThinking/VirtualReality/virtual-reality";
import NodeJs from "./pages/Technologies/Programming/NodeJs/nodejs";
import ERP from "./pages/Technologies/ERP/erp";
import DotNet from "./pages/Technologies/Programming/DotNet/dot-net";
import Home from "./pages/Home/home";
import Footer from "./components/Footer/Footer";
import TermsAndConditions from "./pages/TermsAndConditions/terms-and-conditions";
import ContactUs from "./pages/Contact/contact-us";
import DotnetJob from "./pages/Career/jobs/dotnet";
import ApplyForJob from "./pages/Career/apply-for-job";
import Nav from "./components/Navbar/TestNavBar";
import Navbar from "./components/Navbar/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import PageNotFound from "./pages/PageNotFound/page-not-found";
import JavaJob from "./pages/Career/jobs/java";
import DevopsJob from "./pages/Career/jobs/devops";
ReactDOM.render(
  <Router>
    <ScrollToTop />
    <div className=" invisible lg:visible xl:visible 2xl:visible">
      <Navbar />
    </div>
    <div className="h-auto lg:h-0 visible lg:invisible">
      <Nav />
    </div>

    <Routes>
      {/* <Route
        path="/"
        element={<Navigate replace to="/" />}
      /> */}
      <Route path="/" element={<Home />} />

      <Route path="/talent-world-wide" element={<TalentWorldWide />} />
      <Route path="/partnership" element={<Parther />} />
      <Route path="/culture-and-values" element={<CultureAndValues />} />
      <Route path="/engagement-module" element={<EngagementModule />} />
      <Route path="/career" element={<Career />} />
      <Route path="/about" element={<About />} />
      <Route path="/cloud-enablement" element={<CloudEnablement />} />
      <Route path="/devops" element={<Devops />} />
      <Route path="/cloud-infrastructure" element={<CloudInfrastructure />} />
      <Route path="/cloud-consulting" element={<CloudConsulting />} />
      <Route path="/custom-software" element={<CustomSoftware />} />
      <Route
        path="/business-process-outsourcing"
        element={<BusinessOutsourcing />}
      />
      <Route path="/web-development" element={<WebDevelopment />} />
      <Route path="/mobile-development" element={<MobileAppDevelopment />} />
      <Route path="/ui-ux-design" element={<Design />} />
      <Route path="/responsive-design" element={<ResponsiveDesign />} />
      <Route
        path="/cloud-application-integration"
        element={<CloudApplicationIntegration />}
      />
      <Route
        path="/cloud-application-integration-test"
        element={<CloudApplicationIntegrationTest />}
      />

      <Route path="/application-migration" element={<ApplicationMigration />} />
      <Route path="/testing" element={<Testing />} />
      <Route path="/it-consulting" element={<ItConsulting />} />

      <Route path="/industries/fintech" element={<Fintech />} />
      <Route path="/industries/education" element={<Education />} />
      <Route path="/industries/retail" element={<Retail />} />
      <Route
        path="/industries/professional-services"
        element={<ProfessionalServices />}
      />
      <Route path="/industries/insurance" element={<Insurance />} />
      <Route path="/industries/real-estate" element={<RealEstate />} />
      <Route path="/industries/manufacturing" element={<Manufacturing />} />
      <Route path="/industries/healthcare" element={<HealthCare />} />
      <Route path="/industries/transportation" element={<Transportation />} />

      {/* Technologies */}
      <Route path="/technologies/php" element={<Php />} />
      <Route path="/technologies/react-native" element={<ReactNative />} />
      <Route path="/technologies/python" element={<Python />} />
      <Route path="/technologies/golang" element={<GoLang />} />
      <Route path="/technologies/java" element={<Java />} />
      <Route path="/technologies/javascript" element={<JavaScript />} />
      <Route path="/technologies/cpp" element={<Cpp />} />
      <Route path="/technologies/angular" element={<Angular />} />
      <Route path="/technologies/nodejs" element={<NodeJs />} />
      <Route path="/technologies/dot-net" element={<DotNet />} />

      {/* Latest Thinking */}
      <Route path="/latest-thinking/big-data" element={<BigData />} />
      <Route path="/latest-thinking/iot" element={<Iot />} />
      <Route path="/latest-thinking/blockchain" element={<Blockchain />} />
      <Route
        path="/latest-thinking/artificial-intellegence"
        element={<ArtificialIntellegence />}
      />
      <Route
        path="/latest-thinking/computer-vision"
        element={<ComputerVision />}
      />
      <Route
        path="/latest-thinking/virtual-reality"
        element={<VirtualReality />}
      />

      {/* Platforms */}
      <Route path="/platforms/magento" element={<Magento />} />
      <Route
        path="/platforms/microsoft-dynamics"
        element={<MicrosoftDynamics />}
      />
      <Route path="/platforms/services-now" element={<ServicesNow />} />
      <Route path="/platforms/salesforce" element={<Salesforce />} />
      <Route path="/platforms/power-bi" element={<PowerBi />} />

      <Route path="/technologies/erp" element={<ERP />} />

      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/career" element={<Career />} />
      <Route path="/career/job/dot-net" element={<DotnetJob />} />
      <Route path="/career/job/java" element={<JavaJob />} />
      <Route path="/career/job/devops" element={<DevopsJob />} />

      <Route path="/career/jobs/apply" element={<ApplyForJob />} />
      <Route path="*" element={<PageNotFound />} />
      {/* <Route path="/" element={}/> */}
    </Routes>
    <Footer />
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
