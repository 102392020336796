import React from "react";
import Banner from "../../../../components/Banner";
// import WhyChoose from "../../../../components/WhyChoose";
import { phpDevelopers } from "../../../../data/programming";
import Overview from "../../LatestThinking/ArtificialIntellegence/Overview";
import OurDevelopers from "../../Platforms/Magento/OurDevelopers";
import Offerings from "./Offerings";
// import Overview from "./Overview";
import Services from "./Services";
import WhyChooseUs from "./WhyChooseUs";

const Data2 = [
  {
    id: 1,
    title: "Highly skilled in-house development team ",
  },
  {
    id: 2,
    title: "Cost-effective engagement model ",
  },
  {
    id: 3,
    title: "Comprehensive quality assurance ",
  },
  {
    id: 4,
    title: "Mobile friendly UI ",
  },
  {
    id: 5,
    title: "Maximum Speed and flexibility ",
  },
  {
    id: 6,
    title: "Agile software development ",
  },
  {
    id: 8,
    title: "A pool of smart and experienced PHP developers ",
  },
  {
    id: 9,
    title: "360-degree Requirement Analysis ",
  },
  {
    id: 10,
    title: "Reduced cost to 50% of our competitors ",
  },
  // {
  //   id: 11,
  //   title: "360-degree Requirement Analysis",
  // },
  // {
  //   id: 12,
  //   title: "Competitive project prices to suit everyone",
  // },
];

const offeringData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FICONS%2Fshield.svg?alt=media&token=db4cede8-82a2-4acc-a900-15794b94ee1e",
    title: "High level of security",
    desc: "PHP enables the creation of a hacker-proof web application, which protects against harmful attacks and unauthorized entries.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FICONS%2Feasy.svg?alt=media&token=8acda1de-1c8b-40e9-9c77-0a2a10a6b6d1",
    title: "Easy execution of any project",
    desc: "PHP-based business web app can be quickly built in a short amount of time and on a limited budget. ",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FICONS%2Flow-price.svg?alt=media&token=26a21660-2cf0-497a-910f-0db5ef3de554",
    title: "Reducing web development cost",
    desc: "PHP is not only free to download and use, but it also works on a wide range of operating systems and platforms ",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FICONS%2Fspeedometer.svg?alt=media&token=e1ac7b11-fdea-49f4-8669-a931c1e18dcb",
    title: "Excellent performance of PHP code",
    desc: "Web applications built with PHP are high-performing, fast, and secure. PHP code works effectively in a variety of settings and on a variety of devices.",
  },
];
const servicesData = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FIllustrations%2FPHP-portalDevelopment.svg?alt=media&token=9e243d0c-9862-4bba-a08e-e6827b2b851c",
    title: "PHP Application Development",
    desc: "For our clients, we produce high-performing, customizable, secure, scalable, and rich-UI business apps in a shorter time frame",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FIllustrations%2FPHP-applicationDevelopment.svg?alt=media&token=e1b7a83e-5914-468a-aa67-bd8eab0e4e75",
    title: "PHP Portal Development",
    desc: "From developing architecture and GUI to database planning and execution, we also create faster-loading dynamic websites with large databases",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FIllustrations%2FPHP-Ecommerce.svg?alt=media&token=5ed9080e-2d39-4bef-b1e3-c232826553f7",
    title: "Ecommerce Development ",
    desc: "We create scalable online companies with PHP-based frameworks like Magento and custom ecommerce sites with Laravel.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FIllustrations%2FPHP-Consulting-services.svg?alt=media&token=131d211e-7daa-489f-b2ed-07c3473ef622",
    title: "PHP Consulting Services",
    desc: "Depending on the project parameters and business vertical, we deliver cost-effective end-to-end PHP consultancy all over the world.",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FIllustrations%2FPHP-Migaration-Update.svg?alt=media&token=8e374bb1-8086-48bf-bddb-db45f7903987",
    title: "Migration and Upgrade Solutions",
    desc: "For enhanced security, customisation, and flexibility, we aid our clients in migrating PHP websites to cloud servers.",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2FIllustrations%2FPHP-support-maintaince.svg?alt=media&token=d6f4e6e3-6bf5-4b00-ae57-bb36ed7e467d",
    title: "PHP Support & Maintenance",
    desc: "We provide round-the-clock support for your PHP website or app, as well as regular updates. Our professionals examine your current code and make recommendations for rapid enhancements.",
  },
];
export default function Php() {
  return (
    <>
      <Banner
        button="Talk to Experts"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp.jpeg?alt=media&token=e09c71b3-e43e-4c9a-8616-bafa423ddcd0"
        title="PHP Development and Consulting Services"
        desc="Building result-oriented PHP solutions with interactive applications, websites, customized e-commerce solutions, and open-source implementation."
      />
      <Overview
        textColor="text-white bg-[#69A3C9]"
        title="PHP, simple to build, easy to support "
        desc="The PHP programming language is a commonly used open-source platform and can be thought of as a universal language for constructing custom web applications and web portals. PHP is now used by popular social platforms and services such as Facebook, Flickr, Tumblr, Wikipedia, and Digg.  At Innorik, we put together a handful of amazing ideas to construct extraordinary web systems using a variety of PHP frameworks and organized techniques. We develop a PHP solution by first studying the relevant market and then laying out the venture's structure. We keep the lines of communication open throughout the development process so that the client is always aware of the state of his project."
        img="/images/technologies/programming/php/php-overview.jpeg"
        img2="/images/technologies/programming/php/php-overview-mobile.jpeg"
        alt="php"
      />
      {/* <Overview
        color="bg-white"
        textColor="text-[#222222]"
        title="PHP - Simple to build. Easy to support"
        desc="At Innorik, we put together a handful of amazing ideas to construct extraordinary web systems using a variety of PHP frameworks and organized techniques. We develop a PHP solution by first studying the relevant market and then laying out the venture's structure. We keep the lines of communication open throughout the development process so that the client is always aware of the state of his project. Our developers are always eager to learn new things to expand their knowledge, so we keep our doors open for trending technology"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-overview.jpeg?alt=media&token=53b6defb-e3cc-4c12-a172-69bbd62d008e"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-overview.jpeg?alt=media&token=53b6defb-e3cc-4c12-a172-69bbd62d008e"
      /> */}
      <Offerings
        title="Core advantages of PHP development"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technologies%2FPages%2Fphp%2Fofferings.png?alt=media&token=d3fd7a68-2751-45e1-b71d-5e9fcce9184b"
        data={offeringData}
      />
      <Services
        title="We offer a variety of PHP development services."
        desc=""
        img=""
        data={servicesData}
      />
      <OurDevelopers
        banner="true"
        devs="false"
        profile="false"
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/Technology%2FProgramming%2FPhp%2Fphp-developers.jpeg?alt=media&token=b62c1ca6-e14b-4d59-92d1-a62c4abe094e"
        title="Our expertise"
        desc="Innorik has a large team of PHP developers who have extensive experience with the language due to working with various project domains such as banking, telecommunication, healthcare, insurance systems, and with different companies. Our developers have experience ranging from 1-20 years.."
        button="Hire PHP Expert"
        data={phpDevelopers}
      />
      <WhyChooseUs
        title="Why choose us? "
        data={Data2}
        desc=""
        img="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudApplicationIntegration%2FcloudApplicationIntegrationWhyInnorik.jpg?alt=media&token=6fc3ecdd-478c-4578-8e95-139b86c8bb7f"
        img2="https://firebasestorage.googleapis.com/v0/b/innorik-1c0f9.appspot.com/o/CloudEnablement%2FCloudEnaglementResponsiveWhy.jpg?alt=media&token=81f5808f-8191-4e34-bc90-a7b904263c8e"
      />
    </>
  );
}
